import {
    Card,
    CardHeader,
    Typography,
    CardActionArea,
    Link,
    Button,
    CardMedia,
    Box,
    alpha,
    useTheme,
    styled,
    IconButton,
    Divider,
    InputLabel,
    Select,
    FormControl,
    MenuItem,
    OutlinedInput,
    InputAdornment,
    Autocomplete,
    TextField,
    Stack,
    Tooltip,
    AvatarGroup,
    Avatar,
    MenuList,
    ListItemAvatar,
    ListItemText,
    Grid
  } from '@mui/material';
  
  import { useTranslation } from 'react-i18next';
  import CommentTwoToneIcon from '@mui/icons-material/CommentTwoTone';

  import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
  import FormatListBulletedTwoToneIcon from '@mui/icons-material/FormatListBulletedTwoTone';
  import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { Item, ItemRecommend } from 'src/services/apiService/response-models';
import    Text   from 'src/components/Text';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import cdnService from 'src/services/cdnService';
import { useEffect, useState } from 'react';
import InfoPanel from 'src/components/info-panel';
import _ from 'lodash';
import getItemPrice from 'src/utils/getItemPrice';

  function ItemSectionCard(props:{items:Item[],recommondations:ItemRecommend[], onEdit:(e:ItemRecommend|null)=>void,clearTriggerRef?:string}) {
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();
    const [searchItem, setSearchItem] = useState<Item | null>(null);
    const [selectedRecommend, setSelectedRecommend] = useState<ItemRecommend | null>(null);

    useEffect(()=>{
      if(searchItem){
       let recommend = props.recommondations.find(o=>o.id==searchItem.id);
       if(recommend){
        setSelectedRecommend(recommend);
       }else{
        recommend ={
           itemId : searchItem.id
        }
       }
       setSelectedRecommend(recommend);
       props.onEdit(recommend);
      }
    },[searchItem]);

    useEffect(()=>{
      if(props.clearTriggerRef){
        setSelectedRecommend(null);
        setSearchItem(null)
      }
      // else{
      //   setSelectedRecommend(props.recommondations.find(o=>o.itemId==props.selectedItemId));
      // }

    },[props.clearTriggerRef])
    const isNewRecommend=selectedRecommend && !selectedRecommend.id ? true : false;
 
    const getItemForRecommend=(e:ItemRecommend):Item=>{
      return props.items.find(o=>o.id==e.itemId);
    }

    const renderMenuItem=(e:ItemRecommend)=>{
      const item=getItemForRecommend(e);
      if(!item)
        return (<div></div>);

      return (<MenuItem key={item.id}
        onClick={()=>{
          setSelectedRecommend({...e});
          props.onEdit({...e});
        }}
        sx={{
          p: 2.5,
          borderRadius: 0,
          background:`${selectedRecommend?.id==e.id ? theme.colors.primary.lighter:null}`
        }}
        divider
      >
        <ListItemAvatar
          sx={{
            mr: 1.5,
            display: 'flex',
            alignItems: 'center',
            minWidth: 0
          }}
        >
           <Avatar
          variant="rounded"
          sx={{
            width: 104,
            height: 104
          }}
          src={cdnService.toImageUrl(item.featureImage)}
        />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography gutterBottom variant="h4">
              {t(item.name)}
            </Typography>
          }
          secondary={<Grid container>
            <Grid item xs={12}>
            <Text color="success">${getItemPrice(item)}</Text>
            </Grid>
            <Grid item xs ={12}>
          {(e.categories?.length>0 || e.itemIds?.length>0) ?  <Box display="flex" alignItems="center">
            <Text color="info">
                Recommendations
              </Text>
             
              {e.categories?.length>0 &&   <Typography
                sx={{
                  pl: 0.2
                }}
                component="span"
              variant="subtitle2"
              >
                | {t(e.categories.length + ' Category')}
              </Typography>}
             {e.itemIds?.length>0 && <Typography
              sx={{
                pl: 0.5
              }}
              component="span"
              variant="subtitle2"
            >
              |  {t(e.itemIds.length + ' Item')}
            </Typography>}
            </Box> : 
            <Typography
            sx={{
              pl: 0.2
            }}
            component="span"
          variant="subtitle2"
          >
           {t('No recommendation')}
          </Typography>
            }
           
            </Grid>
          </Grid>}
        />
        <ChevronRightTwoToneIcon
          sx={{
            color: `${theme.colors.info}`,
            opacity: 1
          }}
        />
      </MenuItem>)
    }
    return (
      <Card>
         {/* <Box flex={1} p={2}>
          <BulkActions />
        </Box> */}
        <CardHeader
           
          title={t('Items')}
          subheader="Listing items having recommendation(s)"
        />
        <Divider />
        <Box p={.5}>
        <Autocomplete
        fullWidth
        value={searchItem}
        onChange={(e,newValue:Item)=>{
              const val=newValue ? {...newValue} :null;
              setSearchItem(val);
      }}
      options={props.items}
      autoHighlight
      getOptionLabel={(option:Item) => option.name}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            height="20"
            src={cdnService.toImageUrl(option.featureImage)}
            alt=""
          />
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search an item"
           
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
        </Box>    <Divider />
        <MenuList disablePadding>
         {isNewRecommend && renderMenuItem(selectedRecommend)}
          {props.recommondations.map(o=> renderMenuItem(o) )} 
       {!isNewRecommend && props.recommondations.length==0 && <Box px={.5}>
          <InfoPanel  title='No recommendation' message='No item has recommendation mapping. Search an item and configure recommendation.' />
        </Box>}
      </MenuList>
      
      </Card>
    );
  }
  
  
  export default ItemSectionCard;
  