export function searchData(
    data: any,   // The array in which search operation performed 
    searchText: string,   // Whatever user type in the search bar
    searchFields: string[], // Fields on which search functionality needed 
  ) {
    if (searchText.trim() === '' || !searchFields.length) {
      return data;
    }
  
    const searchLowerCase = searchText.toLowerCase();
  
    const filteredData = data?.filter((item: any) => {
      return searchFields.some((field: string) => {
        const fieldValue = item[field];
  
        if (fieldValue !== undefined && fieldValue !== null) {
          const fieldText = fieldValue.toString().toLowerCase();
          return fieldText.includes(searchLowerCase);
        }
  
        return false;
      });
    });
  
    return filteredData;
  }
  