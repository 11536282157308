
const generateOrderNumber = async (): Promise<string> => {
  try {
    // Retrieve the last order number and date from storage
    const storedOrderNumber = localStorage.getItem('lastOrderNumber');
    const storedDate = localStorage.getItem('lastDate');

    // Get the current date
    const currentDate = new Date().toLocaleDateString();

    // Check if it's a new day
    if (currentDate !== storedDate) {
      // If it's a new day, reset the counter to 401
      localStorage.setItem('lastDate', currentDate);
      localStorage.setItem('lastOrderNumber', '1');
      return `401`;
    } else {
      // If it's the same day, increment the counter
      const nextOrderNumber = parseInt(storedOrderNumber || '0', 10) + 1;
      localStorage.setItem('lastOrderNumber', nextOrderNumber.toString());
      if(nextOrderNumber <= 9){
        return `40${nextOrderNumber}`;
      }else {
        return `4${nextOrderNumber}`;
      }
    }
  } catch (error) {
    // Handle specific errors

    console.error('AsyncStorage error:', error.message);
    // Handle AsyncStorage-specific errors here

    console.error('Unexpected error:', error);
    // Handle other unexpected errors here

    // Rethrow the error to propagate it
    throw error;
  }
};

export default generateOrderNumber;
