import {
  Box,
  Typography,
  Container,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const MainContent = styled(Box)(
  () => `
      height: 100%;
      display: flex;
      flex: 1;
      overflow: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  `
);


function PaymentSuccess() {
  const { t }: { t: any } = useTranslation();
  return (
    <>
      <MainContent>
        <Container maxWidth="md">
          <Box textAlign="center">
            <img alt="404" height={50} src="/static/images/logo.png" />
            <Typography
              variant="h2"
              sx={{
                my: 2
              }}
            >
              {t('Payment success. You can close this window.')}
            </Typography>
          </Box>
        </Container>
      </MainContent>
    </>
  );
}

export default PaymentSuccess;
