import {
  FC,
  ChangeEvent,
  MouseEvent,
  SyntheticEvent,
  useState,
  ReactElement,
  Ref,
  forwardRef,
  useEffect
} from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Grid,
  Slide,
  Divider,
  Tooltip,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  TextField,
  Button,
  Typography,
  Dialog,
  Zoom,
  styled
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import Label from 'src/components/Label';
import BulkActions from './BulkActions';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useSnackbar } from 'notistack';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import { Customer, Employee } from 'src/services/apiService/response-models';
import { deleteEmployee } from 'src/services/apiService';

const DialogWrapper = styled(Dialog)(
  () => `
        .MuiDialog-paper {
          overflow: visible;
        }
  `
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
        background-color: ${theme.colors.error.lighter};
        color: ${theme.colors.error.main};
        width: ${theme.spacing(12)};
        height: ${theme.spacing(12)};
  
        .MuiSvgIcon-root {
          font-size: ${theme.typography.pxToRem(45)};
        }
  `
);

const CardWrapper = styled(Card)(
  ({ theme }) => `
  
    position: relative;
    overflow: visible;
  
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: inherit;
      z-index: 1;
      transition: ${theme.transitions.create(['box-shadow'])};
    }
        
      &.Mui-selected::after {
        box-shadow: 0 0 0 3px ${theme.colors.primary.main};
      }
    `
);

const ButtonError = styled(Button)(
  ({ theme }) => `
       background: ${theme.colors.error.main};
       color: ${theme.palette.error.contrastText};
  
       &:hover {
          background: ${theme.colors.error.dark};
       }
      `
);


interface ResultsProps {
  employee: Employee[];
  onDeleteSuccess: () => void;
}

interface Filters {
  customerType?: string;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const getCustTypeLabel = (customerType: string): JSX.Element => {
  const map = {
    customer: {
      text: 'Customer',
      color: 'info',
    },
    subscriber: {
      text: 'Subscriber',
      color: 'warning',
    },
  };

  const typeInfo = map[customerType] || { text: 'Unknown', color: 'default' };

  const { text, color }: any = typeInfo;

  return <Label color={color}>{text}</Label>;
};



const applyFilters = (employee: Employee[], query: string, filters: Filters): Employee[] => {
  // Apply filtering logic based on query and filters
  const filtered = employee.filter((user) =>
    user.fullName.toLowerCase().includes(query) ||
    user.email.toLowerCase().includes(query) ||
    user.fullName.toLowerCase().includes(query)
  );

  return filtered;
};


const applyPagination = (
  employee: Employee[],
  page: number,
  limit: number
): Employee[] => {
  return employee.slice(page * limit, page * limit + limit);
};

const Results: FC<ResultsProps> = ({ onDeleteSuccess, employee: employee }) => {
  console.log("here", employee);
  const [allCheckboxesChecked, setAllCheckboxesChecked] = useState(false);
  const [areCheckboxesSelected, setAreCheckboxesSelected] = useState(false);

  const [selectedItems, setSelectedUsers] = useState<string[]>([]);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [deleteInProgressIds, setDeleteInProgressIds] = useState<string[]>([]);
  const [idRequestedToDelete, setIdRequestedToDelete] = useState<string>();
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [query, setQuery] = useState<string>('');
  const [filteredCustomers, setFilteredCustomers] = useState<Employee[]>(employee);
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Filters>({
    customerType: null
  });

  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

  const handleSelectOneUser = (
    _event: ChangeEvent<HTMLInputElement>,
    userId: string
  ): void => {
    setSelectedUsers((prevSelected) => {
      if (!prevSelected.includes(userId)) {
        return [...prevSelected, userId];
      } else {
        return prevSelected.filter((id) => id !== userId);
      }
    });

    setSelectedUserIds((prevBulkSelected) => {
      if (prevBulkSelected.includes(userId)) {
        return prevBulkSelected.filter((id) => id !== userId);
      } else {
        return [...prevBulkSelected, userId];
      }
    });
  };

  const handleSelectAllUsers = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      const allUserIds = employee.map((user) => user.id);
      setSelectedUsers(allUserIds);
      setSelectedUserIds(allUserIds);
      setSelectAll(true); 
    } else {
      setSelectedUsers([]);
      setSelectedUserIds([]);
      setSelectAll(false); 
    }
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newQuery = event.target.value.toLowerCase();
    setQuery(newQuery);
  
    // Filter customers based on the new query
    const filtered = applyFilters(employee, newQuery, filters);
    setFilteredCustomers(filtered);
  };
  

  const handlePageChange = (_event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };


  const filteredUsers = applyFilters(employee, query, filters);
  const paginatedUsers = applyPagination(filteredUsers, page, limit);
  const selectedBulkActions = selectedItems.length > 0;
  const selectedSomeUsers =
    selectedItems.length > 0 && selectedItems.length < employee.length;
  const selectedAllUsers = selectedItems.length === employee.length;
  const [selectAll, setSelectAll] = useState(false);

  const [toggleView, setToggleView] = useState<string | null>('table_view');

  const [flag, setFlag] = useState(0);

  const [openCreateCustomerDialog, setOpenCreateCustomerDialog] = useState(false);
  const [customerIdForDialog, setCustomerIdForDialog] = useState<string | null>(null); 

  const handleOpenCreateCustomerDialog = (id: string) => {
    navigate('/' + location.pathname.split('/')[1] + "/manage/employee/edit/" + id);
  }

  useEffect(() => {
    if (flag === 1) {
      console.log("Flag is now 1. You can trigger a rerender or other actions here.");
    }
  }, [flag]);
  const handleViewOrientation = (
    _event: MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    setToggleView(newValue);
  };

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleConfirmDelete = (id: string) => {
    setIdRequestedToDelete(id);
    setOpenConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };
useEffect(() => {
  console.log('Selected User IDs:', selectedUserIds);
}, [selectedUserIds]);
 
  const handleDeleteCompleted = async () => {
    setOpenConfirmDelete(false);
    if (idRequestedToDelete) {
      setDeleteInProgressIds([...deleteInProgressIds, idRequestedToDelete]);
      try {
        await deleteEmployee(idRequestedToDelete);
        onDeleteSuccess();
        enqueueSnackbar(t('The user account has been removed'), {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          TransitionComponent: Zoom
        });
      } catch (ex) {
        enqueueSnackbar(t('Failed to delete user account'), {
          variant: 'error'
        });
      }
      setDeleteInProgressIds(deleteInProgressIds.filter(o => o !== idRequestedToDelete));
    }

  };
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        pb={3}
      >
        <Box></Box>
        <ToggleButtonGroup
          sx={{
            mt: { xs: 2, sm: 0 }
          }}
          value={toggleView}
          exclusive
          onChange={handleViewOrientation}
        >
          <ToggleButton disableRipple value="table_view">
            <TableRowsTwoToneIcon />
          </ToggleButton>
          <ToggleButton disableRipple value="grid_view">
            <GridViewTwoToneIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {toggleView === 'table_view' && (
        <Card>
          <Box p={2}>
            {!selectedBulkActions && (
              <TextField
                sx={{
                  m: 0
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                onChange={handleQueryChange}
                placeholder={t('Search by name, email or username...')}
                value={query}
                size="small"
                fullWidth
                margin="normal"
                variant="outlined"
              />
            )}
            {selectedBulkActions && <BulkActions selectedUserIds={selectedUserIds} />}
          </Box>

          <Divider />

          {paginatedUsers.length === 0 ? (
            <>
              <Typography
                sx={{
                  py: 25
                }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {t("We couldn't find any users matching your search criteria")}
              </Typography>
            </>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectAll}
                          indeterminate={selectedSomeUsers}
                          onChange={handleSelectAllUsers}
                        />


                      </TableCell>
                      <TableCell>{t('Username')}</TableCell>
                      <TableCell>{t('Name')}</TableCell>
                      <TableCell>{t('Email')}</TableCell>
                      <TableCell align="center">{t('Posts')}</TableCell>
                      <TableCell>{t('Location')}</TableCell>
                      <TableCell>{t('Role')}</TableCell>
                      <TableCell align="center">{t('Actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedUsers.map((user) => {
                      const isUserSelected = selectedItems.includes(user.id);
                      return (
                        <TableRow hover key={user.id} selected={isUserSelected}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedUserIds.includes(user.id)}
                              onChange={(event) => handleSelectOneUser(event, user.id)}
                              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              value={user.id}
                            />


                          </TableCell>
                          <TableCell>
                            <Typography variant="h5">
                              {user.fullName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <Avatar
                                sx={{
                                  mr: 1
                                }}
                                src={user.image}
                              />
                              <Box>
                                <Link
                                  variant="h5"
                                  component={RouterLink}
                                  to={''}
                                  onClick={() => handleOpenCreateCustomerDialog(user.id)}
                                >
                                  {user.fullName}
                                </Link>
                                <Typography noWrap variant="subtitle2">
                                  {user.email}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Typography>{user.email}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography fontWeight="bold">
                              {/* {user.contactNumber} */}undefined
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{user.address.addressLine}</Typography>
                          </TableCell>
                          <TableCell>
                            {user.userRoles.length > 0 ? (
                              user.userRoles.map((role, index) => (
                                typeof role === 'string' ? (
                                  <span key={index}>{role} &nbsp;</span>
                                ) : (
                                  <span key={index}>{role.value} &nbsp;</span>
                                )
                              ))
                            ) : (
                              'N/A'
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <Typography noWrap>
                              <Tooltip title={t('View')} arrow>
                                <IconButton
                                  onClick={() => handleOpenCreateCustomerDialog(user.id)} 
                                  color="primary"
                                >
                                  <LaunchTwoToneIcon fontSize="small" />
                                </IconButton>

                              </Tooltip>
                              {allCheckboxesChecked || (
                                <Tooltip title={t('Delete')} arrow>
                                  <IconButton
                                    onClick={() => handleConfirmDelete(user.id)}
                                    color="primary"
                                  >
                                    <DeleteTwoToneIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              )}

                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={filteredUsers.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 15]}
                />
              </Box>
            </>
          )}
        </Card>
      )}
      {toggleView === 'grid_view' && (
        <>
          <Card
            sx={{
              p: 2,
              mb: 3
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              {paginatedUsers.length !== 0 && (
                <>
                  <Box display="flex" alignItems="center">
                    <Tooltip
                      arrow
                      placement="top"
                      title={t('Select all users')}
                    >
                      <Checkbox
                        checked={selectedAllUsers}
                        indeterminate={selectedSomeUsers}
                        onChange={handleSelectAllUsers}
                      />
                    </Tooltip>
                  </Box>
                  {selectedBulkActions && (
                    <Box flex={1} pl={2}>
                      <BulkActions selectedUserIds={selectedUserIds} />
                    </Box>
                  )}
                </>
              )}
              {!selectedBulkActions && (
                <TextField
                  sx={{
                    my: 0,
                    ml: paginatedUsers.length !== 0 ? 2 : 0
                  }}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchTwoToneIcon />
                      </InputAdornment>
                    )
                  }}
                  onChange={handleQueryChange}
                  placeholder={t('Search by name, email or username...')}
                  value={query}
                  size="small"
                  margin="normal"
                  variant="outlined"
                />
              )}
            </Box>
          </Card>
          {paginatedUsers.length === 0 ? (
            <Typography
              sx={{
                py: 10
              }}
              variant="h3"
              fontWeight="normal"
              color="text.secondary"
              align="center"
            >
              {t("We couldn't find any users matching your search criteria")}
            </Typography>
          ) : (
            <>
              <Grid container spacing={3}>
                {paginatedUsers.map((user) => {
                  const isUserSelected = selectedItems.includes(user.id);

                  return (
                    <Grid item xs={12} sm={6} md={4} key={user.id}>
                      <CardWrapper
                        className={clsx({
                          'Mui-selected': isUserSelected
                        })}
                      >
                        <Box
                          sx={{
                            position: 'relative',
                            zIndex: '2'
                          }}
                        >
                          <Box
                            px={2}
                            pt={2}
                            display="flex"
                            alignItems="flex-start"
                            justifyContent="space-between"
                          >
                            <IconButton
                              color="primary"
                              sx={{
                                p: 0.5
                              }}
                            >
                              <MoreVertTwoToneIcon />
                            </IconButton>
                          </Box>
                          <Box p={2} display="flex" alignItems="flex-start">
                            <Avatar
                              sx={{
                                width: 50,
                                height: 50,
                                mr: 2
                              }}
                              src={user.image}
                            />
                            <Box>
                              <Box>
                                <Link
                                  variant="h5"
                                  component={RouterLink}
                                  to={''}
                                  onClick={() => handleOpenCreateCustomerDialog(user.id)}
                                >
                                  {user.fullName}
                                </Link>{' '}
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  ({user.contactNumber})
                                </Typography>
                              </Box>
                              <Typography
                                sx={{
                                  pt: 0.3
                                }}
                                variant="subtitle2"
                              >
                                {user.email}
                              </Typography>
                              <Typography
                                sx={{
                                  pt: 1
                                }}
                                variant="h6"
                              >
                                {user.email}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider />
                          <Box
                            pl={2}
                            py={1}
                            pr={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography>
                              <b>
                                {/* {user.contactNumber} */}
                              Undefined</b> {t('posts')}
                            </Typography>
                            <Checkbox
                              checked={isUserSelected}
                              onChange={() => {
                                const updatedSelectedItems = isUserSelected
                                  ? selectedItems.filter((id) => id !== user.id)
                                  : [...selectedItems, user.id];
                                setSelectedUsers(updatedSelectedItems);
                              }}
                            />
                          </Box>
                        </Box>
                      </CardWrapper>
                    </Grid>
                  );
                })}
              </Grid>
              <Card
                sx={{
                  p: 2,
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Box>
                  <Typography component="span" variant="subtitle1">
                    {t('Showing')}
                  </Typography>{' '}
                  <b>{limit}</b> {t('of')} <b>{filteredUsers.length}</b>{' '}
                  <b>{t('users')}</b>
                </Box>
                <TablePagination
                  component="div"
                  count={filteredUsers.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  labelRowsPerPage=""
                  rowsPerPageOptions={[5, 10, 15]}
                />
              </Card>
            </>
          )}
        </>
      )}
      {!toggleView && (
        <Card
          sx={{
            textAlign: 'center',
            p: 3
          }}
        >
          <Typography
            align="center"
            variant="h4"
            fontWeight="normal"
            color="text.secondary"
            sx={{
              my: 5
            }}
            gutterBottom
          >
            {t(
              'Choose between table or grid views for displaying the users list.'
            )}
          </Typography>
        </Card>
      )}

      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              py: 4,
              px: 6
            }}
            variant="h3"
          >
            {t('Are you sure you want to permanently delete this user account')}
            ?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1
              }}
              onClick={closeConfirmDelete}
            >
              {t('Cancel')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
            >
              {t('Delete')}
            </ButtonError>
          </Box>


        </Box>
      </DialogWrapper>
    </>
  );
};

Results.propTypes = {
  employee: PropTypes.array.isRequired
};

Results.defaultProps = {
  employee: []
};

export default Results;
