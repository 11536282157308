import axios, { AxiosResponse } from 'axios';
import { BLUEY_SMOKE_URL, BOMBAY_CURRY_PIZZA_URL, SAGE_N_SALT_URL } from 'src/utils/urls';
import readTenantCodeFromUrl from 'src/utils/readTenantCodeFromUrl';

const ApiBaseUrl = process.env.REACT_APP_API_URL || '';

const bombay_curry_pizza_headers = {
  tenantcode: 'bombay-curry-pizza',
  clientapikey: '6521e02e89ea8c5586836f33_webAdminbombaycurry',
  clientapisecrete: 'snixGepz6JRShIyGg1KDZea1xP3bCuzplcbRHsUxBpED83RNnDBDWbombaycurry',
};
const blue_smoke_headers = {
  tenantcode: 'blue-smoke',
  clientapikey: 'virtual-terminal-1blue',
  clientapisecrete: '8468fIyGg1KDZea1xP343BpED83RNnG1GAblue',
};
const buono_pizza_headers = {
  tenantcode: 'buono-pizza',
  clientapikey: '6521e02e89ea8c5586836f31_pos',
  clientapisecrete: 's2bs10s3jbEdg6IxtfKzlWmxWrXSuHrOrPvpNEbAwGVHd9AX085BpI0nZggy',
};
const SAGE_N_SALT_headers = {
  tenantcode: 'sage-n-salt',
  clientapikey: '66facd43aecd95d4f9a73086_web-admin',
  clientapisecrete: '$2b$10$p10xgMJUaGVEE0hoxnYcLeHbBKVBWMUsitZx8PxLGFFQgzpmXeMAO',
};

export const callApi = async (
  method: 'GET' | 'POST' | 'DELETE' | 'PUT',
  url: string,
  onAceesFail: (error?: any) => void,
  onAceesSuccess: (response: AxiosResponse) => void,
  values?: any
): Promise<void> => {
  // Get the tenant code from the URL
  const tenantCode = readTenantCodeFromUrl();
  console.log('Tenant Code:', tenantCode); // Log the tenant code

  // Select headers based on tenant code
  let headers;
  switch (tenantCode) {
    case 'bombay-curry-pizza':
      headers = bombay_curry_pizza_headers;
      break;
    case 'blue-smoke':
      headers = blue_smoke_headers;
      break;
    case 'buono-pizza':
      headers = buono_pizza_headers;
      break;
    case 'sage-n-salt':
      headers = SAGE_N_SALT_headers;
      break;
    default:
      headers = {}; // Default headers or handle the case where tenant code is not found
      break;
  }

  let formData: any = {
    method: method,
    headers: headers, // Use the dynamically set headers
  };

  if (values !== undefined && values !== '') {
    formData['data'] = values;
  }

  console.log('URL:', url, 'Form Data:', formData);

  try {
    switch (method) {
      case 'GET':
        const getResponse = await axios.get(url, formData);
        if (getResponse.status === 200) {
          onAceesSuccess(getResponse);
        } else {
          onAceesFail(getResponse);
        }
        break;

      case 'POST':
        const postResponse = await axios.post(url, values, formData);
        if (postResponse.status === 200 || postResponse.status === 201) {
          onAceesSuccess(postResponse);
        } else {
          onAceesFail(postResponse);
        }
        break;

      case 'DELETE':
        const deleteResponse = await axios.delete(url, { data: values, ...formData });
        if (deleteResponse.status === 200) {
          onAceesSuccess(deleteResponse);
        } else {
          onAceesFail();
        }
        break;

      case 'PUT':
        const updateResponse = await axios.put(url, formData);
        if (updateResponse.status === 200) {
          onAceesSuccess(updateResponse);
        } else {
          onAceesFail(updateResponse);
        }
        break;

      default:
        break;
    }
  } catch (error) {
    if (error) {
      onAceesFail(error);
    }
    throw error;
  }
};
