import * as React from 'react';
import {
    Box,
    Grid,
    LinearProgress,
    styled,
} from '@mui/material';
import MenuItemHeader from './MenuHeader';
import MenuItemCenterView from './MenuCenterView';
import { useDispatch, useSelector } from 'src/store';
import { getDiscount, getListItems } from 'src/services/apiService';

const RootWrapper = styled(Box)(() => `
`);

function MenuItem() {
  const dispatch = useDispatch()
    const { priceMenuDetails } = useSelector(
        (state: any) => state.priceMenu,
    );

    React.useEffect(() => {
      dispatch(getDiscount());
    }, [dispatch]);

    return (
        <RootWrapper>
            {!priceMenuDetails?.priceMenuList && (
                <LinearProgress />
            )}
            <MenuItemHeader />
            <Grid container xs={12} px={4}>
                <MenuItemCenterView />
            </Grid>
        </RootWrapper>
    );
}

export default MenuItem;
