import { Grid, Skeleton } from "@mui/material";
import TableSectionCard from "./TableSectionCard";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageHeader from "./PageHeader";
import { useEffect, useState } from "react";
import { getDineSections } from "src/services/apiService";
import { DineLayoutSection } from "src/services/apiService/response-models";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import ErrorPanel from "src/components/error-panel";
import TableEditorDialog from "./TableEditorDialog";

export default function ManageTables(){
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [dineSections, setDineSections] = useState<DineLayoutSection[]>([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [tableToEdit, setTableToEdit] = useState<{sectionId:string,tableId:string}>(null);
      
  const getData = async () => {
    try {
      setIsLoading(true);
        const response = await getDineSections();
        setDineSections(response.data);
    } catch (ex) {
        console.error(ex);
        const msg = ex.message ? ex.message : `Failed to load data`;
        enqueueSnackbar(t(msg), { variant: 'error' });
        setErrorMsg(msg);
    }

    setIsLoading(false);
};

useEffect(()=>{
  getData();
},[])

const handleDialogClose=()=> {
  setIsDialogOpen(false);
  setTableToEdit(null);
}

const onTableEdit=(sectionId:string,tableId:string)=>{
  setTableToEdit({sectionId,tableId});
  setIsDialogOpen(true);
}

const onTableDeleteSuccess=(sectionId:string,tableId:string)=>{
    const sections=[...dineSections];
    const section = sections.find(o=>o.id==sectionId);
    section.tables=section.tables.filter(o=>o.id!==tableId);
    if(section.tables.length==0){
     setDineSections([...sections.filter(o=>o.id!==sectionId)]);
    }else{
     setDineSections([...sections]);
    }
}
const onTableSave=(e:DineLayoutSection)=>{
  
  const sections=[...dineSections];

   const index = dineSections.findIndex(o=>o.name.toLowerCase()==e.name.toLowerCase());
   if(index==-1){
    sections.push(e);
   }else{
    sections[index]=e;
   }
   setDineSections(sections);
   handleDialogClose();
}

    return (
        <>
          <PageTitleWrapper>
            <PageHeader sections={dineSections} onAddClick={()=>setIsDialogOpen(true)}/>
          </PageTitleWrapper>
    
          <Grid
            sx={{
              px: 4
            }}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={4}
          >
        
            {isLoading && <Grid item xs={12}>
                {[1,2,3,4].map(o=>
                    <Skeleton key={o} sx={{m:2}} variant="rectangular" width={'100%'} height={250} />
                  )}
            </Grid>}

            {!isLoading && Boolean(errorMsg.length) && <Grid item xs={12}>
                <ErrorPanel title="Critical error" message={errorMsg} />
            </Grid>}

            {!isLoading && !Boolean(errorMsg.length) && <Grid item xs={12}>
              <TableSectionCard dineSections={dineSections} onEdit={onTableEdit} onDelete={onTableDeleteSuccess}/>
            </Grid>}
           
          </Grid>
          {isDialogOpen && <TableEditorDialog
       sections={dineSections}
       onClose={handleDialogClose}
       tableToEdit={tableToEdit}
       onSave={onTableSave}
     />}
        </>
      );
}