import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit';
import { useEffect, useState } from 'react';
import axios from 'axios';
import getMerchantKeyObj from './utils/getMerchantKeyObj';
import { useDispatch } from 'react-redux';
import { hideAppLoader } from './store/slices/app';
import eventsGateway from './services/event-gateway';
import {
  getClientCred,
  getEmployeeById,
  getMerchant
} from './services/apiService';

function App() {
  const dispatch = useDispatch();
  const content = useRoutes(router);
  const auth = useAuth();
  const [isHeaderSet, setIsHeaderSet] = useState(false);

  useEffect(() => {
    (async () => {
      console.log('ver: 1.19 - multi-tenant support fixed');
      const merchantObj = await getMerchantKeyObj();
      if (axios.defaults.headers) {
        Object.keys(merchantObj).forEach((o) => {
          axios.defaults.headers[o] = merchantObj[o];
        });
      }
      setIsHeaderSet(true);
    })();
  }, []);

  useEffect(() => {
    if (!isHeaderSet) return;
    (async () => {
      if (auth.isInitialized && !auth.isAuthenticated) {
        auth.loginWithPopup().then(() => {});
      }

      if (auth.isInitialized && auth.isAuthenticated) {
        const merchantObj = await getMerchantKeyObj();
        //web socket init
        setTimeout(() => {
          eventsGateway.init({
            tenantcode: merchantObj?.tenantcode,
            clientKey: merchantObj?.clientapikey,
            clientSecrete: merchantObj?.clientapisecrete,
            userId: auth.user.id
          });
        });
      }
    })();
  }, [isHeaderSet, auth.isInitialized]);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <CssBaseline />
          {auth.isInitialized ? content : <AppInit />}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
