import Authenticated from 'src/components/Authenticated';
import { Navigate, RouteObject } from 'react-router-dom';

import BaseLayout from 'src/layouts/BaseLayout';
import AccentSidebarLayout from 'src/layouts/AccentSidebarLayout';
import HomePage from 'src/content/pos-admin/home';
import baseRoutes from './base';
import Status404 from 'src/content/shared/pages/Status/Status404';
import Status500 from 'src/content/shared/pages/Status/Status500';
import StatusComingSoon from 'src/content/shared/pages/Status/ComingSoon';
import StatusMaintenance from 'src/content/shared/pages/Status/Maintenance';
import ItemCreate from 'src/content/pos-admin/items/item-create';
import ItemList from 'src/content/pos-admin/items/list';
import PriceMenuCreate from 'src/content/pos-admin/price-menu/create';
import FoodCategoryCreate from 'src/content/pos-admin/category/item-category-add';
import PriceMenuList from 'src/content/pos-admin/price-menu/list';
import TodayOrderList from 'src/content/pos-admin/orders/today-orders';
import ManageAddOnCategories from 'src/content/pos-admin/add-on-categories';
import ManageAddOnItems from 'src/content/pos-admin/add-on-items/list';
import AddOnItemCreate from 'src/content/pos-admin/add-on-items/create-addon-item';
import AddOnCategoryCreate from 'src/content/pos-admin/add-on-categories/create-addon-category';
import ManageCategories from 'src/content/pos-admin/category/list';
import ManageSizes from 'src/content/pos-admin/variants/index';
import OrderDetail from 'src/content/pos-admin/orders/order-detail';
import CustomerList from 'src/content/pos-admin/customer/list';
import AuthCallback from 'src/content/shared/auth-callback';
import AllOrderList from 'src/content/pos-admin/orders/all-order-list';
import CreateEmployee from 'src/content/pos-admin/employee/create-employee';
import EmployeeList from 'src/content/pos-admin/employee/list';
import ManageTables from 'src/content/pos-admin/manage-tables';
import ManageRecommend from 'src/content/pos-admin/manage-recommend';
import AccountSetup from 'src/content/pos-admin/account-setup';
import TodayOrderList2 from 'src/content/pos-admin/orders/today-orders2';
import DeviceList from 'src/content/pos-admin/manage-devices/list';
import DeviceCreate from 'src/content/pos-admin/manage-devices/create-addon-item';
import VirtualTerminal from 'src/content/pos-admin/virtual-terminal';
import CheckOut from 'src/content/pos-admin/virtual-terminal/check-out';
import PaymentSuccess from 'src/content/pos-admin/virtual-terminal/paymentSuccess';


const PosRutes: RouteObject[] = [
  
      {
        path: '',
        element: <Navigate to="home" replace />
      },
      {
        path:'home',
        element: <HomePage/>
      },
      {
        path:'virtualterminal',
        element: <VirtualTerminal/>
      },
      {
        path:'check-out',
        element: <CheckOut/>
      },
      {
        path: 'payment-success',
        element: <PaymentSuccess/>
      },
      {
        path:'inventory/addOnCategories/list',
        element: <ManageAddOnCategories/>
      },
      {
        path:'inventory/addOnCategories/add',
        element: <AddOnCategoryCreate/>
      },
      {
        path:'inventory/addOnCategories/edit/:id',
        element: <AddOnCategoryCreate/>
      },
      {
        path:'inventory/item-variants/list',
        element: <ManageSizes/>
      },
      {
        path:'inventory/add-on-items/list',
        element: <ManageAddOnItems/>
      },
      {
        path:'inventory/add-on-items/add',
        element: <AddOnItemCreate/>
      },
      {
        path:'inventory/add-on-items/edit/:id',
        element: <AddOnItemCreate/>
      },
      {
        path:'inventory/items/list',
        element: <ItemList/>
      },
      {
        path:'inventory/category/list',
        element: <ManageCategories/>
      },
      {
        path:'inventory/category/new',
        element: <FoodCategoryCreate/>
      },
      {
        path:'inventory/category/edit/:id',
        element: <FoodCategoryCreate/>
      },
      {
        path:'inventory/items/new',
        element: <ItemCreate/>
      },
      {
        path:'inventory/items/edit/:id',
        element: <ItemCreate/>
      },
      {
        path:'inventory/item/recommend',
        element: <ManageRecommend/>
      },
      {
        path:'inventory/price-menu/list',
        element: <PriceMenuList/>
      },
      {
        path:'inventory/price-menu/new',
        element: <PriceMenuCreate/>
      },
      {
        path:'inventory/price-menu/edit/:id',
        element: <PriceMenuCreate/>
      },
      {
        path:'orders/today/list',
        element: <TodayOrderList2/>
      },
      {
        path:'orders/list',
        element: <AllOrderList />
      },
      {
        path:'orders/detail/:id',
        element: <OrderDetail/>
      },
      {
        path:'orders/invoice/:id',
        element: <OrderDetail/>
      },
      {
        path:'manage/customer/list',
        element:<CustomerList/>
      },
      {
        path:'manage/employee/list',
        element:<EmployeeList/>
        // element:<CustomerList/>
      },
      {
        path:'manage/employee/new',
        element: <CreateEmployee/>
      },
      {
        path:'manage/employee/edit/:id',
        element: <CreateEmployee/>
      },
      {
        path:'manage/tables',
        element: <ManageTables/>
      },
      {
        path:'manage/devices/list',
        element: <DeviceList/>
      },
      {
        path:'manage/devices/new',
        element: <DeviceCreate/>
      },
      {
        path:'manage/devices/edit/:id',
        element: <DeviceList/>
      },
      {
        path:'account/setup',
        element: <AccountSetup/>
      },
    ];
  

export default PosRutes;
