import { ItemPrice, ItemVariant } from "src/services/apiService/response-models";

export default function validateItemPrice(prices:ItemPrice[],variants:ItemVariant[]):string{
    

    if(!variants?.length && !prices?.length){
        return 'Price information is missing';
    }

    if(variants && variants.length){
       const match = variants.find(o=>!o.prices?.length);
       if(match){
        return 'Price is missing for variant: '+match.name;
       }
     
       const match2 = variants.find(o=>o.prices.find(p=>p.price<=0));
       if(match2){
        return 'Price is invalid for variant: '+match2.name;
       }
    }else{
        const match = prices.find(o=>!o);
        if(match){
         return 'Price is missing for item.';
        }
      
        const match2 = prices.find(p=>p.price<=0);
        if(match2){
         return 'Price is invalid for item.';
        }
    }

    return '';
    
}