import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, ImageList, ImageListItem, Card, Typography, styled, Radio, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'src/store';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import cdnService from 'src/services/cdnService';
import EditIcon from '@mui/icons-material/Edit';
import { decreaseItemQuantity, deleteItemFromCart, inCreaseItemQuantity } from 'src/store/slices/priceMenu';
import AddToOrderModal from '../../menu-item/AddToOrderModal';
import { useTranslation } from 'react-i18next';
import CartTableBody from './cart-table-body';



const ButtonWrapper = styled('div')(() => `
    display: flex;
    align-items: center;
    cursor: default,
    margin-bottom: 14px
`);

const ImgWrapper = styled('img')(
    ({ theme }) => `
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
      border-radius: 12px;
      margin-right: 16px
`
);

const SelectWrapper = styled('div')(() => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #919EAB52;
    border-radius: 8px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;

`);

const IconWrapper = styled('div')(() => `
    display: flex;
    align-items: center;
`);

const InputBox = styled(TextField)(() => `
    width: 24px;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 5px
`)

const paymentOptions = [
    { label: 'Pay with PayMongo', value: 'paymongo', subTitle: 'You will be redirected to PayMongo website to complete your order securely.', icon: '/static/images/Payment/ic_paymongo.png' },
    // { label: 'Pay with Paypal', value: 'paypal', subTitle: 'You will be redirected to PayPal website to complete your order securely.', icon: '/static/images/Payment/ic_paypal.png' },
    // { label: 'Credit/Debit Card', value: 'card', subTitle: 'We support Mastercard, Visa, Discover and Stripe.', icon: '/static/images/Payment/ic_mastercard.png', visaIcon: '/static/images/Payment/ic_visa.png' },
    { label: 'Cash', value: 'cod', subTitle: 'Pay with cash.', },
    { label: 'Pay with card', value: 'card', subTitle: '(Debit/Credit)', },
    // { label: 'Pay with Gift Card', value: 'giftCard', subTitle: 'Pay with Gift Card.', },
];

interface ICartTable {
    isReadyForPayment: boolean;
    paymentType: string;
    setPaymentType: (value: string) => void;
}

export default function CartTable({ isReadyForPayment, paymentType, setPaymentType }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t }: { t: any } = useTranslation();
    const { cartData } = useSelector((state) => state.priceMenu);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectItem, setSelectItem] = useState({});

    const onSelectPaymentMethod = (item) => { }
    return (
        <>
            <Card sx={{
                p: 2,
                mb: 3.2,
                mr: 2
            }}>
                <Box display={'flex'} alignItems={'center'} py={2}>
                    <Typography variant='h4'>
                        {isReadyForPayment ? 'Payment options' : 'Cart'}
                    </Typography>
                    <Typography variant='h4' fontWeight={'normal'}>
                        {' '} {!isReadyForPayment && `(${cartData?.length} item)`}
                    </Typography>
                </Box>
                {isReadyForPayment ? (
                    <>
                        {paymentOptions?.map((item, index) => (
                            <SelectWrapper key={index} onClick={() => {
                                setPaymentType(item?.value)
                                onSelectPaymentMethod(item)
                            }}>
                                <Box display={'flex'} alignItems={'center'}>
                                    <Radio sx={{ m: 1 }} checked={paymentType === item?.value} />
                                    <Box>
                                        <Typography variant='h5' color={'#212B36'}>{item?.label}</Typography>
                                        <Typography variant='h5' fontWeight={'normal'} color={'#637381'}>{item?.subTitle}</Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    {item?.icon && (
                                        <img style={{ objectFit: "contain", height: 24, width: 36 }} src={item?.icon} />
                                    )}
                                </Box>
                            </SelectWrapper>
                        ))}
                    </>
                ) : (
                    <>
                        {cartData?.length > 0 ? (<TableContainer component={Paper} sx={{ boxShadow: 0, border: 'none', overflowX: 'auto' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Product</TableCell>
                                        <TableCell align="right">Price</TableCell>
                                        <TableCell align="center">Quantity</TableCell>
                                        <TableCell align="right">Total Price</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {cartData?.map((item: any, index: number) => (
                                        <CartTableBody
                                            key={index}
                                            cartItem={item}
                                            qty={item?.quantity}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>) : (
                            <Typography textAlign={'center'} variant='h4' py={2}>{t('Cart is empty')}</Typography>
                        )}
                    </>

                )}


            </Card>
            {!isReadyForPayment && (
                <ButtonWrapper onClick={() => navigate(-1)}>
                    <ChevronLeftIcon />
                    <Typography variant='h5'>
                        Continue Ordering
                    </Typography>
                </ButtonWrapper>
            )}

            {isModalVisible && (
                <AddToOrderModal
                    isVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    data={selectItem}
                    isEdit={true}
                />
            )}
        </>
    );
}