import { Grid, LinearProgress } from "@mui/material";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageHeader from "./PageHeader";
import ItemSectionCard from "./ItemSectionCard";
import RecommendEditor from "./RecommendEditor";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { getCategories, getItemList, getItemRecommends } from "src/services/apiService";
import { Category, Item, ItemRecommend } from "src/services/apiService/response-models";
import ErrorPanel from "src/components/error-panel";
import _ from "lodash";

interface DataModel{
  categories:Category[];
  items:Item[];
  recommends:ItemRecommend[];
}

export default function ManageRecommend(){
  const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [isSaveInProgress, setIsSaveInProgress] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [recommedToEdit, setRecommedToEdit] = useState<ItemRecommend |null>(null);
    const [clearTriggerRef, setClearTriggerRef] = useState('');

    const [data, setData] = useState<DataModel>({
      items:[],
      categories:[],
      recommends:[]
    });
    
         
   const getItemsAndCategories = async () => {
    try {
      setIsLoading(true);
       const responses =await Promise.all([getCategories(), getItemList(),getItemRecommends()]);

       const items = _.sortBy(responses[1].data,o=>o.name);
       const recommends = responses[2].data.recommendations || [];

       setData({
        categories: _.sortBy(responses[0].data,o=>o.name),
        items:  items,
        recommends:recommends
       });

       if(recommends?.length>0){
        for(let i=0;i<recommends.length;i++){
          const match = items.find(o=> o.id == recommends[i].itemId);
          if(match){
            setRecommedToEdit(recommends[i]);
            break;
          }
        }
       }
     
    } catch (ex) {
        console.error(ex);
        const msg = ex.message ? ex.message : `Failed to load data`;
        enqueueSnackbar(t(msg), { variant: 'error' });
        setErrorMsg(msg);
    }
    setIsLoading(false);
};

useEffect(()=>{
  getItemsAndCategories().then(()=>{});
},[]);

const onRecommendEdit=(e:ItemRecommend)=>{
  if(e){
    setRecommedToEdit({...e});
  }
}

const onRecommendSave=(e:ItemRecommend)=>{

  const arr=[...data.recommends];

  const index = data.recommends.findIndex(o=>o.id==e.id);

  if(index==-1){
    //new
    arr.push(e);
  }else{
    arr[index]={...e};
  }

  setRecommedToEdit({...e});
  setData({
    ...data,
    recommends:arr.filter(o=>o.id)
  });
  setClearTriggerRef(Date.now()+'');
}
    return (
        <>
          <PageTitleWrapper>
            <PageHeader />
          </PageTitleWrapper>
          {isLoading && <Loader/>}
          {!isLoading && errorMsg.length>0 && <ErrorPanel title="Data error" message={errorMsg}/>}
          {!isLoading &&  errorMsg.length==0 &&   <Grid
            sx={{
              px: 4
            }}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={4}
          >
        
            <Grid item xs={12} md={4}>
              <ItemSectionCard items={data.items}
              recommondations={data.recommends}
              onEdit={onRecommendEdit}
              clearTriggerRef={clearTriggerRef}/>
            </Grid>
            <Grid item xs={12}  md={8}>
              <RecommendEditor 
              items={data.items}
               categories={data.categories}
              recommedToEdit={recommedToEdit}
              onSave={onRecommendSave}
              onDelete={(id)=>{
                    setData({
                      ...data,
                      recommends:data.recommends.filter(o=>o.id!=id)
                    })
              }}
              onCancel={()=>{
                setRecommedToEdit(null);
                setClearTriggerRef(Date.now()+'');
                setData({
                  ...data,
                  recommends:data.recommends.filter(o=>o.id)
                })
              }}  
              />
            </Grid>
          </Grid>}
        </>
      );
}

function Loader(){
  return (<LinearProgress />)
}