import React from 'react';
import _ from 'lodash';
import {
    TextField,
    Autocomplete,
    Box,
    Card,
    Grid,
    styled,
    Button,
    Switch,
    FormControlLabel,
    CircularProgress
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SaveIcon from '@mui/icons-material/Save';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AddOnCategory } from '../../../../services/apiService/response-models';

const EditorWrapper = styled(Box)(
    ({ theme }) => `

    .ql-editor {
      min-height: 100px;
    }

    .ql-snow .ql-picker {
      color: ${theme.colors.alpha.black[100]};
    }

    .ql-snow .ql-stroke {
      stroke: ${theme.colors.alpha.black[100]};
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${theme.colors.alpha.black[30]};
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[50]};
      }
    }
`
);


function GeneralSection(props: { model: AddOnCategory | null, isSaveInProgress: boolean, onSaveClick: () => void, onSave: (updatedModel: AddOnCategory) => Promise<boolean> }) {
    const { t }: { t: any } = useTranslation();

    const [isEdit] = React.useState(props.model != null && props.model.id);


    return (
        <Card
            sx={{
                p: 3
            }}
        >
            <Formik
                initialValues={{
                    name: props.model?.name || '',
                    description: props.model?.description || '',
                    isActive: isEdit ? (props.model?.isActive === true ? true : false) : true
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string()
                        .max(250)
                        .required(t('The name field is required'))
                })}
                onSubmit={async (
                    _values,
                    { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                    try {
                        var isSuccess = await props.onSave({ ...props.model, ..._values });
                        if (isSuccess) {
                            resetForm();
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                    } catch (err) {
                        console.error(err);
                        setStatus({ success: false });
                        // setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }}
            >{({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue
            }) => (

                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="name"
                                placeholder={t('Enter category name...')}
                                variant="outlined"
                                id="name" label="Add-On Category Name"
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <EditorWrapper>
                                <ReactQuill
                                    placeholder="Category description..."
                                    id="description"
                                    value={values.description}
                                    onChange={(o) => setFieldValue('description', o)}
                                />
                            </EditorWrapper>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel labelPlacement="start"
                                control={
                                    <Switch
                                        name="isActive"
                                        disabled={!isEdit}
                                        color={'primary'}
                                        defaultChecked
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        checked={values.isActive}
                                    />
                                }
                                label={t('Is Active')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" onClick={() => props.onSaveClick()} variant="contained"
                                startIcon={
                                    isSubmitting || props.isSaveInProgress ? <CircularProgress size="1rem" /> : <SaveIcon />
                                }
                                disabled={isSubmitting || props.isSaveInProgress}>
                                Save</Button>
                        </Grid>
                    </Grid>
                </form>
            )}
            </Formik>
        </Card>
    );
}

export default GeneralSection;
