import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  styled,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useSelector } from 'src/store';

const RootWrapper = styled(Box)(
  () => `
  flex: 1;
`
);

type PageHeaderType = {
  title: string;
  subtitle?: string;
  isCart: boolean;
};

function PageHeader({
  title,
  subtitle,
  isCart
}: PageHeaderType) {
  const { t }: { t: any } = useTranslation();
  const {cartData } = useSelector(
    (state: any) => state.priceMenu,
);

  const [customer, setCustomer] = React.useState('');

  const handleCustomerChange = (event) => {
    setCustomer(event.target.value);
  };
  return (
    <RootWrapper>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box display="flex" flexDirection="column">
            <Typography variant="h3" component="h3" gutterBottom>
              {t(title)}
            </Typography>
            {subtitle && (
              <Typography variant="subtitle2">{t(subtitle)}</Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={2}>
          {isCart ? (
            <Box display={'flex'} justifyContent={'flex-end'}>
              <ShoppingCartIcon />{cartData?.length}
            </Box>
          ) : (

            <FormControl fullWidth>
              <InputLabel id="customer-label">{t("Transaction Type")}</InputLabel>
              <Select
                labelId="customer-label"
                id="customer"
                value={customer}
                label={t("Transaction Type")}
                onChange={handleCustomerChange}
              >
                <MenuItem value="Take Payment">Take Payment</MenuItem>
              </Select>
            </FormControl>
          )}

        </Grid>
      </Grid>
    </RootWrapper>
  );
}

export default PageHeader;
