import {
  TextField,
  Autocomplete,
  Card,
  Grid,
  CardHeader,
  Divider,
  useTheme
} from '@mui/material';
import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import DropdownMultiSelect from '../../../../components/dropdown-multi-select';
import { CodeItem, Employee, RefData } from 'src/services/apiService/response-models';
import { TextField as FmTextFiled } from 'formik-mui';
import { getEmployeeRole } from 'src/services/apiService';
import { useEffect, useState } from 'react';

function GroupInfo(props: { accountRefData: RefData; data }) {
  console.log(props.data);

  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const jobTitlesList = [];
  const departmentsList = [];
  const [selectedUserRoles, setSelectedUserRoles] = useState<any>([]);
  const [userRolesList, setUserRolesList] = useState<any>({ userRoles: {} }); // Initialize as an empty array

  useEffect(() => {
    const fetchEmployeeRoles = async () => {
      try {
        const response = await getEmployeeRole(); // Assuming getEmployeeRole is your API call function
        setUserRolesList(response.data); // Assuming response.data contains the user roles data
        console.log('User Roles:', response.data);
      } catch (error) {
        console.error('Error fetching user roles:', error);
      }
    };

    fetchEmployeeRoles(); // Call the fetchEmployeeRoles function when the component mounts
  }, []);

  const handleUserRolesChange = (values: any, form: any) => {
    const updatedSelectedUserRoles = values.map((role: string) => {
      return {
        roleName: role,
        permissions: userRolesList.userRoles[role] || [] // Extract permissions for the selected role
      };
    });
    setSelectedUserRoles(updatedSelectedUserRoles);
    console.log(updatedSelectedUserRoles);
    // Update Formik form values
    form.setFieldValue('selectedUserRoles', updatedSelectedUserRoles);
  };

  return (
    <Card>
      <CardHeader title={t("Employee's Group Info")} />
      <Divider />

      <Grid sx={{ padding: theme.spacing(2) }} container spacing={3}>
        <Grid item xs={12} md={6}>
          <Field name="departments">
            {({
              field,
              form: {
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                setFieldError
              },
              meta
            }: FieldProps) => (
              <Autocomplete
                sx={{
                  m: 0
                }}
                freeSolo
                selectOnFocus
                clearOnBlur
                multiple={true}
                limitTags={10}
                value={field.value}
                autoSelect
                onChange={(
                  event: any,
                  newValues: string[] | null | CodeItem[]
                ) => {
                  const vals = newValues.map((o) => {
                    if (o?.codeId) {
                      return o;
                    }
                    return {
                      codeId: departmentsList.find((x) => x.value == o?.trim())
                        ?.codeId,
                      value: o?.trim()
                    };
                  });
                  setFieldValue(field.name, vals);
                  // setFieldTouched(field.name, true);
                }}
                // isOptionEqualToValue={(option: CodeItem, value: CodeItem) => {
                //   return option?.codeId === value?.codeId;
                // }}
                getOptionLabel={(option: CodeItem) => {
                  return (
                    option.value ||

                    ''
                  );
                }}
                options={departmentsList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder={t('Enter or select departments...')}
                    label={t('Departments')}
                  />
                )}
              />
            )}
          </Field>
          {/* <Field name="departments">
            {({ field, form, meta }: FieldProps) => (
              <DropdownMultiSelect
                label="Department"
                name="departments"
                options={convertToCodeItems(props.accountRefData.departments)}
              />
            )}
          </Field> */}
        </Grid>
        <Grid item xs={12} md={6}>
          <Field name="jobTitle">
            {({
              field,
              form: {
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                setFieldError
              },
              meta
            }: FieldProps) => (
              <Autocomplete
                sx={{
                  m: 0
                }}
                freeSolo
                selectOnFocus
                clearOnBlur
                limitTags={10}
                value={field.value || null}
                autoSelect
                onChange={(event: any, newValue: any) => {
                  const jobTitleId =
                    newValue?.id ||
                    jobTitlesList.find((x) => x.value == newValue)?.id;
                  setFieldValue(field.name, {
                    ...(jobTitleId
                      ? { codeId: jobTitleId }
                      : { value: newValue })
                  });
                  // setFieldTouched(field.name, true);
                }}
                getOptionLabel={(option: any) => {
                  return (
                    option.value ||
                    jobTitlesList.find((x) => x.id == option.codeId)?.value ||
                    ''
                  );
                }}
                // isOptionEqualToValue={(
                //   option: CodeMaster,
                //   value: CodeMaster
                // ) => {
                //   return option.id === value.id;
                // }}
                options={jobTitlesList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder={t('Enter or select job title...')}
                    label={t('Job Title')}
                  />
                )}
              />
            )}
          </Field>
        </Grid>

        <Grid item xs={12} md={6}>
          <Field name="userRoles">
            {({ field, form, meta }: FieldProps) => (
              <Autocomplete
                disablePortal
                multiple={true}
                getOptionLabel={(option) => option || ''}
                value={field.value}
                options={Object.keys(userRolesList.userRoles)} // Use Object.keys() to get only the role names
                onChange={(event, values) => {
                  form.setFieldValue(field.name, values);
                  handleUserRolesChange(values , form);
                  console.log(form.values);
                  
                }}
                renderInput={(params) => (
                  <TextField
                    error={Boolean(form.touched[field.name] && form.errors[field.name])}
                    helperText={
                      (form.touched[field.name] && form.errors[field.name]) ||
                      'These roles will be assigned to the user associated with this employee.'
                    }
                    fullWidth
                    {...params}
                    label={t('User role')}
                  />
                )}
              />

            )}
          </Field>
        </Grid>



        <Grid item xs={12} md={6}>
          <Field
            fullWidth
            name="code"
            component={FmTextFiled}
            label="Employee code"
            placeholder={t('Enter code here...')}
          />
        </Grid>
      </Grid>
    </Card>
  );
}

export default GroupInfo;
