import PageHeader from './PageHeader';
import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { getAllOrders , deleteActiveOrder } from 'src/services/apiService';

import {Divider,
  Box,
  Card,
  Typography,
  InputAdornment,
  alpha,
  Link,
  Avatar,
  TextField,
  Tooltip,
  IconButton,
  TablePagination,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  styled,
  useTheme,
  Grid,
  LinearProgress,
  AvatarGroup} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState, ChangeEvent, MouseEvent, useEffect } from 'react';
import React from 'react';
import { OrderMain } from 'src/services/apiService/response-models';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import cdnService from 'src/services/cdnService';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';

const TableWrapper = styled(Table)(
  ({ theme }) => `

    thead tr th {
        border: 0;
    }

    tbody tr td {
        position: relative;
        border: 0;

        & > div {
            position: relative;
            z-index: 5;
        }

        &::before {
            position: absolute;
            left: 0;
            top: 0;
            transition: ${theme.transitions.create(['all'])};
            height: 100%;
            width: 100%;
            content: "";
            background: ${theme.colors.alpha.white[100]};
            border-top: 1px solid ${theme.colors.alpha.black[10]};
            border-bottom: 1px solid ${theme.colors.alpha.black[10]};
            pointer-events: none;
            z-index: 4;
        }

        &:first-of-type:before {
            border-top-left-radius: ${theme.general.borderRadius};
            border-bottom-left-radius: ${theme.general.borderRadius};
            border-left: 1px solid ${theme.colors.alpha.black[10]};
        }
        

        &:last-child:before {
            border-top-right-radius: ${theme.general.borderRadius};
            border-bottom-right-radius: ${theme.general.borderRadius};
            border-right: 1px solid ${theme.colors.alpha.black[10]};
        }
    }

    tbody tr:hover td::before {
        background: ${alpha(theme.colors.primary.main, 0.02)};
        border-color: ${alpha(theme.colors.alpha.black[100], 0.25)} !important;
    }

  `
);

const TableRowDivider = styled(TableRow)(
  ({ theme }) => `
    height: ${theme.spacing(2)};
  `
);
const LabelSuccess = styled(Box)(
  ({ theme }) => `
    display: inline-block;
    background: ${theme.colors.success.lighter};
    color: ${theme.colors.success.main};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(11)};
    font-weight: bold;
    padding: ${theme.spacing(1, 2)};
    border-radius: ${theme.general.borderRadiusSm};
  `
);

const LabelError = styled(Box)(
  ({ theme }) => `
    display: inline-block;
    background: ${theme.colors.error.lighter};
    color: ${theme.colors.error.main};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(11)};
    font-weight: bold;
    padding: ${theme.spacing(1, 2)};
    border-radius: ${theme.general.borderRadiusSm};
  `
);

const LabelWarning = styled(Box)(
  ({ theme }) => `
    display: inline-block;
    background: ${theme.colors.warning.lighter};
    color: ${theme.colors.warning.main};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(11)};
    font-weight: bold;
    padding: ${theme.spacing(1, 2)};
    border-radius: ${theme.general.borderRadiusSm};
  `
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
    transition: ${theme.transitions.create(['transform', 'background'])};
    transform: scale(1);
    transform-origin: center;

    &:hover {
        transform: scale(1.1);
    }
  `
);

const TableHeadWrapper = styled(TableHead)(
  ({ theme }) => `
      .MuiTableCell-root {
          text-transform: none;
          font-weight: normal;
          color: ${theme.colors.alpha.black[100]};
          font-size: ${theme.typography.pxToRem(16)};
          padding: ${theme.spacing(2)};
      }

      .MuiTableRow-root {
          background: transparent;
      }
  `
);

const SearchInputWrapper = styled(TextField)(
  ({ theme }) => `
    background: ${theme.colors.alpha.white[100]};
    border-radius: ${theme.general.borderRadius};

    .MuiInputBase-input {
        font-size: ${theme.typography.pxToRem(16)};
    }
  `
);


function AllOrderList(){
  
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
  
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [orders, setOrders] = useState<OrderMain[]>([]);
    const [filteredOrders, setFilteredOrders] = useState<OrderMain[]>([]);
    const [searchKey, setSearchKey] = useState('');
    const [isLoading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
  
    useEffect(() => {
      setLoading(true);
      async function fetchData() {
        try {
          const response = await getAllOrders();
          console.log(response.data);

          setOrders(response.data);
          
        } catch (error) {
          console.error('Error fetching data:', error);
          setErrorMsg('Failed to load data');
        } finally {
          setLoading(false);
        }
      }
      fetchData();
    }, []);
    
    const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      setSearchKey(event.target.value);
    };
    useEffect(() => {
      const key = searchKey.toLowerCase().trim();
      if (key.length) {
        const items = orders.filter((o) => {
          const orderNumberMatch = o.orderNumber?.toLowerCase().includes(key) || false;
          const locationMatch = o.location?.value?.toLowerCase().includes(key) || false;
          const orderAmountMatch = o.orderAmount?.toString().toLowerCase().includes(key) || false;
          const currentStageMatch = o.currentStage?.name?.toLowerCase().includes(key) || false;
          const itemMatch =
            o.items?.find((i) => i.itemName?.toLowerCase().includes(key)) || false;
    
          return (
            orderNumberMatch ||
            locationMatch ||
            orderAmountMatch ||
            currentStageMatch ||
            itemMatch
          );
        });
    
        const startIndex = page * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        setFilteredOrders(items.slice(startIndex, endIndex));
      } else {
        const startIndex = page * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        setFilteredOrders(orders.slice(startIndex, endIndex));
      }
    }, [searchKey, orders, page, rowsPerPage]);
    
  
    const handleChangePage = (
      _event: MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    const onOrderViewClick=(id:string)=>{
      navigate('/'+location.pathname.split('/')[1]+	"/orders/detail/"+id);
    }

    const onViewInvoiceClick=(id:string)=>{
      navigate('/'+location.pathname.split('/')[1]+	"/orders/invoice/"+id);
    }
    
    const handleDeleteClick = (id: string) => {
      console.log("id>>>>", id);
      deleteActiveOrder(id)
        .then((response) => {
          console.log("Delete successful:", response);
        })
        .catch((error) => {
          console.error("Error deleting order:", error);
        });
    };
  
  return (
    <>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
  
      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <Card variant="outlined">
            <Box
              p={3}
              sx={{
                background: `${theme.colors.alpha.black[5]}`
              }}
            >
              <SearchInputWrapper
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                placeholder={t('Search orders...')}
                fullWidth
                onChange={handleSearchInputChange}
              />
            </Box>
            <Divider />
            {isLoading && <LinearProgress />}
            <Box px={3} pb={3}>
              <TableContainer>
                <TableWrapper>
                  <TableHeadWrapper>
                    <TableRow>
                      <TableCell>{t('Order')}</TableCell>
                      <TableCell align="left">{t('Description')}</TableCell>
                      <TableCell align="left">{t('Item(s)')}</TableCell>
                      <TableCell align="center">{t('Status')}</TableCell>
                      <TableCell align="center">{t('Total')}</TableCell>
                      <TableCell align="right">{t('Actions')}</TableCell>
                    </TableRow>
                  </TableHeadWrapper>
                  <TableBody>
                    {!isLoading && !errorMsg && filteredOrders.length === 0 && (
                       <TableRow>
                       <TableCell colSpan={6}>
                       <Typography
                        sx={{
                          py: 10,
                        }}
                        variant="h3"
                        fontWeight="normal"
                        color="text.secondary"
                        align="center"
                      >
                        {t("No record to display")}
                      </Typography>
                       </TableCell>
                     </TableRow>
                     
                )}
                    {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={6}>
                        <LinearProgress />
                          loading...
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredOrders.map((order, index) => (
                        <React.Fragment key={index}>
                        <TableRow hover key={index}>
                          
                          <TableCell>
                            <Box>
                              <Typography variant="h4">#{order.orderNumber}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              
                              <Box pl={1}>
                                <Link
                                  href="#"
                                  color="text.primary"
                                  underline="none"
                                  noWrap
                                  variant="h5"
                                  sx={{
                                    '&:hover': {
                                      color: `${theme.colors.primary.main}`
                                    }
                                  }}
                                >
                                  {order.orderType + ' - ' + order.sourceApp.appName}
                                </Link>
                                <Typography variant="subtitle2" noWrap>
                                  {format(new Date(order.createdOn), 'MM/dd/yyyy hh:mm aa')}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                          <Box display="flex" justifyContent="flex-start">
                                <AvatarGroup max={4}>
                                  {order.items.map((item) => (
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      key={item.description || item.itemName}
                                      title={item.description || item.itemName}
                                    >
                                      <Avatar
                                        sx={{
                                          width: 30,
                                          height: 30
                                        }}
                                        key={item.description || item.itemName}
                                        src={cdnService.toImageUrl(item.featureImage)}
                                      />
                                      
                                    </Tooltip>
                                  ))}
                                </AvatarGroup>
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <div>
                              <LabelSuccess>{order.currentStage.name}</LabelSuccess>
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <div>
                              <Typography
                                sx={{
                                  pr: 0.5
                                }}
                                component="span"
                                variant="h4"
                                color="text.primary"
                              >
                                ${Number(order.orderAmount).toFixed(2)}
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell
                            sx={{
                              whiteSpace: 'nowrap'
                            }}
                            align="right"
                          >
                            <Box>
                              <Tooltip title={t('View invoice')} arrow>
                                <IconButtonWrapper
                                onClick={()=>onViewInvoiceClick(order.id)}
                                  sx={{
                                    backgroundColor: `${theme.colors.secondary.lighter}`,
                                    color: `${theme.colors.primary.main}`,
                                    transition: `${theme.transitions.create(['all'])}`,
  
                                    '&:hover': {
                                      backgroundColor: `${theme.colors.secondary.main}`,
                                      color: `${theme.palette.getContrastText(
                                        theme.colors.primary.main
                                      )}`
                                    }
                                  }}
                                >
                                  <ReceiptTwoToneIcon fontSize="small" />
                                </IconButtonWrapper>
                              </Tooltip>
                            </Box>
                          </TableCell>
                          
                        </TableRow>
                        <TableRowDivider />
                        </React.Fragment>
                      ))
                    )}
                    <TableRowDivider />
                  </TableBody>
                </TableWrapper>
              </TableContainer>
              <Box pt={1} display="flex" justifyContent="flex-end">
                <TablePagination
                  component="div"
                  count={orders.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
  
      <Footer />
    </>
  );
}

export default AllOrderList;