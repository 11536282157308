import { getClientCred } from "src/services/apiService";
import readTenantCodeFromUrl from "./readTenantCodeFromUrl"
import axios from "axios";

export interface MerchantKeyObj {
    tenantcode: string;
    clientapikey: string;
    clientapisecrete: string;
}

let mObj: MerchantKeyObj = null;
export default async function getMerchantKeyObj(): Promise<MerchantKeyObj> {
    const tenantCode = readTenantCodeFromUrl();
    if (mObj)
        return mObj;

    axios.defaults.headers['tenantcode'] = tenantCode;
    const cred = await getClientCred();
    return {
        tenantcode: tenantCode,
        clientapikey: cred.data.clientApiKey,
        clientapisecrete: cred.data.clientApiSecrete,
    }
}