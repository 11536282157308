import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  styled,
  useTheme
} from '@mui/material';
import { useRef } from 'react';
import { useCallback, useEffect, useState } from 'react';
import PageHeader from 'src/components/page-header';

import Sidebar from './Sidebar';

import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'src/store';
import * as Yup from 'yup';
import ContactInfo from './ContactInfo';
import GroupInfo from './GroupInfo';
import PersonalDetails from './PersonalDetails';
import { CodeItem, Employee, RefData } from 'src/services/apiService/response-models';
import { getEmployeeById, getRefData, parseToApiErrorMessage, saveEmployee } from 'src/services/apiService';
import Scrollbar from 'src/components/Scrollbar';
import extractNumber from 'src/utils/extractNumber';
import extractDialCode from 'src/utils/extractDialCode';
import { any } from 'prop-types';

const DrawerWrapper = styled(Drawer)(
  ({ theme }) => `
    width: 400px;
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: 400px;
        height: calc(100% - ${theme.header.height});
        position: absolute;
        top: ${theme.header.height};
        right: 0;
        z-index: 3;
        background: ${theme.colors.alpha.white[10]};
    }
`
);

const DrawerWrapperMobile = styled(Drawer)(
  ({ theme }) => `
    width: 360px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 360px;
        z-index: 3;
        background: ${theme.colors.alpha.white[30]};
  }
`
);

const MainContentWrapper = styled(Box)(
  () => `
  flex-grow: 1;
`
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(6)};
  height: ${theme.spacing(6)};
`
);

function CreateEmployee() {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [featureImage, setFeatureImage] = useState('');
  const { id: employeeId } = useParams();
  const formikRef = useRef(null);
  const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState('');
  // const [userRole, setUserRole] = useState<CodeItem[]>([]); // Assuming CodeItem is the correct type
  const [accountRefData, setAccountRefData] = useState<RefData>({
    items: []
  });

  const [model, setModel] = useState<Employee | null>(null);

  const getById = async (employeeId: string) => {
    try {
      const response = await getEmployeeById(employeeId);
      setIsEdit(true);
      setFeatureImage(response.data.image);
      setModel(response.data);
      debugger
//       const userRolesArray = Array.from(response.data.userRoles.values);
// setUserRole(userRolesArray);




    } catch (ex) {
      console.error(ex);
      const msg = ex.message ? ex.message : `Failed to load employee by id`;
      enqueueSnackbar(t(msg), { variant: 'error' });
      navigate('/app/team/list');
    }
  };

  useEffect(() => {
    if (employeeId && employeeId.length > 0) {
      setIsLoading(true);
      getById(employeeId).then(() => {
        setIsLoading(false);
      });
    } else {
      setModel(null);
      setIsEdit(false);
    }
  }, [employeeId]);

  // const loadRefData = 
  //   async (callback: (hasError: boolean, data: RefData) => void) => {
  //       const res = await getRefData('');
  //       callback(false, res.data);
  //     }

  // useEffect(() => {
  //   setIsLoading(true);
  //   loadRefData((hasError, data) => {
  //     if (data) {

  //     }
  //     setIsLoading(false);
  //   });

  // }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const onFeatureImageChange = (file: File): void => {
    const name = (file as any).blobName;
    setFeatureImage(name);
}
const onSave = async (updatedModel: Employee) => {
  let isSuccess = false;
  try {
    if (!featureImage) {
      enqueueSnackbar(t(`Please upload Feature image`), { variant: 'error' });
      return isSuccess;
    }
    updatedModel.image = featureImage;
    setIsSaveInProgress(true);
    const saveRes = await saveEmployee(updatedModel);
    enqueueSnackbar(
      t(`Employee was ${!isEdit ? 'created' : 'updated'} successfully`),
      { variant: 'success' }
    );
    isSuccess = true;
    navigate('/pos-admin/manage/employee/list');
  } catch (ex) {
    setIsSaveInProgress(false);
    console.log(ex);
    const msg = parseToApiErrorMessage(
      ex,
      `Failed to ${!isEdit ? 'created' : 'updated'} Employee`
    );
    enqueueSnackbar(t(msg), { variant: 'error' });
  }
  return isSuccess;
};


  const onSaveClick = () => {
    console.log('Save button clicked');
    if (formikRef.current) {
      formikRef.current.handleSubmit();
    }
  };
  
  // const sidebarContent = (
  //   <Scrollbar>
  //     <Sidebar />
  //   </Scrollbar>
  // );
  const sidebarContent = (
    <Scrollbar>
        <Sidebar onFeatureImageChange={onFeatureImageChange} isSubmit={isSubmitAttempted} isEdit={isEdit} uploadedFileName={featureImage} itemId={model?.id}/>
    </Scrollbar>
);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const urlRegExp =
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

  const isValidZipRegExp = /(^\d{5}$)|(^\d{5}-\d{4}$)/;


  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        initialValues={{
          isActive: isEdit ? (model?.isActive === true ? true : false) : true,
          firstName: model?.firstName || '',
          pronouns: model?.pronouns || '',
          middleName: model?.middleName || '',
          lastName: model?.lastName || '',
          imageUrl: model?.image,
          email: model?.email || '',
          altEmail: model?.altEmail || '',
          contactNumberWork: extractNumber(model?.altContactNumber) || '',
          code: model?.code || '',
          // countryDialCodeWork: extractDialCode(model?.altContactNumber) || '1',
          contactNumber: extractNumber(model?.contactNumber) || '',
          // countryDialCodePersonal: extractDialCode(model?.contactNumber) || '1',
          description: model?.description || '',
          dateOfBirth: model?.dateOfBirth || null,
          departments: model?.departments || [],
          jobTitle: model?.jobTitle,
          address: {
            addressLine: model?.address?.addressLine || '',
            addressLine2: model?.address?.addressLine2 || '',
            countryCode: model?.address?.countryCode || '',
            stateCode: model?.address?.stateCode || '',
            zipCode: model?.address?.zipCode || ''
          },
          userRoles: model?.userRoles || []
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().max(250).required(t('The field is required')),
          lastName: Yup.string().max(250).required(t('The field is required')),
          email: Yup.string()
            .max(250)
            .required(t('The email field is required'))
            .email('Not a proper email')
          // .test('Unique Email', 'Email already in use', async (value) => {
          //   if (!value || isEdit) return true;
          //   return false;
          // })
          ,
          alternateEmail: Yup.string().max(250).email('Not a proper email'),
          // teams: Yup.array()
          //   .min(1, 'Choose at least 1 team/group')
          //   .required(t('Choose at least 1 team/group')),
          contactNumberWork: Yup.string().matches(
            phoneRegExp,
            'Work Phone number is not valid'
          ),

          contactNumber: Yup.string().matches(
            phoneRegExp,
            'Phone number is not valid'
          ),
         
          zipcode: Yup.string().matches(
            isValidZipRegExp,
            'Enter correct zipcode!'
          ),
          userRoles: Yup.array()
            .min(1, 'Choose at least 1 role')
            .required(t('Choose at least 1 role'))
        })}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            console.log('Form Values:', values);
            const isSuccess = await onSave({ ...model, ...values });
        
            if (isSuccess) {
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            const msg = parseToApiErrorMessage(err, `Failed to save employee`);
            enqueueSnackbar(t(msg), { variant: 'error' });
            setStatus({ success: false });
            setSubmitting(false);
          }
        }}
        
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Box mb={3} display="flex">
              <MainContentWrapper>
                <Grid
                  sx={{
                    px: 4
                  }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={4}
                >
                  <Grid item xs={12}>
                    <Box
                      mt={3}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <PageHeader type="create" title="Employee Management" subtitle="All aspects related to the employee can be managed from this page" route={'/' + location.pathname.split('/')[1] + "/manage/employee/list"} buttonTitle="Go back to all employees"></PageHeader>

                      <Box
                        component="span"
                        sx={{
                          display: { lg: 'none', xs: 'inline-block' }
                        }}
                      >
                        <IconButtonToggle
                          sx={{
                            ml: 2
                          }}
                          color="primary"
                          onClick={handleDrawerToggle}
                          size="small"
                        >
                          <MenuTwoToneIcon />
                        </IconButtonToggle>
                      </Box>
                    </Box>
                  </Grid>
                  {isLoading && (
                    <Grid item xs={12}>
                      <LinearProgress />
                      loading...
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <PersonalDetails accountRefData={accountRefData}  />
                  </Grid>
                  <Grid item xs={12}>
                    <GroupInfo
                      accountRefData={accountRefData}
                      data={0}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ContactInfo isEdit={isEdit} />
                  </Grid>
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        p: 1
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Button
                            type="submit" 
                            variant="contained"
                            onClick={onSaveClick}
                            startIcon={
                              isSubmitting || isSaveInProgress ? (
                                <CircularProgress size="1rem" />
                              ) : (
                                <SaveIcon />
                              )
                            }
                            disabled={isLoading || isSubmitting || isSaveInProgress}
                          >
                            Save
                          </Button>


                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
              </MainContentWrapper>
              <Box
                component="span"
                sx={{
                  display: { lg: 'none', xs: 'inline-block' }
                }}
              >
                <DrawerWrapperMobile
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                >
                  {sidebarContent}
                </DrawerWrapperMobile>
              </Box>
              <Box
                component="span"
                sx={{
                  display: { xs: 'none', lg: 'inline-block' }
                }}
              >
                <DrawerWrapper
                  variant="permanent"
                  anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                  open
                >
                  {sidebarContent}
                </DrawerWrapper>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}

export default CreateEmployee;
