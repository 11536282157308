import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  Typography,
  Box,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { useParams } from 'react-router-dom';
import { getOrderStagesByOrderId } from 'src/services/apiService';

const MainContentWrapper = styled(Box)(
  () => `
  flex-grow: 1;
`
);

// ... (other imports)

function OrderStageCard({ data }) {
  const { t }: { t: any } = useTranslation();
  const { id } = useParams();
  const [orderStages, setOrderStages] = useState([]);
  const [loading, setLoading] = useState(true);
  // console.log(data.orderStages[0].name,"stage card");
  const orderStagesData = data && data.orderStages ? data.orderStages : [];
  useEffect(() => {
    let isMounted = true; // Flag to check if the component is still mounted

    const fetchOrderStages = async () => {
      try {
        // const responses = await getOrderStagesByOrderId(id);

        // Check if the component is still mounted before updating state
        if (isMounted) {
          setOrderStages(orderStagesData);

          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchOrderStages();

    // Cleanup function to cancel the API request if the component unmounts
    return () => {
      isMounted = false;
    };
  }, [id,orderStagesData]);

  // Function to format date to display time with AM/PM
  const formatTimeWithAMPM = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  return (
    <Card>
      <CardHeader title={t('Order Stages')} />
      <Divider />
      <Box py={0.5} px={2}>
        <Timeline>
          {loading ? (
            <Typography>Loading...</Typography>
          ) : (
            orderStages.map((stage, index) => (
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot variant="outlined" color="primary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent display="flex" alignItems="center">
                  <Box sx={{ mr: 3 }}>
                    <Typography variant="h5" noWrap>
                      {formatTimeWithAMPM(stage.createdOn)} {/* Display the formatted time with AM/PM */}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {stage.name} {/* Display the name value */}
                    </Typography>
                  </Box>
                </TimelineContent>
              </TimelineItem>
            ))
          )}
        </Timeline>
      </Box>
    </Card>
  );
}

export default OrderStageCard;


