import { useState, useEffect, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet-async';
import PageHeader from 'src/components/page-header';
import Sidebar from './Sidebar';
import { styled, Box, Grid, IconButton, Drawer, useTheme, LinearProgress, CircularProgress, Skeleton } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import Results from './Results';
import { ItemListingRes, ListingItemVm } from 'src/services/apiService/response-models/view-models';
import { getItemListingVmRes } from 'src/services/apiService';
import { minBy, maxBy, flatten, uniqBy } from 'lodash';
import Scrollbar from 'src/components/Scrollbar';
import Footer from 'src/components/Footer';

const DrawerWrapperMobile = styled(Drawer)(
  () => `
    width: 340px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 340px;
        z-index: 3;
  }
`
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(6)};
  height: ${theme.spacing(6)};
`
);

function ItemList() {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [itemListingRes, setItemListingRes] = useState<ItemListingRes>();
  const [filteredItems, setFilteredItems] = useState<ListingItemVm[]>([]);
  const [seelctedCategoryIds, setSelectedCategoryIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  
  const [priceRange, setPriceRange] = useState<number[]>([0, 20]); 
  const [filterVm, setFilterVm] = useState({
    min: undefined,
    max: undefined,
    categories: [],
  });
  const getItems = async () => {
    try {
      const response = await getItemListingVmRes();
      setItemListingRes(response.data);
      setFilteredItems(response.data.items);
      const newFilterVm = {
        min: minBy(response.data.items, (o) => o.price)?.price,
        max: maxBy(response.data.items, (o) => o.price)?.price,
        categories: uniqBy(
          flatten(response.data.items.map((o) => o.categories)),
          (o) => o.name
        ),
      };
      setFilterVm(newFilterVm);
    } catch (err) {
      console.error(err);
      setError('Failed to load data');
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getItems().then(() => setIsLoading(false));
  }, []);
  const filterItems = useCallback(() => {
    const items = itemListingRes?.items || [];
  
    let filteredItems;
    if (seelctedCategoryIds.length === 0) {
      filteredItems = items.filter(
        (item) => item.price >= priceRange[0] && item.price <= priceRange[1]
      );
    } else {
      filteredItems = items.filter((item) =>
        item.categories.some((category) =>
          seelctedCategoryIds.includes(category.id)
        )
      );
    }
  
    setFilteredItems(filteredItems);
    console.log("List of Data:", items);
    console.log("Filtered Items:", filteredItems);
  }, [itemListingRes, priceRange, seelctedCategoryIds]);
 
  
useEffect(() => {
  const filterItemsByCategories = () => {
    const items = itemListingRes?.items || [];
    const filteredItems = items.filter((item) =>
      item.categories.some((category) =>
        seelctedCategoryIds.includes(category.id)
      )
    );
    setFilteredItems(filteredItems);
  };
  if (seelctedCategoryIds.length > 0) {
    filterItemsByCategories();
  } else {
    filterItems();
  }
}, [seelctedCategoryIds, itemListingRes]);

  const onPriceChange = (minMax: number[]) => {
    setPriceRange(minMax); 
  };
  const onCategoryChange = (selectedCategoryIds) => {
    setSelectedCategoryIds(selectedCategoryIds);
    filterItems();
  };
  const onDeleteSuccess = async () => {
    await getItems();
  }
  const filteredItemsByPrice = useMemo(() => {
    return filteredItems.filter(item => item.price >= priceRange[0] );
  }, [filteredItems, priceRange]);

  return (
    <>
      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid display="flex" alignItems="center" item xs={12}>
          <IconButtonToggle
            sx={{
              mr: 1,
              display: { md: 'none', xs: 'flex' }
            }}
            color="primary"
            onClick={() => setMobileOpen(!mobileOpen)}
            size="small"
          >
            <MenuTwoToneIcon />
          </IconButtonToggle>
          <Box flex={1} mt={3}>
            <PageHeader type="list" title="Items" subtitle="Manage Items On this Page" route={'/'+location.pathname.split('/')[1]+"/inventory/items/new"} buttonTitle="Create Item"></PageHeader>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: { xs: 'none', md: 'block' }
          }}
        >
          {isLoading && <LinearProgress sx={{ my: 2 }} />}
          {!isLoading &&
            <Sidebar
              priceMin={filterVm.min }
              priceMax={filterVm.max}
              categories={filterVm.categories}
              ingredients={[]}
              onPriceChange={onPriceChange}
              onCategoryChange={onCategoryChange}
            />
          }
        </Grid>
        <Grid item xs={12} md={9}>
          {isLoading && <Box sx={{ width: '100%' }}>
            <Skeleton variant="rectangular" height={60} sx={{ mb: 3 }} />
            <Grid container spacing={3}>
              {[1, 2, 3, 4, 5, 6].map(o => <Grid key={o} item xs={6}>
                <Skeleton variant="rectangular" height={200} />
              </Grid>)}
            </Grid>
          </Box>}
          {!isLoading &&
            <Results
              items={filteredItemsByPrice} 
              categories={filterVm.categories.map(o => o.id)}
              ingredients={[]}
              onDeleteSuccess={onDeleteSuccess}
            />}
        </Grid>
      </Grid>
      <DrawerWrapperMobile
        sx={{
          display: { md: 'none', xs: 'flex' }
        }}
        variant="temporary"
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={mobileOpen}
        onClose={() => setMobileOpen(!mobileOpen)}
      >
        <Scrollbar>
          {filterVm &&
            <Sidebar
              priceMin={filterVm.min}
              priceMax={filterVm.max}
              categories={filterVm.categories}
              ingredients={[]}
              onPriceChange={onPriceChange}
              onCategoryChange={onCategoryChange}
            />
          }
        </Scrollbar>
      </DrawerWrapperMobile>
      {!isLoading && <Footer />}
    </>
  );
}

export default ItemList;
