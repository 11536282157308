import { useState } from 'react';

import {
  Box,
  useTheme
} from '@mui/material';
import FeatureImageCard from './FeatureImageCard';


function Sidebar(props: { onFeatureImageChange: (files: File) => void, isSubmit: boolean, isEdit: boolean, uploadedFileName?:string,itemId?:string}) {

  return (
      <Box>
          <FeatureImageCard onChange={(file) => props.onFeatureImageChange(file)} isSubmit={props.isSubmit} uploadedFileName={props.uploadedFileName}/>
    </Box>
  );
}

export default Sidebar;
