import * as React from 'react';
import {
    Box,
    Grid,
    Divider,
    styled,
    InputAdornment,
    TextField,
    Input,
    Button
} from '@mui/material';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { useTranslation } from 'react-i18next';
import { getListItems } from 'src/services/apiService';
import { useDispatch, useSelector } from 'src/store';
import { setActiveCategory, setSearchValue } from 'src/store/slices/priceMenu';

const ButtonWrapper = styled('div')(() => `
`)
const BoxContaineWrapper = styled(Box)(() => `
display: flex;
justify-content: space-between;
align-items: center;
overflow-x: auto;
::-webkit-scrollbar {
    display: none;
}
`)

const MenuItemHeader = () => {
    const { t }: { t: any } = useTranslation();
    const dispatch = useDispatch();
    const { priceMenuDetails, activeCat, searchText } = useSelector(
        (state: any) => state.priceMenu,
    );

    // const [searchValue, setSearchValue] = React.useState('');
    const [selectedItem, setSelectedItem] = React.useState(activeCat);

    const handleSearch = (value: any) => {
        dispatch(setSearchValue(value));
    };

    React.useEffect(() => {
        try {
            dispatch(getListItems());
        } catch (error) {
            console.log('error==>', error);
        }

    }, []);

    React.useEffect(() => {
        dispatch(
            setActiveCategory(
                priceMenuDetails?.priceMenuList?.length > 0
                    ? priceMenuDetails?.priceMenuList[0]?.title
                    : '',
            ),
        );
        setSelectedItem(
            priceMenuDetails?.priceMenuList?.length > 0
                ? priceMenuDetails?.priceMenuList[0]?.title
                : '',
        );
    }, [priceMenuDetails])

    const handleItemPress = item => {
        setSelectedItem(item === selectedItem ? null : item);
        dispatch(setActiveCategory(item));
        dispatch(setSearchValue(''));
    };

    return (
        <Box sx={{ borderTop: 1, borderBottom: 1 }} py={1.2}>
            <Grid container xs={12} px={4} alignItems={'center'}>
                <Grid item xs={7}>
                    <Input
                        id="customer"
                        name="customer"
                        placeholder={t('Search menu...')}
                        value={t(searchText)}
                        onChange={e => handleSearch(e.currentTarget.value)}
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchTwoToneIcon />
                            </InputAdornment>
                        }
                        sx={{ m: 0 }}
                        fullWidth
                        disableUnderline
                    />
                </Grid>
                <Grid item xs={5}>
                    <BoxContaineWrapper >
                        {priceMenuDetails?.priceMenuList?.map((item: any) => (
                            <ButtonWrapper onClick={() => handleItemPress(item?.title)
                            }>
                                <Box px={1} py={0.5} mr={0.5} border={1} borderRadius={3} borderColor={selectedItem === item?.title ? '#00AB55' : '#E4E4E4'} color={selectedItem === item?.title ? '#00AB55' : '#19191C'} fontWeight={selectedItem === item?.title ? '600' : '400'} sx={{
                                    backgroundColor: selectedItem === item?.title ? '#E6FFF394' : '#FFFFFF',
                                    cursor: 'pointer',
                                    whiteSpace: 'nowrap'
                                }} >
                                    {t(item?.title)}
                                </Box>
                            </ButtonWrapper>
                        ))}
                    </BoxContaineWrapper>
                </Grid>
            </Grid>
        </Box>
    );
}

export default MenuItemHeader;