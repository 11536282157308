import { useState, useEffect, useCallback, ChangeEvent } from 'react';
import axios from 'src/utils/axios';

import { Helmet } from 'react-helmet-async';
// import PageHeader from './PageHeader';
import PageHeader from 'src/components/page-header';
import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';

import { Card, Grid, InputAdornment, LinearProgress, Paper, TextField, Typography } from '@mui/material';
import useRefMounted from 'src/hooks/useRefMounted';
import { PriceMenu } from 'src/services/apiService/response-models';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import MenuBlock from './menu-block';
import { deletePriceMenuById, getPriceMenus } from 'src/services/apiService';
import DeleteConfirm from 'src/components/delete-confirm';


function PriceMenuList() {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [menus, setMenus] = useState<PriceMenu[]>([]);
  const [filteredMenus, setFilteredMenus] = useState<PriceMenu[]>([]);
  const [query, setQuery] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [idRequestedToDelete, setIdRequestedToDelete] = useState<string>('');

  const loadData = async () => {
    try {
    setIsLoading(true);
    const response = await getPriceMenus();
      setMenus(response.data);
      setFilteredMenus(response.data);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t('failed to load data'), { variant: 'error' });
    }
  }

  useEffect(() => {
    loadData().then(o=>setIsLoading(false));
  }, []);

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setQuery(event.target.value);
  };

  const onDeleteMenu=(id:string)=>{
    setIdRequestedToDelete(id);
  }
  const handleConfirmDelete=async()=>{
    try{
      const success = await deletePriceMenuById(idRequestedToDelete);
      if(success){
        loadData().then(o=>setIsLoading(false));
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t('failed to delete'), { variant: 'error' });
    }
    setIdRequestedToDelete('');
  }
  
  
  

  return (
    <>
       
      <PageTitleWrapper>
      <PageHeader type="list"  title="Price Menu" subtitle="All menu can be found below" route={'/'+location.pathname.split('/')[1]+"/inventory/price-menu/new"} buttonTitle="Add new menu"></PageHeader>
{/* 
        <PageHeader /> */}
      </PageTitleWrapper>

      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
        <Card
        sx={{
          p: 2,
          mb: 3,
          display: 'flex',
          alignItems: 'center'
        }}
      >

        <Grid alignItems="center" container spacing={3}>
          {isLoading  && <Grid item xs={12}>
            <LinearProgress />
          </Grid>}
          <Grid item xs={12} lg={7} md={6}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                )
              }}
              sx={{
                m: 0
              }}
              onChange={handleQueryChange}
              placeholder={t('Search menus by item ...')}
              value={query}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} lg={5} md={6}>
              
          </Grid>
        </Grid>
      </Card>
        </Grid>
        {filteredMenus.length==0 && !isLoading && <Grid item xs={12}>
            <div style={{height:400,padding:10}}>
                <Typography variant='subtitle2'>
                    No record to display
                </Typography>
              </div>
        </Grid>}
       <Grid item xs={12}>
        <Grid container spacing={3}>
              {filteredMenus.map(o=>  
              <Grid  key={o.id}  item xs>
                    <MenuBlock menu={o} onDelete={onDeleteMenu}/>
                </Grid>
                )}
            </Grid>
        </Grid>
      </Grid>
      <Footer />
      <DeleteConfirm message='Are you sure want to delete menu?' 
            okText="Yes" cancelText="No"
            onClose={()=>setIdRequestedToDelete('')} open={Boolean(idRequestedToDelete.length)}
           onDeleteConfirm={()=> handleConfirmDelete()}  />   
    </>
  );
}

export default PriceMenuList;
