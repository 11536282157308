import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// import PageHeader from './PageHeader';
import PageHeader from 'src/components/page-header';
import { Box, Drawer, Grid, useTheme, IconButton, styled, LinearProgress, Card, Skeleton, Stack } from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';

import Sidebar from './Sidebar';

import GeneralSection from './GeneralSection';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import useRefMounted from 'src/hooks/useRefMounted';
import { AddOnCategory, AddOnItem } from '../../../../services/apiService/response-models';
import { getAddOnItemById,  parseToApiErrorMessage, saveAddOnItem } from '../../../../services/apiService';

const DrawerWrapper = styled(Drawer)(
    ({ theme }) => `
    width: 400px;
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: 400px;
        height: calc(100% - ${theme.header.height});
        position: absolute;
        top: ${theme.header.height};
        right: 0;
        z-index: 3;
        background: ${theme.colors.alpha.white[10]};
    }
`
);

const DrawerWrapperMobile = styled(Drawer)(
    ({ theme }) => `
    width: 360px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 360px;
        z-index: 3;
        background: ${theme.colors.alpha.white[30]};
  }
`
);

const MainContentWrapper = styled(Box)(
    () => `
  flex-grow: 1;
`
);

const IconButtonToggle = styled(IconButton)(
    ({ theme }) => `
  width: ${theme.spacing(6)};
  height: ${theme.spacing(6)};
`
);

function AddOnItemCreate() {
    const theme = useTheme();
    const navigate = useNavigate();
    const { id } = useParams();

    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [model, setModel] = useState< AddOnItem | null>();
    const [isSaveInProgress, setIsSaveInProgress] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);
    const [featureImage, setFeatureImage] = useState('');
    const [isEdit,setIsEdit] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);
    
    const getById = async (itemId: string) => {
        try {
            const response = await getAddOnItemById(itemId);
            setIsEdit(true);
            setFeatureImage(response.data.featureImage);
            setModel(response.data);
        } catch (ex) {
            console.error(ex);
            const msg = ex.message ? ex.message : `Failed to load add-on item by id`;
            enqueueSnackbar(t(msg), { variant: 'error' });
            navigate("/inventory/add-on-items/list", { replace: true });
        }
    };

    useEffect(() => {
        if (id && id.length > 0) {
            setIsLoading(true);
            getById(id).then(() => {
                setIsLoading(false);
            });
        }
    }, []);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const onFeatureImageChange = (file: File): void => {
        const name = (file as any).blobName;
        setFeatureImage(name);
    }
    const onSave = async (updatedModel: AddOnItem) => {
        let isSuccess = false;
        try {
            //if featureImage not uploaded
            if (!featureImage) {
                enqueueSnackbar(t(`Please upload Feature image`), { variant: 'error' });
                return isSuccess;
            }
            updatedModel.featureImage = featureImage;
            setIsSaveInProgress(true);
            await saveAddOnItem(updatedModel);
            enqueueSnackbar(t(`Add-On Item was ${!isEdit ? 'created' : 'updated'} successfully`), { variant: 'success' });
            isSuccess = true;
            navigate('/'+location.pathname.split('/')[1]+"/inventory/add-on-items/list", { replace: true });
            setIsSaveInProgress(false);
        } catch (ex) {
            setIsSaveInProgress(false);
            console.log(ex);
            const msg = parseToApiErrorMessage(ex, `Failed to ${!isEdit ? 'created' : 'updated'} Add-On item`);
            enqueueSnackbar(t(msg), { variant: 'error' });
        }
        return isSuccess;
    }

    const sidebarContent = (
        <Scrollbar>
            <Sidebar onFeatureImageChange={onFeatureImageChange} isSubmit={isSubmitAttempted} isEdit={isEdit} uploadedFileName={featureImage} itemId={model?.id}/>
        </Scrollbar>
    );

    return (
        <>
            <Helmet>
                <title>Create Brand - Brand Management</title>
            </Helmet>
            <Box mb={3} display="flex">
                <MainContentWrapper>
                    <Grid
                        sx={{
                            px: 4
                        }}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={4}
                    >
                        <Grid item xs={12}>
                            <Box
                                mt={3}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                {/* <PageHeader isEdit={isEdit} /> */}
                                <PageHeader type="create"  title="AddOn Item" subtitle="Manage addon Item" route={'/'+location.pathname.split('/')[1]+	"/inventory/add-on-items/list"} buttonTitle="Go Back To AddOn Items"></PageHeader>
                                <Box
                                    component="span"
                                    sx={{
                                        display: { lg: 'none', xs: 'inline-block' }
                                    }}
                                >
                                    <IconButtonToggle
                                        sx={{
                                            ml: 2
                                        }}
                                        color="primary"
                                        onClick={handleDrawerToggle}
                                        size="small"
                                    >
                                        <MenuTwoToneIcon />
                                    </IconButtonToggle>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            {isLoading && <Card
                                sx={{
                                    p: 3
                                }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Skeleton variant="rectangular" height={60} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Skeleton variant="rectangular" height={60} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Skeleton variant="rectangular" width="40%" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Skeleton variant="rectangular" width="20%" />
                                    </Grid>
                                </Grid>
                            </Card>}
                            {!isLoading && <GeneralSection model={model} onSave={onSave} isSaveInProgress={isSaveInProgress} onSaveClick={() => setIsSubmitAttempted(true)} />}
                        </Grid>
                      

                        <Grid item xs={12}
                        sx={{
                            display: { xs: "none", md: "block" }
                        }}>
                            {/*    for side bar height*/}
                        <div style={{ height: '250px' }}>&nbsp;</div>
                        </Grid>
                    </Grid>
                </MainContentWrapper>
                <Box
                    component="span"
                    sx={{
                        display: { lg: 'none', xs: 'inline-block' }
                    }}
                >
                    <DrawerWrapperMobile
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                    >
                        {sidebarContent}
                    </DrawerWrapperMobile>
                </Box>
                <Box
                    component="span"
                    sx={{
                        display: { xs: 'none', lg: 'inline-block' }
                    }}
                >
                    <DrawerWrapper
                        variant="permanent"
                        anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                        open
                    >
                        {sidebarContent}
                    </DrawerWrapper>
                </Box>
            </Box>
        </>
    );
}

export default AddOnItemCreate;