import type { ReactNode } from 'react';

import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import StackedBarChartTwoToneIcon from '@mui/icons-material/StackedBarChartTwoTone';
import LocalAtmTwoToneIcon from '@mui/icons-material/LocalAtmTwoTone';
import ShoppingBagTwoToneIcon from '@mui/icons-material/ShoppingBagTwoTone';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import InsertInvitationTwoToneIcon from '@mui/icons-material/InsertInvitationTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import PersonTwoToneIcon from '@mui/icons-material/PersonTwoTone';
import DiscountTwoToneIcon from '@mui/icons-material/DiscountTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import { PortalNames } from 'src/store/app-state';
import TableBarTwoToneIcon from '@mui/icons-material/TableBarTwoTone';
import RecommendTwoToneIcon from '@mui/icons-material/RecommendTwoTone';
import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const app=PortalNames.PosAdmin;

const PosMenuItems: MenuItems[] = [
  {
    heading: 'General',
    items: [
      {
        name: 'Home',
        icon: HomeTwoToneIcon,
        link: app + '/home',
      },
      {
        name: 'Reporting',
        icon: StackedBarChartTwoToneIcon,
        link: app + '/home',
        items: [
          {
            name: 'Daily Report',
            link: app +  '/dashboards/reports'
          }
        ]
      },
      {
        name: 'Orders',
        icon: ShoppingBagTwoToneIcon,
        link: app + '/home',
        items: [
          {
            name: 'Today',
            link: app + '/orders/today/list'
          },
          {
            name: 'List',
            link: app +  '/orders/list'
          }
        ]
      },
      // {
      //   name: 'Close Out',
      //   link: app + '/home',
      //   icon: InsertInvitationTwoToneIcon,
      // },
      // {
      //   name: 'Cash Log',
      //   link: app + '/home',
      //   icon: MonetizationOnTwoToneIcon,
      // }
    ]
  },
  {
    heading: 'Management',
    items: [
     
      {
        name: 'Inventory',
        icon: ShoppingCartTwoToneIcon,
        items: [
          {
            name: 'Item Category',
            link: app + '/inventory/category/list'
          },
          {
            name: 'Item Variants',
            link: app + '/inventory/item-variants/list'
          },
         
          {
            name: 'Add-On Categories',
            link: app + '/inventory/addOnCategories/list'
          },
          {
            name: 'Add-On Items',
            link: app + '/inventory/add-on-items/list'
          },
          // {
          //   name: 'Ingredients',
          //   link: 'inventory/ingredients/list'
          // },
          {
            name: 'Items',
            link: app + '/inventory/items/list'
          },
          {
            name: 'Price Menu',
            link: app + '/inventory/price-menu/list'
          },
          {
            name: 'Recommendation',
            link: app + '/inventory/item/recommend'
          }
        ]
      },
      {
        name: 'Customers',
        icon: AssignmentIndTwoToneIcon,
        items: [
          {
            name: 'List',
            link: app + '/manage/customer/list'
          }
        ]
      },
      {
        name: 'Employees',
        icon: PersonTwoToneIcon,
        items: [
          {
            name: 'List',
            link: app + '/manage/employee/list'
          },
          {
            name: 'New',
            link: app + '/manage/employee/new'
          }
        ]
      },
      {
        name: 'Tables',
        icon: TableBarTwoToneIcon,
        link: app + '/manage/tables'
      },
      {
        name: 'Devices ',
        icon: CreditCardTwoToneIcon,
        // items: [
        //   {
        //     name: 'List',
        //     link: app + '/manage/devices/list'
        //   },
        //   {
        //     name: 'New',
        //     link: app + '/manage/devices/new'
        //   }
        // ]
        link: app + '/manage/devices/list'
      }
     
    ]
  },
  {
    heading: 'Apps',
    items: [
      {
        name: 'Account & Setup',
        icon: SettingsTwoToneIcon,
        link: app + '/account/setup'
      }
    ]
  },
];

export default PosMenuItems;
