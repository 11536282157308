import { Card, CardHeader, Divider, Box, styled, IconButton, List, ListItem, ListItemText, alpha, useTheme, Button, Typography, Dialog, DialogTitle, DialogContent, Grid, TextField, Autocomplete } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import Scrollbar from "src/components/Scrollbar";
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';
import { useTranslation } from "react-i18next";
import { Ingredient } from "src/services/apiService/response-models";
import { useField } from "formik";
import IngredientEditor from "../../ingredient/editor";
import { getIngredients } from "src/services/apiService";
import { useSnackbar } from "notistack";


const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
    transition: ${theme.transitions.create(['transform', 'background'])};
    transform: scale(1);
    transform-origin: center;

    &:hover {
        transform: scale(1.1);
    }
  `
);

const ListWrapper = styled(List)(
    () => `
      .MuiListItem-root:last-of-type + .MuiDivider-root {
          display: none;
      }
  `
);


function IngredientsCard() {
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const theme = useTheme();
    const [ field, meta, helpers  ] = useField('ingredients');
    const [selectedItems, setSelectedItems] = useState<Ingredient[]>(field.value || []);
    const [allIngredients, setAllIngredients] = useState<Ingredient[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    
    const loadData = async () => {
        try {
            const response = await getIngredients();
            setAllIngredients(response.data);
            if(field.value){
                const ids=(field.value as Ingredient[]).map(o=>o.id);
                setSelectedItems(response.data.filter(o=>ids.includes(o.id)));
            }
        } catch (ex) {
            console.error(ex);
            const msg = ex.message ? ex.message : `Failed to load ingredients`;
            enqueueSnackbar(t(msg), { variant: 'error' });
        }
    };

    useEffect(() => {
        setIsLoading(true);
        (async () => {
           await loadData();
           setIsLoading(false);
          })();

        setSelectedItems(field.value)
    }, []);

    useEffect(() => {
        if(field.value){
            const ids=(field.value as Ingredient[]).map(o=>o.id);
            setSelectedItems(allIngredients.filter(o=>ids.includes(o.id)));
        }
    }, [field.value]);

    const onAddClick = () => {
        setIsModalOpen(true);
    }
    const onNewItemAdded= (e:Ingredient) => {
        setIsModalOpen(false);
        setAllIngredients([...allIngredients,e]);
        setSelectedItems([...selectedItems,e]);
    }

    const handleDelete = (itemToDelete: Ingredient) => {
        setSelectedItems((items) =>
            items.filter(
                (o) => o.id !== itemToDelete.id
            )
        );
        helpers.setValue(selectedItems.filter(
            (o) => o.id !== itemToDelete.id
        ).map(o => { return { id: o.id } }));
    };


    const onSelection = (items: Ingredient[]) => {
        setSelectedItems(items);
        helpers.setValue(items.map(o => { return { id:  o.id} }));
        setIsModalOpen(false);
    }

    return (<>
        <Card
        sx={{
            m: 3
        }}
    >
        <CardHeader
            action={
                <Button onClick={onAddClick} size="small" variant="outlined">
                    {t('Add...')}
                </Button>
            }
            title={t('Ingredients')}
        />
        <Divider />
        <Box p={2} sx={{
        }}>
            <Box
                
                >
                    <Autocomplete
                        disablePortal
                        filterSelectedOptions
                        multiple
                        onChange={(e,newValue)=>{
                            if(newValue){
                                onSelection(newValue as Ingredient[]);
                            }
                        }}
                        id="ing-tags"
                        value={selectedItems}
                        options={allIngredients}
                        getOptionLabel={(option:Ingredient) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label=""
                                placeholder="Select..."
                            />
                        )}
                    />
            </Box>
        </Box>
        </Card>
        {isModalOpen && <ModalPopUp isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSubmit={onNewItemAdded}  />}
    </>);
}

function ModalPopUp(props: { isOpen: boolean, onClose: () => void, onSubmit: (item: Ingredient) => void, model?: Ingredient }) {
    const { t }: { t: any } = useTranslation();

    return (<Dialog
        fullWidth
        maxWidth="md"
        open={true}
    >
        <DialogTitle
            sx={{
                p: 3
            }}
        >
            <Typography variant="h4" gutterBottom>
                {t('Add ingredient')}
            </Typography>
        </DialogTitle>
        <DialogContent>
            <IngredientEditor id={''} onSave={props.onSubmit} onCancel={props.onClose} />
        </DialogContent>
    </Dialog>)
}

export default IngredientsCard;