
import { ChangeEvent, forwardRef, ReactElement, Ref, useCallback, useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Radio,
    FormControlLabel,
    Typography,
    Card,
    CardHeader,
    Divider,
    lighten,
    CardActionArea,
    CardContent,
    Tooltip,
    IconButton,
    Zoom,
    Avatar,
    styled,
    Dialog,
    DialogTitle,
    TableContainer,
    Button,
    TableFooter,
    TableCell,
    TableRow,
    TextField,
    useMediaQuery,
    DialogContent,
    Autocomplete,
    useTheme,
    TableBody,
    CircularProgress,
    Table,
    TableHead,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Paper,
    Chip,
    Skeleton,
    FormHelperText,
    SelectChangeEvent,
    Stack,
    Slide,
    createFilterOptions,
    ListItem,
    alpha,
    InputAdornment,
    Alert,
    List,
    ListItemAvatar,
    ListItemText
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { Field, Form, Formik, useField } from 'formik';
import { TextField as FmTextFiled, Switch } from 'formik-mui';
import * as Yup from 'yup';
import React from 'react';
import { ItemVariant, ItemVariantMain } from 'src/services/apiService/response-models';
import _, { iteratee } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import DeleteConfirm from 'src/components/delete-confirm';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { deleteItemVariantById, getItemVariants, parseToApiErrorMessage } from 'src/services/apiService';



const ListItemWrapper = styled(ListItem)(
    ({ theme }) => `
      position: relative;
      transition: ${theme.transitions.create(['background'])};

      .MuiActionButtons {
            background: ${alpha(theme.colors.alpha.white[100], 0.95)};
            border-radius: ${theme.general.borderRadius};
            opacity: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            visibility: hidden;
            top: 50%;
            margin-top: -${theme.spacing(3.5)};
            position: absolute;
            right: ${theme.spacing(1.5)};
            padding: ${theme.spacing(0.5, 1)};
            transition: ${theme.transitions.create(['visibility', 'opacity'])};

            .MuiIconButton-root {
                border-radius: 100px;
                width: ${theme.spacing(5)};
                height: ${theme.spacing(5)};
                margin: ${theme.spacing(0.5)};
            }
      }

      &:hover {
          background: ${alpha(theme.colors.secondary.main, 0.15)};

          .MuiActionButtons {
            visibility: visible;
            opacity: 1;
      }   
      }
  `
);


function VariantList(props: { onEdit: (id: string) => void, onDelete: (id: string) => void  , flag}) {
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [items, setItems] = useState<ItemVariantMain[]>([]);
    const [idRequestedToDelete, setIdRequestedToDelete] = useState<string>('');
    const [searchQuery, setSearchQuery] = useState<string>(''); // State to store the search query
    const [resetFlag, setResetFlag] = useState(0);

    console.log('Flag value in VariantList: 125>>>>', props.flag);
    const loadItems = async () => {
        setIsLoading(true);
        try {
            const res = await getItemVariants();
            setItems(res.data);
        } catch (ex) {
            console.log(ex);
            const msg = parseToApiErrorMessage(ex, `Failed to load variants`);
            enqueueSnackbar(t(msg), { variant: 'error' });
        }
        setIsLoading(false)
    }

    useEffect(() => {
        console.log('Flag value in VariantList:', props.flag);
        loadItems();
        // props.flag(0)
    }, [props.flag ,resetFlag]);

    // Filter items based on the search query
    const filteredItems = items.filter(item =>
        item.type.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (item.names || []).join(', ').toLowerCase().includes(searchQuery.toLowerCase())
    );

    // VariantList component

    const onEdit = (id: string) => {
        console.log(`Edit button clicked for ID: ${id}`);
        props.onEdit(id);
      }


    const onDelete = (id: string) => {
        setIdRequestedToDelete(id);
    }

    const handleDeleteConfirm = async () => {
        if (idRequestedToDelete) {
            try {
                await deleteItemVariantById(idRequestedToDelete);
                enqueueSnackbar(t('Successfully deleted the variant'), {
                    variant: 'success',
                    TransitionComponent: Zoom
                });
                props.onDelete(idRequestedToDelete);

                // Reset idRequestedToDelete to empty string after successful deletion
                setIdRequestedToDelete('');

                // Reload items after deletion
                await loadItems();
            } catch (ex) {
                enqueueSnackbar(t('Failed to delete the variant'), {
                    variant: 'error'
                });
            }
        }
    };

    return (
        <>
            <Card sx={{ p: 2 }}>
                <CardHeader
                    title={t('All Variants')}
                >

                </CardHeader>


                <Grid item xs={12}>
                    <Card variant="outlined">
                        <Box
                            flex={1}
                            p={2}
                            display={{ xs: 'block', md: 'flex' }}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box
                                sx={{
                                    mb: { xs: 2, md: 0 }
                                }}
                            >
                                <TextField
                                    size="small"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchTwoToneIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder={t('Search variants...')}
                                    value={searchQuery} // Controlled input value
                                    onChange={(e) => setSearchQuery(e.target.value)} // Update searchQuery state
                                />
                            </Box>

                        </Box>
                        {!isLoading && !errorMsg && filteredItems.length === 0 && (
              <Typography
                sx={{
                  py: 10
                }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {t("No record to display")}
              </Typography>
            )}
                        {!isLoading && errorMsg && <Alert severity="error">Failed to load data</Alert>}
                        {isLoading && Array.from(Array(5).keys()).map(o => <Skeleton sx={{ mx: 2 }} key={o} variant="text" height={80} />)}
                        <Divider />
                        <List disablePadding>
                            {filteredItems.map((item) =>
                                <>
                                    <ListItemWrapper
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 3.15
                                        }}
                                    >
                                        <Box className="MuiActionButtons">
                                            <Tooltip
                                                arrow
                                                placement="top"
                                                title={t('Edit variant')}
                                            >
                                                <IconButton onClick={() => onEdit(item.id)} color="primary">
                                                    <EditTwoToneIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip arrow placement="top" title={t('Delete size')}>
                                                <IconButton onClick={() => onDelete(item.id)} color="error">
                                                    <DeleteTwoToneIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                        <ListItemAvatar
                                            sx={{
                                                minWidth: 0
                                            }}
                                        >
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography lineHeight={1} gutterBottom variant="h4">
                                                    {t(item.type)}
                                                </Typography>
                                            }
                                            secondary={
                                                <>
                                                    <Typography
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                        noWrap
                                                        variant="subtitle2"
                                                    >

                                                        <Box pl={0.5} component="span">
                                                            {(item.names || []).join(', ')}
                                                        </Box>
                                                    </Typography>
                                                </>
                                            }
                                        />
                                        <Box
                                            sx={{
                                                textAlign: 'right'
                                            }}
                                            alignSelf="center"
                                        >

                                        </Box>
                                    </ListItemWrapper>
                                    <Divider />
                                </>
                            )}
                        </List>
                    </Card>
                </Grid>

            </Card>
            <DeleteConfirm
                open={Boolean(idRequestedToDelete)}
                onClose={() => setIdRequestedToDelete('')}
                onDeleteConfirm={handleDeleteConfirm}
            />

        </>
    )

}

export default VariantList;