import { useEffect, useState } from 'react';

import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Card,
  ListItemIcon,
  Slider,
  Checkbox,
  List,
  ListItemButton,
  ListItemText,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NameItem } from 'src/services/apiService/response-models';

const AccordionSummaryWrapper = styled(AccordionSummary)(
  () => `
      &.Mui-expanded {
        min-height: 48px;
      }

      .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0;
      }
  `
);

const ListItemWrapper = styled(ListItemButton)(
  () => `
      &.MuiButtonBase-root {
        border-radius: 0;
      }
  `
);

function Sidebar(props: {
  priceMin: number,
  priceMax: number,
  categories: NameItem[],
  ingredients: NameItem[],
  onPriceChange: (minMax: number | number[]) => void,
  onCategoryChange: (categoryIds: string[]) => void
}) {
  const { t }: { t: any } = useTranslation();
  const [checked2, setChecked2] = useState<string[]>([]); 
  const [checked3, setChecked3] = useState([0]);
  const [value, setValue] = useState<number[]>([props.priceMin, props.priceMax]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>([]);

  useEffect(() => {
    setChecked2(selectedCategoryIds); 
  }, [props.categories, selectedCategoryIds]);
  
  const handleCategories = (categoryId: string) => () => {
    const isChecked = checked2.includes(categoryId);
    const newChecked = isChecked
      ? checked2.filter((item) => item !== categoryId)
      : [...checked2, categoryId];

    setChecked2(newChecked);
    props.onCategoryChange(newChecked);

    const selectedCategoryIds = newChecked;
    console.log('Selected Category IDs:', selectedCategoryIds);
  };
  
  
  const handlePriceChange = (newValue: number | number[]) => {
    props.onPriceChange(newValue);
  };
  const handleCategoryToggle = (categoryId: string) => {
    if (selectedCategoryIds.includes(categoryId)) {
      setSelectedCategoryIds((prevSelectedCategoryIds) =>
        prevSelectedCategoryIds.filter((id) => id !== categoryId)
      );
    } else {
      setSelectedCategoryIds((prevSelectedCategoryIds) =>
        [...prevSelectedCategoryIds, categoryId]
      );
    }
  };
  const handleToggle3 = (value: number) => () => {
    const currentIndex = checked3.indexOf(value);
    const newChecked = [...checked3];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked3(newChecked);
  };


  const handleChange = (_event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  const handleChangeCommitted = (_event: Event, newValue: number | number[]) => {
    props.onPriceChange(newValue);
  };

  return (
    <Card>
      <Accordion defaultExpanded>
        <AccordionSummaryWrapper expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">{t('Price Range')}</Typography>
        </AccordionSummaryWrapper>
        <AccordionDetails
          sx={{
            pt: 5
          }}
        >
          <Slider
            value={value}
            step={1}
            min={props.priceMin}
            max={props.priceMax}
            onChange={handleChange}
            onChangeCommitted={handleChangeCommitted}
            valueLabelDisplay="on"
            valueLabelFormat={(value) => <div>${value}</div>}
          />
        </AccordionDetails>
      </Accordion>
      <Divider />
      <Accordion defaultExpanded>
        <AccordionSummaryWrapper expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">{t('Categories')}</Typography>
        </AccordionSummaryWrapper>
        <AccordionDetails
          sx={{
            p: 0
          }}
        >
          <List component="div">
            {props.categories.map((item) => {
              return (
                <ListItemWrapper
                sx={{
                  py: 0,
                  px: 2
                }}
                key={item.id}
                onClick={handleCategories(item.id)} 
              >
                  <ListItemIcon
                    sx={{
                      minWidth: 32
                    }}
                  >
                    <Checkbox
                      edge="start"
                      checked={checked2.includes(item.id)} 
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    primaryTypographyProps={{ variant: 'body1' }}
                  />
                </ListItemWrapper>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion>
      {props.ingredients.length > 0 &&
        <>
          <Divider />
          <Accordion defaultExpanded>
            <AccordionSummaryWrapper expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">{t('Ingredients')}</Typography>
            </AccordionSummaryWrapper>
            <AccordionDetails
              sx={{
                p: 0
              }}
            >
              <List component="div">
  {props.categories.map((item) => {
    return (
      <ListItemWrapper
        sx={{
          py: 0,
          px: 2
        }}
        key={item.id}
        onClick={handleCategories(item.id.toString())} 
      >
        <ListItemIcon
          sx={{
            minWidth: 32
          }}
        >
          <Checkbox
            edge="start"
            checked={checked2.includes(item.id.toString())} 
            tabIndex={-1}
            disableRipple
          />
        </ListItemIcon>
        <ListItemText
          primary={item.name}
          primaryTypographyProps={{ variant: 'body1' }}
        />
      </ListItemWrapper>
    );
  })}
</List>
            </AccordionDetails>
          </Accordion>
        </>
      }
    </Card>
  );
}

export default Sidebar;
