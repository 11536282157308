import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { AppState, PortalNames, UserInfo } from '../app-state';
import { Restaurant } from '../../services/apiService/response-models';
import readTenantCodeFromUrl from 'src/utils/readTenantCodeFromUrl';
 

const initialState: AppState = {
   sidebarOpen:false,
   portal:PortalNames.PosAdmin,
   tenantCode:readTenantCodeFromUrl(),
};

const slice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setBrand(
            state: AppState,
            action: PayloadAction<{ brand: Restaurant }>
        ) {
            const { brand } = action.payload;
           // state.brand = _.cloneDeep(brand);
        },
        setUser(
            state: AppState,
            action: PayloadAction<{ user: UserInfo }>
        ) {
            const { user } = action.payload;

           // state.user = { ...user };
        },
        openSidebar(state: AppState): void {
            state.sidebarOpen = true;
        },
        closeSidebar(state: AppState): void {
            state.sidebarOpen = false;
        },
        showAppLoader(state: AppState): void {
           (document.getElementsByClassName('main_app_loader_container') as any)[0].style.display='block';
            state.appLoaderVisible = true;
        },
        hideAppLoader(state: AppState): void {
            (document.getElementsByClassName('main_app_loader_container') as any)[0].style.display='none';
            state.appLoaderVisible = false;
        },
        changePortal(state: AppState,action: PayloadAction<{ portal: PortalNames }>): void {
            if(state.portal!=action.payload.portal){
                state.portal = action.payload.portal;
            }
        },
    }
});

const { actions, reducer } = slice;

export const {  setBrand,setUser,closeSidebar,openSidebar,showAppLoader ,hideAppLoader,changePortal} = actions;
export default reducer
