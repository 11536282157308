import { FC, useState, ChangeEvent, MouseEvent, forwardRef,Ref, ReactElement } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import {
  Grid,
  Typography,
  Card,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Avatar,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Rating,
  Slide,
  Divider,
  styled,
  TablePagination,
  CircularProgress,
  Tooltip,
  Zoom,
  Stack,
  Drawer,
  Dialog
} from '@mui/material';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import Text from 'src/components/Text';
import { useSnackbar } from 'notistack';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone';
import _ from 'lodash';
import { deleteItemById } from 'src/services/apiService';
import CloseIcon from '@mui/icons-material/Close';
import { ListingItemVm } from 'src/services/apiService/response-models/view-models';
import { NameItem } from 'src/services/apiService/response-models';
import cdnService from 'src/services/cdnService';
import HtmlDescription from 'src/components/html-description';
import { TransitionProps } from '@mui/material/transitions';

export const DialogWrapper = styled(Dialog)(
  () => `
    .MuiDialog-paper {
      overflow: visible;
    }
`
);

export const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DrawerWrapper = styled(Drawer)(
  ({ theme }) => `
  width: 400px;
  flex-shrink: 0;
  z-index: 3;

  & > .MuiPaper-root {
      width: 400px;
      height: calc(100% - ${theme.header.height});
      position: absolute;
      top: ${theme.header.height};
      right: 0;
      z-index: 3;
      background: ${theme.colors.alpha.white[10]};
  }
`
);

const DrawerWrapperMobile = styled(Drawer)(
  ({ theme }) => `
  width: 360px;
  flex-shrink: 0;

& > .MuiPaper-root {
      width: 360px;
      z-index: 3;
      background: ${theme.colors.alpha.white[30]};
}
`
);

export const AvatarError = styled(Avatar)(
  ({ theme }) => `
    background-color: ${theme.colors.error.lighter};
    color: ${theme.colors.error.main};
    width: ${theme.spacing(12)};
    height: ${theme.spacing(12)};

    .MuiSvgIcon-root {
      font-size: ${theme.typography.pxToRem(45)};
    }
`
);

export const ButtonError = styled(Button)(
  ({ theme }) => `
   background: ${theme.colors.error.main};
   color: ${theme.palette.error.contrastText};

   &:hover {
      background: ${theme.colors.error.dark};
   }
  `
);

interface ResultsProps {
  items: ListingItemVm[];
  categories: string[]; // array of selected category id's
  ingredients: NameItem[];
  onDeleteSuccess: () => void;
}

const applyFilters = (items: ListingItemVm[], query: string, prices: number[], categories: string[]): ListingItemVm[] => {
  return items.filter((product) => {
    let matches = true;
    if (query) {
      const properties = ['name', 'description'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (product[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    if (prices.length > 0) {
      const min = prices[0];
      const max = prices[1];
      const itemPrices = [product.price];
      const found = itemPrices.every(price => {
        if (price >= min && price <= max) return false
        else return true
      })
      if (found) {
        matches = false;
      }
    }

    if (categories.length > 0) {
      const itemCategories = product.categories.map(category => category.id) || [];
      const found = categories.some(r => itemCategories.includes(r));
      if (!found) {
        matches = false;
      }
    }

    return matches;
  });
};

const applyPagination = (
  items: ListingItemVm[],
  page: number,
  limit: number
): ListingItemVm[] => {
  return items.slice(page * limit, page * limit + limit);
};

const Results: FC<ResultsProps> = ({ items,  categories, ingredients, onDeleteSuccess }) => {
    const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(50);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [query, setQuery] = useState<string>('');
  const [toggleView, setToggleView] = useState<string | null>('grid_view');
  const location = useLocation();
  const [deleteInProgressIds, setDeleteInProgressIds] = useState<string[]>([]);
  const [idRequestedToDelete, setIdRequestedToDelete] = useState<string>('');
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  console.log("results page data",items);
  
  const handleViewOrientation = (
    _event: MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    setToggleView(newValue);
  };

  const handleCart = () => {
    enqueueSnackbar(t('You added a product to cart'), {
      variant: 'success',
      preventDuplicate: false,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      TransitionComponent: Slide
    });
  };

  const handlePageChange = (_event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setQuery(event.target.value);
  };

  const filteredProducts = applyFilters(items, query, [], categories);
  const paginatedProducts = applyPagination(filteredProducts, page, limit);

  const AvatarWrapper = styled(Box)(
    ({ theme }) => `
      .MuiAvatar-root {
        height: ${theme.spacing(20)};
        width: ${theme.spacing(20)};
        transition: ${theme.transitions.create(['opacity'])};

        &:hover {
          opacity: .8;
        }
      }
`
  );

  const IconButtonError = styled(IconButton)(
    ({ theme }) => `
       color: ${theme.colors.error.main};
  
       &:hover {
        background: ${theme.colors.error.lighter};
      }
  `
  );

  const handleConfirmDelete = (id: string) => {
    setIdRequestedToDelete(id);
    setOpenConfirmDelete(true);
    };

    const handleCloneItem = (id: string) => {
        if (id) {
          //  navigate("/app/menu/manage/food-item/create/from/" + id, { replace: true });
        }
    };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleDeleteCompleted = async () => {
    setOpenConfirmDelete(false);
    if (idRequestedToDelete) {
      setDeleteInProgressIds([...deleteInProgressIds, idRequestedToDelete]);
      try {
        await deleteItemById(idRequestedToDelete);
        onDeleteSuccess();
        enqueueSnackbar(t('You successfully deleted the item'), {
          variant: 'success',
          TransitionComponent: Zoom
        });
      } catch (ex) {
        enqueueSnackbar(t('Failed to delete item'), {
          variant: 'error'
        });
      }
      setDeleteInProgressIds(deleteInProgressIds.filter(o => o !== idRequestedToDelete));
    }
  };


  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <TextField
              fullWidth
              onChange={handleQueryChange}
              value={query}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                )
              }}
              placeholder={t('Search by keyword...')}
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              <Typography component="span" variant="subtitle1">
                {t('Showing')}:
              </Typography>{' '}
              <b>
                {filteredProducts.length} {t('items')}
              </b>
            </Box>
            <ToggleButtonGroup
              value={toggleView}
              exclusive
              onChange={handleViewOrientation}
            >
              <ToggleButton disableRipple value="list_view">
                <TableRowsTwoToneIcon />
              </ToggleButton>
              <ToggleButton disableRipple value="grid_view">
                <GridViewTwoToneIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid>

        {toggleView === 'grid_view' && (
          <>
            {paginatedProducts.length === 0 ? (
              <Grid item xs={12}>
                <Typography
                  sx={{
                    py: 10
                  }}
                  variant="h3"
                  fontWeight="normal"
                  color="text.secondary"
                  align="center"
                >
                  {t(
                    "We couldn't find any items matching your search criteria"
                  )}
                </Typography>
              </Grid>
            ) : (
              <>
                {paginatedProducts.map((product) => {
                  return (
                    <Grid key={product.id} item xs={12} md={6}>
                      <Card>
                        <AvatarWrapper
                          p={3}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Avatar
                           
                            component={RouterLink}
                            to={
                              `/${location.pathname.split('/')[1]
                              }/inventory/items/edit/` + product.id
                            }
                            src={cdnService.toImageUrl(product.featureImage)}
                            variant="rounded"
                          />
                        </AvatarWrapper>
                        <Box textAlign="center" px={3}>
                          <Typography
                            variant="h3"
                            sx={{
                              mb: 1
                            }}
                          >
                            {product.name}
                          </Typography>
                          <Typography variant="h6" color="text.secondary">
                            <HtmlDescription value={product.description || '[No description]'} maxLen={200} />
                          </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center">
                          <Box>
                            <Typography component="span" variant="h3">
                              <Text >
                                {typeof product.price === 'number' ? `$${product.price.toFixed(2)}` : 'Price not available'}
                              </Text>
                            </Typography>
                          </Box>
                        </Box>
                        <Divider />
                        <Box
                          p={2}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                              >
                                  <Stack direction="row">
                          <>
                            {deleteInProgressIds.includes(product.id) && <CircularProgress size="20px" />}
                            {!deleteInProgressIds.includes(product.id) &&
                              <Typography noWrap>
                                <Tooltip title={t('Delete')} arrow>
                                  <IconButton
                                    onClick={() => handleConfirmDelete(product.id)}
                                    color="error"
                                  >
                                    <DeleteTwoToneIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Typography>}
                                  </>
                                  <Typography noWrap>
                                      <Tooltip title={t('Copy & Create Item')} arrow>
                                          <IconButton
                                              onClick={() => handleCloneItem(product.id)}
                                              color="info"
                                          >
                                            <ContentCopyTwoToneIcon fontSize="small" />
                                          </IconButton>
                                      </Tooltip>
                                  </Typography>
                                  </Stack>
                          <Typography variant="subtitle2">
                            {t('Category')}:{' '}
                            <Typography
                              component="span"
                              color="text.primary"
                              variant="h4"
                            >
                              {product.categories.map(o => o.name).join(',')}
                            </Typography>
                          </Typography>
                        </Box>
                      </Card>
                    </Grid>
                  );
                })}
                <Grid item xs={12}>
                  <Card
                    sx={{
                      p: 2
                    }}
                  >
                    <TablePagination
                      component="div"
                      count={filteredProducts.length}
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={handleLimitChange}
                      page={page}
                      rowsPerPage={limit}
                      rowsPerPageOptions={[50, 100, 500]}
                    />
                  </Card>
                </Grid>
              </>
            )}
          </>
        )}
        {toggleView === 'list_view' && (
          <>
            {paginatedProducts.length === 0 ? (
              <Grid item xs={12}>
                <Typography
                  sx={{
                    py: 10
                  }}
                  variant="h3"
                  fontWeight="normal"
                  color="text.secondary"
                  align="center"
                >
                  {t(
                    "We couldn't find any products matching your search criteria"
                  )}
                </Typography>
              </Grid>
            ) : (
              <>
                {paginatedProducts.map((product) => {
                  return (
                    <Grid key={product.id} item xs={12}>
                      <Card
                        sx={{
                          p: 2,
                          display: { md: 'flex' },
                          alignItems: { md: 'center' }
                        }}
                      >
                        <AvatarWrapper
                          p={2}
                          sx={{
                            justifyContent: { md: 'center' }
                          }}
                          display="flex"
                          alignItems="center"
                        >
                          <Avatar
                            component={RouterLink}
                            to={
                              `/${location.pathname.split('/')[1]
                              }/inventory/items/edit/` + product.id
                            }
                            src={cdnService.toImageUrl(product.featureImage)}
                            variant="rounded"
                          />
                        </AvatarWrapper>
                        <Box
                          component="span"
                          sx={{
                            display: { xs: 'none', md: 'inline-block' }
                          }}
                        >
                          <Divider
                            sx={{
                              mr: 2
                            }}
                            orientation="vertical"
                            flexItem
                          />
                        </Box>
                        <Box flex={1} p={2}>
                          <Typography
                            variant="h3"
                            sx={{
                              my: 1
                            }}
                          >
                            {product.name}
                          </Typography>
                          <Typography variant="subtitle2">
                            <HtmlDescription value={product.description || '[No description]'} maxLen={200} />
                          </Typography>
                          <Box py={2}>
                            <Typography component="span" variant="h4">
                              <Text >
                                {typeof product.price === 'number' ? `$${product.price.toFixed(2)}` : 'Price not available'}
                              </Text>
                            </Typography>

                          </Box>

                        </Box>
                      </Card>
                    </Grid>
                  );
                })}
                <Grid item xs={12}>
                  <Card
                    sx={{
                      p: 2
                    }}
                  >
                    <TablePagination
                      component="div"
                      count={filteredProducts.length}
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={handleLimitChange}
                      page={page}
                      rowsPerPage={limit}
                      rowsPerPageOptions={[50, 100, 500]}
                    />
                  </Card>
                </Grid>
              </>
            )}
          </>
        )}
        {!toggleView && (
          <Grid item xs={12}>
            <Card
              sx={{
                textAlign: 'center',
                p: 3
              }}
            >
              <Typography
                align="center"
                variant="h4"
                fontWeight="normal"
                color="text.secondary"
                sx={{
                  my: 5
                }}
                gutterBottom
              >
                {t(
                  'Choose between table or grid views for displaying the projects list.'
                )}
              </Typography>
            </Card>
          </Grid>
        )}
      </Grid>
      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              pt: 4,
              px: 6
            }}
            variant="h3"
          >
            {t('Do you really want to delete this item')}?
          </Typography>

          <Typography
            align="center"
            sx={{
              pt: 2,
              pb: 4,
              px: 6
            }}
            fontWeight="normal"
            color="text.secondary"
            variant="h4"
          >
            {t("You won't be able to revert after deletion")}
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1
              }}
              onClick={closeConfirmDelete}
            >
              {t('Cancel')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
            >
              {t('Delete')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
};

export default Results;
