import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';

import {
  Grid,
  Typography} from '@mui/material';



function PageHeader() {
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {t('Orders')}
          </Typography>
          <Typography variant="subtitle2">
          {t('Listing all orders received today')}
          </Typography>
        </Grid>
        <Grid item>
          {/* <Button
            sx={{
              mt: { xs: 2, sm: 0 }
            }}
            onClick={handleCreateProjectOpen}
            variant="contained"
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            {t('Create new project')}
          </Button> */}
        </Grid>
      </Grid>
    </>
  );
}

export default PageHeader;
