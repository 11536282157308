import { FC, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Card,
  Grid,
  Divider,
  Container,
  Tooltip,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TableContainer,
  styled
} from '@mui/material';
import { format } from 'date-fns';
import numeral from 'numeral';
import useAuth from 'src/hooks/useAuth';
import Logo from 'src/components/LogoSign';
import { useTranslation } from 'react-i18next';

import { OrderItem, OrderMain } from 'src/services/apiService/response-models';

const BoxWrapper = styled(Box)(
  ({ theme }) => `
  border-radius: ${theme.general.borderRadius};
  background: ${theme.colors.alpha.black[5]};
`
);

const TableWrapper = styled(Box)(
  ({ theme }) => `
  border: 1px solid ${theme.colors.alpha.black[10]};
  border-bottom: 0;
  margin: ${theme.spacing(4)} 0;
`
);

const LogoWrapper = styled(Box)(
  () => `
    width: '52px'
`
);

interface OrderBodyProps {
  order: OrderMain;
}

const OrderBody: FC<OrderBodyProps> = ({ order }) => {
  const { t }: { t: any } = useTranslation();
console.log(order);

  return (
    <Container maxWidth="lg">
      <Card
        sx={{
          p: 3,
          mb: 3
        }}
      >
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h1" gutterBottom>
              {t('Order')}
            </Typography>
            <Typography variant="h3" color="text.secondary">
              #{order.orderNumber}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column">
          <LogoWrapper>
              <Logo />
            </LogoWrapper>
            <Typography
              sx={{
                py: 2,
                fontStyle:order.customer?.fullName ? "normal" :'italic'
              }}
              variant={order.customer?.fullName ? "h4" :'caption'}
            >
              {order.customer?.fullName || '[customer name]'}
            </Typography>
           {order.customer?.mobileNumber &&  <Typography variant="h5" fontWeight="normal">
              {order.customer?.mobileNumber}
            </Typography>}
            {order.customer?.email && <Typography variant="h5" gutterBottom fontWeight="normal">
            {order.customer?.email}
            </Typography>}
            {order.customer?.type &&  <Typography variant="h5" fontWeight="normal">
            {order.customer?.type}
            </Typography>}

            {/* <Typography variant="h5" fontWeight="normal">
              83 Laurel Lane
            </Typography>
            <Typography variant="h5" gutterBottom fontWeight="normal">
              Fort Walton Beach, FL 32547
            </Typography>
            <Typography variant="h5" fontWeight="normal">
              New York, USA
            </Typography> */}
          </Box>
        </Box>
        <Divider
          sx={{
            my: 4
          }}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>

            <Typography variant="subtitle2" gutterBottom>
              {t('Order type')}:
            </Typography>
            <Typography
              sx={{
                pb: 2
              }}
              variant="h5"
            >
              {order?.orderType}
            </Typography>
            <Typography variant="h5" fontWeight="normal">
              Recieved via: {order.sourceApp?.name} {order.tableNumber? ', '+ order.tableNumber : ''} 
            </Typography>
            <Typography variant="h5" fontWeight="normal">
              {order.createdBy?.name}
            </Typography> 
            <Typography variant="h5" gutterBottom fontWeight="normal">
              {order.currentStage.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid
              container
              spacing={4}
              justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
            >
              <Grid item>
                <Typography variant="subtitle2" gutterBottom>
                  {t('Created on')}:
                </Typography>
                <Typography
                  sx={{
                    pb: 2
                  }}
                  variant="h5"
                >
                  {new Date(order.createdOn).toLocaleString()} {/* Display the formatted date */}
                </Typography>

              </Grid>
              {/* <Grid item>
                <Typography variant="subtitle2" gutterBottom>
                  {t('Due on')}:
                </Typography>
                <Typography
                  sx={{
                    pb: 2
                  }}
                  variant="h5"
                >

                </Typography>
              </Grid> */}
            </Grid>
            <BoxWrapper textAlign="right" mt={1} p={3}>
              <Typography component="span" variant="h4" fontWeight="normal">
                {t('Amount paid')}:{' '}{Number(order.orderAmount).toFixed(2)}
              </Typography>
              <Typography component="span" variant="h4">
                {' (Cash)'}
              </Typography>
            </BoxWrapper>

          </Grid>
        </Grid>

        <TableWrapper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Item')}</TableCell>
                  <TableCell>{t('Qty')}</TableCell>
                  <TableCell>{t('Price')}</TableCell>
                  <TableCell>{t('Total')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.items.map(item => (
                  <TableRow key={item.description}>
                     <TableCell>
                      <Typography>
                        <span
                          dangerouslySetInnerHTML={{ __html: item.itemName }}
                        />
                        <span
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>
                      ${Number(item.price).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      ${Number(item.quantity*item.price).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={0} />
                  <TableCell colSpan={4} align="right">
                    <Typography
                      gutterBottom
                      variant="caption"
                      color="text.secondary"
                      fontWeight="bold"
                    >
                      {t('Total')}:
                    </Typography>
                    <Typography variant="h3" fontWeight="bold">
                      {order.orderAmount}
                    </Typography>

                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </TableWrapper>
        {/* <Typography variant="subtitle2" gutterBottom>
          {t('Additional informations')}
        </Typography>
        <Typography variant="body2">
          These projects were completed between January and February of 2021.
        </Typography> */}
        {/* <Tooltip
          placement="top"
          arrow
          title="This functionality will be added in a future release!"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt={4}
          >
            <Button
              disabled
              variant="contained"
              sx={{
                mx: 2
              }}
              startIcon={<DownloadTwoToneIcon />}
            >
              {t('Download PDF')}
            </Button>
            <Button
              disabled
              variant="outlined"
              sx={{
                mx: 2
              }}
              startIcon={<PictureAsPdfTwoToneIcon />}
            >
              {t('Preview PDF')}
            </Button>
          </Box>
        </Tooltip> */}
      </Card>
    </Container>
  );
};

OrderBody.propTypes = {
  // @ts-ignore
  order: PropTypes.object.isRequired
};

export default OrderBody;
