import {
    FC,
    useState,
    ReactElement,
    ChangeEvent,
    Ref,
    forwardRef
} from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import numeral from 'numeral';

import {
    Avatar,
    Box,
    Card,
    Checkbox,
    Slide,
    Divider,
    Tooltip,
    IconButton,
    Link,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableContainer,
    TableRow,
    TextField,
    Button,
    Typography,
    Dialog,
    useMediaQuery,
    useTheme,
    Zoom,
    styled,
    CircularProgress
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
// import Label from 'src/components/Label';
import BulkActions from './BulkActions';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import DisableIcon from '@mui/icons-material/BlockTwoTone';
import { useSnackbar } from 'notistack';
// import Text from 'src/components/Text';
import LocalFireDepartmentTwoToneIcon from '@mui/icons-material/LocalFireDepartmentTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import _ from 'lodash';
import { deleteAddOnCategory } from '../../../services/apiService';
import { AddOnCategory } from '../../../services/apiService/response-models';

const DialogWrapper = styled(Dialog)(
    () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const ImgWrapper = styled('img')(
    ({ theme }) => `
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
`
);

const AvatarError = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
    ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

interface ResultsProps {
    categories: AddOnCategory[];
    onDeleteSuccess: () => void;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children: ReactElement<any, any> },
    ref: Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const applyFilters = (categories: AddOnCategory[], query: string): AddOnCategory[] => {
    return categories.filter((item) => {
        let matches = true;

        if (query) {
            const properties = ['name'];
            let containsQuery = false;

            properties.forEach((property) => {
                if (item[property].toLowerCase().includes(query.toLowerCase())) {
                    containsQuery = true;
                }
            });

            if (!containsQuery) {
                matches = false;
            }
        }

        return matches;
    });
};

const applyPagination = (
    categories: AddOnCategory[],
    page: number,
    limit: number
): AddOnCategory[] => {
    return categories.slice(page * limit, page * limit + limit);
};

const PAGE_OPTIONS = [10, 50, 100];

const Results: FC<ResultsProps> = ({ categories, onDeleteSuccess }) => {
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const location = useLocation();

    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(50);
    const [query, setQuery] = useState<string>('');

    const [deleteInProgressIds, setDeleteInProgressIds] = useState<string[]>([]);
    const [idRequestedToDelete, setIdRequestedToDelete] = useState<string>();

    const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.persist();
        setQuery(event.target.value);
    };

    const handleSelectAllItems = (
        event: ChangeEvent<HTMLInputElement>
    ): void => {
        setSelectedItems(
            event.target.checked ? categories.map((o) => o.id) : []
        );
    };

    const handleSelectOneItem = (
        event: ChangeEvent<HTMLInputElement>,
        itemId: string
    ): void => {
        if (!selectedItems.includes(itemId)) {
            setSelectedItems((prevSelected) => [...prevSelected, itemId]);
        } else {
            setSelectedItems((prevSelected) =>
                prevSelected.filter((id) => id !== itemId)
            );
        }
    };

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };

    const filteredItems = applyFilters(categories, query);
    const paginatedItems = applyPagination(filteredItems, page, limit);
    const selectedBulkActions = selectedItems.length > 0;
    const selectedSomeItems =
        selectedItems.length > 0 && selectedItems.length < categories.length;
    const selectedAllItems = selectedItems.length === categories.length;
    const mobile = useMediaQuery(theme.breakpoints.down('md'));

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    const handleConfirmDelete = (id: string) => {
        setIdRequestedToDelete(id);
        setOpenConfirmDelete(true);
    };

    const closeConfirmDelete = () => {
        setOpenConfirmDelete(false);
    };

    const handleDeleteCompleted = async () => {
        setOpenConfirmDelete(false);
        if (idRequestedToDelete) {
            setDeleteInProgressIds([...deleteInProgressIds, idRequestedToDelete]);
            try {
                await deleteAddOnCategory(idRequestedToDelete);
                onDeleteSuccess();
                enqueueSnackbar(t('You successfully deleted the category'), {
                    variant: 'success',
                    TransitionComponent: Zoom
                });
            } catch (ex) {
                enqueueSnackbar(t('Failed to delete category'), {
                    variant: 'error'
                });
            }
            setDeleteInProgressIds(deleteInProgressIds.filter(o => o !== idRequestedToDelete));
        }

    };

    return (
        <>
            <Card>
                <Box display="flex" alignItems="center">
                    {selectedBulkActions && (
                        <Box flex={1} p={2}>
                            <BulkActions />
                        </Box>
                    )}
                    {!selectedBulkActions && (
                        <Box
                            flex={1}
                            p={2}
                            display={{ xs: 'block', md: 'flex' }}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box
                                sx={{
                                    mb: { xs: 2, md: 0 }
                                }}
                            >
                                <TextField
                                    size="small"
                                    fullWidth={mobile}
                                    onChange={handleQueryChange}
                                    value={query}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchTwoToneIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                    placeholder={t('Search by category name...')}
                                />
                            </Box>
                            <TablePagination
                                component="div"
                                count={filteredItems.length}
                                onPageChange={handlePageChange}
                                onRowsPerPageChange={handleLimitChange}
                                page={page}
                                rowsPerPage={limit}
                                rowsPerPageOptions={PAGE_OPTIONS}
                            />
                        </Box>
                    )}
                </Box>
                <Divider />
                {!categories.length && <Typography
                    sx={{
                        py: 10
                    }}
                    variant="h3"
                    fontWeight="normal"
                    color="text.secondary"
                    align="center"
                >
                    {t("No record to display")}
                </Typography>}

                {categories.length > 0 && <>
                    {paginatedItems.length === 0 ? (
                        <Typography
                            sx={{
                                py: 10
                            }}
                            variant="h3"
                            fontWeight="normal"
                            color="text.secondary"
                            align="center"
                        >
                            {t("We couldn't find any categories matching your search criteria")}
                        </Typography>
                    ) : (
                        <>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>

                                            <TableCell>{t('Category name')}</TableCell>
                                            <TableCell>{t('Is Active')}</TableCell>
                                            <TableCell align="center">{t('Actions')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {paginatedItems.map((item) => {
                                            const isItemSelected = selectedItems.includes(
                                                item.id
                                            );
                                            return (
                                                <TableRow
                                                    hover
                                                    key={item.id}
                                                    selected={isItemSelected}
                                                >

                                                    <TableCell>
                                                        <Box display="flex" alignItems="left">
                                                            <Box
                                                                pl={1}
                                                                sx={{
                                                                    width: 250
                                                                }}
                                                            >
                                                                <Link
                                                                    component={RouterLink}
                                                                    to={
                                                                        `/${location.pathname.split('/')[1]
                                                                        }/inventory/addOnCategories/edit/` +
                                                                        item.id
                                                                    }
                                                                    variant="h5"
                                                                >
                                                                    {item.name}
                                                                </Link>
                                                                {/*<Typography variant="subtitle2" noWrap>*/}
                                                                {/*    {product.summary}*/}
                                                                {/*</Typography>*/}
                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography noWrap>
                                                            {item.isActive ? 'Yes' : 'No'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {deleteInProgressIds.includes(item.id) && <CircularProgress size="20px" />}
                                                        {!deleteInProgressIds.includes(item.id) &&
                                                            <Typography noWrap>
                                                                <Tooltip title={t('Delete')} arrow>
                                                                    <IconButton
                                                                        onClick={() => handleConfirmDelete(item.id)}
                                                                        color="primary"
                                                                    >
                                                                        <DeleteTwoToneIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Typography>}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box p={2}>
                                <TablePagination
                                    component="div"
                                    count={filteredItems.length}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleLimitChange}
                                    page={page}
                                    rowsPerPage={limit}
                                    rowsPerPageOptions={PAGE_OPTIONS}
                                />
                            </Box>
                        </>
                    )}
                </>}
            </Card>
            <DialogWrapper
                open={openConfirmDelete}
                maxWidth="sm"
                fullWidth
                TransitionComponent={Transition}
                keepMounted
                onClose={closeConfirmDelete}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    p={5}
                >
                    <AvatarError>
                        <CloseIcon />
                    </AvatarError>

                    <Typography
                        align="center"
                        sx={{
                            pt: 4,
                            px: 6
                        }}
                        variant="h3"
                    >
                        {t('Do you really want to delete this category')}?
                    </Typography>

                    <Typography
                        align="center"
                        sx={{
                            pt: 2,
                            pb: 4,
                            px: 6
                        }}
                        fontWeight="normal"
                        color="text.secondary"
                        variant="h4"
                    >
                        {t("You won't be able to revert after deletion")}
                    </Typography>

                    <Box>
                        <Button
                            variant="text"
                            size="large"
                            sx={{
                                mx: 1
                            }}
                            onClick={closeConfirmDelete}
                        >
                            {t('Cancel')}
                        </Button>
                        <ButtonError
                            onClick={handleDeleteCompleted}
                            size="large"
                            sx={{
                                mx: 1,
                                px: 3
                            }}
                            variant="contained"
                        >
                            {t('Delete')}
                        </ButtonError>
                    </Box>
                </Box>
            </DialogWrapper>
        </>
    );
};

Results.propTypes = {
    categories: PropTypes.array.isRequired
};

Results.defaultProps = {
    categories: []
};

export default Results;
