import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// import PageHeader from './PageHeader';
import PageHeader from 'src/components/page-header';
import { Box, Drawer, Grid, useTheme, IconButton, styled, LinearProgress, Card, Skeleton, Stack } from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';

import GeneralSection from './GeneralSection';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { AddOnCategory } from 'src/services/apiService/response-models';
import { getAddOnCategoryByID, parseToApiErrorMessage, saveAddOnCategories } from 'src/services/apiService';

const MainContentWrapper = styled(Box)(
    () => `
  flex-grow: 1;
`
);

const IconButtonToggle = styled(IconButton)(
    ({ theme }) => `
  width: ${theme.spacing(6)};
  height: ${theme.spacing(6)};
`
);

function AddOnCategoryCreate() {
    const theme = useTheme();
    const navigate = useNavigate();
    const { id } = useParams();

    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [model, setModel] = useState<AddOnCategory | null>(null);
    const [isSaveInProgress, setIsSaveInProgress] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);
    const [featureImage, setFeatureImage] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);


    const getCategoryById = async (id: string) => {
        try {
            const response = await getAddOnCategoryByID(id);
            console.log("categoryID");
            
            setIsEdit(true);
            setModel(response.data);
        } catch (ex) {
            console.error(ex);
            const msg = ex.message ? ex.message : `Failed to load add-on category by id`;
            enqueueSnackbar(t(msg), { variant: 'error' });
            navigate("/addOnCategories/list", { replace: true });
        }
    };

    useEffect(() => {
        if (id && id.length > 0) {
             setIsLoading(true);
            getCategoryById(id).then(() => {
                setIsLoading(false);
            });
        }
    }, []);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const onFeatureImageChange = (file: File): void => {
        const name = (file as any).blobName;
        setFeatureImage(name);
    }
    const onSave = async (updatedModel: AddOnCategory) => {
        let isSuccess = false;
        try {
            setIsSaveInProgress(true);
            await saveAddOnCategories(updatedModel);
            enqueueSnackbar(t(`Add-On Category was ${!isEdit ? 'created' : 'updated'} successfully`), { variant: 'success' });
            isSuccess = true;
            navigate('/'+location.pathname.split('/')[1]+"/inventory/addOnCategories/list", { replace: true });
            setIsSaveInProgress(false);
        } catch (ex) {
            setIsSaveInProgress(false);
            console.log(ex);
            const msg = parseToApiErrorMessage(ex, `Failed to ${!isEdit ? 'created' : 'updated'} Add-On category`);
            enqueueSnackbar(t(msg), { variant: 'error' });
        }
        return isSuccess;
    }
    return (
        <>
            <Box mb={3} display="flex">
                <MainContentWrapper>
                    <Grid
                        sx={{
                            px: 4
                        }}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={4}
                    >
                        <Grid item xs={12}>
                            <Box
                                mt={3}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                {/* <PageHeader isEdit={isEdit}/> */}
                                <PageHeader type="create"  title="Addon Categories" subtitle="Manage addon category" route={'/'+location.pathname.split('/')[1]+"/inventory/addOnCategories/list"} buttonTitle="Go Back To Addon Categories"></PageHeader>
                                <Box
                                    component="span"
                                    sx={{
                                        display: { lg: 'none', xs: 'inline-block' }
                                    }}
                                >
                                    <IconButtonToggle
                                        sx={{
                                            ml: 2
                                        }}
                                        color="primary"
                                        onClick={handleDrawerToggle}
                                        size="small"
                                    >
                                        <MenuTwoToneIcon />
                                    </IconButtonToggle>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            {isLoading && <Card
                                sx={{
                                    p: 3
                                }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Skeleton variant="rectangular" height={60} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Skeleton variant="rectangular" height={60} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Skeleton variant="rectangular" width="40%" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Skeleton variant="rectangular" width="20%" />
                                    </Grid>
                                </Grid>
                            </Card>}
                            {!isLoading && <GeneralSection model={model} onSave={onSave} isSaveInProgress={isSaveInProgress} onSaveClick={() => setIsSubmitAttempted(true)} />}
                        </Grid>


                        <Grid item xs={12} sx={{
                            display: { xs: "none", md: "block" }
                        }}>
                            {/*    for side bar height*/}
                            <div style={{ height: '250px' }}>&nbsp;</div>
                        </Grid>
                    </Grid>
                </MainContentWrapper>
            </Box>
        </>
    );
}

export default AddOnCategoryCreate;