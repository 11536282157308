import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import wait from 'src/utils/wait';

import {
  styled,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  Zoom,
  Typography,
  Divider,
  TextField,
  CircularProgress,
  Switch,
  Avatar,
  Autocomplete,
  IconButton,
  Button
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useSnackbar } from 'notistack';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import { saveCustomer , getCustomerById} from 'src/services/apiService';

const Input = styled('input')({
  display: 'none'
});

const AvatarWrapper = styled(Box)(
  ({ theme }) => `

    position: relative;

    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`
);

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: ${theme.spacing(6)};
    height: ${theme.spacing(6)};
    bottom: -${theme.spacing(2)};
    right: -${theme.spacing(2)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      box-shadow: ${theme.colors.shadows.primary};
      width: ${theme.spacing(6)};
      height: ${theme.spacing(6)};
      padding: 0;
  
      &:hover {
        background: ${theme.colors.primary.dark};
      }
    }
`
);
interface CreateCustomerDialogProps {
  open: boolean;
  onClose: () => void;
  customerId: string; // Define the customerId prop
}

async function fetchCustomerData(customerId, enqueueSnackbar, t, setCustomerData) {
  try {
    console.log("function called");
    
    const response = await getCustomerById(customerId);
    console.log(response.data);
    
    setCustomerData(response.data);

  } catch (ex) {
    console.error(ex);
    const msg = ex.message ? ex.message : `Failed to load customer by id`;
    enqueueSnackbar(t(msg), { variant: 'error' });
  }
}

export default function CreateCustomerDialog({ open, onClose ,customerId ,flag}){
console.log("customer dialog",customerId);

    const { t }: { t: any } = useTranslation();
    // const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [customerData, setCustomerData] = useState(null);
    

  // const handleCreateCustomerOpen = () => {
  //   setOpen(true);
  // };
  const redirectToManageCustomerList = () => {
    // Redirect to /manage/customer/list
    window.location.href = '/manage/customer/list';
  };
  const handleCreateCustomerClose = () => {
    onClose(); 
    // redirectToManageCustomerList();
    flag=1;
    console.log("create " ,flag);
    
    
  };

  useEffect(() => {
    if (customerId) {
      fetchCustomerData(customerId, enqueueSnackbar, t, setCustomerData);
    }

  }, [customerId, enqueueSnackbar, t]);
  
  const handleUpdateUser = async (values) => {
    try {
      console.log("Updated values", values);
  
      await saveCustomer({ ...values, id: customerId }); // Assuming customerId is available
  
      enqueueSnackbar(t('User updated successfully'), { variant: 'success' });
      onClose();
      flag = 1
      console.log("create " ,flag);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t('Failed to update user'), { variant: 'error' });
    }
  };
  
  // const handleAddNewUser = async (values) => {
  //   try {
  //     console.log("OLD",values);
  //     // Call the saveCustomer API with the form data
  //   //   values = {
  //   //     "firstName": "etest",
  //   //       "lastName": "mjen",
  //   //     "email": "oep@3232.ocm",
  //   //     "image": "img-1693936815610.png",
  //   //     "contactNumber": "1243445678",
  //   // }
  //     console.log("NEW",values);
      
  //     await saveCustomer(values);

  //     enqueueSnackbar(t('User added successfully'), { variant: 'success' });

  //     onClose();
  //   } catch (err) {
  //     console.error(err);
  //     enqueueSnackbar(t('Failed to add user'), { variant: 'error' });
  //   }
  // };
  
    return (<Dialog
        fullWidth
        maxWidth="md"
        open={open}
      onClose={onClose}
      >
        <DialogTitle
          sx={{
            p: 3
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t('Add new user')}
          </Typography>
          <Typography variant="subtitle2">
            {t(
              'Fill in the fields below to create and add a new user to the site'
            )}
          </Typography>
        </DialogTitle>
        <Formik
         enableReinitialize={true}
          initialValues={{
            email: customerData ? customerData.email : '',
            firstName: customerData ? customerData.firstName : '',
            lastName: customerData ? customerData.lastName : '',
            // password: '',
            submit: null
          }}
          validationSchema={Yup.object().shape({
            // username: Yup.string()
            //   .max(255)
            //   .required(t('The username field is required')),
            firstName: Yup.string()
              .max(255)
              .required(t('The first name field is required')),
            lastName: Yup.string()
              .max(255)
              .required(t('The last name field is required')),
            email: Yup.string()
              .email(t('The email provided should be a valid email address'))
              .max(255)
              .required(t('The email field is required')),
            // password: Yup.string()
            //   .max(255)
            //   .required(t('The password field is required'))
          })}
          onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
            try {
              await wait(1000);
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);
              handleUpdateUser(values); // Call the update function
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
          
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setValues // Add setValues function to update form values
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent
                dividers
                sx={{
                  p: 3
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={7}>
                    <Grid container spacing={3}>
                      {/* <Grid item xs={12}>
                        <TextField
                          error={Boolean(touched.username && errors.username)}
                          fullWidth
                          helperText={touched.username && errors.username}
                          label={t('Username')}
                          name="username"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.username}
                          variant="outlined"
                        />
                      </Grid> */}
                      <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(
                          touched.firstName && errors.firstName
                        )}
                        fullWidth
                        helperText={touched.firstName && errors.firstName}
                        label={t('First name')}
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName} // Bind value to formik values
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(
                          touched.lastName && errors.lastName
                        )}
                        fullWidth
                        helperText={touched.lastName && errors.lastName}
                        label={t('Last name')}
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastName} // Bind value to formik values
                        variant="outlined"
                      />
                    </Grid>
                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          helperText={touched.email && errors.email}
                          label={t('Email address')}
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="email"
                          value={values.email}
                          variant="outlined"
                        />
                      </Grid>
                      {/* <Grid item xs={12}>
                        <TextField
                          error={Boolean(touched.password && errors.password)}
                          fullWidth
                          margin="normal"
                          helperText={touched.password && errors.password}
                          label={t('Password')}
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="password"
                          value={values.password}
                          variant="outlined"
                        />
                      </Grid> */}
                      <Grid item xs={12} md={6}>
                        <Autocomplete
                          disablePortal
                          options={[]}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              label={t('User role')}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={5} justifyContent="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                      mt={3}
                    >
                      <AvatarWrapper>
                        <Avatar
                          variant="rounded"
                          alt={''}
                          src={''}
                        />
                        <ButtonUploadWrapper>
                          <Input
                            accept="image/*"
                            id="icon-button-file"
                            name="icon-button-file"
                            type="file"
                          />
                          <label htmlFor="icon-button-file">
                            <IconButton component="span" color="primary">
                              <CloudUploadTwoToneIcon />
                            </IconButton>
                          </label>
                        </ButtonUploadWrapper>
                      </AvatarWrapper>
                      <Divider
                        flexItem
                        sx={{
                          m: 4
                        }}
                      />
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            pb: 1
                          }}
                        >
                          {t('Public Profile')}
                        </Typography>
                        <Switch
                          checked={false}
                          onChange={()=>{}}
                          name="public"
                          color="primary"
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  p: 3
                }}
              >
                <Button color="secondary" onClick={handleCreateCustomerClose}>
                  {t('Cancel')}
                </Button>
                <Button
                  type="submit"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                >
                  {t('Add new user')}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>)
}