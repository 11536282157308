import { useState, useEffect, useCallback, forwardRef, ReactElement, Ref } from 'react';

 
import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
    Box,
    CardHeader,
    Card,
    List,
    ListItemAvatar,
    ListItem,
    ListItemText,
    Typography,
    Divider,
    useTheme,
    styled,
    Tooltip,
    IconButton,
    alpha,
    Grid,
    TextField,
    InputAdornment,
    useMediaQuery,
    Alert,
    Skeleton,
    Zoom,
    Dialog,
    Slide,
    Button,
    Avatar
} from '@mui/material';
import useRefMounted from 'src/hooks/useRefMounted';
import * as Models from '../../../services/apiService/response-models';

import { useTranslation } from 'react-i18next';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { useNavigate } from 'react-router-dom';
import { deleteSize, getItemVariants, parseToApiErrorMessage } from '../../../services/apiService';
import { useSnackbar } from 'notistack';
import { TransitionProps } from '@mui/material/transitions';
import PageHeader from 'src/components/page-header';
import VariantCreate from './variant-create';
import VariantList from './list';

function ManageVariants() {
  const [selectedId, setSelectedId] = useState('');
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('md'));
    const { t }: { t: any } = useTranslation();
    const [flag, setFlag] = useState(0);
    // const navigate = useNavigate();
    // const { enqueueSnackbar } = useSnackbar();

    // const [isDataLoading, setIsDataLoading] = useState(false);
    // const [hasDataLoadError, setHasDataLoadError] = useState(false);

    // const refreshData = () => {
    //   // Increment the state to trigger a re-render of child components
    //   setRefresh(refresh + 1);
    // };
    // const updateFlagInVariantCreate = (newFlag) => {
    //   setFlag(newFlag);
    // };
    return (
       <>
         <Grid
            sx={{
            px: 4
            }}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={4}
        >
             <Grid display="flex" alignItems="center" item xs={12}>
          
                <Box flex={1} mt={3}>
                    <PageHeader type="list" title="Item Variants" subtitle="Manage Items On this Page"   showButton={false}></PageHeader>
                </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
         <VariantCreate selectedId={selectedId} flag={setFlag}  />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
         <VariantList onDelete={(id) => setSelectedId(id)} onEdit={(id) => setSelectedId(id)} flag={flag} />
        </Grid>
      </Grid>
       </>
    );
}

export default ManageVariants;
