import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, ImageList, ImageListItem, Card, Typography, styled, Radio, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'src/store';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import cdnService from 'src/services/cdnService';
import EditIcon from '@mui/icons-material/Edit';
import { decreaseItemQuantity, deleteItemFromCart, inCreaseItemQuantity } from 'src/store/slices/priceMenu';
import AddToOrderModal from '../../menu-item/AddToOrderModal';
import { useTranslation } from 'react-i18next';



const ButtonWrapper = styled('div')(() => `
    display: flex;
    align-items: center;
    cursor: default,
    margin-bottom: 14px
`);

const ImgWrapper = styled('img')(
    ({ theme }) => `
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
      border-radius: 12px;
      margin-right: 16px
`
);

const SelectWrapper = styled('div')(() => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #919EAB52;
    border-radius: 8px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;

`);

const IconWrapper = styled('div')(() => `
    display: flex;
    align-items: center;
`);

const InputBox = styled(TextField)(() => `
    width: 24px;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 5px
`)

const paymentOptions = [
    { label: 'Pay with PayMongo', value: 'paymongo', subTitle: 'You will be redirected to PayMongo website to complete your order securely.', icon: '/static/images/Payment/ic_paymongo.png' },
    // { label: 'Pay with Paypal', value: 'paypal', subTitle: 'You will be redirected to PayPal website to complete your order securely.', icon: '/static/images/Payment/ic_paypal.png' },
    // { label: 'Credit/Debit Card', value: 'card', subTitle: 'We support Mastercard, Visa, Discover and Stripe.', icon: '/static/images/Payment/ic_mastercard.png', visaIcon: '/static/images/Payment/ic_visa.png' },
    { label: 'Cash', value: 'cod', subTitle: 'Pay with cash.', },
    // { label: 'Pay with Gift Card', value: 'giftCard', subTitle: 'Pay with Gift Card.', },
];

interface ICartTable {
    isReadyForPayment: boolean;
    paymentType: string;
    setPaymentType: (value: string) => void;
}

export default function CartTableBody({ cartItem, index, onPressEdit, qty }: any) {

    const dispatch = useDispatch();
    const { t }: { t: any } = useTranslation();
    const { cartData } = useSelector((state) => state.priceMenu);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectItem, setSelectItem] = useState({});
    const [quantityText, setQuantityText] = useState(qty);

    useEffect(() => {
        setQuantityText(qty);
    }, [qty]);

    const handleEdit = (item) => {
        setIsModalVisible(true);
        setSelectItem(item)
    };

    const handleDelete = (item) => {
        cartData?.map((el) => {
            if (el?.id === item?.id) {
                console.log("el?.id === item?.id", el?.id === item?.id);
                dispatch(deleteItemFromCart(el));
            }
        })
    };

    return (
        <>
            <TableRow key={index}>
                <TableCell component="th" scope="row">
                    <Box display={'flex'} alignItems={'center'}>
                        <ImgWrapper src={cdnService.toImageUrl(cartItem.featureImage)} />
                        <Box>
                            <Typography variant='h4' component={'h4'}>{cartItem.name}</Typography>
                            <Box>
                                {`${'\n'}`}
                                { }
                                <span>Size: {cartItem.cartVariant.name}</span> |
                                <span>addOns: {cartItem.cartAddOn.map(addon => addon.name).join(', ')}</span>
                            </Box>
                            {cartItem.notes && (
                                <span>Note: {cartItem.notes}</span>
                            )}
                        </Box>
                    </Box>
                </TableCell>
                <TableCell align="right">${cartItem?.cartVariant?.prices?.[0]?.priceType === 'all'
                    ? cartItem?.cartVariant?.prices?.[0]?.price?.toFixed(2)
                    : cartItem?.cartVariant?.prices?.[0]?.price?.toFixed(2)}</TableCell>
                <TableCell align="right">
                    <Box px={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'} border={1} borderRadius={8} borderColor={'#919EAB52'}>
                        <IconWrapper onClick={() => {
                            if (quantityText < 50) {
                                dispatch(inCreaseItemQuantity(cartItem));
                                setQuantityText(parseInt(quantityText) + 1);
                            }
                        }}>
                            <AddIcon />
                        </IconWrapper>
                        <Box
                            component={'form'}
                        >
                            <InputBox id="outlined-basic" variant="standard" margin="normal" fullWidth value={quantityText} onChange={(event: any) => {
                                const text = event.target.value;
                                cartData?.map((el) => {
                                    if (el?.id === cartItem?.id) {
                                        if (text?.length > 0) {
                                            if (text.includes('.') || parseInt(text) <= 0) {
                                                return;
                                            }
                                            const numericValue = parseInt(text);
                                            if (!isNaN(numericValue) && numericValue > 0 && numericValue <= 50) {
                                                dispatch(inCreaseItemQuantity({ ...cartItem, qtyTxt: text }));
                                                setQuantityText(text);
                                            }
                                        } else {
                                            setQuantityText(text);
                                        }
                                    }
                                })
                            }
                            }
                                type='text'
                                InputProps={{
                                    disableUnderline: true,
                                }}
                            />
                        </Box>
                        <IconWrapper onClick={() => {
                            if (parseInt(quantityText) !== 1) {
                                dispatch(decreaseItemQuantity(cartItem));
                                setQuantityText(parseInt(quantityText) - 1);
                            }
                        }}>
                            <RemoveIcon />
                        </IconWrapper>
                    </Box>
                </TableCell>
                <TableCell align="right">${cartItem.itemCashTotal?.toFixed(2)}</TableCell>
                <TableCell align="right" onClick={() => handleDelete(cartItem)}><DeleteIcon /></TableCell>
                <TableCell align="right" onClick={() => handleEdit(cartItem)}><EditIcon /></TableCell>
            </TableRow>

            {isModalVisible && (
                <AddToOrderModal
                    isVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    data={selectItem}
                    isEdit={true}
                />
            )}
        </>
    );
}