import _ from "lodash";
import { Item } from "src/services/apiService/response-models";

export default function getItemPrice(e:Item):number{
    if(e && e.variants && e.variants.length){
        const p = _.first(_.first(e.variants).prices);
            return p.priceAfterDiscount || p.price;
    }

    if(e && e.prices && e.prices.length){
        const p = _.first(e.prices);
        return p.priceAfterDiscount || p.price;
    }
}