import { Box, Card, Grid, Typography, useTheme, alpha } from '@mui/material';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';

function InfoPanel(props: { title?: string; message?: string }) {
  const theme = useTheme();

  return (
    <Grid item xs={12} mt={1} mb={1}>
      <Card
        variant="outlined"
        sx={{
          background: alpha(theme.colors.info.main, 0.08),
          display: 'flex',
          alignItems: 'flex-start',
          p: 2
        }}
      >
              <InfoTwoToneIcon
          sx={{
            mr: 1,
                      color: theme.colors.info.main,
            fontSize: theme.typography.pxToRem(22)
          }}
        />
        <Box>
          <Typography
            variant="h4"
            sx={{
              pt: 0.2
            }}
            gutterBottom
          >
            {props.title}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: theme.typography.pxToRem(13)
            }}
          >
            {props.message}
          </Typography>
        </Box>
      </Card>
    </Grid>
  );
}

export default InfoPanel;
