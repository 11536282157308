import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  useTheme,
  CardActionArea,
  Typography,
  MenuList,
  Grid,
  Divider,
  Card,
  MenuItem,
  ListItemText,
  alpha,
  Link,
  Popover,
  Stack,
  styled,
  Badge
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import Text from 'src/components/Text';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleOutlineTwoTone';
import DashboardCustomizeTwoToneIcon from '@mui/icons-material/DashboardCustomizeTwoTone';
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';
import PointOfSaleTwoToneIcon from '@mui/icons-material/PointOfSaleTwoTone';
import GolfCourseTwoToneIcon from '@mui/icons-material/GolfCourseTwoTone';
import { useDispatch, useSelector } from 'src/store';
import { PortalDes, PortalNames } from 'src/store/app-state';
import { changePortal } from 'src/store/slices/app';
import { useLocation, useNavigate } from 'react-router-dom';

const CardActionAreaWrapper = styled(CardActionArea)(
  ({ theme }) => `
      .MuiTouchRipple-root {
        opacity: .2;
      }

      .MuiCardActionArea-focusHighlight {
        background: ${theme.colors.primary.main};
      }

      &:hover {
        .MuiCardActionArea-focusHighlight {
          opacity: .05;
        }
      }
`
);

 

function HeaderMenu() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {   portal  } = useSelector((state) => state.app);

  const ref2 = useRef<any>(null);
  const [isOpen2, setOpen2] = useState<boolean>(false);

  const handleOpen2 = (): void => {
    setOpen2(true);
  };

  const handleClose2 = (): void => {
    setOpen2(false);
  };

  useEffect(() => {
    const appPath=location.pathname.split('/')[1] as PortalNames;
    if(appPath && portal!=appPath){
      onSwitchClick(appPath);
    }
}, [location]);

  const onSwitchClick=(e:PortalNames)=>{
    dispatch(changePortal({portal:e}));
    navigate('/'+e+'/',{replace:true})
    handleClose2();
  }

  return (
    <>
      <Box
        sx={{
          display: { xs: 'none', md: 'inline-flex' }
        }}
      >
        <Button
          ref={ref2}
          onClick={handleOpen2}
          endIcon={<KeyboardArrowDownTwoToneIcon />}
          color="secondary"
          size="small"
          sx={{
            px: 2,
            backgroundColor: `${theme.colors.secondary.lighter}`,
            color: `${theme.colors.secondary.dark}`,

            '.MuiSvgIcon-root': {
              color: `${theme.colors.secondary.dark}`,
              transition: `${theme.transitions.create(['color'])}`
            },

            '&:hover': {
              backgroundColor: `${theme.colors.secondary.main}`,
              color: `${theme.palette.getContrastText(
                theme.colors.secondary.main
              )}`,

              '.MuiSvgIcon-root': {
                color: `${theme.palette.getContrastText(
                  theme.colors.secondary.main
                )}`
              }
            }
          }}
        >
          {t(PortalDes(portal))}
        </Button>
      </Box>
      <Popover
        disableScrollLock
        anchorEl={ref2.current}
        onClose={handleClose2}
        open={isOpen2}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{
          '.MuiPopover-paper': {
            background: theme.colors.gradients.blue3
          }
        }}
      >
        <Box
          sx={{
            maxWidth: 400
          }}
          p={3}
        >
          <Typography
            variant="h4"
            gutterBottom
            textAlign="center"
            sx={{
              color: theme.colors.alpha.trueWhite[100],
              fontSize: theme.typography.pxToRem(18)
            }}
          >
            {t('Dashboards')}
          </Typography>
          <Typography
            variant="subtitle2"
            textAlign="center"
            sx={{
              color: theme.colors.alpha.trueWhite[70]
            }}
          >
            {t("Switch between dashboards")}
          </Typography>
          <Grid container mt={1} spacing={2}>
            <Grid item xs={12} sm={6}>
              <Card sx={{width:150}}>
                <CardActionAreaWrapper
                  onClick={()=>onSwitchClick(PortalNames.PosAdmin)}
                  sx={{
                    p: 2,
                  }}
                >
                  <Text color="warning">
                    <PointOfSaleTwoToneIcon
                      sx={{
                        mb: 1
                      }}
                    />
                  </Text>
                  <Typography variant="h4">{t('POS Admin')}</Typography>
                </CardActionAreaWrapper>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card sx={{width:150}}>
                
                <CardActionAreaWrapper
                   onClick={()=>onSwitchClick(PortalNames.GolfCourse)}
                  sx={{
                    p: 2
                  }}
                >
                 <Text color="success">
                    <GolfCourseTwoToneIcon
                      sx={{
                        mb: 1
                      }}
                    />
                  </Text>
                  <Typography variant="h4">{t('Golf Course')}</Typography>
                </CardActionAreaWrapper>
              </Card>
            </Grid>
      
          </Grid>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderMenu;
