import { FC } from 'react';
import {
  Breadcrumbs,
  Box,
  Grid,
  Link,
  Typography,
  Tooltip,
  Button,
  Container,
  IconButton
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, Link as RouterLink, useParams, useSearchParams } from 'react-router-dom';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import StairsIcon from '@mui/icons-material/Stairs';
import LocalPrintshopTwoToneIcon from '@mui/icons-material/LocalPrintshopTwoTone';

const PageHeader = (props:{orderNumber:string}) => {
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams ();
  
  const handleBack = (): void => {
    if(searchParams.get('ret')){
        navigate(searchParams.get('ret'));
    }else{
      navigate(`/${location.pathname.split('/')[1]}/orders/list`);
    }
  };
  return (
    <Container maxWidth="lg">
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Tooltip arrow placement="top" title={t('Go back')}>
              <IconButton
                onClick={handleBack}
                color="primary"
                sx={{
                  p: 2,
                  mr: 2
                }}
              >
                <ArrowBackTwoToneIcon />
              </IconButton>
            </Tooltip>
            <Box>
              <Typography variant="h3" component="h3" gutterBottom>
                #{props.orderNumber}
              </Typography>
              <Breadcrumbs maxItems={2} aria-label="breadcrumb">
                <Link color="inherit" href="#">
                  {t('Orders')}
                </Link>
                <Link color="inherit" href="#">
                  {t('List')}
                </Link>
                
                {/* <Link
                  color="inherit"
                  component={RouterLink}
                  to={`/${location.pathname.split('/')[1]}/orders/list`}
                >
                  {t('Invoices')}
                </Link> */}
                <Typography color="text.primary">
                  {t('Invoice')} #{props.orderNumber}
                </Typography>
              </Breadcrumbs>
            </Box>
          </Box>
        </Grid>
        <Grid item>
        {/* <Tooltip title="View order stages">
              <IconButton color="secondary"  sx={{
                  mt: { xs: 2, sm: 0 }
                }}>
                      <StairsIcon />
                    </IconButton>
                </Tooltip> */}
                <Tooltip title="Print invoice">
              <IconButton color="secondary"  sx={{
                  mt: { xs: 2, sm: 0 }
                }}>
                      <LocalPrintshopTwoToneIcon />
                    </IconButton>
                </Tooltip>
        </Grid>
      </Grid>
    </Container>
  );
};
 
export default PageHeader;
