import { ChangeEvent, forwardRef, ReactElement, Ref, useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Grid,
    Radio,
    FormControlLabel,
    Typography,
    Card,
    CardHeader,
    Divider,
    lighten,
    CardActionArea,
    CardContent,
    Tooltip,
    IconButton,
    Zoom,
    Avatar,
    styled,
    Dialog,
    DialogTitle,
    TableContainer,
    Button,
    TableFooter,
    TableCell,
    TableRow,
    TextField,
    useMediaQuery,
    DialogContent,
    Autocomplete,
    useTheme,
    TableBody,
    CircularProgress,
    Table,
    TableHead,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
        Paper,
    Chip,
    Skeleton,
    FormHelperText,
    SelectChangeEvent,
    Stack,
    Slide,
    FilledInput,
    InputAdornment
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { Field, Form, Formik, useField } from 'formik';
import { TextField as FmTextFiled, Switch } from 'formik-mui';
import * as Yup from 'yup';
import React from 'react';
import { AddOnCategory, AddOnGroup, AddOnItem } from 'src/services/apiService/response-models';
import _,{Dictionary} from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import cdnService from 'src/services/cdnService';
import DeleteConfirm from 'src/components/delete-confirm';

const AvatarAddWrapper = styled(Avatar)(
    ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
`
);

const CardLogo = styled('img')(
    ({ theme }) => `
      border: 1px solid ${theme.colors.alpha.black[30]};
      border-radius: ${theme.general.borderRadius};
      padding: 0px;
      margin-right: ${theme.spacing(2)};
      background: ${theme.colors.alpha.white[100]};
width: 80px;
    height: 80px;
    border-radius: 20px;
`
);


const CardAddAction = styled(Card)(
    ({ theme }) => `
        border: ${theme.colors.primary.main} dashed 1px;
        height: 100%;
        color: ${theme.colors.primary.main};
        box-shadow: none;
        transition: ${theme.transitions.create(['all'])};
        
        .MuiCardActionArea-root {
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
        }
        
        .MuiTouchRipple-root {
          opacity: .2;
        }
        
        &:hover {
          border-color: ${theme.colors.alpha.black[70]};
        }
`
);

const IconButtonError = styled(IconButton)(
    ({ theme }) => `
     background: ${theme.colors.error.lighter};
     color: ${theme.colors.error.main};
     padding: ${theme.spacing(0.5)};

     &:hover {
      background: ${lighten(theme.colors.error.lighter, 0.4)};
     }
`
);

const IconButtonEdit = styled(IconButton)(
    ({ theme }) => `
     background: ${theme.colors.primary.lighter};
     color: ${theme.colors.primary.main};
     padding: ${theme.spacing(0.5)};

     &:hover {
      background: ${lighten(theme.colors.primary.lighter, 0.4)};
     }
`
);

const CardCc = styled(Card)(
    ({ theme }) => `
     border: 1px solid ${theme.colors.alpha.black[30]};
     background: ${theme.colors.alpha.black[5]};
     box-shadow: none;
`
);



function AddOnInfo(props:{items:AddOnItem[]}) {
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    

    const [isAddonModalOpen, setAddonModalOpen] = useState(false);

    //parent form
    const [fieldAddOns, metaAddOns, helpersAddOns] = useField('addOns')
    const [addOnGroups, setAddOnGroups] = useState<AddOnGroup[]>(fieldAddOns.value || []);
    const [addOnToEdit, setAddOnToEdit] = useState<AddOnGroup | null>(null);

    const [idRequestedToDelete, setIdRequestedToDelete] = useState<string>('');

   const categoryNames = useMemo(()=>{
        const names:string[]=[];
        props.items.forEach(o=>o.categories.forEach(c=>names.push(c.name)));
        return _.uniq(names);
    },[props.items]);

    const catNameHash = useMemo(()=>{
        const hash:Dictionary<AddOnItem[]>={};
        props.items.forEach(o=>{
            o.categories.forEach(c=>{
                if(hash[c.name]){
                    hash[c.name].push(o);
                }else{
                    hash[c.name]=[o];
                }
            });
        });
        return hash;
    },[props.items]);

    useEffect(() => {
        setAddOnGroups(fieldAddOns.value)
    }, [fieldAddOns.value]);


    const onAddonModalSubmit = (value: AddOnGroup) => {
        const addon = _.cloneDeep(value);
        setAddonModalOpen(false);
        setAddOnToEdit(null);

        const arr: AddOnGroup[] = [...addOnGroups];

        const index =  arr.findIndex(o => o.groupName == addon.groupName)

        if (index!=-1) {
            //if isEdit
                arr[index] = addon;
        } else {
            arr.push(addon);
        }
        setAddOnGroups(o=>arr);
        helpersAddOns.setValue(arr);
    }

    const handleCreateAddonOpen = () => {
        setAddOnToEdit(null);
        setAddonModalOpen(true);
    };

    const onAddonModalClose = () => {
        setAddOnToEdit(null);
        setAddonModalOpen(false);
    };


    const handleEditAddonOpen = (groupName: string) => {
        const match = addOnGroups.find(o => o.groupName == groupName);
        if (match) {
            setAddOnToEdit(_.cloneDeep(match));
            setAddonModalOpen(true);
        }
    };

    const handleDeleteClick = (groupName:string) => {
        setIdRequestedToDelete(groupName || '');
    };

    const handleConfirmDelete = () => {
        const arr = addOnGroups.filter(o => o.groupName !== idRequestedToDelete);
        helpersAddOns.setValue(arr);
        setAddOnGroups(arr);
         setIdRequestedToDelete('');
    };


    const addOnGroupNames=_.sortBy(addOnGroups.map(o=>o.groupName));
    const itemInGroup=(e:string)=>addOnGroups.find(o=>o.groupName==e);
    return (
        <>
        <Card>
            <CardHeader
                    subheader={t('(optional)')}
                title={t('Add-On')}
            />
            <Divider />
            <Box p={3}>
                <Grid container spacing={3}>
                        {addOnGroupNames.map(groupName => <Grid key={groupName} item xs={12} sm={6}>
                            <CardCc
                                sx={{
                                    px: 2,
                                    pt: 2,
                                    pb: 1
                                }}
                            >
                                <Box display="flex" alignItems="center">
                                    <CardLogo
                                        src={cdnService.noImageUrl()}
                                        alt={groupName}
                                    />
                                    <Box>
                                        <Typography variant="h3" fontWeight="normal">
                                            {groupName}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            {t((itemInGroup(groupName).isRequired ? 'Required' : 'Not Required') + (itemInGroup(groupName).maxSelection ? ', Max: ' +itemInGroup(groupName).maxSelection: ''))}
                                            <Typography component="span" color="text.primary">
                                            </Typography>
                                        </Typography>
                                    </Box>

                                </Box>
                                <Box pt={1}>
                                    <ChipsArray addOnItems={itemInGroup(groupName).addOnItems} />
                                </Box>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Tooltip arrow title={t('Edit add-on details')}>
                                        <IconButtonEdit onClick={() => handleEditAddonOpen(groupName)}>
                                            <EditTwoToneIcon fontSize="small" />
                                        </IconButtonEdit>
                                    </Tooltip>
                                    <Tooltip arrow title={t('Remove this add-on ' + groupName)}>
                                        <IconButtonError onClick={() => handleDeleteClick(groupName)}>
                                            <DeleteTwoToneIcon fontSize="small" />
                                        </IconButtonError>
                                    </Tooltip>

                                </Box>
                            </CardCc>
                        </Grid>)}
                  
                    <Grid item xs={12} sm={3}>
                        <Tooltip arrow title={t('Click to add an Add-on')}>
                                <CardAddAction onClick={handleCreateAddonOpen}>
                                <CardActionArea
                                    sx={{
                                        px: 1
                                    }}
                                >
                                    <CardContent>
                                        <AvatarAddWrapper>
                                            <AddTwoToneIcon fontSize="large" />
                                        </AvatarAddWrapper>
                                    </CardContent>
                                </CardActionArea>
                            </CardAddAction>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Box>
        </Card>
            {isAddonModalOpen && <ModalPopUp isOpen={isAddonModalOpen} categoryNames={categoryNames} catNameHash={catNameHash} onClose={onAddonModalClose} onSubmit={onAddonModalSubmit} model={addOnToEdit} />}
            <DeleteConfirm message='Are you sure want to remove this add-on?' 
            okText="Yes" cancelText="No"
            onClose={()=>setIdRequestedToDelete('')} open={Boolean(idRequestedToDelete.length)}
           onDeleteConfirm={()=> handleConfirmDelete()}  />   

        </>
    );
}

function ModalPopUp(props: { isOpen: boolean,categoryNames:string[],catNameHash:Dictionary<AddOnItem[]>,  onClose: () => void, onSubmit: (item: AddOnGroup) => void, model?: AddOnGroup }) {
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { enqueueSnackbar } = useSnackbar();
    
    const [isEdit] = useState(props.model?true:false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedName, setSelectedName] = useState('');


    const handleConfirmClick = (addon: AddOnGroup) => {
         props.onSubmit(addon);
    };
    const handleModalClose = () => {
        props.onClose();
    };
    
    const onAddOnCategoryChange = (event: SelectChangeEvent<string>) => {
        setSelectedName(event.target.value);
    };

    return (<Dialog
        fullWidth
        maxWidth="md"
        open={true}
        onClose={handleModalClose}
    >
        <DialogTitle
            sx={{
                p: 3
            }}
        >
            <Typography variant="h4" gutterBottom>
                {t('Choose Add-On' + (isEdit?' (Update)' : ''))}
            </Typography>
            <Typography variant="subtitle2">
                {t('Use this form to select Add-On')}
            </Typography>
        </DialogTitle>
        <Formik
            initialValues={{
                isRequired: !isEdit ? true : (props.model?.isRequired === true ? true : false),
                maxSelection: null,
                categoryName: props.model?.groupName || '',
                addOnItems:  isEdit ? props.model.addOnItems : []
            }}
            validationSchema={Yup.object().shape({
                categoryName: Yup.string()
                    .required(t('Select add-on category')),
                addOnItems: Yup.array()
                    .min(1, "Choose at least 1 add-on item")
                    .required(t('Add-on item is required')),
            })}
            onSubmit={async (
                _values,
                { resetForm, setErrors, setStatus, setSubmitting }
            ) => {
                try {
                    resetForm();
                    setStatus({ success: true });
                    setSubmitting(false);
                    handleConfirmClick({
                        groupName: _values.categoryName,
                        addOnItems: _values.addOnItems,
                        isRequired: _values.isRequired,
                        maxSelection: _values.maxSelection
                    });
                    
                } catch (err) {
                    console.error(err);
                    setStatus({ success: false });
                    setSubmitting(false);
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                setFieldValue,
                handleSubmit,
                isSubmitting,
                touched,
                values
            }) => (
                <Form id="addOnForm">
                    <DialogContent
                        dividers
                        sx={{
                            p: 3
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {isLoading && <Skeleton variant="rectangular" width={'100%'} height={50} />}
                                {!isLoading && <FormControl variant="filled" sx={{ minWidth: 220 }} 
                                fullWidth
                                    error={Boolean(touched.categoryName && errors.categoryName)}
                                >
                                    <InputLabel id="demo-simple-select-filled-label">Add-on Category</InputLabel>
                                    <Select
                                        name="categoryId"
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        onChange={(o) => {
                                            setFieldValue('categoryName',o.target.value);
                                         }}
                                        onBlur={handleBlur}
                                        value={values.categoryName}
                                    >
                                        <MenuItem value={''}>
                                            <em>None</em>
                                        </MenuItem>
                                        {props.categoryNames.map(cat => <MenuItem key={cat} value={cat} >{cat}</MenuItem>)}
                                    </Select>
                                    {Boolean(touched.categoryName && errors.categoryName) && <FormHelperText>{errors.categoryName}</FormHelperText>}
                                </FormControl>}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    control={
                                        <Field
                                            name="isRequired"
                                            type="checkbox"
                                            component={Switch}
                                        />
                                    }
                                    labelPlacement="end"
                                    label="Required?"
                                />
                                {/*<FormControlLabel*/}
                                {/*    control={<Switch color="primary" defaultChecked />}*/}
                                {/*    label={t('Required?')}*/}
                                {/*/>*/}
                            </Grid>
                            <Grid sx={{display:{xs: 'none',md:'block'}}} item xs={12} md={4}></Grid>
                            <Grid item xs={12} md={2}>
                                <FormControl fullWidth variant="filled" >
                                            <InputLabel htmlFor="filled-all-price">Max Selection</InputLabel>
                                            <FilledInput 
                                                id="filled-adornment-amount"
                                                    value={values.maxSelection || ''}
                                                    type="number"
                                                    onChange={(o) => setFieldValue('maxSelection',Number(o.target.value)>0 && Number(o.target.value)<50?Number(o.target.value):'')}
                                                />
                                        </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Box pb={1}>
                                    <b>{t('Choose Add-on Item')}:</b>
                                </Box>
                                {/* <Stack direction="row" spacing={1} width='100%'>*/}
                                <Autocomplete
                                    fullWidth
                                    blurOnSelect={true}
                                    multiple
                                    id="addon-item-auto-comp"
                                    value={values.addOnItems}
                                    options={(props.catNameHash[values.categoryName] || [])}
                                    getOptionLabel={(option: AddOnItem) => option.name}
                                    onChange={(event: any, newValues) => {
                                        if (newValues) {
                                         setFieldValue('addOnItems',newValues);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Search & select items..."
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                            }}
                                        />
                                    )}
                                />
                                {/*    <Button variant="outlined" startIcon={<PostAddTwoToneIcon />}>*/}
                                {/*        Add*/}
                                {/*    </Button>*/}
                                {/*</Stack>*/}

                            </Grid>

                        </Grid>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('')}</TableCell>
                                        <TableCell>{t('Item')}</TableCell>
                                        <TableCell>{t('Desc.')}</TableCell>
                                        <TableCell align="right">{t('Price')}</TableCell>
                                        {/* <TableCell align="right">{t('Actions')}</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                      {values.addOnItems.map((item) => (
                                        <TableRow key={item.id}>
                                            <TableCell>{''}</TableCell>
                                            <TableCell>
                                                <Typography noWrap>{item.name}</Typography>
                                            </TableCell>
                                            <TableCell>{''}</TableCell>
                                            <TableCell  align="right">
                                                {Number(item.price).toFixed(2)}
                                            </TableCell>
                                            {/* <TableCell align="right">
                                                <Tooltip arrow title={t('Remove Item')}>
                                                    <IconButtonError onClick={() => {
                                                        // setAddedAddOnItems(items => {
                                                        //     const arr = items.filter(o => o.id != item.id);
                                                        //     setFieldValue('addOnItems', arr);
                                                        //     return arr;
                                                        // });
                                                    }}>
                                                        <DeleteTwoToneIcon fontSize="small" />
                                                    </IconButtonError>
                                                </Tooltip>
                                            </TableCell> */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan={0}>

                                        </TableCell>
                                        <TableCell colSpan={4} align="right">
                                            <Typography
                                                gutterBottom
                                                variant="caption"
                                                color="text.secondary"
                                                fontWeight="bold"
                                            >
                                                {t('Total Count')}:
                                            </Typography>
                                            <Typography variant="h3" fontWeight="bold">
                                                {values.addOnItems.length}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    {Boolean(errors.addOnItems) && <FormHelperText error={true}>{errors.addOnItems}</FormHelperText>}
                                </TableFooter>
                            </Table>
                        </TableContainer>

                    </DialogContent>
                    <Box
                        sx={{
                            display: { xs: 'block', sm: 'flex' },
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: 3
                        }}
                    >
                        <Box>

                        </Box>
                        <Box>
                            <Button
                                fullWidth={mobile}
                                sx={{
                                    mr: { xs: 0, sm: 2 },
                                    my: { xs: 2, sm: 0 }
                                }}
                                color="secondary"
                                variant="outlined"
                                onClick={handleModalClose}
                            >
                                {t('Cancel')}
                            </Button>
                            <Button
                                fullWidth={mobile}
                                type="submit"
                                form="addOnForm"
                                startIcon={
                                    isSubmitting ? <CircularProgress size="1rem" /> : null
                                }
                                disabled={isLoading ||  isSubmitting}
                                variant="contained"
                                size="large"
                            >
                                {t('Confirm')}
                            </Button>
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    </Dialog>)
}

interface ChipData {
    key: string;
    label: string;
    price:number
}

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

function ChipsArray(props: { addOnItems: AddOnItem[] }) {

    const [addOnItems, setAddOnItems] = useState<AddOnItem[]>(props.addOnItems);
    const [chipData, setChipData] = React.useState<ChipData[]>([]);

    useEffect(() => {
        setAddOnItems(props.addOnItems);
        setChipData(props.addOnItems.map(item => {
            return {
                key: item.id, label: item.name, price: item.price
            }
        }));
    }, [props.addOnItems]);


    const handleDelete = (chipToDelete: ChipData) => () => {
        setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };

    return (
        <Paper elevation={0}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 0,
            }}
            component="ul"
        >
            {chipData.map((data) => {
                let icon;

                

                return (
                    <ListItem key={data.key}>
                        <Chip
                            label={data.label + '  ' + '(' + Number(data.price).toFixed(2)+')'}
                        />
                    </ListItem>
                );
            })}
        </Paper>
    );
}
export default AddOnInfo;
