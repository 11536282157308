import * as React from 'react';
import {
    Box,
    Grid,
    LinearProgress,
    styled
} from '@mui/material';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { useTranslation } from 'react-i18next';
import PageHeader from '../page-header';
import CartTable from './cart-table';
import OrderSummary from './order-summary';
import { isEmpty } from 'lodash';
import { setTaxDetails } from 'src/store/slices/priceMenu';
import { useDispatch, useSelector } from 'src/store';
import { getTaxDetails } from 'src/services/apiService';

const RootWrapper = styled(Box)(() => `
  flex: 1;
  background: #FFFFFF;
`);

function CheckOut() {
    const dispatch = useDispatch();
    const { t }: { t: any } = useTranslation();
    const { taxDetails, paymentStatusLoading } = useSelector(
        (state: any) => state.priceMenu,
    );
    const [isReadyForPayment, setIsReadyForPayment] = React.useState(false);
    const [payMethod, setPayMethod] = React.useState<any>('');
    const [paymentType, setPaymentType] = React.useState('');

    React.useEffect(() => {
        if (isEmpty(taxDetails)) {
            dispatch(getTaxDetails())
        }
    }, []);

    return (
        <RootWrapper>
            <PageTitleWrapper>
                <PageHeader title={t("Checkout")} subtitle="" isCart={true} />
            </PageTitleWrapper>
            {paymentStatusLoading && (
                <LinearProgress />
            )}
            <Grid container spacing={2} px={4} >
                <Grid xs={6} md={8}>
                    <CartTable isReadyForPayment={isReadyForPayment} paymentType={paymentType} setPaymentType={setPaymentType} />
                </Grid>
                <Grid item xs={6} md={4}>
                    <OrderSummary isReadyForPayment={isReadyForPayment} setIsReadyForPayment={setIsReadyForPayment} paymentType={paymentType} />
                </Grid>
            </Grid>
        </RootWrapper>
    );
}

export default CheckOut;