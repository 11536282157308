import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
    TextField,
    Autocomplete,
    Box,
    Card,
    Grid,
    styled,
    Button,
    Switch,
    FormControlLabel,
    CircularProgress
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import SaveIcon from '@mui/icons-material/Save';
import { getIngredientById, parseToApiErrorMessage, saveIngredient } from "src/services/apiService";
import {  Ingredient } from '../../../../services/apiService/response-models';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import EditorWrapper from '../../../../components/StyledWrappers/EditorWrapper';
export default function IngredientEditor(props: { id?: string,onCancel:()=>void, onSave: (e: Ingredient) => void }) {
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [isSaveInProgress, setIsSaveInProgress] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [model, setModel] = useState<Ingredient | null>(null);
    const [isEdit, setIsEdit] = useState(false);
    const [iconNames, setIconNames] = useState(['grs','mjn']);
    const [inputTextIcon, setInputTextIcon] = useState('');
       
   const getById = async (id: string) => {
    try {
        const response = await getIngredientById(id);
        setIsEdit(true);
        setModel(response.data);
    } catch (ex) {
        console.error(ex);
        const msg = ex.message ? ex.message : `Failed to load data by id`;
        enqueueSnackbar(t(msg), { variant: 'error' });
        setErrorMsg(msg);
    }
};

useEffect(() => {
    if (props.id?.length) {
        setIsLoading(true);
        getById(props.id).then(() => {
            setIsLoading(false);
        });
    }else{
        setModel({
            name:''
        });
    }
}, []);

    return (
        <Card
            sx={{
                p: 3
            }}
        >
            <Formik
                initialValues={{
                    name: model?.name || '',
                    description: model?.description || '',
                    icon:  model?.icon || ''
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string()
                        .max(100)
                        .required(t('The name field is required')),
                    description: Yup.string()
                        .max(500),
                    icon: Yup.string()
                        .max(10),
                })}
                
                onSubmit={async (
                    _values,
                    { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                    try {
                        setIsSaveInProgress(true);
                        const res = await saveIngredient({...model,..._values});
                        if (res) {
                            resetForm();
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                        enqueueSnackbar(t(`Ingredient was ${!isEdit ? 'created' : 'updated'} successfully`), { variant: 'success' });
                        props.onSave(res.data);
                    } catch (err) {
                        console.error(err);
                        enqueueSnackbar(t(parseToApiErrorMessage(err,'failed to save')), { variant: 'error' });
                        setStatus({ success: false });
                        // setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                    setIsSaveInProgress(false);
                }}
            >{({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue
            }) => (

                <form onSubmit={handleSubmit} noValidate onKeyDown={(e)=>e.stopPropagation()}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="name"
                                    placeholder={t('Enter ingredient name...')}
                                variant="outlined"
                                    id="name" label="Item Ingredient"
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <EditorWrapper>
                                <ReactQuill
                                        placeholder="Ingredient description..."
                                    id="description"
                                    value={values.description}
                                    onChange={(o) => setFieldValue('description', o)}
                                />
                            </EditorWrapper>
                        </Grid>
                        <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    options={iconNames}
                                    value={model?.icon}
                                    onChange={(event, newValue) => {
                                          if(newValue){
                                            model.icon=newValue.trim();
                                          }
                                         setFieldValue("icon",newValue || "")
                                      }}
                                    sx={{ width: 300 }}
                                    freeSolo
                                    autoSelect
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    renderInput={(params) => <TextField {...params} name="Icon" 
                                    label="Icon" 
                                    helperText={errors.icon?errors.icon: "Select or enter (Press enter to add new value)"}
                                    error={errors.icon?true:false}
                                    />}
                                />
                        </Grid>
                            <Grid item xs={12}>
                                <Button sx={{ mr: 2 }} type="button" onClick={props.onCancel} variant="text">
                                    Cancel</Button>
                            <Button type="submit" 
                            variant="contained"
                                startIcon={
                                    isSubmitting || isSaveInProgress ? <CircularProgress size="1rem" /> : <SaveIcon />
                                }
                                disabled={isSubmitting || isSaveInProgress || Boolean(errorMsg.length)}>
                                Save</Button>
                        </Grid>
                    </Grid>
                </form>
            )}
            </Formik>
        </Card>
    );
}

