import { Fragment, type FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { setHours, setMinutes, subDays } from 'date-fns';
import _ from 'lodash';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import Text from 'src/components/Text';
import SaveIcon from '@mui/icons-material/Save';

import {
  styled,
  Box,
  Card,
  TextField,
  Button,
  IconButton,
  Divider,
  FormControlLabel,
  Grid,
  Alert,
  Checkbox,
  CircularProgress,
  Tooltip,
  Typography,
  lighten,
  Zoom,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItem,
  useTheme,
  FormControl,
  InputAdornment,
  OutlinedInput,
  List,
  Paper,
  LinearProgress
} from '@mui/material';
import { DateTimePicker } from '@mui/lab';
import { useDispatch } from 'src/store';
import { useTranslation } from 'react-i18next';
import Scrollbar from 'src/components/Scrollbar';
import { Item } from 'src/services/apiService/response-models';
import { closeSidebar } from 'src/store/slices/app';
import { getItemList } from 'src/services/apiService';
import cdnService from 'src/services/cdnService';



const OutlinedInputWrapper = styled(OutlinedInput)(
    ({ theme }) => `
        background-color: ${theme.colors.alpha.white[100]};
  
        .MuiOutlinedInput-notchedOutline {
            border: 0;
        }
    `
  );
  
  const ListWrapper = styled(List)(
    () => `
        .MuiListItem-root:last-of-type + .MuiDivider-root {
            display: none;
        }
    `
  );
  
interface ItemPickerModalProps {
  preSelectedIds?:string[];
  onComplete?: (items:Item[]) => void;
  onCancel?: () => void;
}
 
const ItemPickerDrawer=(props:{
  preSelectedIds,
    onComplete,
  onCancel,
})=>
{
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [allItems, setAllItems] = useState<Item[]>([]);
  const [filteredItems, setFilteredItems] = useState<Item[]>([]);
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(()=>{
    setIsLoading(true);
    getItemList().then(res=>{
      setAllItems(res.data);
      setFilteredItems(res.data);
      setIsLoading(false);
      console.log(res.data)
    });

  },[]);

 const onAdd=(e:Item)=>{
    setSelectedItems([...selectedItems,e]);
 }
 const onRemove=(e:Item)=>{
  setSelectedItems([...selectedItems.filter(o=>o.id!==e.id)]);
}
 


  const getItemPrice=(e:Item)=>{
      if(e.variants?.length > 0){
        return _.first(e.variants[0].prices).price;
      }
      else if (e.prices?.length > 0){
        return _.first(e.prices).price;
      }
  }

  return (
    <Grid container sx={{width:800}}>
        <Grid item xs={12}>
        <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={2}
      >
        <Box>
          
          <Typography variant="h4">{t('Select items to add')}</Typography>
        </Box>
       
      </Box>
      <Divider />
      <Box
        py={1}
        px={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          background: `${theme.colors.alpha.black[5]}`
        }}
      >
        <Button onClick={()=> props.onComplete(selectedItems)} disabled={selectedItems.length==0} size="small" color="primary">
          {t('Confirm '+ (selectedItems.length?'('+selectedItems.length+')':''))}
        </Button>
        <Button  onClick={(e)=>dispatch(closeSidebar())} size="small" color="secondary">
          {t('Cancel')}
        </Button>
      </Box>
      <Divider />
      <Box>
        <FormControl variant="outlined" fullWidth>
          <OutlinedInputWrapper
            type="text"
            onChange={(e)=>{
              const key=e.target.value.toString().trim().toLowerCase();
              const items = allItems.filter(o=>o.name.toLowerCase().includes(key) 
              || o.description?.toLowerCase().includes(key)
               || o.categories?.find(c=>c.name.toLowerCase().includes(key)));
              setFilteredItems(items);
            }}
            placeholder={t('Search category or item name, code...')}
            startAdornment={
              <InputAdornment position="start">
                <SearchTwoToneIcon />
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
      <Divider />
      {isLoading && <LinearProgress />  }
      <Box
        sx={{
          height: 700
        }}
      >
       {filteredItems.length==0 && <Box sx={{ textAlign:'center',my:2}} ><Typography>
          No items to display
        </Typography></Box>}
        <Scrollbar>
           <ListWrapper disablePadding>
            {filteredItems.map((item) => (
              <Fragment key={item.id}>
                <ListItem
                  sx={{
                    '&:hover': {
                      background: `${theme.colors.alpha.black[5]}`
                    }
                  }}
                  secondaryAction={<>
                   {!selectedItems.find(o=>o.id==item.id) && <Button
                      disabled={props.preSelectedIds.includes(item.id)}
                      size="small"
                      variant="text"
                      color="primary"
                      startIcon={<AddTwoToneIcon />}
                      onClick={()=>onAdd(item)}
                      sx={{
                        alignSelf: 'center',
                        padding: `${theme.spacing(0.5, 1.6, 0.5, 1.2)}`,
                        backgroundColor: `${theme.colors.primary.lighter}`,
                        textTransform: 'uppercase',
                        fontSize: `${theme.typography.pxToRem(11)}`,
                        '&:hover': {
                          backgroundColor: `${theme.colors.primary.main}`,
                          color: `${theme.palette.getContrastText(
                            theme.colors.primary.main
                          )}`
                        }
                      }}
                    >
                      {t(props.preSelectedIds.includes(item.id) ? 'Added' : 'Select')}
                    </Button>}
                    {selectedItems.find(o=>o.id==item.id) &&  <Button
                    disabled={props.preSelectedIds.includes(item.id)}
                    size="small"
                    variant="text"
                    color="secondary"
                    startIcon={<AddTwoToneIcon />}
                    onClick={()=>onRemove(item)}
                    sx={{
                      alignSelf: 'center',
                      padding: `${theme.spacing(0.5, 1.6, 0.5, 1.2)}`,
                      backgroundColor: `${theme.colors.secondary.lighter}`,
                      textTransform: 'uppercase',
                      fontSize: `${theme.typography.pxToRem(11)}`,
                      '&:hover': {
                        backgroundColor: `${theme.colors.secondary.main}`,
                        color: `${theme.palette.getContrastText(
                          theme.colors.secondary.main
                        )}`
                      }
                    }}
                  >
                    {'Remove'}
                  </Button>}</>
                  }
                >
                  <ListItemAvatar
                    sx={{
                      mr: 1
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 50,
                        height: 50
                      }}
                      alt={item.name}
                      src={cdnService.toImageUrl(item.featureImage)}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{
                      flexGrow: 0,
                      maxWidth: '50%',
                      flexBasis: '50%'
                    }}
                    disableTypography
                    primary={
                      <Typography
                        sx={{
                          pb: 0.6
                        }}
                        color="text.primary"
                        variant="h5"
                      >
                        {item.name}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Box display="flex" alignItems="flex-start">
                        <Typography
                            sx={{
                              fontSize: `${theme.typography.pxToRem(11)}`,
                              lineHeight: 1
                            }}
                            variant="body1"
                          >
                            <Text color="secondary">{t(_.first(item.categories)?.name)}</Text>
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: `${theme.typography.pxToRem(11)}`,
                              lineHeight: 1,
                              ml:1

                            }}
                            variant="body1"
                          >
                            <Text color="success">{t('$'+getItemPrice(item)+'')}</Text>
                          </Typography>
                        </Box>
                      </>
                    }
                  />
                </ListItem>
                <Divider />
              </Fragment>
            ))}
          </ListWrapper>
        </Scrollbar>
      </Box>
      <Divider />
      <Box
        sx={{
          background: `${theme.colors.alpha.black[5]}`,
          textAlign: 'center'
        }}
        p={2}
      >
        <Text
          color="info"
        >
          {t('Total item count: '+allItems.length)}
        </Text>
      </Box>
      </Grid>
                 
    </Grid>
  );
};

ItemPickerDrawer.propTypes = {
  // @ts-ignore
  event: PropTypes.object,
  // @ts-ignore
  range: PropTypes.object,
  onAddComplete: PropTypes.func,
  onCancel: PropTypes.func,
  onDeleteComplete: PropTypes.func,
  onEditComplete: PropTypes.func
};

ItemPickerDrawer.defaultProps = {
  onComplete: () => {},
  onCancel: () => {},
};

export default ItemPickerDrawer;
