import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// import PageHeader from './PageHeader';
import PageHeader from 'src/components/page-header';
import { Box, Drawer, Grid, useTheme, IconButton, styled, Card, Divider, Button, CircularProgress, LinearProgress, DialogContent, Switch, FormGroup, FormControlLabel, FormHelperText, FormControl, InputAdornment, Dialog, DialogTitle, Typography, InputLabel, FilledInput, useMediaQuery } from '@mui/material';

import Sidebar from './Sidebar';

import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import SaveIcon from '@mui/icons-material/Save';
import GeneralSection from './GeneralSection';
import AddOnInfo from './AddOnInfo';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { AddOnItem, Item, ItemPrice } from 'src/services/apiService/response-models';
import { getAllAddOnItems, getItemById, parseToApiErrorMessage, saveItem } from 'src/services/apiService';
import Scrollbar from 'src/components/Scrollbar';
import VariantCard from './variant-card';
import ItemPriceCard from './item-price-card';
import validateItemPrice from './utils/validateItemPrice';

const DrawerWrapper = styled(Drawer)(
    ({ theme }) => `
    width: 400px;
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: 400px;
        height: calc(100% - ${theme.header.height});
        position: absolute;
        top: ${theme.header.height};
        right: 0;
        z-index: 3;
        background: ${theme.colors.alpha.white[10]};
    }
`
);

const DrawerWrapperMobile = styled(Drawer)(
    ({ theme }) => `
    width: 360px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 360px;
        z-index: 3;
        background: ${theme.colors.alpha.white[30]};
  }
`
);

const MainContentWrapper = styled(Box)(
    () => `
  flex-grow: 1;
`
);

const IconButtonToggle = styled(IconButton)(
    ({ theme }) => `
  width: ${theme.spacing(6)};
  height: ${theme.spacing(6)};
`
);

function ItemCreate(props: { isCopy?: boolean }) {
    const theme = useTheme();

    const navigate = useNavigate();
    const { id } = useParams();

    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [model, setModel] = useState<Item | null>(null);
    const [isSaveInProgress, setIsSaveInProgress] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [name, setName] = useState('');
    const [allAddonItems, setAllAddonItems] = useState<AddOnItem[]>([]);

    const loadRefData = async (callback: (e: AddOnItem[]) => void) => {
        const promise1 = getAllAddOnItems();

        Promise.all([promise1])
            .then(async responses => {

                const allItems = responses[0].data;
                setAllAddonItems(allItems);
                callback(allItems);

            }).catch(ex => {
                console.error(ex);
                const msg = ex.message ? ex.message : `Failed to load ref data`;
                enqueueSnackbar(t(msg), { variant: 'error' });
                callback([]);
            });

    };

    const getById = async (itemId: string) => {
        try {
            const response = await getItemById(itemId);
            if (props.isCopy === true) {
                setIsEdit(false);
                const modelClone = { ...response.data };
                modelClone.id = '';
                return modelClone;
            } else {
                setIsEdit(true);
                setName(response.data.name);
                console.log(response.data[0].name);
                
                console.log(response.data);
                
                return response.data[0];
            }

        } catch (ex) {
            console.error(ex);
            const msg = ex.message ? ex.message : `Failed to load item by id`;
            enqueueSnackbar(t(msg), { variant: 'error' });
            navigate("/inventory/items/list", { replace: true });
        }
    };

    useEffect(() => {
        setIsLoading(true);
        loadRefData((addonItems) => {
            if (id?.length) {
                setIsLoading(true);
                getById(id).then((item) => {
                    setIsLoading(false);
                    //setup addon master
                    if (item) {
                        (item.addOns || []).forEach(tg => {
                            (tg.addOnItems || []).forEach(ti => {
                                const m = addonItems.find(a => a.id == ti.id);
                                if (m) {
                                    tg.groupName = m.categories?.find(o => o)?.name;
                                    ti.price = m.price;
                                    ti.name = m.name;
                                    ti.categories = m.categories;
                                }
                            });
                        });
                        ///-------
                        setModel(item);
                    }
                });
            } else {
                setIsLoading(false);
            }
        });
    }, []);


    const onSave = async (updatedModel: Item) => {
        let isSuccess = false;
        try {
            setIsSaveInProgress(true);

            await saveItem(updatedModel);
            enqueueSnackbar(t(`Item was ${!isEdit ? 'created' : 'updated'} successfully`), { variant: 'success' });
            isSuccess = true;
            navigate('/' + location.pathname.split('/')[1] + "/inventory/items/list", { replace: true });
            setIsSaveInProgress(false);
        } catch (ex) {
            setIsSaveInProgress(false);
            console.log(ex);
            const msg = parseToApiErrorMessage(ex, `Failed to ${!isEdit ? 'created' : 'updated'} Item`);
            enqueueSnackbar(t(msg), { variant: 'error' });
        }
        return isSuccess;
    }


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };



    const sidebarContent = (
        <Scrollbar>
            <Sidebar />
        </Scrollbar>
    );

    return (
        <>
    <Formik
        enableReinitialize={true}
        initialValues={{
            name: model?.name || '',
            code: model?.code || '',
            description: model?.description || '',
            featureImage: model?.featureImage || '',
            addOns: model?.addOns || [],
            categories: model?.categories || [],
            ingredients: model?.ingredients || [],
            prices: model?.prices || [],
            variants: model?.variants || [],
        }}
        validationSchema={Yup.object().shape({
            name: Yup.string()
                .max(250)
                .required(t('The name field is required')),
            featureImage: Yup.string()
                .required(t('The feature image is required')),
            categories: Yup.array()
                .min(1, "Choose at least 1 item category")
                .required(t('Choose at least 1 item category')),
        })}
        onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
            try {
                const errorMsg = validateItemPrice(_values.prices, _values.variants);
                if (errorMsg) {
                    enqueueSnackbar(t(errorMsg), { variant: 'error' });
                    setSubmitting(false);
                    return;
                }

                const updateModel = {
                    id: model?.id || '',
                    name: _values.name,
                    code: _values.code,
                    description: _values.description,
                    featureImage: _values.featureImage,
                    categories: _values.categories,
                    addOns: _values.addOns,
                    ingredients: _values.ingredients,
                    variants: _values.variants,
                    prices: _values.prices,
                    createdBy: '',
                    createdOn: new Date(),
                    images: [],
                };

                // Set AddOn ID logic
                if (updateModel.addOns) {
                    updateModel.addOns.forEach(o => {
                        (o.addOnItems || []).forEach(item => {
                            o.id = item.categories?.find(c => c.name === o.groupName)?.id;
                        });
                    });
                    // Filter out addOns without ids
                    updateModel.addOns = updateModel.addOns.filter(o => o.id);
                }

                const isSuccess = await onSave(updateModel);
                if (isSuccess) {
                    resetForm();
                    setStatus({ success: true });
                    enqueueSnackbar(t('Item saved successfully!'), { variant: 'success' });
                    setSubmitting(false);
                }
            } catch (err) {
                console.error(err);
                const msg = parseToApiErrorMessage(err, 'Failed to save item');
                enqueueSnackbar(t(msg), { variant: 'error' });
                setStatus({ success: false });
                setSubmitting(false);
            }
        }}
    >
        {({ handleSubmit, isSubmitting, values }) => (
            <form onSubmit={handleSubmit}>
                <Box mb={3} display="flex">
                    <MainContentWrapper>
                        <Grid
                            sx={{ px: 4 }}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="stretch"
                            spacing={4}
                        >
                            <Grid item xs={12}>
                                <Box
                                    mt={3}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <PageHeader
                                        type="create"
                                        title="Add new Item"
                                        subtitle="Fill in the fields below to create a new item"
                                        route={`/${location.pathname.split('/')[1]}/inventory/items/list`}
                                        buttonTitle="Go back to all items"
                                    />
                                    <Box component="span" sx={{ display: { lg: 'none', xs: 'inline-block' } }}>
                                        <IconButtonToggle
                                            sx={{ ml: 2 }}
                                            color="primary"
                                            onClick={handleDrawerToggle}
                                            size="small"
                                        >
                                            <MenuTwoToneIcon />
                                        </IconButtonToggle>
                                    </Box>
                                </Box>
                            </Grid>

                            {isLoading && (
                                <Grid item xs={12}>
                                    <LinearProgress />
                                    Loading...
                                </Grid>
                            )}

                            <Grid item xs={12}>
                            <GeneralSection name={values.name} description={values.description} />
                            </Grid>
                            <Grid item xs={12}>
                                <VariantCard itemName={values.name} />
                            </Grid>
                            <Grid item xs={12}>
                                <AddOnInfo items={allAddonItems} />
                            </Grid>
                            <Grid item xs={12}>
                                {!isLoading && (
                                    <ItemPriceCard
                                        itemName={values.name}
                                        prices={values.prices || []}
                                        itemVariants={values.variants || []}
                                    />
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <Card sx={{ p: 1 }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                startIcon={isSubmitting ? <CircularProgress size="1rem" /> : <SaveIcon />}
                                                disabled={isLoading || isSubmitting}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </MainContentWrapper>

                    {/* Drawer Wrappers */}
                    <Box component="span" sx={{ display: { lg: 'none', xs: 'inline-block' } }}>
                        <DrawerWrapperMobile
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        >
                            {sidebarContent}
                        </DrawerWrapperMobile>
                    </Box>
                    <Box component="span" sx={{ display: { xs: 'none', lg: 'inline-block' } }}>
                        <DrawerWrapper variant="permanent" anchor={theme.direction === 'rtl' ? 'left' : 'right'} open>
                            {sidebarContent}
                        </DrawerWrapper>
                    </Box>
                </Box>
            </form>
        )}
    </Formik>
</>

    );
}


export default ItemCreate;
