import { Autocomplete, Box, Button, Card, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Skeleton, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone'
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { ChangeEvent, useState, MouseEvent, useEffect } from "react";

export type OrderStatus = 'not_started' | 'in_progress' | 'completed';

export interface FilterRefData{
  tableNumbers:string[];
  devices:string[];
  orderTypes:string[];
  statuses:string[];
}

export interface FilterModel{
    isLoading?:boolean;
    orderType?:string;
    tableNumber?:string;
    status?:string;
    devices?:string[];
    minAmount?:number;
    maxAmount?:number;
    minDate?:Date;
    maxDate?:Date;
    searchKey?:string;
    refData?:FilterRefData;
}


  
export default function OrderFilterCard(props:{filter?:FilterModel,onApply:(e:FilterModel)=>void}){
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
  const [filter, setFilter] = useState<FilterModel>({});

  useEffect(()=>{
    
  },[]);

  useEffect(()=>{
    if(props.filter){
        setFilter({...filter,...props.filter});
    }
  },[props.filter]);

 
  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setFilter({...filter,searchKey:event.target.value.trim()});
  };

  const onFilterApply=()=>{
      const filterClone={...filter};
      filterClone.status=filter.status=='all'?'':filter.status;
      filterClone.orderType=filter.orderType=='all'?'':filter.orderType;
      filterClone.tableNumber=filter.tableNumber=='all'?'':filter.tableNumber;
      props.onApply(filterClone);
  }

    return (<Card
        sx={{
          p: 1,
          mb: 3
        }}
      >
        {filter.isLoading==true?<Loader />:
        <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4}>
            <Box p={1}>
              <TextField
                sx={{
                  m: 0
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                onChange={handleQueryChange}
                placeholder={t('Search by order number, item, category, customer name...')}
                value={filter.searchKey || ''}
                fullWidth
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Box p={1}>
            <FormControl fullWidth variant="outlined">
                <InputLabel>{t('Order Type')}</InputLabel>
                <Select
                  value={filter.orderType || 'all'}
                  onChange={(e)=>setFilter({...filter,orderType:e.target.value})}
                  label={t('Order Type')}
                >
                  <MenuItem   value={'all'}>
                      All
                    </MenuItem>
                  {props.filter.refData?.orderTypes.map((o) => (
                    <MenuItem key={o} value={o}>
                      {o}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Box p={1}>
            <FormControl fullWidth variant="outlined">
                <InputLabel>{t('Table Number')}</InputLabel>
                <Select
                  value={ filter.tableNumber || 'all'}
                  onChange={(e)=>setFilter({...filter,tableNumber:e.target.value})}
                  label={t('Table Number')}
                >
                  <MenuItem   value={'all'}>
                      All
                    </MenuItem>
                  {props.filter.refData?.tableNumbers.map((o) => (
                   <MenuItem key={o} value={o}>
                   {o}
                 </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box p={1}>
              <Autocomplete
                multiple
                sx={{
                  m: 0
                }}
                // @ts-ignore
                getOptionLabel={(option) => option}
                value={filter.devices || []}
                options={props.filter.refData?.devices || []}
                onChange={(e,values)=>setFilter({...filter,devices:values})}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    label={t('Device')}
                    placeholder={t('Select device(s)...')}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box p={1}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>{t('Status')}</InputLabel>
                <Select
                   value={ filter.status || 'all'}
                    onChange={(e)=>setFilter({...filter,status:e.target.value})}
                  label={t('Status')}
                >
                   <MenuItem   value={'all'}>
                      All
                    </MenuItem>
                    {props.filter.refData?.statuses.map((o) => (
                   <MenuItem key={o} value={o}>
                   {o}
                 </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            display="flex"
            justifyContent={{ xs: 'center', md: 'flex-end' }}
          >
            <Box p={1}>
                <Button onClick={onFilterApply} variant="outlined">Apply</Button>
            </Box>
          </Grid>
        </Grid>}
      </Card>)
}

function Loader(){
    return (<Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4}>
            <Box p={1}>
            <Skeleton variant="rectangular" height={60} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Box p={1}>
            <Skeleton variant="rectangular" height={60} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Box p={1}>
            <Skeleton variant="rectangular" height={60} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box p={1}>
            <Skeleton variant="rectangular" height={60} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box p={1}>
              <Skeleton variant="rectangular" height={60} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            display="flex"
            justifyContent={{ xs: 'center', md: 'flex-end' }}
          >
            <Box p={1}>
              <ToggleButtonGroup
                
                exclusive
               
              >
                <Skeleton variant="rectangular" width={90} height={60}/>
              </ToggleButtonGroup>
            </Box>
          </Grid>
        </Grid>)
}