import { useState, useEffect, useCallback } from 'react';

// import PageHeader from './PageHeader';
import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from 'src/components/page-header';
import { Alert, Box, Grid, LinearProgress, Skeleton } from '@mui/material';
import useRefMounted from 'src/hooks/useRefMounted';

import Results from './Result';
import { parseToApiErrorMessage } from '../../../../services/apiService';
import { getCategoryListView } from '../../../../services/dataViewService';
import { CategoryListItemViewModel } from '../../../../services/dataViewService/data-view-models';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { getCategories } from '../../../../services/apiService';
import { Category } from 'src/services/apiService/response-models';

function ManageCategories() {
    const { enqueueSnackbar } = useSnackbar();
    const { t }: { t: any } = useTranslation();
    const isMountedRef = useRefMounted();

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [hasDataLoadError, setHasDataLoadError] = useState(false);

    const [categories, setCategories] = useState<Category[]>([]);

    const fetchgetCategories = useCallback(async () => {
        try {
            setIsDataLoading(true);
            setHasDataLoadError(false);

            const response = await getCategories();
            
            if (isMountedRef.current) {
                setCategories(response.data);
            }
        } catch (err) {
            setHasDataLoadError(true);
            const msg = parseToApiErrorMessage(err, `Failed to load category data`);
            enqueueSnackbar(t(msg), { variant: 'error' });
            console.error(err);
        }
        setIsDataLoading(false);
    }, [isMountedRef]);

    useEffect(() => {
        fetchgetCategories();
    }, [fetchgetCategories]);

    const onDeleteSuccess =async () => {
        await fetchgetCategories();
    }
    return (
        <>
            <PageTitleWrapper>
                <PageHeader type="list"  title="Item Category" subtitle="Use this page to manage Item category." route={'/'+location.pathname.split('/')[1]+"/inventory/category/new"} buttonTitle="Create Item Category"></PageHeader>
                {/* <PageHeader /> */}
            </PageTitleWrapper>

            <Grid
                sx={{
                    px: 4
                }}
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={4}
            >
                <Grid item xs={12}>
                    {!isDataLoading && hasDataLoadError && <Alert severity="error">Failed to load data</Alert>}
                    {isDataLoading && <LinearProgress />}
                    <Results categories={categories} onDeleteSuccess={onDeleteSuccess} />
                </Grid>
            </Grid>
            <Footer />
        </>
    );
}

export default ManageCategories;
