import {
    alpha,
    Box,
    Card,
    Link,
    Grid,
    CardActionArea,
    CardMedia,
    Typography,
    Button,
    styled,
    useTheme,
    IconButton,
    Tooltip
  } from '@mui/material';
  import { useTranslation } from 'react-i18next';
import { PriceMenu } from 'src/services/apiService/response-models';
import cdnService from 'src/services/cdnService';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';


const CardWrapper = styled(Card)(
  ({ theme }) => `

    .MuiActionButtons {
          background: ${alpha(theme.colors.alpha.white[100], 0.95)};
          border-radius: ${theme.general.borderRadius};
          opacity: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          visibility: hidden;
          top: 15%;
          margin-top: -${theme.spacing(3.5)};
          position: absolute;
          right: ${theme.spacing(1.5)};
          padding: ${theme.spacing(0.5, 1)};
          transition: ${theme.transitions.create(['visibility', 'opacity'])};
          z-index:100;
          .MuiIconButton-root {
              border-radius: 100px;
              width: ${theme.spacing(5)};
              height: ${theme.spacing(5)};
              margin: ${theme.spacing(0.5)};
          }
    }

    &:hover {
        .MuiActionButtons {
          visibility: visible;
          opacity: 1;
    }   
    }
`
);


  const CardActions = styled(Box)(
    ({ theme }) => `
      position: absolute;
      right: ${theme.spacing(2)};
      bottom: ${theme.spacing(2)};
      z-index: 7;
      display: flex;
    `
  );
  
  const LabelWrapper = styled(Box)(
    ({ theme }) => `
      background: ${theme.palette.success.main};
      color: ${theme.palette.success.contrastText};
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      line-height: 23px;
      height: 22px;
      padding: ${theme.spacing(0, 1.2)};
      border-radius: 50px;
    `
  );
  
  const CardActionAreaWrapper = styled(CardActionArea)(
    () => `
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
  
      .MuiTouchRipple-root {
          opacity: .3;
      }
  
      &:hover {
          .MuiCardActionArea-focusHighlight {
              opacity: .05;
          }
      }
    `
  );
  
  function MenuBlock(props:{menu:PriceMenu,onDelete:(id:string)=>void}) {
    const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
    // const goToEdit=()=>{
    //   console.log("logloglogloglogl");
      
    //   navigate('/inventory/price-menu/edit/'+props.menu.id);
    // }

    const goToEdit = () => {
      navigate('/inventory/price-menu/edit/' + props.menu.id);
    };
    

    return (
        <Box>
        <CardWrapper
          sx={{
            textAlign: 'center',
            transition: `${theme.transitions.create([
              'box-shadow',
              'transform'
            ])}`,
            transform: 'translateY(0px)',

            '&:hover': {
              transform: 'translateY(-10px)',
              boxShadow: `0 2rem 8rem 0 ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}, 
                              0 0.6rem 1.6rem ${alpha(
                                theme.colors.alpha.black[100],
                                0.2
                              )}, 
                              0 0.2rem 0.2rem ${alpha(
                                theme.colors.alpha.black[100],
                                0.15
                              )}`
            }
          }}
        >
          <Box className="MuiActionButtons">
            <Tooltip arrow placement="top" title={t('Delete menu')}>
                <IconButton onClick={() => props.onDelete(props.menu.id)} color="error">
                    <CloseTwoToneIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Box>
          <CardActionAreaWrapper>
            <CardMedia
              component="img"
            // to={`/inventory/price-menu/edit/${props.menu.id}`}
              height="230"
            image={props.menu.coverImage? cdnService.toImageUrl(props.menu.coverImage) : cdnService.noImageUrl()}
             alt="..."
            //  onClick={goToEdit}
             
            />
            <CardActions>
              <LabelWrapper>{t('Available')}</LabelWrapper>
            </CardActions>
          </CardActionAreaWrapper>
        </CardWrapper>
        <Box
          sx={{
            px: { md: 2, lg: 1.5, xl: 3 },
            pt: 3,
            textAlign: 'center'
          }}
        >
          <Link
            lineHeight={1.5}
            href="#"
            sx={{
              transition: `${theme.transitions.create(['color'])}`,
              color: `${theme.colors.alpha.black[100]}`,

              '&:hover': {
                color: `${theme.colors.primary.main}`
              }
            }}
            component={RouterLink}
            to={`/${
              location.pathname.split('/')[1]
            }/inventory/price-menu/edit/`+props.menu.id}
            color="text.primary"
            variant="h3"
            underline="none"
          >
            {t(props.menu.title)}
          </Link>
          <Typography
            variant="subtitle2"
            sx={{
              py: 2
            }}
          >
          {t(props.menu.description || 'no desciption')}
            .
          </Typography>
          <Button
            variant="outlined"
            sx={{
              borderWidth: '2px',
              '&:hover': {
                borderWidth: '2px'
              }
            }}
            component={RouterLink}
            to={`/${
              location.pathname.split('/')[1]
            }/inventory/price-menu/edit/`+props.menu.id}
          >
            {t('Items ('+props.menu.items.length+')')}
          </Button>
        </Box>
      </Box>
    );
  }
  
  export default MenuBlock;