import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container, Typography, Box } from '@mui/material';
import Footer from 'src/components/Footer';
import PageHeader from './PageHeader';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';


function HomePage() {
  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Grid container>
                <Grid item sm={3} sx={{padding: 2}}>
                    <Box sx={{boxShadow: 2, background: 'white',borderRadius: 1}}>
                      <Grid container sx={{padding : 1}}>
                        <Grid item sm={8}>
                              <Typography variant='subtitle2' textTransform={'capitalize'}>Order</Typography>
                              <Box sx={{flex: 1, alignContent: "center", flexDirection: "row"}}>
                                  <Typography variant='h5'>&nbsp;&nbsp; Last Week : 14</Typography>
                              </Box>
                              <Typography variant='h3'>$2.4</Typography>
                        </Grid>
                        <Grid item sm={4}  sx={{padding: 2}}>
                                <LeaderboardIcon sx={{fontSize: 35, color: "green"}} />
                        </Grid>
                      </Grid>
                    </Box>
                </Grid>

                <Grid item sm={3} sx={{padding: 2}}>
                    <Box sx={{boxShadow: 2, background: 'white',borderRadius: 1}}>
                      <Grid container sx={{padding : 1}}>
                        <Grid item sm={8}>
                              <Typography variant='subtitle2' textTransform={'capitalize'}>Order</Typography>
                              <Box sx={{flex: 1, alignContent: "center", flexDirection: "row"}}>
                                  <Typography variant='h5'>&nbsp;&nbsp; Last Week : 14</Typography>
                              </Box>
                              <Typography variant='h3'>$2.4</Typography>
                        </Grid>
                        <Grid item sm={4}  sx={{padding: 2}}>
                                <LeaderboardIcon sx={{fontSize: 35, color: "green"}} />
                        </Grid>
                      </Grid>
                    </Box>
                </Grid>

                <Grid item sm={3} sx={{padding: 2}}>
                    <Box sx={{boxShadow: 2, background: 'white',borderRadius: 1}}>
                      <Grid container sx={{padding : 1}}>
                        <Grid item sm={8}>
                              <Typography variant='subtitle2' textTransform={'capitalize'}>Order</Typography>
                              <Box sx={{flex: 1, alignContent: "center", flexDirection: "row"}}>
                                  <Typography variant='h5'>&nbsp;&nbsp; Last Week : 14</Typography>
                              </Box>
                              <Typography variant='h3'>$2.4</Typography>
                        </Grid>
                        <Grid item sm={4}  sx={{padding: 2}}>
                                <LeaderboardIcon sx={{fontSize: 35, color: "green"}} />
                        </Grid>
                      </Grid>
                    </Box>
                </Grid>

                <Grid item sm={3} sx={{padding: 2}}>
                    <Box sx={{boxShadow: 2, background: 'white',borderRadius: 1}}>
                      <Grid container sx={{padding : 1}}>
                        <Grid item sm={8}>
                              <Typography variant='subtitle2' textTransform={'capitalize'}>Order</Typography>
                              <Box sx={{flex: 1, alignContent: "center", flexDirection: "row"}}>
                                  <Typography variant='h5'>&nbsp;&nbsp; Last Week : 14</Typography>
                              </Box>
                              <Typography variant='h3'>$2.4</Typography>
                        </Grid>
                        <Grid item sm={4}  sx={{padding: 2}}>
                                <LeaderboardIcon sx={{fontSize: 35, color: "green"}} />
                        </Grid>
                      </Grid>
                    </Box>
                </Grid>
                
            </Grid>
            
          </Grid>
        </Grid>
        <Grid item sm={12} sx={{mt: 3, background: "white", borderRadius: 2}}>
          <Grid container sx={{padding: 2}}>
            <Grid item sm={12}>
              <Typography variant='h3'>Today's Net Sales</Typography>
            </Grid>
            <Grid item sm={12} sx={{mt: 1}}>
              <Typography variant='caption'>$700.98</Typography>
            </Grid>
            <Grid item sm={12} sx={{mt: 1}}>
              <Typography variant='body2'>Last Week: $1000.98</Typography>
            </Grid>
          </Grid>
          <Grid item sm={12} sx={{padding: 2}}>
             <Box sx={{height:200}}>
              
             </Box>
          </Grid>
          
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default HomePage;
