function HtmlDescription(props: { value: string, maxLen?: number }) {

    const extractContent = (s) => {
        const span = document.createElement('span');
        span.innerHTML = s;
        let text = span.textContent || span.innerText;

        if (props.maxLen) {
            const suffix = text.length > props.maxLen ? '...' : '';
            text = text.substr(0, props.maxLen) + suffix;
        }

        return text;
    };


    return (<>
        {extractContent(props.value)}
    </>)
}

export default HtmlDescription;