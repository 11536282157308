import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  TextField,
  Autocomplete,
  Box,
  Card,
  Grid,
  styled,
  Button,
  Switch,
  FormControlLabel,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FilledInput
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import SaveIcon from '@mui/icons-material/Save';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { AddOnCategory, AddOnItem } from '../../../../services/apiService/response-models';
import DropdownMultiSelect from 'src/components/dropdown-multi-select';
import { getAllAddOnCategories } from '../../../../services/apiService';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';

const EditorWrapper = styled(Box)(
  ({ theme }) => `

    .ql-editor {
      min-height: 100px;
    }

    .ql-snow .ql-picker {
      color: ${theme.colors.alpha.black[100]};
    }

    .ql-snow .ql-stroke {
      stroke: ${theme.colors.alpha.black[100]};
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${theme.colors.alpha.black[30]};
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[50]};
      }
    }
`
);
const CopyContentIcon = styled(ContentCopyTwoToneIcon)(
  ({ theme }) => `
    margin-left: ${theme.spacing(1)};
    cursor: pointer;
    color: ${theme.palette.primary.main};
    font-size: 30px;
  `
);

function GeneralSection(props: { model: AddOnItem | null, isSaveInProgress: boolean, onSaveClick: () => void, onSave: (updatedModel: AddOnItem) => Promise<boolean> }) {
  const { t }: { t: any } = useTranslation();

  const [isEdit] = React.useState(props.model != null && props.model.id);
  const [categories, setCategories] = useState<AddOnCategory[]>([]);
  const deviceTypes = ['POS', 'Kiosk', 'Go'];
  const getByCategories = async () => {
    try {
      const response = await getAllAddOnCategories();
      setCategories(response.data.filter(o => o.isActive));
    } catch (ex) {
      console.error(ex);
    }
  };

  useEffect(() => {
    getByCategories();
  }, []);

  return (
    <Card
      sx={{
        p: 3
      }}
    >
      <Formik
        initialValues={{
          name: props.model?.name || '',
          description: props.model?.description || '',
          price: props.model?.price,
          categoryIds: (props.model?.categories || []).map(o => o.id + ''),
          isActive: isEdit ? (props.model?.isActive === true ? true : false) : true
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(250)
            .required(t('The name field is required')),
          price: Yup.number().max(999999)
            .required(t('The price field is required')),
          categoryIds: Yup.array()
            .min(1, "The category must have at least 1 item")
            .required(t('The category selection is required')),
        })}
        onSubmit={async (
          _values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            const categories = _values.categoryIds.map(o => { return { id: o } as AddOnCategory });
            var isSuccess = await props.onSave({ ...props.model, ..._values, categories });
            if (isSuccess) {
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            // setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >{({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                placeholder={t('Enter item name...')}
                variant="outlined"
                label="Device Name"
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
              />
            </Grid>

            <Grid item xs={12}>
              <EditorWrapper>
                <ReactQuill
                  placeholder="Device description..."
                  id="description"
                  value={values.description}
                  onChange={(o) => setFieldValue('description', o)}
                />
              </EditorWrapper>
            </Grid>
            {/* <Grid item xs={12}>
                              <TextField
                                  name="price"
                                  type="number"
                                  label="Item Price"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={Boolean(touched.price && errors.price)}
                                  value={values.price}
                                  sx={{ m: 1, width: '25ch' }}
                                  InputProps={{
                                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                  }}
                                  variant="outlined"
                                  helperText={touched.price && errors.price}
                              />
                          </Grid> */}
            {/* <Grid item xs={12}>
              <DropdownMultiSelect name="categoryIds"
                label="Device Type"
                options={categories.map(o => { return { code: o.id + '', value: o.name } })}
              />
            </Grid> */}
            <Grid item xs={12} md={6}>
              <Autocomplete
                disablePortal
                options={deviceTypes} // Pass the list of device types to the Autocomplete component
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    label={t('Device Type')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel labelPlacement="start"
                control={
                  <Switch
                    name="isActive"
                    disabled={!isEdit}
                    color={isEdit ? 'primary' : "secondary"}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    checked={values.isActive}
                  />
                }
                label={t('Is Active')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  fullWidth
                  name="deviceID"
                  placeholder={t('Enter item name...')}
                  variant="outlined"
                  label="Device ID"
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                />
                <CopyContentIcon />
              </Box>

            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  fullWidth
                  name="apiKey"
                  placeholder={t('Enter item name...')}
                  variant="outlined"
                  label="API KEY"
                  value={values.name}
                />
                <CopyContentIcon />
              </Box>
              <Box sx={{ marginTop: 1 }}>
                <Button variant="outlined">Regenerate</Button>
              </Box>
            </Grid>

            {/* <Grid item xs={12} md={6}>
                    <TextField
                      // error={Boolean(touched.email && errors.email)}
                      fullWidth
                      // helperText={touched.email && errors.email}
                      label={t('API KEY')}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      // value={values.email}
                      variant="outlined"
                    />
                    <Button variant="outlined" >
            Regenerate
          </Button>
                  </Grid> */}
            <Grid item xs={12} >
              <Button type="submit" onClick={() => props.onSaveClick()} variant="contained"
                startIcon={
                  isSubmitting || props.isSaveInProgress ? <CircularProgress size="1rem" /> : <SaveIcon />
                }
                disabled={isSubmitting || props.isSaveInProgress}>
                Save</Button>
            </Grid>
          </Grid>
        </form>
      )}
      </Formik>
    </Card>
  );
}

export default GeneralSection;
