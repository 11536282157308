import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CircleNotificationsTwoToneIcon from '@mui/icons-material/CircleNotificationsTwoTone';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';

export interface SettingTab {
    readonly  name: string;
    readonly text: string;
    readonly icon: React.ReactNode;
}

const SettingTabs: SettingTab[] = [
    {
    name: 'general',
    text: 'General',
    icon: <SettingsIcon />
},
  
    {
        name: 'notification',
        text: 'Notifications',
        icon: <CircleNotificationsTwoToneIcon />
    },
   
];
export default SettingTabs