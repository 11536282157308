import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import { RootState } from 'src/store';


function GeneralContent() {
    const { t }: { t: any } = useTranslation();
  //  const user = useSelector((state: RootState) => state.app.);
  const {user}=useAuth();

    return (
        <Grid container>
            <Grid item xs={12} md={6}>
               
            </Grid>
            <Grid item xs={12} md={6}>
               
            </Grid>
        </Grid>
    );
}

export default GeneralContent;
