import { Box, Card, Grid, Typography, useTheme, alpha } from '@mui/material';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';

function ErrorPanel(props: { title?: string; message?: string }) {
  const theme = useTheme();

  return (
    <Grid item xs={12} mt={1} mb={1}>
      <Card
        variant="outlined"
        sx={{
          background: alpha(theme.colors.error.main, 0.08),
          display: 'flex',
          alignItems: 'flex-start',
          p: 2
        }}
      >
        <ErrorTwoToneIcon
          sx={{
            mr: 1,
            color: theme.colors.error.main,
            fontSize: theme.typography.pxToRem(22)
          }}
        />
        <Box>
          <Typography
            variant="h4"
            sx={{
              pt: 0.2
            }}
            gutterBottom
          >
            {props.title || 'Error'}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: theme.typography.pxToRem(13)
            }}
          >
            {props.message || 'Unknown error occured. Please try again.'}
          </Typography>
        </Box>
      </Card>
    </Grid>
  );
}

export default ErrorPanel;
