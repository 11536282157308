import * as React from 'react';
import {
  Alert,
  AlertTitle,
  Typography,
  Box,
  Button,
  styled,
  Divider,
  Card
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'src/store';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import useAuth from 'src/hooks/useAuth';
import { emptyItemFromCart } from 'src/store/slices/priceMenu';
import { checkStatusPaymentWithCard, paymentWithCard, placeOrder } from 'src/services/apiService';
import generateOrderNumber from 'src/utils/generateOrderNumber';
import { render, Printer, Text } from 'react-thermal-printer';


interface IOrderSummary {
  isReadyForPayment: boolean;
  paymentType: string;
  setIsReadyForPayment: (value: boolean) => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#FFFFFF',
  boxShadow: 24,
  borderRadius: '16px',
  minWidth: '70%',
  height: '90%'
};
const alertStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#FFFFFF',
  boxShadow: 24,
  borderRadius: '16px',
  minWidth: '30%',
  height: '15%'
};

const BoxWrapper = styled(Box)(
  () => `
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px
`
);
const DiscountWrapper = styled(Box)(
  () => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    padding: 10px;
    border-radius: 8px;
`
);

const OrderSummary: React.FC<IOrderSummary> = ({
  isReadyForPayment,
  paymentType,
  setIsReadyForPayment
}) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { t }: { t: any } = useTranslation();
  const { cartData, taxDetails } = useSelector((state: any) => state.priceMenu);
  const navigate = useNavigate();

  const [totalPrice, setTotalPrice] = React.useState(0);
  const [subTotalPrice, setSubTotalPrice] = React.useState(0);
  const [totalTax, setTotalTax] = React.useState(0);
  const [totalDiscount, setTotalDiscount] = React.useState(0);
  const [checkoutWebView, setCheckoutWebView] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isAlertVisiable, setIsAlertVisiable] = React.useState(false);
  const [checkoutUrl, setCheckoutUrl] = React.useState<any | null>(null);
  const [checkoutSessionId, setCheckoutSessionId] = React.useState('');
  const [paymentError, setPaymentError] = React.useState('')
  const [isErrorModalVisiable, setIsErrorModaltVisiable] = React.useState(false);

  React.useEffect(() => {
    const total = cartData?.reduce((accumulator, currentItem) => {
      return accumulator + Number(currentItem?.itemCashTotal);
    }, 0);

    setTotalPrice(total);
  }, [cartData]);

  React.useEffect(() => {
    if (totalPrice !== 0) {
      let taxAmount = 0;
      let TaxtTotal = 0;
      taxDetails?.map((tax: any) => {
        if (tax?.isActive) {
          TaxtTotal += tax.taxValue;
          taxAmount += (totalPrice * tax.taxValue) / 100;
        }
      });
      setTotalTax(TaxtTotal);
      const totalPriceWithTax = totalPrice + taxAmount;
      const finalPrice =
        totalPriceWithTax > totalDiscount
          ? totalPriceWithTax - totalDiscount
          : 0;

      setSubTotalPrice(finalPrice);
    } else {
      setSubTotalPrice(0);
    }
  }, [totalPrice]);

  const onPressCardPay = () => {
    //mqtt_publish value
    const valueObj = {
      channelId: "50809e0fa052b8b5b8e3440b1cd757f6",
      EPI: "2319922025",
      amount: subTotalPrice?.toFixed(2)
    }
    //mqtt_publish onSuccess
    const onSuccess = () => {
    //mqtt_status value 
      const checkStatusValue = {
        channelId: "50809e0fa052b8b5b8e3440b1cd757f6",
        epi: "2319922025",
      }
    //mqtt_status onSuccess 
      const onSuccessPaymentStatus = (messageObject) => {
        const statusCode = messageObject?.STATE;

        if (statusCode === '0') {
        //Order generate API  
          onPressCasePay();
          setIsAlertVisiable(true);
        }else {
          alert('Payment not success, please try again!!')
        }
      }
      //mqtt_status API Call 
      dispatch(checkStatusPaymentWithCard(onSuccessPaymentStatus, checkStatusValue))
      
    };
    
    //mqtt_publish API Call
    dispatch(
      paymentWithCard(onSuccess, valueObj),
    );
  }

  const onPressCasePay = async () => {
    const values = cartData?.map((item: any) => {
      return {
        itemId: item?.id,
        quantity: item?.quantity,
        variantId: !isEmpty(item?.cartVariantId) ? item?.cartVariantId : null,
        addOns: item?.selectedAddOn,
        notes: item?.notes,
      };
    });

    const onSuccess = () => {
      setIsAlertVisiable(true);
    };

    let placOrderObj = {
      orderType: 'TableOrder',
      orderNumber: await generateOrderNumber(),
      items: values,
      priceType: 'cash',
      customer: {},
      tableNumber: '',
    };
    dispatch(
      placeOrder(onSuccess, placOrderObj),
    );
  }

  const createCheckoutSession = () => {
    const itemsArray = cartData?.map((item: any) => {
      let amount = parseInt(item?.itemCardTotal) * 100
      return {
        currency: 'PHP',
        amount: amount,
        name: user?.name,
        quantity: item.quantity,
        description: 'description'
      };
    });
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: 'Basic c2tfdGVzdF92NThnYnlkbkIzSEJvaHJjdTlzRmFBZVI6'
      },
      body: JSON.stringify({
        data: {
          attributes: {
            send_email_receipt: false,
            show_description: false,
            show_line_items: true,
            line_items: itemsArray,
            payment_method_types: [
              'billease',
              'card',
              'dob',
              'dob_ubp',
              'gcash',
              'grab_pay',
              'paymaya'
            ],
            success_url: 'http://localhost:2999/pos-admin/payment-success',
            cancel_url: 'https://cancel'
          }
        }
      })
    };

    fetch('https://api.paymongo.com/v1/checkout_sessions', options)
      .then((response) => response.json())
      .then((response) => {
        if (response?.errors) {
          setPaymentError(response?.errors[0]?.detail)
          setIsErrorModaltVisiable(true)
        } else {
          setIsModalVisible(true);
          setCheckoutUrl(response.data.attributes.checkout_url);
          setCheckoutSessionId(response.data.id);
        }
      })
      .catch((err) => {
        console.error('------', err.detail)
      });
  };

  const handleClose = async () => {
    const val = await paymentStatus();
    if (val) {
      onPressCasePay();
      setCheckoutUrl(null);
      setIsModalVisible(false);
      setIsAlertVisiable(true);
    } else {
      setCheckoutUrl(null);
      setIsModalVisible(false);
    }
  };

  const onPressErrorModal = () => {
    setPaymentError('')
    setIsErrorModaltVisiable(false);
  };
  const handleOk = () => {
    dispatch(emptyItemFromCart());
    setIsAlertVisiable(false);
    navigate('/pos-admin/virtualterminal');
  };

  function paymentStatus(): Promise<any> {
    return new Promise((resolve, reject) => {
      const options = {
        method: 'GET',
        headers: {
          accept: 'application/json',
          authorization: 'Basic c2tfdGVzdF92NThnYnlkbkIzSEJvaHJjdTlzRmFBZVI6'
        }
      };
      fetch(
        `https://api.paymongo.com/v1/checkout_sessions/${checkoutSessionId}`,
        options
      )
        .then((response) => response.json())
        .then((response) => {
          return response.data.attributes.payment_intent.attributes.status ===
            'succeeded'
            ? true
            : false;
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });

      return false;
    });
  }

  return (
    <>
      <Card
        sx={{
          px: 2,
          mb: 2.5
        }}
      >
        <Typography variant="h4" component={'h4'} gutterBottom p={2}>
          {t('Order Summary')}
        </Typography>
        <Box px={2} pb={2}>
          {!isEmpty(taxDetails) ? (
            <>
              {taxDetails?.map((el: any, index) => (
                <BoxWrapper key={index}>
                  <Typography variant="body1">
                    {el?.taxType === 'service' ? 'Service Tax' : el?.taxType}
                  </Typography>
                  <Typography variant="body1">+{el?.taxValue}%</Typography>
                </BoxWrapper>
              ))}
            </>
          ) : null}
          <BoxWrapper>
            <Typography variant="body1">{t('Sub Total')}</Typography>
            <Typography variant="body1">${totalPrice?.toFixed(2)}</Typography>
          </BoxWrapper>
          <BoxWrapper>
            <Typography variant="body1">{t('Discount')}</Typography>
            <Typography variant="body1">${totalDiscount}</Typography>
          </BoxWrapper>
          <Divider />
          <BoxWrapper pt={2}>
            <Typography variant="h4">{t('Total')}</Typography>
            <Typography variant="h4" color={'#FF4842'}>
              {' '}
              ${subTotalPrice?.toFixed(2)}
            </Typography>
          </BoxWrapper>
          <Box textAlign={'end'}>
            <Typography gutterBottom>
              {t('(VAT included if applicable)')}
            </Typography>
          </Box>
          {isReadyForPayment && (
            <DiscountWrapper
              sx={{ bgcolor: 'background.paper' }}
              mt={1}
              border={1}
              borderColor={'#919EAB52'}
            >
              <Typography variant="body2">Discount codes / Gifts</Typography>
              <Button variant="text" sx={{ textTransform: 'none' }}>
                Apply
              </Button>
            </DiscountWrapper>
          )}
        </Box>
      </Card>
      {isReadyForPayment ? (
        <Button
          variant="contained"
          sx={{ width: '100%' }}
          onClick={() => {
            if (paymentType === 'paymongo') {
              createCheckoutSession()
            } else if (paymentType === 'cod') {
              onPressCasePay()
            } else {
              onPressCardPay()
            }
          }
          }
        >
          {t('Complete Order')}
        </Button>
      ) : (
        <Button
          variant="contained"
          sx={{ width: '100%' }}
          onClick={() => {
            setIsReadyForPayment(true);
          }}
        >
          {t('Check Out')}
        </Button>
      )}

      {isModalVisible && (
        <Modal
          open={isModalVisible}
          onClose={() => {
            handleClose();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Button
              variant="text"
              size="small"
              sx={{
                mx: 1
              }}
              style={{ float: 'right', padding: '15px', color: 'red' }}
              onClick={handleClose}
            >
              Close
            </Button>
            <iframe
              id="checkoutFrame"
              title="Checkout Frame"
              width="100%"
              height="90%"
              style={{ padding: '0px 20px 20px 20px', border: 'none' }}
              src={checkoutUrl}
            ></iframe>
          </Box>
        </Modal>
      )}
      {isAlertVisiable && (
        <Modal
          open={isAlertVisiable}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={alertStyle}>
            <Alert
              severity="success"
              style={{ alignItems: 'center', padding: '30px' }}
              action={
                <Button color="inherit" size="small" onClick={handleOk}>
                  {t('OK')}
                </Button>
              }
            >
              <AlertTitle>{t('Success')}</AlertTitle>
              {t('Thanks for order.')}
            </Alert>
          </Box>
        </Modal>
      )}
      {isErrorModalVisiable && (
        <Modal
          open={isErrorModalVisiable}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={alertStyle}>
            <Alert
              severity="error"
              style={{ alignItems: 'center', padding: '30px' }}
              action={
                <Button color="inherit" size="small" onClick={onPressErrorModal}>
                  {t('OK')}
                </Button>
              }
            >
              <AlertTitle>{t('Error')}</AlertTitle>
              {t(paymentError)}
            </Alert>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default OrderSummary;