import { useState, useEffect, useCallback } from 'react';

// import PageHeader from './PageHeader';
import PageHeader from 'src/components/page-header';
import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';

import { Alert, Box, Grid, LinearProgress, Skeleton } from '@mui/material';
import useRefMounted from 'src/hooks/useRefMounted';

import Results from './Results';
import {  getAllAddOnItems,parseToApiErrorMessage } from '../../../../services/apiService';
import { getAddOnItemListView } from '../../../../services/dataViewService';
import { AddOnItemListItemViewModel } from '../../../../services/dataViewService/data-view-models';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

function ManageAddOnItems() {
    const { enqueueSnackbar } = useSnackbar();
    const { t }: { t: any } = useTranslation();
    const isMountedRef = useRefMounted();

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [hasDataLoadError, setHasDataLoadError] = useState(false);

    const [items, setItems] = useState<AddOnItemListItemViewModel[]>([]);

    const getCategories = useCallback(async () => {
        try {
            setIsDataLoading(true);
            setHasDataLoadError(false);

            const response = await getAllAddOnItems();
            
            if (isMountedRef.current) {
                setItems(response.data);
            }
        } catch (err) {
            setHasDataLoadError(true);
            const msg = parseToApiErrorMessage(err, `Failed to load add-on item data`);
            enqueueSnackbar(t(msg), { variant: 'error' });
            console.error(err);
        }
        setIsDataLoading(false);
    }, [isMountedRef]);

    useEffect(() => {
        getCategories();
    }, [getCategories]);

    const onDeleteSuccess =async () => {
        await getCategories();
    }

 
    return (
        <>
            <PageTitleWrapper>
            <PageHeader type="list"  title="AddOn Items" subtitle="Manage all addon Items" route={'/'+location.pathname.split('/')[1]+"/inventory/add-on-items/add"} buttonTitle="Create AddOn Item"></PageHeader>
                {/* <PageHeader /> */}
            </PageTitleWrapper>

            <Grid
                sx={{
                    px: 4
                }}
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={4}
            >
                <Grid item xs={12}>
                    {!isDataLoading && hasDataLoadError && <Alert severity="error">Failed to load data</Alert>}
                    {isDataLoading && <LinearProgress />}
                    <Results items={items} onDeleteSuccess={onDeleteSuccess} />
                </Grid>
            </Grid>
            <Footer />
        </>
    );
}

export default ManageAddOnItems;
