import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, CardMedia, Grid, Typography, styled } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import AddToOrderModal from './AddToOrderModal';
import { searchData } from 'src/utils/search';
import moment from 'moment';

const ButtonWrapper = styled('div')(() => `
cursor: pointe
`)

const MenuItemCenterView: React.FC = () => {
    const dispatch = useDispatch();
    const { priceMenuDetails, activeCat, searchText, discountData } = useSelector((state: any) => state.priceMenu);
    const [selectData, setSelectData] = useState<any>([]);
    const [selectedItem, setSelectedItem] = useState<string>('');
    const [selectItem, setSelectItem] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        let data = priceMenuDetails?.priceMenuList?.find(
            (item) => item?.title === activeCat,
        );

        if (data) {
            let cat = data?.categories?.find(
              (item: any) => item?.name === selectedItem,
            );
            if (cat) {
              const search = searchText
                ? searchData(cat?.items, searchText, ['name'])
                : cat?.items;
              let updated = data?.categories?.map((item: any) => {
                if (item?.name === selectedItem) {
                  return {...item, items: search};
                } else {
                  return item;
                }
              });
              if (!searchText) {
                setSelectData(data);
              } else {
                setSelectData({...selectData, categories: updated});
              }
            }
          }
       
    }, [searchText]);

    useEffect(() => {
        let data = priceMenuDetails?.priceMenuList?.find(
          (item: any) => item?.title === activeCat,
        );
        const itemwithDiscountArr: any = addDiscountIntoItem(data);
        setSelectData(itemwithDiscountArr);
        setSelectedItem(
          data?.categories?.length > 0 ? data?.categories[0]?.name : '',
        );
      }, [activeCat]);
    
      const addDiscountIntoItem = (itemArr: any) => {
        const modifiedData: any = itemArr;
        const currentDate = moment();
        modifiedData?.categories?.forEach((category: any) => {
          category?.items?.forEach((item: any) => {
            const discount = discountData?.data?.find(
              (el: any) =>
                el?.itemId === item?.id &&
                currentDate.isBetween(
                  moment(discount?.validFrom),
                  moment(discount?.validTo),
                ),
            );
    
            if (discount) {
              item.discount = {
                discountType: discount.discountType,
                discountUnit: discount.discountUnit,
                discountValue: discount.discountValue,
                maximumDiscountValue: discount.maximumDiscountValue,
              };
            }
          });
        });
    
        return modifiedData;
      };

    const handleItemPress = (item: any) => {
        setSelectedItem(item?.name);
    };

    const toggleModal = (data: any) => {
        setIsModalVisible(true);
        setSelectItem(data);
    };

    const ProductCard = ({ item, index }) => (
        <Card sx={{ height: 274 }} key={index} onClick={() => toggleModal(item)}>
            <CardMedia
                component="img"
                height="140"
                image={item.featureImage}
                alt={item.name}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div" textAlign={'center'}>
                    {item.name}
                </Typography>
                <Box display={'flex'} justifyContent={'space-around'}>
                    <Typography variant="body2" color="#00AB55">
                        {item?.prices[0]?.priceAfterDiscount && 'Cash\n'}
                        {item?.prices?.length > 0 &&
                            item?.prices[0]?.priceAfterDiscount && (
                                <Typography variant="body2" color="#00AB55">
                                    ${item?.prices[0]?.priceAfterDiscount}
                                </Typography>
                            )}
                    </Typography>
                    <Typography variant="body2" color="#00AB55">
                        {item?.prices[0]?.price && 'Card\n'}
                        {item?.prices?.length > 0 &&
                            item?.prices[0]?.price && (
                                <Typography variant="body2" color="#00AB55">
                                    ${item?.prices[0]?.price}
                                </Typography>
                            )}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    )

    return (
        <>
            <Grid item xs={12}>
                <Box py={1.2} display={'flex'} alignItems={'center'} justifyContent={'space-around'}>
                    {selectData?.categories?.map((item: any, index: number) => (
                        <ButtonWrapper onClick={() => handleItemPress(item)
                        }>
                            <Box px={2} py={1} border={1} borderRadius={3} borderColor={selectedItem === item?.name ? '#00AB55' : '#E4E4E4'} color={selectedItem === item?.name ? '#00AB55' : '#19191C'} fontWeight={selectedItem === item?.name ? '600' : '400'} sx={{
                                backgroundColor: selectedItem === item?.name ? '#E6FFF394' : '#FFFFFF'
                            }} >
                                {item?.name}
                            </Box>
                        </ButtonWrapper>
                    ))}
                </Box>
                <Grid container spacing={2}>
                    {selectData?.categories?.map(
                        (item: any) =>
                            item?.name === selectedItem &&
                            item?.items?.map((data: any, index: number) => (
                                <Grid item xs={12} sm={6} md={3} key={item.title}>
                                    <ProductCard item={data} index={index} />
                                </Grid>
                            )),
                    )}
                </Grid>
            </Grid>
            {isModalVisible && (
                <AddToOrderModal
                    isVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    data={selectItem}
                />
            )}

        </>
    );
}

export default MenuItemCenterView;
