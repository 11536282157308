import { combineReducers } from '@reduxjs/toolkit';
import appReducer from './slices/app';
import priceMenuReducer from './slices/priceMenu';

const rootReducer = combineReducers({
    app: appReducer,
    priceMenu: priceMenuReducer,
});

export default rootReducer;
