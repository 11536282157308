import { useState, ChangeEvent } from 'react';
import {
  Box,
  Typography,
  Avatar,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  lighten,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingTabs, { SettingTab } from './setting-tabs';

const RootWrapper = styled(Box)(
  ({ theme }) => `
        padding: ${theme.spacing(2.5)};
  `
);

const ListItemWrapper = styled(ListItemButton)(
  ({ theme }) => `
        &.MuiButtonBase-root {
            margin: ${theme.spacing(1)} 0;
        }
  `
);

function SidebarContent(props: {
  selectedTab: SettingTab;
  onChange: (tab: SettingTab) => void;
}) {
  const { t }: { t: any } = useTranslation();
  const [activeTab, setActiveTab] = useState<SettingTab>(props.selectedTab);

  const [state, setState] = useState({
    invisible: true
  });

  const onChange = (e: SettingTab) => {
    setActiveTab(e);
    props.onChange(e);
  };

  return (
    <RootWrapper>
      <Typography
        sx={{
          mb: 1,
          mt: 2
        }}
        variant="h3"
      >
        {t('Settings')}
      </Typography>

      <Box mt={2}>
        <List disablePadding component="div">
          {SettingTabs.map((tab) => (
            <ListItemWrapper
              key={tab.name}
              onClick={() => onChange(tab)}
              selected={activeTab?.name == tab.name}
            >
              <ListItemAvatar>
                <Avatar>{tab.icon}</Avatar>
              </ListItemAvatar>
              <ListItemText
                sx={{
                  mr: 1
                }}
                primaryTypographyProps={{
                  color: 'textPrimary',
                  variant: 'h5',
                  noWrap: true
                }}
                secondaryTypographyProps={{
                  color: 'textSecondary',
                  noWrap: true
                }}
                primary={tab.text}
              />
            </ListItemWrapper>
          ))}
        </List>
      </Box>
    </RootWrapper>
  );
}

export default SidebarContent;
