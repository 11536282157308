import React, { useState, useEffect, useCallback } from 'react';
import { Alert, Grid, LinearProgress } from '@mui/material';
import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { getCustomerList, parseToApiErrorMessage } from 'src/services/apiService';
import Results from './Results';
import { Customer } from 'src/services/apiService/response-models';
import PageHeader from 'src/components/page-header';
// import PageHeader from './PageHeader';
import CreateCustomerDialog from '../create-device-dialog';
import useRefMounted from 'src/hooks/useRefMounted';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

function DeviceList() {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const isMountedRef = useRefMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { t }: { t: any } = useTranslation();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [hasDataLoadError, setHasDataLoadError] = useState(false)
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const fetchCustomerData = useCallback(async () => {
    try {
      setIsDataLoading(true);
      setHasDataLoadError(false);

      const response = await getCustomerList();

      if (isMountedRef.current) {
        setCustomers(response.data);
      }
    } catch (err) {
      setHasDataLoadError(true);
      const msg = parseToApiErrorMessage(err, 'Failed to load customer data');
      enqueueSnackbar(t(msg), { variant: 'error' });
      console.error(err);
    }
    setIsDataLoading(false);
  }, [isMountedRef]);

  useEffect(() => {
    setIsDataLoading(true);
    fetchCustomerData();
    
  }, [fetchCustomerData]);

  const onDeleteSuccess =async () => {
    await fetchCustomerData();
}
  return (
    <>
      <PageTitleWrapper>
      <PageHeader type="list"  title="Device Management" subtitle="All aspects related to the app Devices can be managed from this page" 
      route={'/'+location.pathname.split('/')[1]+"/manage/devices/new"} 
      buttonTitle="Add Device"></PageHeader>
       
      </PageTitleWrapper>

      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
        {!isDataLoading && hasDataLoadError && <Alert severity="error">Failed to load data</Alert>}
                    {isDataLoading && <LinearProgress />}
          <Results customers={customers} onDeleteSuccess={onDeleteSuccess}/>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default DeviceList;
