import {
    Box,
    CardHeader,
    Card,
    List,
    ListItemAvatar,
    ListItem,
    ListItemText,
    Typography,
    Divider,
    useTheme,
    styled,
    Tooltip,
    IconButton,
    alpha,
    Switch,
    Dialog,
    Button,
    CircularProgress,
    DialogContent,
    FormHelperText,
    FilledInput,
    FormControl,
    Grid,
    FormGroup,
    FormControlLabel,
    InputAdornment,
    InputLabel,
    DialogTitle,
    useMediaQuery
  } from '@mui/material';
  
  import { useTranslation } from 'react-i18next';
  import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
  import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
  import PriceChangeTwoToneIcon from '@mui/icons-material/PriceChangeTwoTone';
import { Form, Formik, useField } from 'formik';
import { ItemPrice, ItemVariant } from 'src/services/apiService/response-models';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import React from 'react';
import validateItemPrice from './utils/validateItemPrice';
import { useSelector } from 'src/store';
import useAuth from 'src/hooks/useAuth';
  
  const ListItemWrapper = styled(ListItem)(
    ({ theme }) => `
        position: relative;
        transition: ${theme.transitions.create(['background'])};
  
        .MuiActionButtons {
              background: ${alpha(theme.colors.alpha.white[100], 0.95)};
              border-radius: ${theme.general.borderRadius};
              opacity: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              visibility: hidden;
              top: 50%;
              margin-top: -${theme.spacing(3.5)};
              position: absolute;
              right: ${theme.spacing(1.5)};
              padding: ${theme.spacing(0.5, 1)};
              transition: ${theme.transitions.create(['visibility', 'opacity'])};
  
              .MuiIconButton-root {
                  border-radius: 100px;
                  width: ${theme.spacing(5)};
                  height: ${theme.spacing(5)};
                  margin: ${theme.spacing(0.5)};
              }
        }
  
        &:hover {
            background: ${alpha(theme.colors.secondary.main, 0.15)};
  
            .MuiActionButtons {
              visibility: visible;
              opacity: 1;
        }   
        }
    `
  );
  
  function ItemPriceCard(props:{itemName:string,prices:ItemPrice[], itemVariants:ItemVariant[]}) {
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();
    const [fieldPrices, metaPrices, helpersPrices] = useField('prices');
    const [fieldVariants, metaVariants, helpersVariants] = useField('variants');
    const {   merchant  } = useAuth();
    const itemPrices:ItemPrice[]=fieldPrices.value || [];
    const itemVariants:ItemVariant[]=fieldVariants.value || [];
    const [changePriceInput, setChangePriceInput] = useState<ChangePriceInput>();

    console.log('merchant',merchant);
    const onAvailableChange=(e:ItemVariant,checked:boolean)=>{
       const index = itemVariants.findIndex(o=>o.id==e.id);
       if(index!=-1){
        const cloneArr=[...itemVariants];
          const match=cloneArr[index];
          match.isAvailable=checked;
          helpersVariants.setValue([...cloneArr]);
    }
  }

const onPriceChangeClick = (e:ChangePriceInput) =>{
    setChangePriceInput(e);
}
const onPriceChangeSave=(e:ItemVariant,prices:ItemPrice[])=>{
  if(e){
    const index = itemVariants.findIndex(o=>o.id==e.id);
       if(index!=-1){
        const cloneArr=[...itemVariants];
          const match=cloneArr[index];
          match.prices=[...prices];
          helpersVariants.setValue([...cloneArr]);
    }
  }else{
    helpersPrices.setValue([...prices]);
  }
  setChangePriceInput(null);
}

    return (<>
      <Card variant="outlined">
        <CardHeader
          sx={{
            p: 2,
            background: `${theme.colors.primary.lighter}`
          }}
          disableTypography
          title={
            <Typography
              variant="h4"
              sx={{
                fontSize: `${theme.typography.pxToRem(16)}`
              }}
            >
              {t('Item Pricing')}
            </Typography>
          }
        />
        <Divider />
        {props.itemVariants.length==0 && <List disablePadding>
         
         
             <ListItemWrapper
             sx={{
               alignItems: 'flex-start',
               p: 3.15
             }}
           >
             <Box className="MuiActionButtons">
               <Tooltip
                 arrow
                 placement="top"
                 title={t('Change price')}
               >
                 <IconButton color="primary" onClick={()=>onPriceChangeClick({
                    itemTitle:props.itemName,
                    prices:props.prices
                })}>
                   <PriceChangeTwoToneIcon fontSize="small" />
                 </IconButton>
               </Tooltip>
             </Box>
            
             <ListItemText
               primary={
                 <Typography lineHeight={1} gutterBottom variant="h4">
                   {t(props.itemName)}
                 </Typography>
               }
               secondary={
                 <>
                   <Typography
                     sx={{
                       display: 'flex',
                       alignItems: 'center'
                     }}
                     noWrap
                     variant="subtitle2"
                   >
                     <Box pl={0.5} component="span">
                      
                     </Box>
                   </Typography>
                 </>
               }
             />
              <ListItemText
               primary={
                <Box></Box>
               }
             />

             <PriceColumn prices={props.prices || []}/>
           </ListItemWrapper>
           <Divider />
           
        
         </List>}
       {props.itemVariants.length>0 && <List disablePadding>
         
        {props.itemVariants.map(o=>
            <React.Fragment key={o.id}>
            <ListItemWrapper key={o.name}
            sx={{
              alignItems: 'flex-start',
              p: 3.15
            }}
          >
            <Box className="MuiActionButtons">
              <Tooltip
                arrow
                placement="top"
                title={t('Change price')}
              >
                <IconButton color="primary" onClick={()=>onPriceChangeClick({
                    itemTitle:props.itemName + ' ' + o.name + '',
                    prices:o.prices,
                    itemVariant:o
                })}>
                  <PriceChangeTwoToneIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
           
            <ListItemText
              primary={
                <Typography lineHeight={1} gutterBottom variant="h4">
                  {t(props.itemName + ' ' + o.name)}
                </Typography>
              }
              secondary={
                <>
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    noWrap
                    variant="subtitle2"
                  >
                    <Box pl={0.5} component="span">
                      {t(o.type)}
                    </Box>
                  </Typography>
                </>
              }
            />
             <ListItemText
              primary={
                <Switch checked={o.isAvailable==false?false:true} onChange={(e)=>onAvailableChange(o,e.target.checked)} />
              }
              secondary={
                <>
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    noWrap
                    variant="subtitle2"
                  >
                    <Box pl={0.5} component="span">
                      {t(o.isAvailable==false?'Not Availabe':'Availabe')}
                    </Box>
                  </Typography>
                </>
              }
            />
             <PriceColumn prices={o.prices  || []}/>
          </ListItemWrapper>
          <Divider />
            </React.Fragment>
            )}  
       
        </List>}
        {/* {Boolean(metaPriceVal?.error) && <FormHelperText error={true}>{metaPriceVal?.error}&nbsp;</FormHelperText>} */}
      </Card>
        {changePriceInput && <ChangePriceModalPopUp dualPrice={merchant.dualPrice===true} variantName={changePriceInput.itemTitle} itemVariant={changePriceInput.itemVariant}  prices={changePriceInput.prices || []}   onClose={() => setChangePriceInput(null)} onSave={onPriceChangeSave} />}
      </>
    );
  }

  function PriceColumn(props:{prices:ItemPrice[]}){
    const prices =props.prices || [];
   
    return (<Box
        sx={{
         textAlign: 'right'
       }}
       alignSelf="center"
     >
      {prices.length==0 && <><Typography variant="h5">--</Typography>
       <Typography variant="subtitle2">{'No price'}</Typography>
       </>}
      {prices.length!==0 && prices.map(o=><React.Fragment   key={o.priceType}> <Typography variant="h5">${o.price}</Typography>
       <Typography variant="subtitle2">{o.priceType}</Typography>
       </React.Fragment>)}
     </Box>);
  }

  interface ChangePriceInput{
    itemTitle:string;
    prices:ItemPrice[];
    itemVariant?:ItemVariant;
  }
  

  function ChangePriceModalPopUp(props: {dualPrice:boolean, variantName:string, itemVariant?:ItemVariant, prices:ItemPrice[],onClose: () => void, onSave: (e:ItemVariant, prices: ItemPrice[]) => void}) {
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    

    return (<Dialog
        fullWidth
        maxWidth="sm"
        open={true}
    >
        <DialogTitle
            sx={{
                p: 3
            }}
        >
            <Typography variant="h4" gutterBottom>
                {t('Change Price')}
            </Typography>
            <Typography variant="subtitle2">
                {t(props.variantName)}
            </Typography>
        </DialogTitle>
        <Formik
            initialValues={{
                isDualPrice: props.dualPrice,
                allPrice: props.prices.find(o=>o.priceType=='all')?.price || '',
                cardPrice: props.prices.find(o=>o.priceType=='card')?.price || '',
                cashPrice: props.prices.find(o=>o.priceType=='cash')?.price || '',
            }}
            validationSchema={Yup.object().shape({
                allPrice: Yup.number().when('isDualPrice',{
                    is:false,
                    then:Yup.number().required('Price is required').
                            min(1, t('Price must be non-zero value')).max(99999,'Invalid max price')
                }),
                cardPrice: Yup.number().when('isDualPrice',{
                    is:true,
                    then:Yup.number().required('Price is required').
                            min(1, t('Price must be non-zero value')).max(99999,'Invalid max price')
                }),
                cashPrice: Yup.number().when('isDualPrice',{
                    is:true,
                    then:Yup.number().required('Price is required').
                            min(1, t('Price must be non-zero value')).max(99999,'Invalid max price')
                })
            })}
            onSubmit={async (
                _values,
                { resetForm, setErrors, setStatus, setSubmitting }
            ) => {
                try {
                    if(_values.isDualPrice){
                        props.onSave(props.itemVariant, [{
                            price:+Number(_values.cashPrice).toFixed(2),
                            priceType:'cash'
                        },
                        {
                            price:+Number(_values.cardPrice).toFixed(2),
                            priceType:'card'
                        }
                    ]);
                    }else{
                        props.onSave(props.itemVariant, [{
                            price:+Number(_values.allPrice).toFixed(2),
                            priceType:'all'
                        }
                    ]);
                    }
                    
                //    resetForm();
                //    setStatus({ success: true });
                //    setSubmitting(false);
                   
                } catch (err) {
                    console.error(err);
                    setStatus({ success: false });
                   // setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                setFieldValue,
                handleSubmit,
                isSubmitting,
                touched,
                values
            }) => (
                <Form >
                    <DialogContent
                        dividers
                        sx={{
                            p: 3
                        }}
                    >
                        <Grid container spacing={3}>
                            {/* <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel control={<Switch checked={values.isDualPrice} onChange={(e)=>setFieldValue('isDualPrice',e.target.checked)} />} label="Dual price" />
                                </FormGroup>
                            </Grid> */}
                           {!values.isDualPrice && <> <Grid item xs={6}>
                                 <FormControl fullWidth variant="filled" error={Boolean(errors.allPrice?.length)}>
                                            <InputLabel htmlFor="filled-all-price">Price</InputLabel>
                                            <FilledInput
                                                id="filled-adornment-amount"
                                                    value={values.allPrice}
                                                    
                                                    type="number"
                                                    onChange={(o) => setFieldValue('allPrice',o.target.value)}
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                />
                                                <FormHelperText error={Boolean(errors.allPrice?.length)}>{errors.allPrice}&nbsp;</FormHelperText>
                                        </FormControl>
                            </Grid>
                            <Grid item xs={6}>  </Grid></>}
                            {values.isDualPrice &&<><Grid item xs={6}>
                                 <FormControl fullWidth variant="filled" error={Boolean(errors.cashPrice?.length)}>
                                            <InputLabel htmlFor="filled-all-price">Cash Price</InputLabel>
                                            <FilledInput
                                                id="filled-adornment-amount"
                                                    value={values.cashPrice}
                                                    
                                                    type="number"
                                                    onChange={(o) => setFieldValue('cashPrice',o.target.value)}
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                />
                                                <FormHelperText error={Boolean(errors.cashPrice?.length)}>{errors.cashPrice}&nbsp;</FormHelperText>
                                        </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                 <FormControl fullWidth variant="filled" error={Boolean(errors.cardPrice?.length)}>
                                            <InputLabel htmlFor="filled-all-price">Card Price</InputLabel>
                                            <FilledInput
                                                id="filled-adornment-amount"
                                                    value={values.cardPrice}
                                                    
                                                    type="number"
                                                    onChange={(o) => setFieldValue('cardPrice', o.target.value)}
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                />
                                                <FormHelperText error={Boolean(errors.cardPrice?.length)}>{errors.cardPrice}&nbsp;</FormHelperText>
                                        </FormControl>
                            </Grid>
                            </> }
                        </Grid>
                    </DialogContent>
                    <Box
                        sx={{
                            display: { xs: 'block', sm: 'flex' },
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: 3
                        }}
                    >
                        <Box>

                        </Box>
                        <Box>
                            <Button
                                fullWidth={mobile}
                                sx={{
                                    mr: { xs: 0, sm: 2 },
                                    my: { xs: 2, sm: 0 }
                                }}
                                color="secondary"
                                variant="outlined"
                                onClick={props.onClose}
                            >
                                {t('Cancel')}
                            </Button>
                            <Button
                                fullWidth={mobile}
                                type="submit"
                                startIcon={
                                    isSubmitting ? <CircularProgress size="1rem" /> : null
                                }
                                disabled={isSubmitting}
                                variant="contained"
                                size="large"
                            >
                                {t('Confirm')}
                            </Button>
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    </Dialog>)
}
  
  export default ItemPriceCard;
  