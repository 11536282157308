import { ChangeEvent, forwardRef, ReactElement, Ref, useCallback, useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Radio,
    FormControlLabel,
    Typography,
    Card,
    CardHeader,
    Divider,
    lighten,
    CardActionArea,
    CardContent,
    Tooltip,
    IconButton,
    Zoom,
    Avatar,
    styled,
    Dialog,
    DialogTitle,
    TableContainer,
    Button,
    TableFooter,
    TableCell,
    TableRow,
    TextField,
    useMediaQuery,
    DialogContent,
    Autocomplete,
    useTheme,
    TableBody,
    CircularProgress,
    Table,
    TableHead,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Paper,
    Chip,
    Skeleton,
    FormHelperText,
    SelectChangeEvent,
    Stack,
    Slide,
    createFilterOptions
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { Field, Form, Formik, useField } from 'formik';
import { TextField as FmTextFiled, Switch } from 'formik-mui';
import wait from 'src/utils/wait';
import numeral from 'numeral';
import { DatePicker } from '@mui/x-date-pickers';
import PostAddTwoToneIcon from '@mui/icons-material/PostAddTwoTone';
import * as Yup from 'yup';
import React from 'react';
import useRefMounted from 'src/hooks/useRefMounted';
import { getItemVariants, getAllAddOnItems } from 'src/services/apiService';
import { ItemVariant, ItemVariantMain } from 'src/services/apiService/response-models';
import _, { iteratee }  from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import DeleteConfirm from 'src/components/delete-confirm';
import EditorWrapper from 'src/components/StyledWrappers/EditorWrapper';
import ReactQuill from 'react-quill';

const AvatarAddWrapper = styled(Avatar)(
    ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
`
);

const CardLogo = styled('img')(
    ({ theme }) => `
      border: 1px solid ${theme.colors.alpha.black[30]};
      border-radius: ${theme.general.borderRadius};
      padding: 0px;
      margin-right: ${theme.spacing(2)};
      background: ${theme.colors.alpha.white[100]};
width: 80px;
    height: 80px;
    border-radius: 20px;
`
);

const DialogWrapper = styled(Dialog)(
    () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const ButtonError = styled(Button)(
    ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const AvatarError = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const filter = createFilterOptions<ItemVariantMain>();

const CardAddAction = styled(Card)(
    ({ theme }) => `
        border: ${theme.colors.primary.main} dashed 1px;
        height: 100%;
        color: ${theme.colors.primary.main};
        box-shadow: none;
        transition: ${theme.transitions.create(['all'])};
        
        .MuiCardActionArea-root {
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
        }
        
        .MuiTouchRipple-root {
          opacity: .2;
        }
        
        &:hover {
          border-color: ${theme.colors.alpha.black[70]};
        }
`
);

const IconButtonError = styled(IconButton)(
    ({ theme }) => `
     background: ${theme.colors.error.lighter};
     color: ${theme.colors.error.main};
     padding: ${theme.spacing(0.5)};

     &:hover {
      background: ${lighten(theme.colors.error.lighter, 0.4)};
     }
`
);

const IconButtonEdit = styled(IconButton)(
    ({ theme }) => `
     background: ${theme.colors.primary.lighter};
     color: ${theme.colors.primary.main};
     padding: ${theme.spacing(0.5)};

     &:hover {
      background: ${lighten(theme.colors.primary.lighter, 0.4)};
     }
`
);

const CardCc = styled(Card)(
    ({ theme }) => `
     border: 1px solid ${theme.colors.alpha.black[30]};
     background: ${theme.colors.alpha.black[5]};
     box-shadow: none;
`
);

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children: ReactElement<any, any> },
    ref: Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});


function VariantCard(props:{itemName:string}) {
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));


    const [isModalOpen, setModalOpen] = useState(false);

    //parent form
    const [fieldVariants, metaVariants, helpersVariants] = useField('variants');
    const [idRequestedToDelete, setIdRequestedToDelete] = useState<string>('');
    const [itemVariants, setItemVariants] = useState<ItemVariantMain[]>([]);
    const [groupNameToEdit, setGroupNameToEdit] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);

    const loadData = async () => {
        try {
            const response = await getItemVariants();
            setItemVariants(response.data);
        } catch (ex) {
            console.error(ex);
            const msg = ex.message ? ex.message : `Failed to load variants`;
            enqueueSnackbar(t(msg), { variant: 'error' });
        }
    };
    
    useEffect(() => {
        setIsLoading(true);
        (async () => {
           await loadData();
           setIsLoading(false);
          })();

    }, []);

    useEffect(() => {
     
    }, [fieldVariants.value]);


    const onModalSubmit = (value: ItemVariantMain) => {
        const variant = _.cloneDeep(value);
        setModalOpen(false);
       
        let currentVariants = _.cloneDeep( fieldVariants.value || []) as ItemVariant[];
        currentVariants=currentVariants.filter(o=>o.type.toLowerCase()!=value.type.toLowerCase());
         
        value.names.forEach(name=>{
            currentVariants.push({
                id:value.type+'.'+name,
                name:name,
                type:value.type,
                isAvailable:true,
            });
        });
       

        helpersVariants.setValue(currentVariants);
    }


    const handleCreateAddonOpen = () => {
       
        setModalOpen(true);
    };

    
    const handleDeleteClick = (groupName: string) => {
        setIdRequestedToDelete(groupName);
    };

    const handleConfirmDelete = () => {
        let currentVariants = _.cloneDeep( fieldVariants.value || []) as ItemVariant[];
        currentVariants=currentVariants.filter(o=>o.type.toLowerCase()!=idRequestedToDelete.toLowerCase());
        helpersVariants.setValue(currentVariants);
        setIdRequestedToDelete('');
    };



    const variantMains=_.groupBy( ((fieldVariants.value || []) as ItemVariant[]),o=>o.type);
    
    const handleEditOpen = (groupName: string) => {
        setGroupNameToEdit(groupName);
        setModalOpen(true);
    };


    return (
        <>
            <Card>
                <CardHeader
                    subheader={t('(optional)')}
                    title={t('Item Variants')}
                />
                <Divider />
                <Box p={3}>
                    <Grid container spacing={3}>
                        {_.sortBy(Object.keys(variantMains)).map(keyName => <Grid key={keyName} item xs={12} sm={6}>
                            <CardCc
                                sx={{
                                    px: 2,
                                    pt: 2,
                                    pb: 1
                                }}
                            >
                                <Box display="flex" alignItems="center">
                                    <Box>
                                        <Typography variant="h3" fontWeight="normal">
                                            {keyName}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            <Typography component="span" color="text.primary">
                                            </Typography>
                                        </Typography>
                                    </Box>

                                </Box>
                                <Box pt={1}>
                                    <ChipsArray options={variantMains[keyName].map(o=>o.name)} />
                                </Box>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Tooltip arrow title={t('Edit variant details')}>
                                        <IconButtonEdit  onClick={()=>handleEditOpen(keyName)}>
                                            <EditTwoToneIcon fontSize="small" />
                                        </IconButtonEdit>
                                    </Tooltip>
                                    <Tooltip arrow title={t('Remove this variant' + keyName)}>
                                        <IconButtonError onClick={()=>handleDeleteClick(keyName)} >
                                            <DeleteTwoToneIcon fontSize="small" />
                                        </IconButtonError>
                                    </Tooltip>

                                </Box>
                            </CardCc>
                        </Grid>)}

                        <Grid item xs={12} sm={3}>
                            <Tooltip arrow title={t('Click to add item variant')}>
                                <CardAddAction onClick={handleCreateAddonOpen}>
                                    <CardActionArea
                                        sx={{
                                            px: 1
                                        }}
                                    >
                                        <CardContent>
                                            <AvatarAddWrapper>
                                                <AddTwoToneIcon fontSize="large" />
                                            </AvatarAddWrapper>
                                        </CardContent>
                                    </CardActionArea>
                                </CardAddAction>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
            {isModalOpen && <ModalPopUp itemName={props.itemName} itemVariants={itemVariants || []} variantsToEdit={variantMains[groupNameToEdit]} isOpen={isModalOpen} onClose={() => setModalOpen(false)} onSubmit={onModalSubmit} />}
          <DeleteConfirm message='Are you sure want to remove this variant?' 
            okText="Yes" cancelText="No"
            onClose={()=>setIdRequestedToDelete('')} open={Boolean(idRequestedToDelete.length)}
           onDeleteConfirm={()=> handleConfirmDelete()}  />                          
        </>
    );
}

function ModalPopUp(props: {itemName:string, itemVariants:ItemVariantMain[],variantsToEdit?:ItemVariant[], isOpen: boolean, onClose: () => void, onSubmit: (item: ItemVariantMain) => void}) {
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { enqueueSnackbar } = useSnackbar();

    const isEdit=props.variantsToEdit?.length>0;
    const [variantType, setVariantName] = useState<string>(isEdit?props.variantsToEdit[0].type:'');
    const [names, setNames] = useState<string[]>([]);
    const [description, setDescription] = useState<string>();
    const [updatedOptions, setUpdatedOptions] = useState<string[]>(isEdit?props.variantsToEdit.map(o=>o.name):[]);

    useEffect(() => {
       const match = props.itemVariants.find(o=>o.type.toLowerCase()==variantType?.trim().toLowerCase());
       setNames(match?match.names:[]);
      // setUpdatedOptions(match?match.options:[]);
    }, [variantType]);

    const variantTypes=props.itemVariants.map(o=>o.type);

    return (<Dialog
        fullWidth
        maxWidth="md"
        open={true}
    >
        <DialogTitle
            sx={{
                p: 3
            }}
        >
            <Typography variant="h4" gutterBottom>
                {t((isEdit?'Edit':'Add') +' variants')}
            </Typography>
            <Typography variant="subtitle2">
                {t(props.itemName || 'Item')}
            </Typography>
        </DialogTitle>
        <Formik
            initialValues={{
                variantType: variantType,
                names:    names,
                description : description,
            }}
            validationSchema={Yup.object().shape({
                variantType: Yup.string() .required(t('Variant typ[e] is required')),
                names: Yup.array()
                    .min(1, "Choose at least 1 name")
                    .required(t('Name is required')),
                    // description: Yup.string() .required(t('Variant description is required')),
            })}
            onSubmit={async (
                _values,
                { resetForm, setErrors, setStatus, setSubmitting }
            ) => {
                try {
                   resetForm();
                   setStatus({ success: true });
                   setSubmitting(false);
                   props.onSubmit({
                    id:'',
                    type:_values.variantType,
                    names:_values.names,
                    description:_values.description
                   });
                } catch (err) {
                    console.error(err);
                    setStatus({ success: false });
                   // setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                setFieldValue,
                handleSubmit,
                isSubmitting,
                touched,
                values
            }) => (
                <Form >
                    <DialogContent
                        dividers
                        sx={{
                            p: 3
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                 <Autocomplete
                                 disablePortal
                                 options={variantTypes}
                                 filterSelectedOptions
                                 value={variantType}
                                 onChange={(event, newValue) => {
                                       if(newValue){
                                        setVariantName(newValue.trim());
                                        setFieldValue('variantType',newValue.trim());
                                       }
                                   }}
                                fullWidth
                                 freeSolo
                                 autoSelect
                                 selectOnFocus
                                 clearOnBlur
                                 handleHomeEndKeys
                                 renderInput={(params) => <TextField {...params} name="variant" 
                                 label="Variant type" 
                                 helperText={errors.variantType?errors.variantType: ""}
                                 error={errors.variantType?true:false}
                                 />}
                             />
                            </Grid>
                            <Grid item xs={12}>
                                <Box pb={1}>
                                    <b>{t('Variant names')}:</b>
                                </Box>
                              
                                <Autocomplete
                                    fullWidth
                                    blurOnSelect={true}
                                    filterSelectedOptions
                                    multiple
                                    freeSolo
                                    autoSelect
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    value={updatedOptions}
                                    options={names}
                                    onChange={(event: any, newValues) => {
                                        if (newValues) {
                                            setUpdatedOptions(newValues);
                                            setFieldValue('names',newValues);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Variant name..."
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                            }}
                                            helperText={errors.names?errors.names: ""}
                                            error={errors.names?true:false}
                                        />
                                    )}
                                />
                             

                            </Grid>
                                        <Grid item xs={12}>
                                            <EditorWrapper>
                                                <ReactQuill
                                                    placeholder="Variant description..."
                                                    id="description"
                                                    value={values.description}
                                                    onChange={(o) => setFieldValue('description', o)}
                                                />
                                            </EditorWrapper>
                                        </Grid>

                        </Grid>
                    </DialogContent>
                    <Box
                        sx={{
                            display: { xs: 'block', sm: 'flex' },
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: 3
                        }}
                    >
                        <Box>

                        </Box>
                        <Box>
                            <Button
                                fullWidth={mobile}
                                sx={{
                                    mr: { xs: 0, sm: 2 },
                                    my: { xs: 2, sm: 0 }
                                }}
                                color="secondary"
                                variant="outlined"
                                onClick={props.onClose}
                            >
                                {t('Cancel')}
                            </Button>
                            <Button
                                fullWidth={mobile}
                                type="submit"
                                startIcon={
                                    isSubmitting ? <CircularProgress size="1rem" /> : null
                                }
                                disabled={isSubmitting}
                                variant="contained"
                                size="large"
                            >
                                {t('Confirm')}
                            </Button>
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    </Dialog>)
}

interface ChipData {
    key: string;
    label: string;
    price: number
}

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

function ChipsArray(props: { options: string[] }) {

    const [chipData, setChipData] = React.useState<ChipData[]>([]);

    useEffect(() => {
        setChipData(props.options.map(o => {
            return {
                key: o,
                 label:o,
                  price:0
            }
        }));
    }, [props.options]);


    const handleDelete = (chipToDelete: ChipData) => () => {
        setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };

    return (
        <Paper elevation={0}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 0,
            }}
            component="ul"
        >
            {chipData.map((data) => {
                let icon;



                return (
                    <ListItem key={data.key}>
                        <Chip
                            label={data.label}
                        />
                    </ListItem>
                );
            })}
        </Paper>
    );
}
export default VariantCard;
