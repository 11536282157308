import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// import PageHeader from './PageHeader';
import { Box, Drawer, Grid, useTheme, IconButton, styled, LinearProgress, Card, Skeleton, Stack } from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import Sidebar from './Sidebar';
import PageHeader from 'src/components/page-header';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import useRefMounted from 'src/hooks/useRefMounted';
import CategoryEditor from '../editor';
import { Category } from 'src/services/apiService/response-models';
import { getCategoryById } from 'src/services/apiService';

const DrawerWrapper = styled(Drawer)(
    ({ theme }) => `
    width: 400px;
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: 400px;
        height: calc(100% - ${theme.header.height});
        position: absolute;
        top: ${theme.header.height};
        right: 0;
        z-index: 3;
        background: ${theme.colors.alpha.white[10]};
    }
`
);

const DrawerWrapperMobile = styled(Drawer)(
    ({ theme }) => `
    width: 360px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 360px;
        z-index: 3;
        background: ${theme.colors.alpha.white[30]};
  }
`
);

const MainContentWrapper = styled(Box)(
    () => `
  flex-grow: 1;
`
);

const IconButtonToggle = styled(IconButton)(
    ({ theme }) => `
  width: ${theme.spacing(6)};
  height: ${theme.spacing(6)};
`
);

function FoodCategoryCreate() {
    const theme = useTheme();
    const navigate = useNavigate();
    const { id } = useParams();

    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(false);
    const [isEdit,setIsEdit] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);
    //const [categories, setCategories] = useState<Models.Category[]>([]);

    
    useEffect(() => {
        if (id && id.length > 0) {
            setIsLoading(true);
            getCategoryById(id).then(() => {
                setIsLoading(false);
            });
        }
    }, []);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
   
    const onSave = async (e: Category) => {
        navigate('/'+location.pathname.split('/')[1]+ "/inventory/category/list", { replace: true });
    }

    const onCancel = async () => {
        navigate('/'+location.pathname.split('/')[1]+ "/inventory/category/list", { replace: true });
    }


    const sidebarContent = (
        <Scrollbar>
            <Sidebar 
             categoryId={id}/>
        </Scrollbar>
    );

    return (
        <>
            <Box mb={3} display="flex">
                <MainContentWrapper>
                    <Grid
                        sx={{
                            px: 4
                        }}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={4}
                    >
                        <Grid item xs={12}>
                            <Box
                                mt={3}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                {/* <PageHeader isEdit={isEdit} /> */}
                                <PageHeader type="create"  title="Item Category " subtitle="Use this page to manage Item category." route={'/'+location.pathname.split('/')[1]+"/inventory/category/list"} buttonTitle="Go Back To Item Category"></PageHeader>
                                <Box
                                    component="span"
                                    sx={{
                                        display: { lg: 'none', xs: 'inline-block' }
                                    }}
                                >
                                    <IconButtonToggle
                                        sx={{
                                            ml: 2
                                        }}
                                        color="primary"
                                        onClick={handleDrawerToggle}
                                        size="small"
                                    >
                                        <MenuTwoToneIcon />
                                    </IconButtonToggle>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <CategoryEditor id={id} onCancel={onCancel} onSave={onSave}  />
                        </Grid>
                      

                        <Grid item xs={12}
                            sx={{
                            display: { xs: "none", md: "block" }
                        }}>
                            {/*    for side bar height*/}
                        <div style={{ height: '250px' }}>&nbsp;</div>
                        </Grid>
                    </Grid>
                </MainContentWrapper>
                <Box
                    component="span"
                    sx={{
                        display: { lg: 'none', xs: 'inline-block' }
                    }}
                >
                    <DrawerWrapperMobile
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                    >
                        {sidebarContent}
                    </DrawerWrapperMobile>
                </Box>
                <Box
                    component="span"
                    sx={{
                        display: { xs: 'none', lg: 'inline-block' }
                    }}
                >
                    <DrawerWrapper
                        variant="permanent"
                        anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                        open
                    >
                        {sidebarContent}
                    </DrawerWrapper>
                </Box>
            </Box>
        </>
    );
}

export default FoodCategoryCreate;