import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import readTenantCodeFromUrl from 'src/utils/readTenantCodeFromUrl';

class CDNService {
    private cdnUrl = process.env.REACT_APP_CDN_ENDPOINT || '';

    private sasUrl = process.env.REACT_APP_CDN_SAS_URL || '';

    private tenantCode = readTenantCodeFromUrl();

    public  categoryImageContainerName = 'images';

    //constructor(tenantCode: string, containerName:string) {
    //    this.sasUrl = process.env.REACT_APP_BLOB_SAS_URL || '';
    //    this.tenantCode = tenantCode;
    //    this.containerName = containerName;
    //}

    public init(tenantCode: string) {
        this.tenantCode = tenantCode;
    }
   
    public getContainerClient = (containerName?:string): ContainerClient => {
        const blobServiceClient = new BlobServiceClient(this.sasUrl);
        const containerClient = blobServiceClient.getContainerClient('ezzybite/admin-ui/' + this.tenantCode+'/' + this.categoryImageContainerName);
        return containerClient;
    }

    public toCdnUrl(fileName: string) {
        return this.cdnUrl + 'ezzybite/admin-ui/' + this.tenantCode+'/' + this.categoryImageContainerName
    }

    public toImageUrl(fileName: string) {
        return this.cdnUrl + 'ezzybite/admin-ui/' + this.tenantCode + '/' + this.categoryImageContainerName + '/' + fileName;
    }

    public noImageUrl() {
        return this.cdnUrl +'_sys/no-image.jpg';
    }

}
const cdnService = new CDNService();
export default cdnService;