import { useState, useEffect, useCallback } from 'react';
import axios from 'src/utils/axios';

import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';

import { Grid } from '@mui/material';
import useRefMounted from 'src/hooks/useRefMounted';

import Results from './Results';
import { OrderMain } from 'src/services/apiService/response-models';
import OrderFilterCard, { FilterModel, FilterRefData } from '../order-filter-card';
import { getAllActiveOrders } from 'src/services/apiService';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

function TodayOrderList2() {
  const { enqueueSnackbar } = useSnackbar();
  const [orders, setOrders] = useState<OrderMain[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [filter, setFilter] = useState<FilterModel>({});
 
  useEffect(() => {
    getAllActiveOrders()
      .then((response) => {
        setOrders(response.data);
        response.data.forEach(o=>{
          o.createdOn=o.createdOn?new Date(o.createdOn):o.createdOn;
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        enqueueSnackbar('Error fetching data.', { variant: 'error' });
        setLoading(false);
      });
  }, []);

  const getFilterRefData=():FilterRefData=>{
    const result:FilterRefData={
      devices: _.sortBy(_.uniqBy( orders.map(o=>o.sourceApp?.name),o=>o).filter(o=>o)),
      orderTypes:  _.sortBy(_.uniqBy( orders.map(o=>o.orderType),o=>o).filter(o=>o)),
      statuses: _.sortBy(_.uniqBy( orders.map(o=>o.currentStage.name),o=>o).filter(o=>o)),
      tableNumbers: _.sortBy(_.uniqBy( orders.map(o=>o.tableNumber),o=>o).filter(o=>o))
    };
    return result;
  }
  return (
    <>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>

      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
         <OrderFilterCard onApply={(e)=>setFilter({...e})} filter={{isLoading:isLoading,refData:getFilterRefData()}} />
          <Results orders={orders} isLoading={isLoading} filter={filter} />
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default TodayOrderList2;
