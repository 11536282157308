import { io } from "socket.io-client";
import { ApiUrls } from "../endpoints";
import { PosEvent, SetSocketReq } from "./model";
import readTenantCodeFromUrl from "src/utils/readTenantCodeFromUrl";

function EventsGateway() {
    const socket = io(ApiUrls.base);

    socket.on('connect', function () {
        console.log('Connected');
        // _socket.emit('setSocketId', {...header,socketId:_socket.id });
        setTimeout(() => {
            socket.emit('peer-to-peer', {
                eventName: "peer-to-peer",
                context: {
                    tenantCode: readTenantCodeFromUrl(),
                    appName: ''
                },
                eventData: {
                    test: 'hell'
                }
            }, (e) => console.log(e));
        }, 5000);

    });
    socket.on('posEvent', function (posEvent: PosEvent) {
        console.log('posEvent', posEvent);
    });
    socket.on('exception', function (data) {
        //   console.log('event', data);
    });
    socket.on('disconnect', function () {
        //   console.log('Disconnected');
    });

    return {
        init: (header: SetSocketReq) => {
            socket.emit('setSocketId', { ...header, socketId: socket.id });
        }
    }
}

const eventsGateway = EventsGateway();
export default eventsGateway;