import { useState, useEffect, useCallback } from 'react';

// import PageHeader from './PageHeader';
import PageHeader from 'src/components/page-header';
import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';

import { Alert, Box, Grid, LinearProgress, Skeleton } from '@mui/material';
import useRefMounted from 'src/hooks/useRefMounted';

import Results from './Results';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { getAllAddOnCategory, parseToApiErrorMessage } from '../../../services/apiService';
import { AddOnCategory } from '../../../services/apiService/response-models';

function ManageAddOnCategories() {
    const { enqueueSnackbar } = useSnackbar();
    const { t }: { t: any } = useTranslation();
    const isMountedRef = useRefMounted();

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [hasDataLoadError, setHasDataLoadError] = useState(false);

    const [categories, setCategories] = useState<AddOnCategory[]>([]);

    const getCategories = useCallback(async () => {
        try {
            setIsDataLoading(true);
            setHasDataLoadError(false);

            const response = await getAllAddOnCategory();

            if (isMountedRef.current) {
                setCategories(response.data);
            }
        } catch (err) {
            setHasDataLoadError(true);
            const msg = parseToApiErrorMessage(err, `Failed to load add-on category data`);
            enqueueSnackbar(t(msg), { variant: 'error' });
            console.error(err);
        }
        setIsDataLoading(false);
    }, [isMountedRef]);

    useEffect(() => {
        getCategories();
    }, [getCategories]);

    const onDeleteSuccess = async () => {
        await getCategories();
    }
    return (
        <>
            <PageTitleWrapper>
            <PageHeader type="list"  title="AddOn Categories" subtitle="Go back to all addon categories" route={'/'+location.pathname.split('/')[1]+"/inventory/addOnCategories/add"} buttonTitle="Create AddOn Categories"></PageHeader>
                {/* <PageHeader /> */}
            </PageTitleWrapper>

            <Grid
                sx={{
                    px: 4
                }}
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={4}
            >
                <Grid item xs={12}>
                    {!isDataLoading && hasDataLoadError && <Alert severity="error">Failed to load data</Alert>}
                    {isDataLoading && <LinearProgress />}
                    <Results categories={categories} onDeleteSuccess={onDeleteSuccess} />
                </Grid>
            </Grid>
            <Footer />
        </>
    );
}

export default ManageAddOnCategories;
