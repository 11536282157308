import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Card, CardHeader, Divider, FormControl, FormControlLabel, Grid, IconButton, LinearProgress, Radio, RadioGroup, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'src/store';
import { getProductById } from 'src/services/apiService';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { setAddToCartItem, updateItemFromCart } from 'src/store/slices/priceMenu';
import { useNavigate } from "react-router-dom";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#FFFFFF',
    border: '1px solid #FFFFFF',
    boxShadow: 24,
    borderRadius: '16px',
    minWidth: '585px'
};

const ButtonWrapper = styled('div')(() => `
    display: flex;
    align-items: center;
`)

export default function AddToOrderModal({ isVisible, data, setIsModalVisible, isEdit }: any) {
    const { t }: { t: any } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [price, setPrice] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [itemNotes, setItemNotes] = React.useState('');
    const [productData, setProductData] = React.useState<any>({});
    const [isLoading, setIsLoading] = React.useState(true);
    const [selectedVariant, setSelectedVariant] = React.useState<any>('');
    const [selectedAddOn, setSelectedAddOn] = React.useState<any>([]);
    const [addon, setAddOn] = React.useState<any>([]);
    const [selectedSlide, setSelectedSlide] = React.useState('');
    const [variantsValue, setVariantsValue] = React.useState('');
    const [variantsName, setVariantsName] = React.useState('');
    const [addOnValue, setAddOnValue] = React.useState('');
    let totalPrice = total + Number(price);

    const handleClose = () => {
        setIsModalVisible(false)
    }

    React.useEffect(() => {
        setIsLoading(true);
        getItemById();
        setSelectedAddOn([]);
        setSelectedVariant([]);
        setAddOn([]);
    }, [data]);

    const getItemById = async () => {
        
        const onSuccess = (res: any) => {
            
            setIsLoading(false);
            setProductData(res?.data?.[0]);
            
            setSelectedSlide(
                data?.addOns?.length > 0
                    ? isEdit
                        ? data?.addOns[0]?.groupName
                        : res?.data?.[0]?.addOns[0]?.groupName
                    : res?.data?.[0]?.addOns[0]?.groupName,
            );
            setTotal(
                !isEmpty(data?.selectedAddOn)
                    ? data?.selectedAddOn.reduce(
                        (acc: any, item: any) => acc + item?.addOnCashPrice,
                        0,
                    )
                    : 0,
            );
            setPrice(
                isEdit
                    ? res?.data?.[0]?.variants?.length !== 0
                        ? data?.cartVariant?.prices?.[0]?.price
                        : res?.data?.[0]?.prices?.length !== 0
                            ? res?.data?.[0]?.prices?.[0]?.price
                            : 0
                    : res?.data?.[0]?.variants?.length !== 0
                        ? res?.data?.[0]?.variants?.[0]?.prices?.[0]?.price
                        : res?.data?.[0]?.prices?.length !== 0
                            ? res?.data?.[0]?.prices?.[0]?.price
                            : 0,
            );
            setItemNotes(data?.notes ? data?.notes : '');
            setIsLoading(false);
            setSelectedVariant(data?.cartVariant ? data?.cartVariant : '');
            setVariantsValue(data?.cartVariant?.id ? data?.cartVariant?.id : '')
            setSelectedAddOn(
                data?.selectedAddOn?.length !== 0 ? data?.selectedAddOn : [],
            );
            setAddOn(!isEmpty(data?.selectedAddOn) ? data?.selectedAddOn : []);
            setAddOnValue(data?.selectedAddOn?.length !== 0 ? data?.selectedAddOn?.[0]?.name : [])
        };
        const onAceesFail = () => {
            setIsLoading(false);
        };

        if (data?.id || data?.productId) {
            let pId = data?.productId ? data?.productId : data?.id;
            dispatch(
                getProductById(onSuccess, pId),
            );
        }
    }

    const toggleVariantSelection = (itemName: any) => {
        if (selectedVariant === itemName) {
            setSelectedVariant(itemName);
        } else {
            setSelectedVariant(itemName);
        }
    };

    const toggleAddOnSelection = (item: any, itemName: any) => {
        let element: any = addon?.find((el: any) => el?.group === item?.groupName);
        let array: any = [...addon];
        if (element && element.group === item?.groupName) {
            if (element.id === itemName?.id) {
                array = array?.filter((el: any) => el?.id !== element?.id);
            } else {
                array = array.map((itemData: any) =>
                    itemData?.group === item?.groupName
                        ? {
                            ...itemData,
                            id: itemName?.id,
                            addOnCardPrice:
                                itemName?.price?.priceType === 'all'
                                    ? itemName?.price?.price
                                    : itemName?.price?.[1]?.price,
                            addOnCashPrice:
                                itemName?.price?.priceType === 'all'
                                    ? itemName?.price?.price
                                    : itemName?.price?.[0]?.price,
                            name: itemName?.name,
                        }
                        : itemData,
                );
            }
        } else {
            array.push({
                group: item?.groupName,
                id: itemName?.id,
                addOnCardPrice:
                    itemName?.price?.priceType === 'all'
                        ? itemName?.price?.price
                        : itemName?.price?.[1]?.price,
                addOnCashPrice:
                    itemName?.price?.priceType === 'all'
                        ? itemName?.price?.price
                        : itemName?.price?.[0]?.price,
                name: itemName?.name,
            });
        }

        const dataWithNumberPrices = array.map((item: any) => ({
            ...item,
            price: Number(item?.addOnCashPrice),
        }));

        const totalPrice = dataWithNumberPrices.reduce(
            (acc: any, item: any) => acc + item?.addOnCashPrice,
            0,
        );
        setTotal(totalPrice);

        setSelectedAddOn([...array]);
        setAddOn([...array]);
    };
    
    const handleAddItem = () => {
        let cartPayload = {
            ...productData,
            cartVariant: selectedVariant,
            cartAddOn: addon,
            cartVariantId: selectedVariant?.id,
            selectedAddOn: selectedAddOn || [],
            notes: itemNotes,
        };
        if (isEdit) {
            dispatch(
                updateItemFromCart({
                    ...cartPayload,
                    quantity: data?.quantity,
                    uuid: data?.uuid,
                    orderNumber: data?.orderNumber,
                }),
            );
        } else {
            dispatch(setAddToCartItem({ ...cartPayload, uuid: uuidv4() }));
        }

        handleClose();
        navigate("/pos-admin/check-out");

    };

const isEmptyObj = Object.keys(productData).length === 0 && productData.constructor === Object;


return (
    <Modal
        open={isVisible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <Card >
                {isEmptyObj ? (<LinearProgress />) :
                    (
                        <>
                            <CardHeader
                                sx={{
                                    px: 2
                                }}
                                action={
                                    <IconButton aria-label="settings" onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                }
                                subheader={t("Customize as per your taste")}
                                title={t(`${productData?.name} ${productData?.prices?.length > 0
                                    ? 'Cash: $' + productData?.prices?.[0]?.price
                                    : ''} ${' '}
                                            ${productData?.prices?.length > 1 ? '/' : ''}
                                            ${productData?.prices?.length > 1
                                        ? ' Card: $' + productData.prices?.[1]?.price
                                        : ''}`)}
                            />
                            <Divider />
                            {productData?.variants?.length !== 0 && (
                                <Box py={3}>
                                    <Grid container xs={12} px={2}>
                                        <Grid item xs={8}>{t('Choose any one size of the dish (1/1)')}</Grid>
                                        <Grid item xs={2} textAlign={'center'}>{t('Cash')}</Grid>
                                        <Grid xs={2} textAlign={'center'}>{t('Card')}</Grid>
                                    </Grid>
                                    <Grid container xs={12} px={2}>
                                        {productData?.variants && (Array.isArray(productData?.variants) ? (
                                            productData?.variants?.map((item: any) => (
                                                <Grid item xs={12}>
                                                    <ButtonWrapper key={item.id} onClick={() => {
                                                        toggleVariantSelection(item);
                                                        setPrice(
                                                            item?.prices?.length > 0
                                                                ? item?.prices[0]?.price?.toFixed(2)
                                                                : '-',
                                                        );
                                                    }}>
                                                        <Grid item xs={8}>
                                                            <FormControl>
                                                                <RadioGroup
                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                    defaultValue="female"
                                                                    name="radio-buttons-group"
                                                                    value={variantsValue}
                                                                    onChange={(e) => setVariantsValue(e?.target?.value)}
                                                                >
                                                                    <FormControlLabel value={item.id} control={<Radio />} label={t(item.name)} />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={2} textAlign={'center'}>$
                                                            {t(item?.prices?.length > 0
                                                                ? item?.prices[0]?.price?.toFixed(2)
                                                                : '-')}</Grid>
                                                        <Grid item xs={2} textAlign={'center'}>${t(item?.prices?.length > 0
                                                            ? item?.prices[1]?.price?.toFixed(2)
                                                            : '-')}</Grid>
                                                    </ButtonWrapper>
                                                </Grid>
                                            ))
                                        )
                                            : (
                                                <Grid item xs={12} display={'flex'} alignItems={'center'}>
                                                    <Grid item xs={8} flexDirection={'column'}>
                                                        <FormControl >
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                defaultValue="female"
                                                                name="radio-buttons-group"
                                                                value={variantsName}
                                                                onChange={(e) => setVariantsName(e?.target?.value)}
                                                            >
                                                                <FormControlLabel value={productData?.variants?.name} control={<Radio />} label={productData?.variants?.name} />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={2} textAlign={'center'}>$
                                                        $
                                                        {productData?.variants?.prices?.length > 0
                                                            ? productData?.variants?.prices[0]?.price
                                                            : '-'}</Grid>
                                                    <Grid item xs={2} textAlign={'center'}> $
                                                        {productData?.variants?.prices?.length > 0
                                                            ? productData?.variants?.prices[0]?.priceType ===
                                                                'all'
                                                                ? productData?.variants?.prices[0]?.price
                                                                : productData?.variants?.prices[1]?.price
                                                            : '-'}</Grid>
                                                </Grid>
                                            )
                                        )}

                                    </Grid>
                                </Box>
                            )}
                            {productData?.addOns?.length !== 0 && (
                                <>
                                    {productData?.addOns?.length > 0 &&
                                        productData?.addOns[0]?.addOnItems?.length > 0 && (
                                            <>
                                                <Grid px={2} display={'flex'} justifyContent={'space-around'} borderBottom={1} borderColor={'#919EAB'}>
                                                    {productData?.addOns?.length > 0 &&
                                                        selectedSlide &&
                                                        productData?.addOns?.map(
                                                            (item: any) =>
                                                                item?.addOnItems?.length > 0 && (
                                                                    <Box component={'div'} onClick={() => setSelectedSlide(item?.groupName)}
                                                                    p={0.5}
                                                                    borderBottom={item?.groupName === selectedSlide && 2}
                                                                    borderColor={item?.groupName === selectedSlide && '#00AB55'}
                                                                    >
                                                                        {item?.groupName}
                                                                    </Box>
                                                                )
                                                        )}
                                                </Grid>
                                                {productData?.addOns?.length > 0 &&
                                                    productData?.addOns[0]?.addOnItems?.length > 0 && (
                                                        <Box py={3}>
                                                            <Grid container xs={12} px={2}>
                                                                <Grid item xs={8}>Choose any one beverage (1/1)</Grid>
                                                            </Grid>
                                                            <Grid container xs={12} px={2}>
                                                                {productData?.addOns?.length > 0 &&
                                                                    productData?.addOns?.map((item: any) =>
                                                                        item?.groupName === selectedSlide &&
                                                                        item?.addOnItems?.length > 0 &&
                                                                        item?.addOnItems?.map((singleItem: any) => (
                                                                            <Grid item xs={12}>
                                                                                <ButtonWrapper onClick={() => {
                                                                                    toggleAddOnSelection(
                                                                                        item,
                                                                                        singleItem,
                                                                                    );
                                                                                }}>
                                                                                    <Grid item xs={8}>
                                                                                        <FormControl>
                                                                                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female"
                                                                                                name="radio-buttons-group" value={addOnValue} onChange={(e) => setAddOnValue(e?.target?.value)}
                                                                                            >
                                                                                                <FormControlLabel value={singleItem.name} control={<Radio />} label={singleItem.name} />
                                                                                            </RadioGroup>
                                                                                        </FormControl>
                                                                                    </Grid>
                                                                                    <Grid item xs={2} textAlign={'center'}>
                                                                                        $
                                                                                        {singleItem?.price?.price
                                                                                            ? singleItem?.price?.price?.toFixed(2)
                                                                                            : '-'} </Grid>
                                                                                    <Grid item xs={2} textAlign={'center'}>
                                                                                        $
                                                                                        {singleItem?.price?.price
                                                                                            ? singleItem?.price?.price?.toFixed(2)
                                                                                            : '-'}
                                                                                    </Grid>
                                                                                </ButtonWrapper>
                                                                            </Grid>
                                                                        )),
                                                                    )}
                                                            </Grid>
                                                        </Box>
                                                    )}
                                            </>
                                        )}
                                </>
                            )}
                            <Divider />
                            <Grid container xs={12} p={2}>
                                <Grid item xs={7}>
                                    <Box>
                                        Total
                                    </Box>
                                    <Box>
                                        ${totalPrice?.toFixed(2)}
                                    </Box>
                                </Grid>
                                <Grid item xs={5} display={'flex'} justifyContent={'end'}>
                                    <Button variant="contained" onClick={() => handleAddItem()} disabled={
                                        price === 0 ||
                                        (productData?.variants?.length !== 0 && !selectedVariant)
                                    }>
                                        {isEdit ? 'Edit to order' : 'Add to order'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </>)}
            </Card>
        </Box>
    </Modal>

);
}
