import { Card, Box, Divider, CardHeader, styled, Avatar, useTheme, ListItem, ListItemText, Typography, List, Alert, CircularProgress, CircularProgressProps, FormHelperText } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import cdnService from "../../services/cdnService";

const BoxUploadWrapper = styled(Box)(
    ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(2)};
    background: ${theme.colors.alpha.black[5]};
    border: 1px dashed ${theme.colors.alpha.black[30]};
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: ${theme.transitions.create(['border', 'background'])};

    &:hover {
      background: ${theme.colors.alpha.white[50]};
      border-color: ${theme.colors.primary.main};
    }
`
);

const AvatarWrapper = styled(Avatar)(
    ({ theme }) => `
    background: transparent;
    color: ${theme.colors.primary.main};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

const AvatarSuccess = styled(Avatar)(
    ({ theme }) => `
    background: ${theme.colors.success.light};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

const AvatarDanger = styled(Avatar)(
    ({ theme }) => `
    background: ${theme.colors.error.light};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
) {
    return (
        <Box mt={1} sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

function ImageDropZone(props: { label:string,required?:boolean, displayAsField?: boolean; uploadedFileName?: string, isSubmit?: boolean, onChange: (blobName: string) => void }) {

    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [isUploading, setIsUploading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isFileUploaded, setIsFileUploaded] = useState(props.uploadedFileName?.length > 0);
    const [progress, setProgress] = useState(0);
    let progressTimerRef: any;

    useEffect(() => {
        setIsSubmit(props.isSubmit)
    }, [props.isSubmit]);

    useEffect(() => {
        setIsFileUploaded(props.uploadedFileName?.length > 0)
    }, [props.uploadedFileName]);

    const onDropAccepted = useCallback(acceptedFiles => {
        setIsUploading(true);
        const file = acceptedFiles[0];
        uploadFileToCloud(file).then(blobName => {
            if (blobName) {
                props.onChange(blobName);
            }
        });
    }, []);

    const {
        isDragActive,
        isDragAccept,
        isDragReject,
        getRootProps,
        getInputProps
    } = useDropzone({
        maxFiles: 1,
        maxSize: 5242880,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg']
        },
        onDropAccepted,
        disabled: isUploading
    });

    const startTimer = () => {
        setIsUploading(true);
        setProgress(0);
        progressTimerRef = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 90 ? prevProgress : prevProgress + 10));
        }, 200);
    }

    const uploadFileToCloud = async (file: File): Promise<string> => {
        let blobName = '';
        try {

            const getExt = (fileName: string): string => {
                const parts = fileName.split('.');
                return parts.length > 1 ? parts[parts.length - 1] : '';
            }

            startTimer();
            const ext = getExt(file.name);

            blobName = 'img-' + new Date().getTime() + (ext ? '.' + ext : '');

            const containerClient = cdnService.getContainerClient();
            const blockBlobClient = containerClient.getBlockBlobClient(blobName);
            const res = await blockBlobClient.uploadBrowserData(file, {
                onProgress: (o) => {
                    //    total +=   o.loadedBytes;
                }
            });
            setProgress(100);

            setTimeout(o => {
                setIsUploading(false);
                setIsFileUploaded(true);
            }, 2000);

        } catch (error) {
            console.log(error);
            enqueueSnackbar(t(`Failed to upload image`), { variant: 'error' })
            setIsUploading(false);
            setIsFileUploaded(false);
        }
        clearInterval(progressTimerRef);
        return blobName;
    }

    //const files = acceptedFiles.map((file, index) => (
    //    <ListItem disableGutters component="div" key={index}>
    //        <ListItemText primary={file.name} />
    //        <b>{file.size} bytes</b>
    //        <Divider />
    //    </ListItem>
    //));

    const renderUploadWrapper = ()=>{
        return (<><BoxUploadWrapper {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragAccept && (
                <>
                    <AvatarSuccess variant="rounded">
                        <CheckTwoToneIcon />
                    </AvatarSuccess>
                    <Typography
                        sx={{
                            mt: 2
                        }}
                    >
                        {t('Drop the files to start uploading')}
                    </Typography>
                </>
            )}
            {isDragReject && (
                <>
                    <AvatarDanger variant="rounded">
                        <CloseTwoToneIcon />
                    </AvatarDanger>
                    <Typography
                        sx={{
                            mt: 2
                        }}
                    >
                        {t('You cannot upload these file types')}
                    </Typography>
                </>
            )}
            {!isDragActive && (
                <>
                    <AvatarWrapper variant="rounded">
                        <CloudUploadTwoToneIcon />
                    </AvatarWrapper>
                    <Typography
                        sx={{
                            mt: 2
                        }}
                    >
                        {isUploading ? t('Uploading...') : t('Drag & drop file here')}
                    </Typography>
                </>
            )}
            {isUploading && <CircularProgressWithLabel value={progress} />}

        </BoxUploadWrapper>
            {isFileUploaded && !isUploading && (
                <>
                    <Divider />
                    <Box pt={1}>
                        <Alert
                            sx={{
                                py: 0
                            }}
                            severity="success"
                        >
                            {t('You have uploaded')} <b>{1}</b> {t('file')}!
                        </Alert>
                    </Box>
                </>
            )}
            {props.required === true && isSubmit === true && !isFileUploaded && < Box  ><FormHelperText error={true}>The {props.label || 'field'} is required</FormHelperText ></Box>}
        </>)
    }
    return (<Card
        sx={{
            m: 3
        }}
    >
        {props.label && <CardHeader title={t(props.label)} />}
        <Divider />
        <Box p={2}>
            {renderUploadWrapper()}
        </Box>
    </Card>)
}

export default ImageDropZone;