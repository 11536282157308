import { ChangeEvent, useEffect, useState } from 'react';

import {
  ListItemText,
  Avatar,
  Box,
  Typography,
  Divider,
  ListItem,
  List,
  CardHeader,
  Alert,
  Card,
  styled,
  useTheme,
  ListItemButton,
  ListItemAvatar,
  IconButton,
  Tooltip,
  LinearProgress
} from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import { getFoodItemsByCategory, getItemsByCategory } from '../../../../services/apiService';
import cdnService from 'src/services/cdnService';
import { Item } from 'src/services/apiService/response-models';


function Sidebar(props: { categoryId?:string}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
    const [tems, setItems] = useState<Item[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const getItems = async (categoryId: string) => {
        try {
            setIsLoading(true);
            const response = await getItemsByCategory(categoryId);
            setItems(response.data);
            setIsLoading(false);
        } catch (ex) {
            setIsLoading(false);
            console.error(ex);
        }
    };

    useEffect(() => {
        if (props.categoryId) {
            getItems(props.categoryId).then(() => {
            });
        }
    }, [props.categoryId]);

  return (
      <Box>
          {props.categoryId?.length>0 && <Card
              sx={{
                  m: 3
              }}
          >
              <CardHeader

                  title={t('Items in Category')}
              />
              <Divider />
              <Box>
                  <List dense sx={{ width: '100%', maxWidth: 360, }}>
                      {isLoading && <LinearProgress color="secondary" />}
                      {!isLoading && tems.length == 0 && <ListItem>
                          <Typography variant="subtitle2" gutterBottom>
                              {t('No item in this food-category')}
                          </Typography>
                      </ListItem>}
                      {!isLoading && tems.map((item) => {
                          const labelId = `checkbox-list-secondary-label-${item.id}`;
                          return (
                              <ListItem
                                  key={item.id}
                                  secondaryAction={
                                      <Tooltip title={t('View')} arrow>
                                          <IconButton
                                              component={RouterLink}
                                              to={
                                                  `/${location.pathname.split('/')[1]
                                                  }/items/edit/`+item.id
                                              }
                                              color="primary"
                                          >
                                              <LaunchTwoToneIcon fontSize="small" />
                                          </IconButton>
                                      </Tooltip>
                                  }
                                  disablePadding
                              >
                                  <ListItemButton>
                                      <ListItemAvatar>
                                          <Avatar
                                              alt={item.name}
                                              src={cdnService.toImageUrl(item.featureImage)}
                                          />
                                      </ListItemAvatar>
                                      <ListItemText id={labelId} primary={item.name} />
                                  </ListItemButton>
                              </ListItem>
                          );
                      })}
                  </List>
              </Box>
          </Card>}
     
    </Box>
  );
}

export default Sidebar;
