import { ChangeEvent, useEffect, useState } from 'react';

import {
    ListItemText,
    Avatar,
    Box,
    Typography,
    Checkbox,
    FormControlLabel,
    Button,
    Switch,
    Chip,
    Divider,
    Grid,
    ListItem,
    List,
    CardHeader,
    Alert,
    Card,
    styled,
    useTheme,
    TextField,
    InputAdornment,
    Stack,
    FormHelperText,
    Dialog,
    DialogTitle,
    DialogContent,
    Autocomplete
} from '@mui/material';
import { useDropzone } from 'react-dropzone';

import { useTranslation } from 'react-i18next';
import { Category } from 'src/services/apiService/response-models';
import { useField } from 'formik';
import CategoryEditor from 'src/content/pos-admin/category/editor';
import { useSnackbar } from 'notistack';
import { getCategories } from 'src/services/apiService';

const DotLegend = styled('span')(
    ({ theme }) => `
    border-radius: 22px;
    width: ${theme.spacing(1.5)};
    height: ${theme.spacing(1.5)};
    display: inline-block;
    margin-right: ${theme.spacing(0.5)};
`
);

function FoodCategoryCard() {
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const theme = useTheme();
    const [field, meta, helpers] = useField('categories');
    const [selectedItems, setSelectedItems] = useState<Category[]>(field.value || []);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allCategories, setAllCategories] = useState<Category[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadData = async () => {
        try {
            const response = await getCategories();
            setAllCategories(response.data);
            if(field.value){
                const ids=(field.value as Category[]).map(o=>o.id);
                setSelectedItems(response.data.filter(o=>ids.includes(o.id)));
            }
        } catch (ex) {
            console.error(ex);
            const msg = ex.message ? ex.message : `Failed to load categories`;
            enqueueSnackbar(t(msg), { variant: 'error' });
        }
    };

    useEffect(() => {
        setIsLoading(true);
        (async () => {
           await loadData();
           setIsLoading(false);
          })();

        setSelectedItems(field.value)
    }, []);

    useEffect(() => {
        if(field.value){
            const ids=(field.value as Category[]).map(o=>o.id);
            setSelectedItems(allCategories.filter(o=>ids.includes(o.id)));
        }
    }, [field.value]);


    const onAddClick = () => {
        setIsModalOpen(true);
    }

    const onNewItemAdded= (e:Category) => {
        setIsModalOpen(false);
        setAllCategories([...allCategories,e]);
        setSelectedItems([...selectedItems,e]);
    }

    const onSelection = (items: Category[]) => {
        setSelectedItems(items);
        helpers.setValue(items.map(o => { return { id: o.id, name: o.name } }));
        setIsModalOpen(false);
    }

    return (
        <>
            <Card
                sx={{
                    m: 3
                }}
            >
                <CardHeader action={
                    <Button onClick={onAddClick} size="small" variant="outlined">
                        {t('Add...')}
                    </Button>
                }
                    title={t('Item Category')} />
                <Divider />
                <Box p={2}>
                <Autocomplete
                        disablePortal
                        filterSelectedOptions
                        multiple
                        disableClearable
                        onChange={(e,newValue)=>{
                            if(newValue){
                                onSelection(newValue as Category[]);
                            }
                        }}
                        id="cat-tags"
                        value={selectedItems}
                        options={allCategories}
                        getOptionLabel={(option:Category) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label=""
                                placeholder="Select..."
                            />
                        )}
                    />
                    {meta.error && meta.touched && <FormHelperText error={meta.error ? true : false}>{meta.error}</FormHelperText >}
                </Box>
            </Card>
            {isModalOpen && <ModalPopUp isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSubmit={onNewItemAdded}  />}
        </>
    );
}

function ModalPopUp(props: { isOpen: boolean, onClose: () => void, onSubmit: (item: Category) => void, model?: Category }) {
    const { t }: { t: any } = useTranslation();

    return (<Dialog
        fullWidth
        maxWidth="md"
        open={true}
    >
        <DialogTitle
            sx={{
                p: 3
            }}
        >
            <Typography variant="h4" gutterBottom>
                {t('Add category')}
            </Typography>
        </DialogTitle>
        <DialogContent>
            <CategoryEditor id={''} onSave={props.onSubmit} onCancel={props.onClose} />
        </DialogContent>
    </Dialog>)
}


export default FoodCategoryCard;
