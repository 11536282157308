import Authenticated from 'src/components/Authenticated';
import { Navigate, RouteObject } from 'react-router-dom';
import TeesheetList from 'src/content/golf-course/teesheets';
import CustomerList from 'src/content/golf-course/customers';
import TourOperatorList from 'src/content/golf-course/tour-operators';
import DiscountList from 'src/content/golf-course/discounts';

const GolfCourseRutes: RouteObject[] = [
  
      {
        path: '',
        element: <Navigate to="teesheets" replace />
      },
      {
        path:'teesheets',
        element: <TeesheetList/>
      },
      {
        path:'customers',
        element: <CustomerList />
      },
      {
        path:'tour-operators',
        element: <TourOperatorList />
      },
      {
        path:'discounts',
        element: <DiscountList />
      },
    ];
  

export default GolfCourseRutes;
