import { Autocomplete, Avatar, Box, Button, Card, CardContent, CardHeader, CardMedia, Chip, CircularProgress, Divider, FormControlLabel, FormHelperText, IconButton, Stack, Switch, TextField, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import CancelIcon from '@mui/icons-material/CancelTwoTone';
import { Category, Item, ItemRecommend } from "src/services/apiService/response-models";
import InfoPanel from "src/components/info-panel";
import { useEffect, useState } from "react";
import cdnService from "src/services/cdnService";
import { useSnackbar } from "notistack";
import { deleteItemRecommend, parseToApiErrorMessage, saveItem, saveItemRecommend } from "src/services/apiService";
import _ from "lodash";
import SaveIcon from '@mui/icons-material/Save';
import DeleteConfirm from "src/components/delete-confirm";

export default function RecommendEditor(props:{items:Item[], categories:Category[], recommedToEdit?:ItemRecommend,onSave:(e:ItemRecommend)=>void,onDelete:(id:string)=>void,onCancel:()=>void}){
    const { t }: { t: any } = useTranslation();
     const isEdit=props.recommedToEdit?.id && props.recommedToEdit.id.length>0;
     const item= props.items.find(o=>o.id==props.recommedToEdit?.itemId);
    const [model, setModel] = useState<ItemRecommend | null>(props.recommedToEdit);
    const [isSaveInProgress, setIsSaveInProgress] = useState(false);
    const [validationMsg, setValidationMsg] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [idRequestedToDelete, setIdRequestedToDelete] = useState<string>('');
    
    useEffect(()=>{ 
      setModel(props.recommedToEdit);
    },[props.recommedToEdit]);

    const onCategoryChange=(e,values:Category[])=>{

      //if removed
      const removedCatIds=_.difference(model.categories,values.map(v=>v.id));

      setModel({
        ...model,
        categories:(values || []).map(o=>o.id),
        excludedItemIds:removedCatIds.length?[]:model.excludedItemIds
      });
    }

    const onExcludeItemChange=(e,values:Item[])=>{
    
      setModel({
        ...model,
        excludedItemIds:(values || []).map(o=>o.id),
        itemIds:(model.itemIds || []).filter(o=> !(values || []).map(v=>v.id).includes(o))
        });
    }


    const onItemChange=(e,values:Item[])=>{
      setModel({
        ...model,
        itemIds:(values || []).map(o=>o.id)});
    }

    const onSaveChanges=async () =>{
        try{
          setValidationMsg('');
          if(!model.categories?.length && !model.itemIds?.length)
          {
            setValidationMsg('Selected catetory or item');
            return;
          }

          setIsSaveInProgress(true);
          const res = await saveItemRecommend(model);
          props.onSave(res.data);
          enqueueSnackbar(t('Saved changes successfully'), { variant: 'success' });
        }catch(ex){
          const msg = parseToApiErrorMessage(ex, `Failed to save changes`);
          enqueueSnackbar(t(msg), { variant: 'error' });
        }

        setIsSaveInProgress(false);
    }

    const onDelete=async () =>{
      try{
        const res = await deleteItemRecommend(idRequestedToDelete);
        enqueueSnackbar(t('Deleted record successfully'), { variant: 'success' });
        props.onDelete(idRequestedToDelete);
      }catch(ex){
        const msg = parseToApiErrorMessage(ex, `Failed to delete record`);
        enqueueSnackbar(t(msg), { variant: 'error' });
      }
      setIdRequestedToDelete('');
  }

    const selectedCats=props.categories.filter(o=>(model?.categories || []).includes(o.id) );
    const itemsInSelectedCats=props.items.filter(o=> o.categories.map(c=>c.id).some(c=>(model?.categories || []).includes(c)));

    return (   <Card>
       <CardHeader
         action={
           item ? <FormControlLabel labelPlacement="start"  
          control={
          <Switch color="error" checked={model?.disabled}
           onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setModel({...model,disabled:e.target.checked})} />
        } 
        label="Disable recommendations" /> :null
     }
     title={t('Configure Item Recommendation')}
     subheader={item?.name}
   />
         
      
       <Divider />
        {(!item || !props.recommedToEdit) && <Box px={.5} ><InfoPanel title="No item selected" message="Please select an item." /></Box>}
        {item &&  <Stack>
           
         <Card
           sx={{
             m: 2,
           }}
           variant={'elevation'}
         >
            <CardHeader title="Item Category" subheader="Recommend all items of a category" />
            <Divider/>
 <CardContent>
    <Stack spacing={2}>

 <Autocomplete
                  multiple
                  fullWidth
                  // @ts-ignore
                  getOptionLabel={(option) => option.name}
                  options={props.categories}
                  value={selectedCats}
                  onChange={onCategoryChange}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Avatar
                        sx={{
                          mr: 1
                        }}
                        src={cdnService.toImageUrl(option.featureImage)}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                      label={t('Categories')}
                      placeholder={t('Select categories...')}
                    />
                  )}
                  renderTags={(assignees, getTagProps) =>
                    assignees.map((ev, index: number) => (
                      <Chip
                        key={ev.name}
                        label={ev.name}
                        {...getTagProps({ index })}
                        avatar={<Avatar src={cdnService.toImageUrl(ev.featureImage)} />}
                      />
                    ))
                  }

                  
                />
                <FormControlLabel labelPlacement="start"   label="Exclude items"  
                 control={  <Autocomplete
                
                    multiple
                    fullWidth
                    // @ts-ignore
                    getOptionLabel={(option) => option.name}
                    value={props.items.filter(o=> model?.excludedItemIds && model.excludedItemIds.includes(o.id))}
                    onChange={onExcludeItemChange}
                    options={itemsInSelectedCats}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Avatar
                          sx={{
                            mr: 1
                          }}
                          src={cdnService.toImageUrl(option.featureImage)}
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                      helperText="These items will not be remmonded from selected categories"
                        InputLabelProps={{
                          shrink: true
                        }}
                        label={t('Excluded items')}
                        placeholder={t('Select items...')}
                      />
                    )}
                    renderTags={(assignees, getTagProps) =>
                      assignees.map((ev, index: number) => (
                        <Chip
                          key={ev.name}
                          label={ev.name}
                          {...getTagProps({ index })}
                          avatar={<Avatar src={cdnService.toImageUrl(ev.featureImage)} />}
                        />
                      ))
                    }
  
                    
                  /> }
                 />
              
    </Stack>

 </CardContent>
     
         
             
            
          
         </Card>
         <Card
           sx={{
             m: 2,
           }}
           variant={'elevation'}
         >
            <CardHeader title="Specific Item" subheader="Choose item to recommend" />
            <Divider/>
 <CardContent>
    <Stack spacing={2}>

 <Autocomplete
                  multiple
                  fullWidth
                  value={props.items.filter(o=> model?.itemIds && model.itemIds.includes(o.id))}
                  onChange={onItemChange}
                  // @ts-ignore
                  getOptionLabel={(option) => option.name}
                  options={props.items.filter(o=> item.id!==o.id && !model?.excludedItemIds?.includes(o.id))}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Avatar
                        sx={{
                          mr: 1
                        }}
                        src={cdnService.toImageUrl(option.featureImage)}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                      label={t('Items')}
                      placeholder={t('Select items...')}
                    />
                  )}
                  renderTags={(assignees, getTagProps) =>
                    assignees.map((ev, index: number) => (
                      <Chip
                        key={ev.name}
                        label={ev.name}
                        {...getTagProps({ index })}
                        avatar={<Avatar src={cdnService.toImageUrl(ev.featureImage)} />}
                      />
                    ))
                  }

                  
                />
        
              
    </Stack>

 </CardContent>
     
         
             
            
          
         </Card>
         <FormHelperText  sx={{ml:3,mt:1}}  error={Boolean(validationMsg.length)}>{validationMsg}</FormHelperText>
         <Box sx={{m:2}} textAlign="center" display="flex"  justifyContent="space-between" >
         <div></div>
         
          <Button onClick={onSaveChanges} 
                variant="contained"
                                startIcon={
                                  Boolean(idRequestedToDelete.length) || isSaveInProgress ? <CircularProgress size="1rem" /> : <SaveIcon />
                                }
                                disabled={isSaveInProgress}>
                                Save changes</Button>
          {isEdit ? <IconButton onClick={()=>setIdRequestedToDelete(props.recommedToEdit?.id)}
            disabled={Boolean(idRequestedToDelete.length)}
           color="error">
              <DeleteIcon />
          </IconButton >:<Tooltip title="Cancel"><IconButton onClick={props.onCancel}
           color="secondary">
              <CancelIcon />
          </IconButton ></Tooltip>}
         
         </Box>
         <DeleteConfirm onClose={()=>setIdRequestedToDelete('')}
         onDeleteConfirm={onDelete}
         open={Boolean(idRequestedToDelete.length)}
         />
         </Stack>}
     </Card>)
}

