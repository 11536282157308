import {
  FC,
  ChangeEvent,
  useState,
  ReactElement,
  Ref,
  forwardRef,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Grid,
  Slide,
  Divider,
  Tooltip,
  IconButton,
  Link,
  TablePagination,
  Button,
  Typography,
  Dialog,
  CardMedia,
  lighten,
  styled,
  Skeleton,
  useTheme} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Label from 'src/components/Label';
import BulkActions from './BulkActions';
;
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useSnackbar } from 'notistack';
import { formatDistance, format } from 'date-fns';
import Text from 'src/components/Text';
import { OrderMain, OrderStage } from 'src/services/apiService/response-models';
import { FilterModel } from '../order-filter-card';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import cdnService from 'src/services/cdnService';
import { useNavigate } from 'react-router-dom';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';


const CardWrapper = styled(Card)(
  ({ theme }) => `

  position: relative;
  overflow: visible;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    z-index: 1;
    transition: ${theme.transitions.create(['box-shadow'])};
  }
      
    &.Mui-selected::after {
      box-shadow: 0 0 0 3px ${theme.colors.primary.main};
    }
  `
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const IconButtonError = styled(IconButton)(
  ({ theme }) => `
     background: ${theme.colors.error.lighter};
     color: ${theme.colors.error.main};
     padding: ${theme.spacing(0.75)};

     &:hover {
      background: ${lighten(theme.colors.error.lighter, 0.4)};
     }
`
);

 

interface ResultsProps {
  orders: OrderMain[];
  isLoading:boolean;
  filter:FilterModel;
}


const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const getOrderStatusLabel = (e: OrderStage): JSX.Element => {
  const map = {
    open: {
      text: 'Open',
      color: 'warning'
    },
    placed: {
      text: 'Placed',
      color: 'info'
    },
    delivered: {
      text: 'Delivered',
      color: 'success'
    },
    paid: {
      text: 'Paid',
      color: 'success'
    },
    partiallypaid: {
      text: 'Partially Paid',
      color: 'secondary'
    }
  };

  const { text, color }: any = map[e.name];

  return <Label color={color}>{text}</Label>;
};

const applyFilters = (
  orders: OrderMain[],
  filter: FilterModel
): OrderMain[] => {
  return orders.filter((order) => {
    let isMatch = true;

    if (filter.searchKey) {
      const query=filter.searchKey.toLowerCase();
      const properties = ['orderNumber','customer.fullName'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (order[property]?.toLowerCase().includes(query)) {
          containsQuery = true;
        }
      });

      if(!containsQuery){
        order.items.forEach(item=>{
          if (item.itemName.toLowerCase().includes(query)) {
            containsQuery = true;
          }else if(item.categories.map(o=>o.toLowerCase()).includes(query)) {
            containsQuery = true;
          }
        });
      }

      if (!containsQuery) {
        isMatch = false;
      }
    }

      if (filter.status && order.currentStage?.name !== filter.status) {
        isMatch = false;
      }

      if (filter.tableNumber && order.tableNumber !== filter.tableNumber) {
        isMatch = false;
      }

      if (filter.orderType && order.orderType !== filter.orderType) {
        isMatch = false;
      }

      if (filter.devices && !filter.devices.map(o=>o.toLowerCase()).includes(order.sourceApp?.name?.toLowerCase())) {
        isMatch = false;
      }
      

    return isMatch;
  });
};

const applyPagination = (
  orders: OrderMain[],
  page: number,
  limit: number
): OrderMain[] => {
  return orders.slice(page * limit, page * limit + limit);
};

const Results: FC<ResultsProps> = ({ orders,isLoading,filter }) => {
  const [selectedItems, setSelectedOrders] = useState<string[]>([]);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const theme = useTheme();
  
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);

  const handleSelectAllOrders = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedOrders(
      event.target.checked ? orders.map((order) => order.id) : []
    );
  };

  useEffect(()=>{
    applyFilters(orders,filter);
  },[filter])

  const handleSelectOneOrder = (
    _event: ChangeEvent<HTMLInputElement>,
    orderId: string
  ): void => {
    if (!selectedItems.includes(orderId)) {
      setSelectedOrders((prevSelected) => [...prevSelected, orderId]);
    } else {
      setSelectedOrders((prevSelected) =>
        prevSelected.filter((id) => id !== orderId)
      );
    }
  };

  const handlePageChange = (_event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredOrders = applyFilters(orders, filter);
  const paginatedOrders = applyPagination(filteredOrders, page, limit);
  const selectedBulkActions = selectedItems.length > 0;
  const selectedSomeOrders =
    selectedItems.length > 0 && selectedItems.length < orders.length;
  const selectedAllOrders = selectedItems.length === orders.length;

  const onOrderViewClick = (id: string) => {
    navigate('/' + location.pathname.split('/')[1] + "/orders/detail/" + id);
  }
  const onInvoiceClick = (id: string) => {
      navigate('/' + location.pathname.split('/')[1] + "/orders/invoice/" + id+'?ret='+location.pathname);
  }
 
  if(isLoading){
    return (<Card>
        <Box p={2}>
          <Skeleton variant='rectangular' height={250}/>
        </Box></Card>)
  }
  return (
    <>
          {paginatedOrders.length !== 0 && (
            <Card
              sx={{
                p: 2,
                mb: 3
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <>
                  <Box display="flex" alignItems="center">
                    {/* <Tooltip
                      arrow
                      placement="top"
                      title={t('Select all orders')}
                    >
                      <Checkbox
                        checked={selectedAllOrders}
                        indeterminate={selectedSomeOrders}
                        onChange={handleSelectAllOrders}
                      />
                    </Tooltip> */}
                  </Box>
                  {selectedBulkActions && (
                    <Box flex={1} pl={2}>
                      <BulkActions />
                    </Box>
                  )}
                  {!selectedBulkActions && (
                    <TablePagination
                      component="div"
                      count={filteredOrders.length}
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={handleLimitChange}
                      page={page}
                      rowsPerPage={limit}
                      rowsPerPageOptions={[10,50,100]}
                    />
                  )}
                </>
              </Box>
            </Card>
          )}
          {paginatedOrders.length === 0 ? (
            <Typography
              sx={{
                py: 20
              }}
              variant="h3"
              fontWeight="normal"
              color="text.secondary"
              align="center"
            >
              {t("No order to display matching your search criteria")}
            </Typography>
          ) : (
            <>
              <Grid container spacing={3}>
                {paginatedOrders.map((order) => {
                  const isOrderSelected = selectedItems.includes(order.id);

                  return (
                    <Grid item xs={12} sm={6} md={4} key={order.id}>
                      <CardWrapper
                        className={clsx({
                          'Mui-selected': isOrderSelected
                        })}
                      >
                        <Box
                          sx={{
                            position: 'relative',
                            zIndex: '2'
                          }}
                        >
                          <Box
                            pl={2}
                            py={1}
                            pr={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Box>
                              <Typography component="span">
                                <b>Order #{order.orderNumber}</b>
                              </Typography>
                               
                                  {order.tableNumber && <span  >
                                   {' '}<Link >Table # {order.tableNumber}</Link>
                                  </span> }
                                
                            </Box>
                            {order.sourceApp?.name}
                          </Box>
                          <Divider />
                          <Box
        px={3}
        pb={2}
        sx={{
          '.swiper-pagination-bullets': {
            bottom: '0 !important'
          }
        }}
      >
        <Swiper
          spaceBetween={0}
          slidesPerView={2}
          navigation={{
            nextEl: '.MuiSwipe-right',
            prevEl: '.MuiSwipe-left'
          }}
          breakpoints={{
            500: {
              slidesPerView: 2,
              spaceBetween: 12
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 12
            },
            1200: {
              slidesPerView: 2,
              spaceBetween: 24
            }
          }}
          style={{padding:0}}
          modules={[Navigation, Pagination]}
          pagination={{ dynamicBullets: true, clickable: true }}
        >
         {order.items.map(item=>
          <SwiperSlide key={item.itemId}>
            <Card
            sx={{mt:1}}
            >
             
            </Card>
            <CardMedia
                  component="img"
                  height="100"
                  sx={{
                    objectFit:'fill!important'
                  }}
                  image={item.featureImage ? cdnService.toImageUrl(item.featureImage) : cdnService.noImageUrl()}
                  alt="..."
                />
            <Box
              sx={{
             
                textAlign: 'center'
              }}
            >
              
              <Typography
                variant="subtitle2"
                sx={{
                }}
              >
                {t(item.itemName)}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                }}
              >
                {t('Qty '+item.quantity)}
              </Typography>
            </Box>
          </SwiperSlide>
         )} 
        </Swiper>
      </Box>
                          <Divider />
                          <Box p={1}  display="flex"
                            alignItems="center"
                            justifyContent="space-between">
                            {getOrderStatusLabel(order.currentStage)}
                            <Typography
                              sx={{
                                mt: 0
                              }}
                              variant="h4"
                              gutterBottom
                            >
                              ${order.orderAmount}
                            </Typography>
                            <Typography
                              sx={{
                                mt: 0
                              }}
                              variant="h5"
                              gutterBottom
                            >
                              {'Griish Mahaajn'}
                            </Typography>
                           {order.customer?.fullName &&  <Typography
                              sx={{
                                mt: 0
                              }}
                              variant="h4"
                              gutterBottom
                            >
                              {order.customer?.fullName}
                            </Typography>}

                            {/* <Typography noWrap variant="subtitle2">
                              {'order.description'}
                            </Typography> */}
                          </Box>
                          <Box
                            px={2}
                            display="flex"
                            alignItems="flex-end"
                            justifyContent="space-between"
                          >
                            <Box>
                              <Typography variant="subtitle2">
                                {t('By ' + order.createdBy?.name)}
                              </Typography>
                              <Typography variant="h5">
                                 {format(order.createdOn, 'MMM dd yyyy')} 
                              </Typography>
                            </Box>
                            <Box>
                              <Typography variant="subtitle2">
                                {t('Time')}:{' '}
                                <Text color="black">
                                  {formatDistance(
                                    order.createdOn,
                                    new Date(),
                                    {
                                      addSuffix: true
                                    }
                                  )}
                                </Text>
                              </Typography>
                            </Box>
                          </Box>

            
                          <Divider />
                          <Box
                            p={2}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Box >
                             
                            </Box>
                            <Box>
                            <Tooltip title={t('View Details')} arrow>
                              <Button
                                onClick={()=>onOrderViewClick(order.id)}
                                sx={{
                                  mr: 1
                                }}
                                size="small"
                                variant="contained"
                                color="primary"
                              >
                                {t('View')}
                              </Button> 
                              </Tooltip>
                              <Tooltip title={t('View invoice')} arrow>
                              <IconButton color="secondary" onClick={()=>onInvoiceClick(order.id)}>
                                  <ReceiptTwoToneIcon />
                              </IconButton>
                                    </Tooltip>
                            </Box>
                          </Box>
                        </Box>
                      </CardWrapper>
                    </Grid>
                  );
                })}
              </Grid>
              <Card
                sx={{
                  p: 2,
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Box>
                  <Typography component="span" variant="subtitle1">
                    {t('Showing')}
                  </Typography>{' '}
                  <b>{limit}</b> {t('of')} <b>{filteredOrders.length}</b>{' '}
                  <b>{t('orders')}</b>
                </Box>
                <TablePagination
                  component="div"
                  count={filteredOrders.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  labelRowsPerPage=""
                  rowsPerPageOptions={[10,50,100]}
                />
              </Card>
            </>
          )}
        </>
  );
};

Results.propTypes = {
  orders: PropTypes.array.isRequired
};

Results.defaultProps = {
  orders: []
};

export default Results;
