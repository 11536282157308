import { BLUEY_SMOKE_KEY, BLUEY_SMOKE_URL, BOMBAY_CURRY_KEY, BOMBAY_CURRY_PIZZA_URL, BUONO_PIZZA_KEY, SAGE_N_SALT_KEY  , SAGE_N_SALT_URL} from "./urls";

const ApiBaseUrl = process.env.REACT_APP_API_URL || '';

export default function readTenantCodeFromUrl() {
    return getSubdomain() || SAGE_N_SALT_KEY;
    // return getSubdomain() || ApiBaseUrl === SAGE_N_SALT_URL ? SAGE_N_SALT_KEY : ApiBaseUrl === BOMBAY_CURRY_PIZZA_URL ? BOMBAY_CURRY_KEY : ApiBaseUrl === BLUEY_SMOKE_URL ? BLUEY_SMOKE_KEY : BUONO_PIZZA_KEY;
}

function getSubdomain() {
    const hostname = window.location.hostname;
    var regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
    var urlParts = regexParse.exec(hostname);
    console.log(hostname);
    return hostname.replace(urlParts[0], '').slice(0, -1);
}