import axios, { AxiosError, AxiosResponse } from "axios";
import { ApiUrls } from "../endpoints";
import { CreateFoodItemRequest } from "./request-models";
import { AddOnCategory, AddOnItem, Category, Customer, DineLayoutSection, DineTable, Employee, FoodCategory, FoodItem, Ingredient, Item, ItemRecommend, ItemVariant, ItemVariantMain, Merchant, NameItem, OrderMain, PriceMenu, RefData, Restaurant, Size, StoreLocation } from "./response-models";
import CheckoutCart from "./response-models/checkout-cart";
import { ItemListingRes } from "./response-models/view-models";
import { format } from "date-fns";
import { callApi } from "src/hooks/api";
import { setDiscountData, setPaymentStatusLoading, setPriceMenuDetails, setTaxDetails } from "src/store/slices/priceMenu";

export const GetRestaurantByTenantCode = (): Promise<AxiosResponse<Restaurant>> => {
    const dataUrl = ApiUrls.restaurantEndpoint + 'tenant-code';
    return axios.get(dataUrl);
};

export const getAllFoodCategories = (): Promise<AxiosResponse<any>> => {
    const dataUrl = ApiUrls.catalogEndpoint + "food-category/all";
    return axios.get(dataUrl);
};

export const getFoodItemById = (itemId: number): Promise<AxiosResponse<FoodItem>> => {
    const dataUrl = ApiUrls.catalogEndpoint + "food-item/id/" + itemId;
    return axios.get(dataUrl);
};



export const getFoodItemsByCategory = (categoryId: number): Promise<AxiosResponse<FoodItem[]>> => {
    const dataUrl = ApiUrls.catalogEndpoint + "food-items/category-id/" + categoryId;
    return axios.get(dataUrl);
};

export const getAllFoodItems = (): Promise<AxiosResponse<FoodItem[]>> => {
    const dataUrl = ApiUrls.catalogEndpoint + "food-items/all";
    return axios.get(dataUrl);
};

export const getFoodItemsByRestaurantId = (restaurantId: number): Promise<AxiosResponse<FoodItem[]>> => {
    const dataUrl = ApiUrls.catalogEndpoint + `food-items/restaurant/${restaurantId}`;
    return axios.get(dataUrl);
};

export const getFoodItemsDetailed = (): Promise<AxiosResponse<FoodItem[]>> => {
    const dataUrl = ApiUrls.catalogEndpoint + "food-items/all/detail";
    return axios.get(dataUrl);
};

export const saveFoodItem = (req: FoodItem, locationIds: number[]): Promise<AxiosResponse<FoodItem>> => {
    const dataUrl = ApiUrls.catalogEndpoint + "food-item";
    if (req.id > 0)
        return axios.put(dataUrl, req);
    else {
        const craeteReq: CreateFoodItemRequest = {
            foodItem: req,
            locationIds: locationIds
        }
        return axios.post(dataUrl, craeteReq);
    }
};

export const getFoodCategoryById = (id: number): Promise<AxiosResponse<FoodCategory>> => {
    const dataUrl = ApiUrls.catalogEndpoint + "food-category/id/" + id;
    return axios.get(dataUrl);
};


export const saveFoodCategory = (req: FoodCategory): Promise<AxiosResponse<void>> => {
    const dataUrl = ApiUrls.catalogEndpoint + "food-category";
    if (req.id > 0)
        return axios.put(dataUrl, req);
    else
        return axios.post(dataUrl, req);
};

export const saveSize = (req: Size): Promise<AxiosResponse<void>> => {
    const dataUrl = ApiUrls.catalogEndpoint + "item-size";
    if (req.id > 0)
        return axios.put(dataUrl, req);
    else
        return axios.post(dataUrl, req);
};

export const getAllSizes = (): Promise<AxiosResponse<Size[]>> => {
    const dataUrl = ApiUrls.catalogEndpoint + "item-size/all";
    return axios.get(dataUrl);
};
export const deleteSize = (sizeId: string): Promise<AxiosResponse<boolean>> => {
    const dataUrl = ApiUrls.catalogEndpoint + "item-size/" + sizeId;
    return axios.delete(dataUrl);
};

export const getSizeById = (id: number): Promise<AxiosResponse<Size>> => {
    const dataUrl = ApiUrls.catalogEndpoint + "item-size/id/" + id;
    return axios.get(dataUrl);
};

export const saveAddOnCategory = (req: AddOnCategory): Promise<AxiosResponse<void>> => {
    const dataUrl = ApiUrls.catalogEndpoint + "add-on-category";
    if (req.id?.length > 0)
        return axios.put(dataUrl, req);
    else
        return axios.post(dataUrl, req);
};

export const getAddOnCategoryById = (id: number): Promise<AxiosResponse<AddOnCategory>> => {
    const dataUrl = ApiUrls.base + "/api/v1/addon-category/id/" + id;
    return axios.get(dataUrl);
};

export const getAllAddOnItemsOLD = (): Promise<AxiosResponse<AddOnItem[]>> => {
    const dataUrl = ApiUrls.catalogEndpoint + "add-on-item/all";
    return axios.get(dataUrl);
};


export const parseToApiErrorMessage = (ex: AxiosError, defaultMessage: string): string => {

    if (ex.response && ex.response.data && (ex.response.data as any).message)
        return (ex.response.data as any).message;

    return ex.message ? ex.message : defaultMessage;
}


export const getAllIngredients = (): Promise<AxiosResponse<Ingredient[]>> => {
    const dataUrl = ApiUrls.catalogEndpoint + "ingredient/all";
    return axios.get(dataUrl);
};
export const deleteIngredient = (ingredientId: number): Promise<AxiosResponse<boolean>> => {
    const dataUrl = ApiUrls.catalogEndpoint + "ingredient/" + ingredientId;
    return axios.delete(dataUrl);
};
export const GetAllCookingReferences = (): Promise<AxiosResponse<NameItem[]>> => {
    const dataUrl = ApiUrls.catalogEndpoint + "cooking-reference/all";
    return axios.get(dataUrl);
};


export const saveLocation = (req: StoreLocation): Promise<AxiosResponse<void>> => {
    const dataUrl = ApiUrls.catalogEndpoint + "location";
    if (req.id > 0)
        return axios.put(dataUrl, req);
    else
        return axios.post(dataUrl, req);
};

export const getLocations = (): Promise<AxiosResponse<StoreLocation[]>> => {
    const dataUrl = ApiUrls.catalogEndpoint + "location/all";
    return axios.get(dataUrl);
};
export const deleteLocation = (locationId: number): Promise<AxiosResponse<boolean>> => {
    const dataUrl = ApiUrls.catalogEndpoint + "location/" + locationId;
    return axios.delete(dataUrl);
};

export const getLocationById = (id: number): Promise<AxiosResponse<StoreLocation>> => {
    const dataUrl = ApiUrls.catalogEndpoint + "location/id/" + id;
    return axios.get(dataUrl);
};

// export const getAllOrders = (locationId: number): Promise<AxiosResponse<CheckoutCart[]>> => {
//     const dataUrl = ApiUrls.storeAdminEndpoint + locationId + '/order-history/all';
//     return axios.get(dataUrl);
// };

///new

export const getAllActiveOrders = (): Promise<AxiosResponse<OrderMain[]>> => {
    const dataUrl = ApiUrls.base + '/api/v1/order/list?stage=*';
    return axios.get(dataUrl);
};

export const getAllOrders = (): Promise<AxiosResponse<OrderMain[]>> => {
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const toDate = format(tomorrow, 'MM-dd-yyyy');//next day

    const dataUrl = ApiUrls.base + '/api/v1/order/list?stage=*&from=01-01-2023&to=' + toDate;
    return axios.get(dataUrl);
};

export const getOrderById = (id: string): Promise<AxiosResponse<OrderMain>> => {
    const dataUrl = ApiUrls.base + '/api/v1/order/id/' + id;
    return axios.get(dataUrl);
};

export const getOrderStagesByOrderId = (orderId: string): Promise<AxiosResponse<OrderMain>> => {
    const dataUrl = ApiUrls.base + '/api/v1/order/stages/order-id/' + orderId;
    return axios.get(dataUrl);
};

export const deleteActiveOrder = (id: string): Promise<AxiosResponse<boolean>> => {
    const dataUrl = ApiUrls.base + "/api/v1/order/cancel/" + id;
    return axios.delete(dataUrl);
};


export const saveIngredient = (req: Ingredient): Promise<AxiosResponse<Ingredient>> => {
    const dataUrl = ApiUrls.base + '/api/v1/ingredient';
    if (req.id?.length > 0)
        return axios.put(dataUrl, req);
    else
        return axios.post(dataUrl, req);
};

export const getIngredients = (): Promise<AxiosResponse<Ingredient[]>> => {
    const dataUrl = ApiUrls.base + "/api/v1/ingredient";
    return axios.get(dataUrl);
};


export const getIngredientById = (id: string): Promise<AxiosResponse<Ingredient>> => {
    const dataUrl = ApiUrls.base + "/ingredient/id/" + id;
    return axios.get(dataUrl);
};


export const saveCategory = (req: Category): Promise<AxiosResponse<Category>> => {
    const dataUrl = ApiUrls.base + '/api/v1/category';
    if (req.id?.length)
        return axios.put(dataUrl, req);
    else
        return axios.post(dataUrl, req);
};

export const getCategories = (): Promise<AxiosResponse<Category[]>> => {
    const dataUrl = ApiUrls.base + "/api/v1/category";
    return axios.get(dataUrl);
};


export const getCategoryById = (id: string): Promise<AxiosResponse<Category>> => {
    const dataUrl = ApiUrls.base + "/api/v1/category/id/" + id;
    return axios.get(dataUrl);
};

export const getAllAddOnItems = (): Promise<AxiosResponse<AddOnItem[]>> => {
    const dataUrl = ApiUrls.base + "/api/v1/addon-item";
    return axios.get(dataUrl);
};
// export const saveCategory = (req: Category): Promise<AxiosResponse<Category>> => {
//     const dataUrl = ApiUrls.base + '/api/v1/category';
//     if (req.id?.length)
//         return axios.put(dataUrl, req);
//     else
//         return axios.post(dataUrl, req);
// };

export const getAllAddOnCategories = (): Promise<AxiosResponse<AddOnCategory[]>> => {
    const dataUrl = ApiUrls.base + "/api/v1/addon-category";
    return axios.get(dataUrl);
};



export const getAllAddOnCategory = (): Promise<AxiosResponse<AddOnItem[]>> => {
    const dataUrl = ApiUrls.base + "/api/v1/addon-category";
    return axios.get(dataUrl);
};

export const saveAddOnCategories = (req: AddOnCategory): Promise<AxiosResponse<void>> => {
    const dataUrl = ApiUrls.base + '/api/v1/addon-category';
    if (req.id?.length > 0)
        return axios.put(dataUrl, req);
    else
        return axios.post(dataUrl, req);
};

export const saveItem = (req: Item): Promise<AxiosResponse<Item>> => {
    const dataUrl = ApiUrls.base + "/api/v1/item";
    if (req.id?.length > 0)
        return axios.put(dataUrl, req);
    else
        return axios.post(dataUrl, req);
};

export const getItemListingVmRes = (): Promise<AxiosResponse<ItemListingRes>> => {
    const dataUrl = ApiUrls.base + "/api/v1/item/listing-view";
    return axios.get(dataUrl);
};
export const getItemsByCategory = (categoryId: string): Promise<AxiosResponse<Item[]>> => {
    const dataUrl = ApiUrls.base + "/api/v1/item/category-id/" + categoryId;
    return axios.get(dataUrl);
};

export const deleteCategory = (categoryId: string): Promise<AxiosResponse<boolean>> => {
    const dataUrl = ApiUrls.base + "/api/v1/category/id/" + categoryId;
    return axios.delete(dataUrl);
};
export const deleteItemById = (itemId: string): Promise<AxiosResponse<boolean>> => {
    const dataUrl = ApiUrls.base + "/api/v1/item/id/" + itemId;
    return axios.delete(dataUrl);
};


export const getItemById = (id: string): Promise<AxiosResponse<Item>> => {
    const dataUrl = ApiUrls.base + "/api/v1/item/id/" + id;
    return axios.get(dataUrl);
};

export const deleteAddOnCategory = (addOnCategoryId: string): Promise<AxiosResponse<boolean>> => {
    const dataUrl = ApiUrls.base + "/api/v1/addon-category/id/" + addOnCategoryId;
    return axios.delete(dataUrl);
};
export const getAddOnCategoryByID = (addOnCategoryId: string): Promise<AxiosResponse<AddOnCategory>> => {
    const dataUrl = ApiUrls.base + "/api/v1/addon-category/id/" + addOnCategoryId;
    return axios.get(dataUrl);
};
export const deleteAddOnItem = (addOnItemId: string): Promise<AxiosResponse<boolean>> => {
    const dataUrl = ApiUrls.base + "/api/v1/addon-item/id/" + addOnItemId;
    return axios.delete(dataUrl);
};
export const getAddOnItemById = (id: string): Promise<AxiosResponse<AddOnItem>> => {
    const dataUrl = ApiUrls.base + "/api/v1/addon-item/id/" + id;
    return axios.get(dataUrl);
};

export const saveAddOnItem = (req: AddOnItem): Promise<AxiosResponse<AddOnItem>> => {
    const dataUrl = ApiUrls.base + "/api/v1/addon-item";
    if (req.id?.length > 0)
        return axios.put(dataUrl, req);
    else
        return axios.post(dataUrl, req);
};

export const savePriceItem = (req: PriceMenu): Promise<AxiosResponse<Item>> => {
    const dataUrl = ApiUrls.base + "/api/v1/item";
    if (req.id?.length > 0)
        return axios.put(dataUrl, req);
    else
        return axios.post(dataUrl, req);
};


export const getItemList = (): Promise<AxiosResponse<Item[]>> => {
    const dataUrl = ApiUrls.base + "/api/v1/item";
    return axios.get(dataUrl);
};

export const savePriceMenu = (req: PriceMenu): Promise<AxiosResponse<PriceMenu>> => {
    const dataUrl = ApiUrls.base + "/api/v1/price-menu";
    if (req.id?.length > 0)
        return axios.put(dataUrl, req);
    else
        return axios.post(dataUrl, req);
};

export const getPriceMenuById = (id: string): Promise<AxiosResponse<PriceMenu>> => {
    const dataUrl = ApiUrls.base + "/api/v1/price-menu/id/" + id;
    return axios.get(dataUrl);
};

export const getPriceMenus = (): Promise<AxiosResponse<PriceMenu[]>> => {
    const dataUrl = ApiUrls.base + "/api/v1/price-menu";
    return axios.get(dataUrl);
};

export const deletePriceMenuById = (id: string): Promise<AxiosResponse<boolean>> => {
    const dataUrl = ApiUrls.base + "/api/v1/price-menu/id/" + id;
    return axios.delete(dataUrl);
};


export const getItemVariants = (): Promise<AxiosResponse<ItemVariantMain[]>> => {
    const dataUrl = ApiUrls.base + "/api/v1/item-variant";
    return axios.get(dataUrl);
};

export const getItemVariantById = (id: string): Promise<AxiosResponse<ItemVariantMain>> => {
    const dataUrl = ApiUrls.base + "/api/v1/item-variant/id/" + id;
    return axios.get(dataUrl);
};

// export const saveItemVariantById = (id: string): Promise<AxiosResponse<ItemVariantMain>> => {
//     const dataUrl = ApiUrls.base + "/api/v1/item-variant/id/"+id;
//     if (id?.length > 0)
//         return axios.put(dataUrl, id);
// };


export const deleteItemVariantById = (id: string): Promise<AxiosResponse<boolean>> => {
    const dataUrl = ApiUrls.base + "/api/v1/item-variant/id/" + id;
    return axios.delete(dataUrl);
};

export const saveItemVariant = (req: ItemVariantMain): Promise<AxiosResponse<ItemVariantMain>> => {
    const dataUrl = ApiUrls.base + "/api/v1/item-variant";
    if (req.id?.length > 0)
        return axios.put(dataUrl, req);
    else
        return axios.post(dataUrl, req);
};

export const getEmployeeList = (): Promise<AxiosResponse<Employee[]>> => {
    const dataUrl = ApiUrls.base + "/api/v1/employee"
    return axios.get(dataUrl);
};

export const getEmployeeById = (id: string): Promise<AxiosResponse<Employee>> => {
    const dataUrl = ApiUrls.base + "/api/v1/employee/id/" + id;

    return axios.get(dataUrl);
};

export const getEmployeeRole = (): Promise<AxiosResponse<Employee[]>> => {
    const dataUrl = ApiUrls.base + "/api/v1/employee/roles"

    return axios.get(dataUrl);
};

export const saveEmployee = (req: Employee): Promise<AxiosResponse<Employee>> => {
    const dataUrl = ApiUrls.base + "/api/v1/employee";
    if (req.id?.length > 0)
        return axios.put(dataUrl, req);
    else
        return axios.post(dataUrl, req);
};

export const deleteEmployee = (id: string): Promise<AxiosResponse<boolean>> => {
    const dataUrl = ApiUrls.base + "/api/v1/employee/id/" + id;
    return axios.delete(dataUrl);
};

export const getRefData = (name: string): Promise<AxiosResponse<RefData>> => {
    const dataUrl = ApiUrls.base + "/api/v1/ref-data?name=" + name;
    return axios.get(dataUrl);
};


export const getCustomerList = (): Promise<AxiosResponse<Customer[]>> => {
    const dataUrl = ApiUrls.base + "/api/v1/customer";
    return axios.get(dataUrl);
};

export const deleteCustomer = (id: string): Promise<AxiosResponse<boolean>> => {
    const dataUrl = ApiUrls.base + "/api/v1/customer/id/" + id;
    return axios.delete(dataUrl);
};

export const getCustomerById = (id: string): Promise<AxiosResponse<Customer>> => {
    const dataUrl = ApiUrls.base + "/api/v1/customer/id/" + id;
    return axios.get(dataUrl);
};

export const saveCustomer = (req: Customer): Promise<AxiosResponse<Customer>> => {
    const dataUrl = ApiUrls.base + "/api/v1/customer";
    if (req.id?.length > 0)
        return axios.put(dataUrl, req);
    else
        return axios.post(dataUrl, req);
};

export const getItemRecommends = (): Promise<AxiosResponse<{ recommendations: ItemRecommend[] }>> => {
    const dataUrl = ApiUrls.base + "/api/v1/item-recommend";
    return axios.get(dataUrl);
};


export const saveItemRecommend = (req: ItemRecommend): Promise<AxiosResponse<ItemRecommend>> => {
    const dataUrl = ApiUrls.base + "/api/v1/item-recommend";
    if (req.id?.length > 0)
        return axios.put(dataUrl, req);
    else
        return axios.post(dataUrl, req);
};
export const deleteItemRecommend = (id: string): Promise<AxiosResponse<boolean>> => {
    const dataUrl = ApiUrls.base + "/api/v1/item-recommend/id/" + id;
    return axios.delete(dataUrl);
};

export const getDineSections = (): Promise<AxiosResponse<DineLayoutSection[]>> => {
    const dataUrl = ApiUrls.base + "/api/v1/category/dine-layout";
    return axios.get(dataUrl);
};

export const saveDineTable = (req: DineTable): Promise<AxiosResponse<DineLayoutSection>> => {
    const dataUrl = ApiUrls.base + "/api/v1/category/dine-layout/" + req.sectionName;
    return axios.post(dataUrl, req);
};

export const deleteDineTableById = (sectionId: string, tableId: string): Promise<AxiosResponse<boolean>> => {
    const dataUrl = ApiUrls.base + "/api/v1/category/dine-layout/section-id/" + sectionId + '/table-id/' + tableId;
    return axios.delete(dataUrl);
};
export const getMerchant = (): Promise<AxiosResponse<Merchant>> => {
    const dataUrl = ApiUrls.base + "/api/v1/merchant/current";
    return axios.get(dataUrl);
};


export const getClientCred = (): Promise<AxiosResponse<{ clientApiKey: string, clientApiSecrete: string }>> => {
    const dataUrl = ApiUrls.base + "/api/v1/merchant/client-cred?appname=web-admin";
    return axios.get(dataUrl);
};



export const getListItems = () => async (dispatch: any) => {
    const onAceesFail = (err: any) => {
        console.log('err', err);
    };
    const onAceesSuccess = async (data: any) => {
        await dispatch(setPriceMenuDetails(data?.data))
    };

    await callApi(
        'GET',
        ApiUrls.base + "/api/v1/price-menu/store-view/v2?include-items=true",
        onAceesFail,
        onAceesSuccess,
    );
};

export const getTaxDetails = () => async (dispatch: any) => {
    try {
        const onAccessFail = () => { };

        const onAccessSuccess = (response: any) => {
            dispatch(setTaxDetails(response?.data));
        };
        await callApi('GET', ApiUrls.base + '/api/v1/tax', onAccessFail, onAccessSuccess);
    } catch (error) { }
};

export const placeOrder =
    (onSuccess: any, placOrderObj: any) =>
        async (dispatch: any) => {
            const onAceesFail = (err: any) => {
                if (err.response) {
                    console.error(err.response.data); // => the response payload
                }
            };
            const onAceesSuccess = (data: any) => {
                onSuccess(data.data);
            };

            await callApi(
                'POST',
                ApiUrls.base + '/api/v1/order/place',
                onAceesFail,
                onAceesSuccess,
                placOrderObj,
            );
        };
export const getProductById =
    (onSuccess: any, pId: any) =>
        async (dispatch: any) => {
            const onAceesFail = (err: any) => {
                if (err.response) {
                    console.error(err.response.data); // => the response payload
                }
            };
            const onAceesSuccess = (res: any) => {
                onSuccess(res);
            };

            await callApi(
                'GET',
                ApiUrls.base + '/api/v1/item/id/' + pId,
                onAceesFail,
                onAceesSuccess,
            );
        };


export const getDiscount = () => async (dispatch: any) => {
    const onAceesFail = (err: any) => {
        console.log(" ==== err===", err);
    };

    const onAceesSuccess = (data: any) => {

        dispatch(setDiscountData(data.data));
    };
    await callApi(
        'GET',
        ApiUrls.base + '/api/v1/discount',
        onAceesFail,
        onAceesSuccess,
    );
}

export const paymentWithCard =
    (onSuccess: any, placOrderObj: any) =>
        async (dispatch: any) => {
            const onAceesFail = (err: any) => {
                if (err.response) {
                    console.error(err.response.data); // => the response payload
                }
            };
            const onAceesSuccess = (data: any) => {
                onSuccess(data.data);
            };

            await callApi(
                'POST',
                ApiUrls.base + '/api/v1/mqtt/publish',
                onAceesFail,
                onAceesSuccess,
                placOrderObj,
            );
        };

export const checkStatusPaymentWithCard =
    (onSuccess: any, checkStatusValue: any) =>
        async (dispatch: any) => {
            dispatch(setPaymentStatusLoading(true))
            const onAceesFail = (err: any) => {
                dispatch(setPaymentStatusLoading(false))
                console.log(err);
            };
            const onAceesSuccess = (data: any) => {
                dispatch(setPaymentStatusLoading(false))
                console.log("checkStatusPaymentWithCard", data);
                if(data?.data?.message){
                    const messageObject = JSON.parse(data?.data?.message);
                    onSuccess(messageObject);
                }else {
                    alert('Try again!!')
                }
            };

            await callApi(
                'GET',
                ApiUrls.base + '/api/v1/mqtt/status?channelId=' +  checkStatusValue?.channelId +  '&epi=' + checkStatusValue?.epi,
                onAceesFail,
                onAceesSuccess,
            );
        };

