const ApiBaseUrl = process.env.REACT_APP_API_URL || '';
const IdentityBaseUrl = process.env.REACT_APP_IDENTITY_SERVICE_URL || '';

export const ApiUrls = {
    base: ApiBaseUrl,
    catalogEndpoint: ApiBaseUrl + 'api/v1/catalog/',
    restaurantEndpoint: ApiBaseUrl + 'api/v1/restaurant/',
    storeAdminEndpoint: ApiBaseUrl + 'api/v1/store-admin/',
    dataViewEndpoint: ApiBaseUrl + 'api/v1/data-view/',
    membershipEndpoint: ApiBaseUrl + 'api/v1/web-membership/',
    IdentityBaseUrl,
    doorDashEndpoint: ApiBaseUrl + 'api/door-dash/',
    doorDashMarketplaceEndpoint: process.env.REACT_APP_DOORDASH_MARKET_URL || ''
}