import React, { useState, useEffect, useCallback } from 'react';
import { Alert, Grid, LinearProgress } from '@mui/material';
import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { getEmployeeList, parseToApiErrorMessage } from 'src/services/apiService';
import Results from './Results';
import { Employee } from 'src/services/apiService/response-models';
import useRefMounted from 'src/hooks/useRefMounted';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import PageHeader from 'src/components/page-header';

function EmployeeList() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const isMountedRef = useRefMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { t }: { t: any } = useTranslation();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [employee, setEmployee] = useState<Employee[]>([]);
  const [hasDataLoadError, setHasDataLoadError] = useState(false)
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const fetchCustomerData = useCallback(async () => {
    try {
      setIsDataLoading(true);
      setHasDataLoadError(false);

      const response = await getEmployeeList();
      console.log(response.data);

      if (isMountedRef.current) {
        setEmployee(response.data);
      }
    } catch (err) {
      setHasDataLoadError(true);
      const msg = parseToApiErrorMessage(err, 'Failed to load customer data');
      enqueueSnackbar(t(msg), { variant: 'error' });
      console.error(err);
    }
    setIsDataLoading(false);
  }, [isMountedRef]);

  useEffect(() => {
    setIsDataLoading(true)
    fetchCustomerData();
  }, [fetchCustomerData]);

  const onDeleteSuccess = async () => {
    await fetchCustomerData();
  }
  return (
    <>
      <PageTitleWrapper>
        <PageHeader type="list" title="Employee Management" subtitle="All aspects related to the employee can be managed from this page" route={'/' + location.pathname.split('/')[1] + "/manage/employee/new"} buttonTitle="Create Employee"></PageHeader>
      </PageTitleWrapper>
      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          {!isDataLoading && hasDataLoadError && <Alert severity="error">Failed to load data</Alert>}
          {isDataLoading && <LinearProgress />}
          {employee && employee.length > 0 ? (
            <Results employee={employee} onDeleteSuccess={onDeleteSuccess} />
          ) : (
            <div>No employees available</div>
          )}

        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default EmployeeList;
