import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import wait from 'src/utils/wait';

import {
  styled,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  Zoom,
  Typography,
  Divider,
  TextField,
  CircularProgress,
  Switch,
  Avatar,
  Autocomplete,
  IconButton,
  Button
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useSnackbar } from 'notistack';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import { saveCustomer , getCustomerById, saveDineTable, parseToApiErrorMessage} from 'src/services/apiService';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEyeTwoTone';
import RectangleTwoToneIcon from '@mui/icons-material/RectangleTwoTone';
import SquareTwoToneIcon from '@mui/icons-material/SquareTwoTone';
import { DineLayoutSection, DineTable } from 'src/services/apiService/response-models';
import { TextField as FmTextFiled } from 'formik-mui';

const tableShapes=[{
  name:"round",
  displayName:'Round',
  icon:<PanoramaFishEyeIcon/>
},{
  name:"rectangle",
  displayName:'Rectangle',
  icon:<RectangleTwoToneIcon/>
},{
  name:"square",
  displayName:'Squre',
  icon:<SquareTwoToneIcon />
}];
 

export default function TableEditorDialog(props:{onClose:()=>void,onSave:(e:DineLayoutSection)=>void,sections:DineLayoutSection[],tableToEdit?:{sectionId:string,tableId:string}}){

    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [model, setModel] = useState<DineTable>(null);
    const [isSaveInProgress, setIsSaveInProgress] = useState(false);
    const isEdit=props.tableToEdit?.tableId?.length>0;
    const sectionNames=props.sections.map(o=>o.name);

    useEffect(()=>{
      if(isEdit){
       const sec = props.sections.find(o=>o.id==props.tableToEdit.sectionId);
       if(!sec){
          props.onClose();
          return;
       }

       const match = sec.tables.find(o=>o.id==props.tableToEdit.tableId);
       match.sectionName=sec.name;
     
        setModel({...match});
      }
    },[]);
  
  const onSave = async (updateModel: DineTable) => {
    let isSuccess = false;
    try {

      setIsSaveInProgress(true);
      updateModel.id=model?.id;
      const res = await saveDineTable(updateModel);
      enqueueSnackbar(
        t(`Table was ${!isEdit ? 'created' : 'updated'} successfully`),
        { variant: 'success' }
      );
      isSuccess = true;
      setIsSaveInProgress(false);
      props.onSave(res.data);
      return true;
    } catch (ex) {
      setIsSaveInProgress(false);
      console.log(ex);
      const msg = parseToApiErrorMessage(
        ex,
        `Failed to ${!isEdit ? 'created' : 'updated'} table`
      );
      enqueueSnackbar(t(msg), { variant: 'error' });
      return false;
    }
  };
 
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick")   return; 
   props.onClose();
};

 

    return (<Dialog
        open={true}
      onClose={handleClose}
      disableEscapeKeyDown
      >
        <DialogTitle
          sx={{
            p: 3
          }}
        >
          <Typography variant="h4" gutterBottom>
            {isEdit?t('Edit table') : t('Add new table')}
          </Typography>
          <Typography variant="subtitle2">
            {t(
              'Fill in the fields below to create and add a new table to the section'
            )}
          </Typography>
        </DialogTitle>
        <Formik
         enableReinitialize={true}
          initialValues={{
            sectionName: model?.sectionName || '',
            tableName:model?.tableName || '',
            tableShape:model?.tableShape || tableShapes[0].name,
            capacity:model?.capacity,
            isActive:model?.isActive===false?false:true,
          }}
          validationSchema={Yup.object().shape({
            // username: Yup.string()
            //   .max(255)
            //   .required(t('The username field is required')),
            sectionName: Yup.string()
              .max(255)
              .required(t('The section name field is required')),
          tableName: Yup.string()
              .max(255)
              .required(t('The table name field is required')),
            tableShape: Yup.string()
              .max(255)
              .required(t('The table shape field is required')),
              capacity: Yup.number()
              .max(999).min(1)
              .required(t('The table shape field is required')),
          })}
          onSubmit={async (_values, { resetForm, setErrors, setStatus, setSubmitting }) => {
            try {
              var isSuccess = await onSave({..._values});
              if (isSuccess) {
                resetForm();
                setStatus({ success: true });
              }
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
            }
            setSubmitting(false);
          }}
          
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent
                dividers
                sx={{
                  p: 3
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} >
                    <Grid container spacing={3}>
                    <Grid item xs={12}>
                    <Autocomplete
                                    fullWidth
                                    blurOnSelect={true}
                                    filterSelectedOptions
                                    freeSolo
                                    autoSelect
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    value={values.sectionName}
                                    options={sectionNames}
                                    onChange={(event: any, newValue) => {
                                          setFieldValue('sectionName',newValue || '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Section name..."
                                            fullWidth
                                            helperText={errors.sectionName?errors.sectionName: "Select or enter section name"}
                                            error={errors.sectionName?true:false}
                                        />
                                    )}
                                />
                 
                      </Grid>
                      <Grid item xs={12}>
                          <Field
                              fullWidth
                              name="tableName"
                              component={FmTextFiled}
                              label="Table name *"
                              placeholder={t('enter table name here...')}
                              helperText={errors.tableName}
                          />
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <Autocomplete
                          options={tableShapes}
                          value={tableShapes.find(o=>o.name==model?.tableShape)}
                          autoHighlight
                          getOptionLabel={(option:any) => option.displayName}
                          onChange={(event: any, newValue) => {
                            setFieldValue('tableShape',newValue?.name || '');
                      }}
                    
                          renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                             {option.icon}&nbsp;&nbsp;
                              {option.displayName}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Choose shape"
                              inputProps={{
                                ...params.inputProps,
                              }}
                              helperText={errors.tableShape || ''}
                              error={errors.tableShape?true:false}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}  md={6}>
                      <Field
                              fullWidth
                              name="capacity"
                              component={FmTextFiled}
                              label={t('Table capacity')}
                              type="number"
                              placeholder={t('enter number of people can be seated')}
                              helperText={errors.capacity || 'Number of people can be seated'}
                          />
                      </Grid>
                    </Grid>
                  </Grid>
                
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  p: 3
                }}
              >
                <Button color="secondary" onClick={props.onClose}   disabled={Boolean(isSaveInProgress) || isSubmitting}>
                  {t('Cancel')}
                </Button>
                <Button
                  type="submit"
                  startIcon={
                    isSubmitting || isSaveInProgress ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={Boolean(isSaveInProgress) || isSubmitting}
                  variant="contained"
                >
                  {t('Save')}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>)
}