import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { areArraysEqual, calculateCardTotalPrice, calculateTotalAddOnCardPrice, calculateTotalAddOnCashPrice, calculateTotalCardPrice, calculateTotalCashPrice, calculateTotalPrice } from 'src/utils';


interface MenuItem {
  id: string;
  uuid: string;
  cartVariant: any;
  cartAddOn: any[];
  cartVariantId: string;
  selectedAddOn: any[];
  notes: string;
  quantity: number;
  itemCashTotal: number;
  itemCardTotal: number;
  totalAddOnCashPrice: number;
  totalAddOnCardPrice: number;
  totalCashPrice: number;
  totalCardPrice: number;
  hasVariants: any[];
  qtyTxt: number;
  addOns: any[];
  orderNumber: number;
}

export interface State {
  priceMenuDetails: any[];
  activeCat: string,
  cartData: MenuItem[];
  taxDetails: any[];
  searchText: string;
  discount: any[];
  paymentStatusLoading: boolean;
}

const initialState: State = {
  priceMenuDetails: [],
  activeCat: '',
  cartData: [],
  taxDetails: [],
  searchText: '',
  discount: [],
  paymentStatusLoading: false,

};

const slice = createSlice({
  name: 'menu-item',
  initialState,
  reducers: {
    setPriceMenuDetails(state: State, action: PayloadAction<any[]>) {
      state.priceMenuDetails = action.payload;
    },
    setActiveCategory(state: State, action: PayloadAction<string>) {
      state.activeCat = action.payload;
    },
    setAddToCartItem(state: State, action: PayloadAction<MenuItem>) {
      if (isEmpty(state?.cartData)) {
        let totalPrice = calculateTotalPrice(action?.payload, 1);
        let itemtotalCardPrice = calculateCardTotalPrice(action?.payload, 1);
        let addOnCashPrice = calculateTotalAddOnCashPrice(action?.payload, 1);
        let addOnCardPrice = calculateTotalAddOnCardPrice(action?.payload, 1);
        let totalCashPrice = calculateTotalCashPrice(action?.payload, 1);
        let totalCardPrice = calculateTotalCardPrice(action?.payload, 1);

        let cartPayload = {
          ...action?.payload,
          quantity: 1,
          itemCashTotal: totalPrice,
          itemCardTotal: itemtotalCardPrice,
          totalAddOnCashPrice: addOnCashPrice,
          totalAddOnCardPrice: addOnCardPrice,
          totalCashPrice: totalCashPrice,
          totalCardPrice: totalCardPrice,
        };
        return { ...state, cartData: [cartPayload] };
      } else {
        const findExistingItem = state.cartData?.filter((el: any) => {
          // If hasVariants is true and addOns length is greater than 0, check all conditions
          if (
            action?.payload?.hasVariants &&
            action?.payload?.addOns?.length > 0
          ) {
            return (
              el?.id === action?.payload?.id &&
              el?.cartVariant?.name === action?.payload?.cartVariant?.name &&
              el?.cartVariantId === action?.payload?.cartVariantId &&
              el?.notes === action?.payload?.notes &&
              areArraysEqual(el?.selectedAddOn, action?.payload?.selectedAddOn)
            );
          } else if (
            action?.payload?.hasVariants &&
            action?.payload?.addOns?.length === 0
          ) {
            return (
              el?.id === action?.payload?.id &&
              el?.cartVariant?.name === action?.payload?.cartVariant?.name &&
              el?.cartVariantId === action?.payload?.cartVariantId &&
              el?.notes === action?.payload?.notes
            );
          } else {
            // If hasVariants is false or addOns length is 0, check only one condition
            return (
              el?.id === action?.payload?.id &&
              el?.notes === action?.payload?.notes
            );
          }
        });
        if (isEmpty(findExistingItem)) {
          let totalPrice = calculateTotalPrice(action?.payload, 1);
          let itemtotalCardPrice = calculateCardTotalPrice(action?.payload, 1);
          let addOnCashPrice = calculateTotalAddOnCashPrice(action?.payload, 1);
          let addOnCardPrice = calculateTotalAddOnCardPrice(action?.payload, 1);
          let totalCashPrice = calculateTotalCashPrice(action?.payload, 1);
          let totalCardPrice = calculateTotalCardPrice(action?.payload, 1);
          let updatedCartPayload = {
            ...action?.payload,
            cartVariant: action?.payload?.cartVariant,
            cartAddOn: action?.payload?.cartAddOn,
            cartVariantId: action?.payload?.cartVariantId,
            selectedAddOn: action?.payload?.selectedAddOn,
            notes: action?.payload?.notes,
            quantity: 1,
            itemCashTotal: totalPrice,
            itemCardTotal: itemtotalCardPrice,
            totalAddOnCashPrice: addOnCashPrice,
            totalAddOnCardPrice: addOnCardPrice,
            totalCashPrice: totalCashPrice,
            totalCardPrice: totalCardPrice,
          };

          return { ...state, cartData: [...state?.cartData, updatedCartPayload] };
        } else {
          const updatedCartItems = state?.cartData?.map((item: any) => {
            if (
              action?.payload?.hasVariants &&
              action?.payload?.addOns?.length > 0
            ) {
              if (
                item?.id === action?.payload?.id &&
                item?.cartVariant?.name ===
                action?.payload?.cartVariant?.name &&
                item?.cartVariantId === action?.payload?.cartVariantId &&
                item?.notes === action?.payload?.notes &&
                areArraysEqual(
                  item?.selectedAddOn,
                  action?.payload?.selectedAddOn,
                )
              ) {
                let totalPrice = calculateTotalPrice(
                  action?.payload,
                  item.quantity + 1,
                );
                let itemtotalCardPrice = calculateCardTotalPrice(
                  action?.payload,
                  item.quantity + 1,
                );
                let addOnCashPrice = calculateTotalAddOnCashPrice(
                  action?.payload,
                  item.quantity + 1,
                );
                let addOnCardPrice = calculateTotalAddOnCardPrice(
                  action?.payload,
                  item.quantity + 1,
                );
                let totalCashPrice = calculateTotalCashPrice(
                  action?.payload,
                  item.quantity + 1,
                );
                let totalCardPrice = calculateTotalCardPrice(
                  action?.payload,
                  item.quantity + 1,
                );
                return {
                  ...item,
                  cartVariant: action?.payload?.cartVariant,
                  cartAddOn: action?.payload?.cartAddOn,
                  cartVariantId: action?.payload?.cartVariantId,
                  selectedAddOn: action?.payload?.selectedAddOn,
                  quantity: item.quantity + 1,
                  itemCashTotal: totalPrice,
                  itemCardTotal: itemtotalCardPrice,
                  totalAddOnCashPrice: addOnCashPrice,
                  totalAddOnCardPrice: addOnCardPrice,
                  totalCashPrice: totalCashPrice,
                  totalCardPrice: totalCardPrice,
                };
              } else {
                return item;
              }
            } else if (
              action?.payload?.hasVariants &&
              action?.payload?.addOns?.length === 0
            ) {
              if (
                item.id === action?.payload?.id &&
                item?.cartVariant?.name ===
                action?.payload?.cartVariant?.name &&
                item?.cartVariantId === action?.payload?.cartVariantId &&
                item?.notes === action?.payload?.notes
              ) {
                let totalPrice = calculateTotalPrice(
                  action?.payload,
                  item.quantity + 1,
                );
                let itemtotalCardPrice = calculateCardTotalPrice(
                  action?.payload,
                  item.quantity + 1,
                );
                let totalCashPrice = calculateTotalCashPrice(
                  action?.payload,
                  item.quantity + 1,
                );
                let totalCardPrice = calculateTotalCardPrice(
                  action?.payload,
                  item.quantity + 1,
                );
                return {
                  ...item,
                  cartVariant: action?.payload?.cartVariant,
                  cartAddOn: action?.payload?.cartAddOn,
                  cartVariantId: action?.payload?.cartVariantId,
                  selectedAddOn: action?.payload?.selectedAddOn,
                  quantity: item.quantity + 1,
                  itemCashTotal: totalPrice,
                  itemCardTotal: itemtotalCardPrice,
                  totalCashPrice: totalCashPrice,
                  totalCardPrice: totalCardPrice,
                };
              } else {
                return item;
              }
            } else {
              if (
                item?.id === action?.payload?.id &&
                item?.notes === action?.payload?.notes
              ) {
                let totalPrice = calculateTotalPrice(
                  action?.payload,
                  item.quantity + 1,
                );
                let itemtotalCardPrice = calculateCardTotalPrice(
                  action?.payload,
                  item.quantity + 1,
                );
                let addOnCashPrice = calculateTotalAddOnCashPrice(
                  action?.payload,
                  item.quantity + 1,
                );
                let addOnCardPrice = calculateTotalAddOnCardPrice(
                  action?.payload,
                  item.quantity + 1,
                );
                let totalCashPrice = calculateTotalCashPrice(
                  action?.payload,
                  item.quantity + 1,
                );
                let totalCardPrice = calculateTotalCardPrice(
                  action?.payload,
                  item.quantity + 1,
                );
                return {
                  ...item,
                  cartVariant: action?.payload?.cartVariant,
                  cartAddOn: action?.payload?.cartAddOn,
                  cartVariantId: action?.payload?.cartVariantId,
                  selectedAddOn: action?.payload?.selectedAddOn,
                  quantity: item.quantity + 1,
                  itemCashTotal: totalPrice,
                  itemCardTotal: itemtotalCardPrice,
                  totalAddOnCashPrice: addOnCashPrice,
                  totalAddOnCardPrice: addOnCardPrice,
                  totalCashPrice: totalCashPrice,
                  totalCardPrice: totalCardPrice,
                };
              } else {
                return item;
              }
            }
          });

          return { ...state, cartData: updatedCartItems };
        }
      }
    },
    updateItemFromCart(state: State, action: PayloadAction<MenuItem>) {
      if (isEmpty(state?.cartData)) {
        let totalPrice = calculateTotalPrice(
          action?.payload,
          action?.payload?.quantity,
        );
        let itemtotalCardPrice = calculateCardTotalPrice(
          action?.payload,
          action?.payload?.quantity,
        );
        let addOnCashPrice = calculateTotalAddOnCashPrice(
          action?.payload,
          action?.payload?.quantity,
        );
        let addOnCardPrice = calculateTotalAddOnCardPrice(
          action?.payload,
          action?.payload?.quantity,
        );
        let totalCashPrice = calculateTotalCashPrice(
          action?.payload,
          action?.payload?.quantity,
        );
        let totalCardPrice = calculateTotalCardPrice(
          action?.payload,
          action?.payload?.quantity,
        );

        let cartPayload = {
          ...action?.payload,
          quantity: action?.payload?.quantity,
          itemCashTotal: totalPrice,
          itemCardTotal: itemtotalCardPrice,
          totalAddOnCashPrice: addOnCashPrice,
          totalAddOnCardPrice: addOnCardPrice,
          totalCashPrice: totalCashPrice,
          totalCardPrice: totalCardPrice,
          notes: action?.payload?.notes,
          orderNumber: action?.payload?.orderNumber,
        };

        return {...state, cartData: [cartPayload]};
      } else {
        const findExistingItem = state.cartData?.filter((el: any) => {
          return el?.uuid === action?.payload?.uuid;
        });
        if (isEmpty(findExistingItem)) {
          let totalPrice = calculateTotalPrice(
            action?.payload,
            action?.payload?.quantity,
          );
          let itemtotalCardPrice = calculateCardTotalPrice(
            action?.payload,
            action?.payload?.quantity,
          );
          let addOnCashPrice = calculateTotalAddOnCashPrice(
            action?.payload,
            action?.payload?.quantity,
          );
          let addOnCardPrice = calculateTotalAddOnCardPrice(
            action?.payload,
            action?.payload?.quantity,
          );
          let totalCashPrice = calculateTotalCashPrice(
            action?.payload,
            action?.payload?.quantity,
          );
          let totalCardPrice = calculateTotalCardPrice(
            action?.payload,
            action?.payload?.quantity,
          );
          let updatedCartPayload = {
            ...action?.payload,
            cartVariant: action?.payload?.cartVariant,
            cartAddOn: action?.payload?.cartAddOn,
            cartVariantId: action?.payload?.cartVariantId,
            selectedAddOn: action?.payload?.selectedAddOn,
            notes: action?.payload?.notes,
            quantity: action?.payload?.quantity,
            itemCashTotal: totalPrice,
            itemCardTotal: itemtotalCardPrice,
            totalAddOnCashPrice: addOnCashPrice,
            totalAddOnCardPrice: addOnCardPrice,
            totalCashPrice: totalCashPrice,
            totalCardPrice: totalCardPrice,
            orderNumber: action?.payload?.orderNumber,
          };

          return {...state, cartData: [...state?.cartData, updatedCartPayload]};
        } else {
          const updatedCartItems = state?.cartData?.map((item: any) => {
            if (item?.uuid === action?.payload?.uuid) {
              let totalPrice = calculateTotalPrice(
                action?.payload,
                action?.payload?.quantity,
              );
              let itemtotalCardPrice = calculateCardTotalPrice(
                action?.payload,
                action?.payload?.quantity,
              );
              let addOnCashPrice = calculateTotalAddOnCashPrice(
                action?.payload,
                action?.payload?.quantity,
              );
              let addOnCardPrice = calculateTotalAddOnCardPrice(
                action?.payload,
                action?.payload?.quantity,
              );
              let totalCashPrice = calculateTotalCashPrice(
                action?.payload,
                action?.payload?.quantity,
              );
              let totalCardPrice = calculateTotalCardPrice(
                action?.payload,
                action?.payload?.quantity,
              );
              return {
                ...item,
                cartVariant: action?.payload?.cartVariant,
                cartAddOn: action?.payload?.cartAddOn,
                cartVariantId: action?.payload?.cartVariantId,
                selectedAddOn: action?.payload?.selectedAddOn,
                quantity: action?.payload?.quantity,
                itemCashTotal: totalPrice,
                itemCardTotal: itemtotalCardPrice,
                totalAddOnCashPrice: addOnCashPrice,
                totalAddOnCardPrice: addOnCardPrice,
                totalCashPrice: totalCashPrice,
                totalCardPrice: totalCardPrice,
                notes: action?.payload?.notes,
                orderNumber: action?.payload?.orderNumber,
              };
            } else {
              return {...item, orderNumber: action?.payload?.orderNumber};
            }
          });

          return {...state, cartData: updatedCartItems};
        }
      }
    },
    deleteItemFromCart(state: State, action: PayloadAction<MenuItem>) {
      const cartDataArr = current(state?.cartData)
      const findExistingArr = cartDataArr?.filter((el: any) => {
        // If hasVariants is true and addOns length is greater than 0, check all conditions
        console.log("el", el);
        console.log("action", action.payload);
        console.log("111111111",
          el?.id !== action?.payload?.id,
          el?.uuid !== action?.payload?.uuid,
          el?.cartVariant?.name !== action?.payload?.cartVariant?.name,
          el?.cartVariantId !== action?.payload?.cartVariantId,
          el?.notes !== action?.payload?.notes,
          !areArraysEqual(el?.selectedAddOn, action?.payload?.selectedAddOn)
        );

        if (
          action?.payload?.hasVariants &&
          action?.payload?.addOns?.length > 0
        ) {
          return (
            el?.id !== action?.payload?.id ||
            el?.uuid !== action?.payload?.uuid ||
            el?.cartVariant?.name !== action?.payload?.cartVariant?.name ||
            el?.cartVariantId !== action?.payload?.cartVariantId ||
            el?.notes !== action?.payload?.notes ||
            !areArraysEqual(el?.selectedAddOn, action?.payload?.selectedAddOn)
          );
        }
        else if (
          action?.payload?.hasVariants &&
          action?.payload?.addOns?.length === 0
        ) {
          console.log("2222222", action?.payload?.hasVariants &&
            action?.payload?.addOns?.length === 0);

          // If hasVariants is false or addOns length is 0, check only one condition
          return (
            el?.id !== action?.payload?.id ||
            el?.uuid !== action?.payload?.uuid ||
            el?.cartVariant?.name !== action?.payload?.cartVariant?.name ||
            el?.cartVariantId !== action?.payload?.cartVariantId ||
            el?.cartVariant.name !== action?.payload?.cartVariant.name ||
            el?.cartVariantId !== action?.payload?.cartVariantId ||
            el?.notes !== action?.payload?.notes
          );
        } else {
          return (
            el?.id !== action?.payload?.id ||
            el?.uuid !== action?.payload?.uuid ||
            el?.notes !== action?.payload?.notes
          );
        }
      });
      console.log("findExistingArr", findExistingArr);

      return { ...state, cartData: findExistingArr };
    },
    inCreaseItemQuantity(state: State, action: PayloadAction<MenuItem>) {
      const findExistingItem = state.cartData.filter((el: any) => {
        if (el?.hasVariants && el?.addOns?.length > 0) {
          return (
            el?.id === action?.payload?.id &&
            el?.uuid === action?.payload?.uuid &&
            el?.cartVariant?.name === action?.payload?.cartVariant?.name &&
            el?.cartVariantId === action?.payload?.cartVariantId &&
            el?.notes === action?.payload?.notes &&
            areArraysEqual(el?.selectedAddOn, action?.payload?.selectedAddOn)
          );
        } else if (
          action?.payload?.hasVariants &&
          action?.payload?.addOns?.length === 0
        ) {
          // If hasVariants is false or addOns length is 0, check only one condition
          return (
            el?.id === action?.payload?.id &&
            el?.uuid === action?.payload?.uuid &&
            el?.cartVariant?.name === action?.payload?.cartVariant?.name &&
            el?.cartVariantId === action?.payload?.cartVariantId &&
            el?.notes === action?.payload?.notes
          );
        } else {
          return (
            el?.id === action?.payload?.id &&
            el?.uuid === action?.payload?.uuid &&
            el?.notes === action?.payload?.notes
          );
        }
      });

      if (isEmpty(findExistingItem)) {
        let totalPrice = calculateTotalPrice(
          action?.payload,
          action?.payload?.qtyTxt ? action?.payload?.qtyTxt : 1,
        );
        let itemtotalCardPrice = calculateCardTotalPrice(action?.payload, 1);
        let addOnCashPrice = calculateTotalAddOnCashPrice(action?.payload, 1);
        let addOnCardPrice = calculateTotalAddOnCardPrice(action?.payload, 1);
        let totalCashPrice = calculateTotalCashPrice(action?.payload, 1);
        let totalCardPrice = calculateTotalCardPrice(action?.payload, 1);
        let updatedCartPayload = {
          ...action?.payload,
          cartVariant: action?.payload?.cartVariant,
          cartAddOn: action?.payload?.cartAddOn,
          cartVariantId: action?.payload?.cartVariantId,
          selectedAddOn: action?.payload?.selectedAddOn,
          quantity: action?.payload?.qtyTxt ? action?.payload?.qtyTxt : 1,
          itemCashTotal: totalPrice,
          itemCardTotal: itemtotalCardPrice,
          totalAddOnCashPrice: addOnCashPrice,
          totalAddOnCardPrice: addOnCardPrice,
          totalCashPrice: totalCashPrice,
          totalCardPrice: totalCardPrice,
        };

        return { ...state, cartData: [...state?.cartData, updatedCartPayload] };
      } else {
        const updatedCartItems = state?.cartData?.map((item: any) => {
          if (item?.hasVariants && item?.addOns?.length > 0) {
            if (
              item.id === action?.payload?.id &&
              item?.uuid === action?.payload?.uuid &&
              item?.cartVariant?.name === action?.payload?.cartVariant?.name &&
              item?.cartVariantId === action?.payload?.cartVariantId &&
              item?.notes === action?.payload?.notes &&
              areArraysEqual(
                item?.selectedAddOn,
                action?.payload?.selectedAddOn,
              )
            ) {
              let totalPrice = calculateTotalPrice(
                action?.payload,
                action?.payload?.qtyTxt
                  ? action?.payload?.qtyTxt
                  : parseInt(item.quantity) + 1,
              );
              let itemtotalCardPrice = calculateCardTotalPrice(
                action?.payload,
                action?.payload?.qtyTxt
                  ? action?.payload?.qtyTxt
                  : parseInt(item.quantity) + 1,
              );
              let addOnCashPrice = calculateTotalAddOnCashPrice(
                action?.payload,
                action?.payload?.qtyTxt
                  ? action?.payload?.qtyTxt
                  : parseInt(item.quantity) + 1,
              );
              let addOnCardPrice = calculateTotalAddOnCardPrice(
                action?.payload,
                action?.payload?.qtyTxt
                  ? action?.payload?.qtyTxt
                  : parseInt(item.quantity) + 1,
              );
              let totalCashPrice = calculateTotalCashPrice(
                action?.payload,
                action?.payload?.qtyTxt
                  ? action?.payload?.qtyTxt
                  : parseInt(item.quantity) + 1,
              );
              let totalCardPrice = calculateTotalCardPrice(
                action?.payload,
                action?.payload?.qtyTxt
                  ? action?.payload?.qtyTxt
                  : parseInt(item.quantity) + 1,
              );
              return {
                ...item,
                cartVariant: action?.payload?.cartVariant,
                cartAddOn: action?.payload?.cartAddOn,
                cartVariantId: action?.payload?.cartVariantId,
                selectedAddOn: action?.payload?.selectedAddOn,
                quantity: action?.payload?.qtyTxt
                  ? action?.payload?.qtyTxt
                  : parseInt(item.quantity) + 1,
                itemCashTotal: totalPrice,
                itemCardTotal: itemtotalCardPrice,
                totalAddOnCashPrice: addOnCashPrice,
                totalAddOnCardPrice: addOnCardPrice,
                totalCashPrice: totalCashPrice,
                totalCardPrice: totalCardPrice,
              };
            } else {
              return item;
            }
          } else if (
            action?.payload?.hasVariants &&
            action?.payload?.addOns?.length === 0
          ) {
            if (
              item.id === action?.payload?.id &&
              item?.uuid === action?.payload?.uuid &&
              item?.cartVariant?.name === action?.payload?.cartVariant?.name &&
              item?.cartVariantId === action?.payload?.cartVariantId &&
              item?.notes === action?.payload?.notes
            ) {
              let totalPrice = calculateTotalPrice(
                action?.payload,
                action?.payload?.qtyTxt
                  ? action?.payload?.qtyTxt
                  : parseInt(item.quantity) + 1,
              );
              let itemtotalCardPrice = calculateCardTotalPrice(
                action?.payload,
                action?.payload?.qtyTxt
                  ? action?.payload?.qtyTxt
                  : parseInt(item.quantity) + 1,
              );
              let totalCashPrice = calculateTotalCashPrice(
                action?.payload,
                action?.payload?.qtyTxt
                  ? action?.payload?.qtyTxt
                  : parseInt(item.quantity) + 1,
              );
              let totalCardPrice = calculateTotalCardPrice(
                action?.payload,
                action?.payload?.qtyTxt
                  ? action?.payload?.qtyTxt
                  : parseInt(item.quantity) + 1,
              );
              return {
                ...item,
                cartVariant: action?.payload?.cartVariant,
                cartAddOn: action?.payload?.cartAddOn,
                cartVariantId: action?.payload?.cartVariantId,
                selectedAddOn: action?.payload?.selectedAddOn,
                quantity: action?.payload?.qtyTxt
                  ? action?.payload?.qtyTxt
                  : parseInt(item.quantity) + 1,
                itemCashTotal: totalPrice,
                itemCardTotal: itemtotalCardPrice,
                totalCashPrice: totalCashPrice,
                totalCardPrice: totalCardPrice,
              };
            } else {
              return item;
            }
          } else {
            if (
              item?.id === action?.payload?.id &&
              item?.uuid === action?.payload?.uuid &&
              item?.notes === action?.payload?.notes
            ) {
              let totalPrice = calculateTotalPrice(
                action?.payload,
                action?.payload?.qtyTxt
                  ? action?.payload?.qtyTxt
                  : parseInt(item.quantity) + 1,
              );
              let itemtotalCardPrice = calculateCardTotalPrice(
                action?.payload,
                action?.payload?.qtyTxt
                  ? action?.payload?.qtyTxt
                  : parseInt(item.quantity) + 1,
              );
              let addOnCashPrice = calculateTotalAddOnCashPrice(
                action?.payload,
                action?.payload?.qtyTxt
                  ? action?.payload?.qtyTxt
                  : parseInt(item.quantity) + 1,
              );
              let addOnCardPrice = calculateTotalAddOnCardPrice(
                action?.payload,
                action?.payload?.qtyTxt
                  ? action?.payload?.qtyTxt
                  : parseInt(item.quantity) + 1,
              );
              let totalCashPrice = calculateTotalCashPrice(
                action?.payload,
                action?.payload?.qtyTxt
                  ? action?.payload?.qtyTxt
                  : parseInt(item.quantity) + 1,
              );
              let totalCardPrice = calculateTotalCardPrice(
                action?.payload,
                action?.payload?.qtyTxt
                  ? action?.payload?.qtyTxt
                  : parseInt(item.quantity) + 1,
              );
              return {
                ...item,
                cartVariant: action?.payload?.cartVariant,
                cartAddOn: action?.payload?.cartAddOn,
                cartVariantId: action?.payload?.cartVariantId,
                selectedAddOn: action?.payload?.selectedAddOn,
                quantity: action?.payload?.qtyTxt
                  ? action?.payload?.qtyTxt
                  : parseInt(item.quantity) + 1,
                itemCashTotal: totalPrice,
                itemCardTotal: itemtotalCardPrice,
                totalAddOnCashPrice: addOnCashPrice,
                totalAddOnCardPrice: addOnCardPrice,
                totalCashPrice: totalCashPrice,
                totalCardPrice: totalCardPrice,
              };
            } else {
              return item;
            }
          }
        });

        return { ...state, cartData: updatedCartItems };
      }
    },
    decreaseItemQuantity(state: State, action: PayloadAction<MenuItem>) {
      const findExisting = state.cartData?.filter((el: any) => {
        if (el?.hasVariants && el?.addOns?.length > 0) {
          return (
            el?.id === action?.payload?.id &&
            el?.uuid === action?.payload?.uuid &&
            el?.cartVariant?.name === action?.payload?.cartVariant?.name &&
            el?.cartVariantId === action?.payload?.cartVariantId &&
            el?.cartVariant.name === action?.payload?.cartVariant.name &&
            el?.cartVariantId === action?.payload?.cartVariantId &&
            el?.notes === action?.payload?.notes &&
            areArraysEqual(el?.selectedAddOn, action?.payload?.selectedAddOn)
          );
        } else if (
          action?.payload?.hasVariants &&
          action?.payload?.addOns?.length === 0
        ) {
          // If hasVariants is false or addOns length is 0, check only one condition
          return (
            el?.id === action?.payload?.id &&
            el?.cartVariant?.name === action?.payload?.cartVariant?.name &&
            el?.cartVariantId === action?.payload?.cartVariantId &&
            el?.notes === action?.payload?.notes
          );
        } else {
          return (
            el?.id === action?.payload?.id &&
            el?.notes === action?.payload?.notes
          );
        }
      });

      if (isEmpty(findExisting)) {
        let totalPrice = calculateTotalPrice(action?.payload, 1);
        let itemtotalCardPrice = calculateCardTotalPrice(action?.payload, 1);
        let addOnCashPrice = calculateTotalAddOnCashPrice(action?.payload, 1);
        let addOnCardPrice = calculateTotalAddOnCardPrice(action?.payload, 1);
        let totalCashPrice = calculateTotalCashPrice(action?.payload, 1);
        let totalCardPrice = calculateTotalCardPrice(action?.payload, 1);
        let updatedCartPayload = {
          ...action?.payload,
          cartVariant: action?.payload?.cartVariant,
          cartAddOn: action?.payload?.cartAddOn,
          cartVariantId: action?.payload?.cartVariantId,
          selectedAddOn: action?.payload?.selectedAddOn,
          quantity: 1,
          itemCashTotal: totalPrice,
          itemCardTotal: itemtotalCardPrice,
          totalAddOnCashPrice: addOnCashPrice,
          totalAddOnCardPrice: addOnCardPrice,
          totalCashPrice: totalCashPrice,
          totalCardPrice: totalCardPrice,
        };

        return { ...state, cartData: [...state?.cartData, updatedCartPayload] };
      } else {
        const updatedCartItems = state?.cartData?.map((item: any) => {
          if (item?.hasVariants && item?.addOns?.length > 0) {
            if (
              item.id === action?.payload?.id &&
              item?.uuid === action?.payload?.uuid &&
              item?.cartVariant?.name === action?.payload?.cartVariant?.name &&
              item?.cartVariantId === action?.payload?.cartVariantId &&
              item?.notes === action?.payload?.notes &&
              areArraysEqual(
                item?.selectedAddOn,
                action?.payload?.selectedAddOn,
              )
            ) {
              let totalPrice = calculateTotalPrice(
                action?.payload,
                item.quantity - 1,
              );
              let itemtotalCardPrice = calculateCardTotalPrice(
                action?.payload,
                item.quantity - 1,
              );
              let addOnCashPrice = calculateTotalAddOnCashPrice(
                action?.payload,
                item.quantity - 1,
              );
              let addOnCardPrice = calculateTotalAddOnCardPrice(
                action?.payload,
                item.quantity - 1,
              );
              let totalCashPrice = calculateTotalCashPrice(
                action?.payload,
                item.quantity - 1,
              );
              let totalCardPrice = calculateTotalCardPrice(
                action?.payload,
                item.quantity - 1,
              );
              return {
                ...item,
                cartVariant: action?.payload?.cartVariant,
                cartAddOn: action?.payload?.cartAddOn,
                cartVariantId: action?.payload?.cartVariantId,
                selectedAddOn: action?.payload?.selectedAddOn,
                quantity: item?.quantity - 1,
                itemCashTotal: totalPrice,
                itemCardTotal: itemtotalCardPrice,
                totalAddOnCashPrice: addOnCashPrice,
                totalAddOnCardPrice: addOnCardPrice,
                totalCashPrice: totalCashPrice,
                totalCardPrice: totalCardPrice,
              };
            } else {
              return item;
            }
          } else if (
            action?.payload?.hasVariants &&
            action?.payload?.addOns?.length === 0
          ) {
            if (
              item.id === action?.payload?.id &&
              item?.uuid === action?.payload?.uuid &&
              item?.cartVariant?.name === action?.payload?.cartVariant?.name &&
              item?.cartVariantId === action?.payload?.cartVariantId &&
              item?.notes === action?.payload?.notes
            ) {
              let totalPrice = calculateTotalPrice(
                action?.payload,
                item.quantity - 1,
              );
              let itemtotalCardPrice = calculateCardTotalPrice(
                action?.payload,
                item.quantity - 1,
              );
              let totalCashPrice = calculateTotalCashPrice(
                action?.payload,
                item.quantity - 1,
              );
              let totalCardPrice = calculateTotalCardPrice(
                action?.payload,
                item.quantity - 1,
              );
              return {
                ...item,
                cartVariant: action?.payload?.cartVariant,
                cartAddOn: action?.payload?.cartAddOn,
                cartVariantId: action?.payload?.cartVariantId,
                selectedAddOn: action?.payload?.selectedAddOn,
                quantity: item?.quantity - 1,
                itemCashTotal: totalPrice,
                itemCardTotal: itemtotalCardPrice,
                totalCashPrice: totalCashPrice,
                totalCardPrice: totalCardPrice,
              };
            } else {
              return item;
            }
          } else {
            if (
              item?.id === action?.payload?.id &&
              item?.uuid === action?.payload?.uuid &&
              item?.notes === action?.payload?.notes
            ) {
              let totalPrice = calculateTotalPrice(
                action?.payload,
                item.quantity - 1,
              );
              let itemtotalCardPrice = calculateCardTotalPrice(
                action?.payload,
                item.quantity - 1,
              );
              let addOnCashPrice = calculateTotalAddOnCashPrice(
                action?.payload,
                item.quantity - 1,
              );
              let addOnCardPrice = calculateTotalAddOnCardPrice(
                action?.payload,
                item.quantity - 1,
              );
              let totalCashPrice = calculateTotalCashPrice(
                action?.payload,
                item.quantity - 1,
              );
              let totalCardPrice = calculateTotalCardPrice(
                action?.payload,
                item.quantity - 1,
              );
              return {
                ...item,
                cartVariant: action?.payload?.cartVariant,
                cartAddOn: action?.payload?.cartAddOn,
                cartVariantId: action?.payload?.cartVariantId,
                selectedAddOn: action?.payload?.selectedAddOn,
                quantity: item?.quantity - 1,
                itemCashTotal: totalPrice,
                itemCardTotal: itemtotalCardPrice,
                totalAddOnCashPrice: addOnCashPrice,
                totalAddOnCardPrice: addOnCardPrice,
                totalCashPrice: totalCashPrice,
                totalCardPrice: totalCardPrice,
              };
            } else {
              return item;
            }
          }
        });

        return { ...state, cartData: updatedCartItems };
      }
    },
    setTaxDetails(state: State, action: PayloadAction<any[]>) {
      state.taxDetails = action.payload;
    },
    setSearchValue(state: State, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
    setDiscountData(state: State, action: PayloadAction<any[]>) {
      state.discount = action.payload;
    },
    setPaymentStatusLoading(state: State, action: PayloadAction<boolean>) {
      state.paymentStatusLoading= action.payload;
    },
    emptyItemFromCart(state: State) {
      state.cartData = [];
    },
  }
});

const { actions, reducer } = slice;

export const { setPriceMenuDetails, setActiveCategory, setAddToCartItem, updateItemFromCart, deleteItemFromCart, setTaxDetails,setDiscountData, inCreaseItemQuantity, decreaseItemQuantity, setSearchValue, emptyItemFromCart, setPaymentStatusLoading } = actions;
export default reducer
