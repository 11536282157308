import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Grid, Typography, Button, styled } from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { useTranslation } from 'react-i18next';

const RootWrapper = styled(Box)(() => `
  flex: 1;
`);

type PageHeaderType={
  title:string;
  subtitle?:string;
  type:'create' | 'list',
  route?:string,
  buttonTitle?:string;
  showButton?:boolean;
}

function PageHeader({ title, subtitle, type, route, buttonTitle,showButton }:PageHeaderType) {
  const { t }: { t: any } = useTranslation();
  const isListHeader = type === 'list';

  return (
    <RootWrapper>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box display="flex" flexDirection="column">
            <Typography variant="h3" component="h3" gutterBottom>
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="subtitle2">
                {subtitle}
              </Typography>
            )}
          </Box>
        </Grid>
       {showButton!==false && <Grid item>
          {isListHeader ? (
            // Render list header button
            <Button
              sx={{
                mt: { xs: 2, sm: 0 },
              }}
              component={RouterLink}
              startIcon={<AddTwoToneIcon fontSize="small" />}
              to={route} // Replace with the actual list route
              variant="contained"
            >
              {buttonTitle ? buttonTitle : `Create ${title}`}
            </Button>
          ) : (
            // Render create header button
            <Button
              sx={{
                mt: { xs: 2, sm: 0 },
              }}
              component={RouterLink}
              startIcon={<ArrowBackTwoToneIcon fontSize="small" />}
              to={route} // Replace with the actual create route
              variant="contained"
            >
              {buttonTitle ? buttonTitle : `${title}`}
            </Button>
          )}
        </Grid>}
      </Grid>
    </RootWrapper>
  );
}

export default PageHeader;
