import { isEmpty } from 'lodash';

export const areArraysEqual = (arr1: any, arr2: any) => {
    if (arr1?.length !== arr2?.length) {
        return false;
    }

    return arr1?.every((obj1: any, index: any) => {
        const obj2 = arr2?.[index];

        return JSON.stringify(obj1) === JSON.stringify(obj2);
    });
};

export const calculateTotalPrice = (data: any, quantity: number) => {
    let totalPrice = 0;
    if (data?.hasVariants) {
      if (!isEmpty(data?.cartVariant)) {
        let temp1 = data?.cartVariant?.prices?.[0]?.price;
        totalPrice += quantity * temp1;
      } else {
        let temp1 = data?.cartVariant?.prices?.[0]?.price;
        totalPrice += quantity * temp1;
      }
  
      if (!isEmpty(data?.selectedAddOn) && !isEmpty(data?.addOns)) {
        data?.selectedAddOn?.forEach((selectedAddOnItem: any) => {
          data?.addOns?.forEach((addOnGroup: any) => {
            addOnGroup?.addOnItems?.forEach((addOnItem: any) => {
              if (addOnItem?.name === selectedAddOnItem?.name) {
                totalPrice += quantity * selectedAddOnItem?.addOnCashPrice;
              }
            });
          });
        });
      }
    } else {
      let temp2 = data?.prices?.[0]?.price;
      totalPrice += quantity * temp2;
    }
    return totalPrice;
  };
  
  export const calculateCardTotalPrice = (data: any, quantity: number) => {
    let totalPrice = 0;
    if (data?.hasVariants) {
      if (!isEmpty(data?.cartVariant)) {
        let temp1 = data?.cartVariant?.prices?.[1]?.price;
        totalPrice += quantity * temp1;
      } else {
        let temp1 = data?.cartVariant?.prices?.[1]?.price;
        totalPrice += quantity * temp1;
      }
  
      if (!isEmpty(data?.selectedAddOn) && !isEmpty(data?.addOns)) {
        data?.selectedAddOn?.forEach((selectedAddOnItem: any) => {
          data?.addOns?.forEach((addOnGroup: any) => {
            addOnGroup?.addOnItems?.forEach((addOnItem: any) => {
              if (addOnItem?.name === selectedAddOnItem?.name) {
                totalPrice += quantity * selectedAddOnItem?.addOnCardPrice;
              }
            });
          });
        });
      }
    } else {
      let temp2 = data?.prices?.[1]?.price;
      totalPrice += quantity * temp2;
    }
    return totalPrice;
  };
  
  export const calculateTotalAddOnCashPrice = (data: any, quantity: number) => {
    let totalPrice = 0;
    if (data?.hasVariants) {
      if (!isEmpty(data?.selectedAddOn)) {
        data?.selectedAddOn?.forEach((selectedAddOnItem: any) => {
          totalPrice += quantity * selectedAddOnItem?.addOnCashPrice;
        });
      }
    }
    return totalPrice;
  };
  
  export const calculateTotalAddOnCardPrice = (data: any, quantity: number) => {
    let totalPrice = 0;
    if (data?.hasVariants) {
      if (!isEmpty(data?.selectedAddOn)) {
        data?.selectedAddOn?.forEach((selectedAddOnItem: any) => {
          totalPrice += quantity * selectedAddOnItem?.addOnCardPrice;
        });
      }
    }
    return totalPrice;
  };
  
  export const calculateTotalCashPrice = (data: any, quantity: number) => {
    let totalPrice = 0;
    if (data?.hasVariants) {
      if (!isEmpty(data?.cartVariant)) {
        let temp1 = data?.variants?.[0]?.prices?.[0]?.price;
        totalPrice += quantity * temp1;
      } else {
        let temp1 = data?.variants?.[0]?.prices?.[0]?.price;
        totalPrice += quantity * temp1;
      }
    } else {
      let temp2 = data?.prices?.[0]?.priceAfterDiscount;
      totalPrice += quantity * temp2;
    }
    return totalPrice;
  };
  
  export const calculateTotalCardPrice = (data: any, quantity: number) => {
    let totalPrice = 0;
    if (data?.hasVariants) {
      if (!isEmpty(data?.cartVariant)) {
        let temp1 = data?.variants?.[0]?.prices?.[1]?.price;
        totalPrice += quantity * temp1;
      } else {
        let temp1 = data?.variants?.[0]?.prices?.[1]?.price;
        totalPrice += quantity * temp1;
      }
    } else {
      let temp2 = data?.prices?.[0]?.priceAfterDiscount;
      totalPrice += quantity * temp2;
    }
    return totalPrice;
  };
