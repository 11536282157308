import { CardHeader, Card, CardContent, Box } from '@mui/material';
import ImageDropZone from 'src/components/image-dropzone';
import FeatureImageCard from '../../add-on-items/create-addon-item/FeatureImageCard';

// function Sidebar() {
//   return (
//     <ImageDropZone label='Profile image' onChange={()=>{}} />
//   );
// }
function Sidebar(props: { onFeatureImageChange: (files: File) => void, isSubmit: boolean, isEdit: boolean, uploadedFileName?:string,itemId?:string}) {

  return (
      <Box>
          <FeatureImageCard onChange={(file) => props.onFeatureImageChange(file)} isSubmit={props.isSubmit} uploadedFileName={props.uploadedFileName}/>
    </Box>
  );
}

export default Sidebar;
