import PageTitleWrapper from 'src/components/PageTitleWrapper';
// import PageHeader from './PageHeader';
import PageHeader from 'src/components/page-header';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  TextFieldProps,
  Typography
} from '@mui/material';
import EditorWrapper from 'src/components/StyledWrappers/EditorWrapper';
import { Field, FieldProps, Formik } from 'formik';
import ReactQuill from 'react-quill';
import SaveIcon from '@mui/icons-material/Save';
import { TextField as FmTextFiled, Switch } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { JSXElementConstructor, ReactElement, useEffect, useState } from 'react';
import {
  AddOnItem,
  Item,
  PriceMenu
} from 'src/services/apiService/response-models';
import * as Yup from 'yup';
import { getPriceMenuById, getPriceMenus, parseToApiErrorMessage, savePriceMenu } from 'src/services/apiService';
import ImageDropZone from 'src/components/image-dropzone';
import ItemListCard from './item-list-card';
import Footer from 'src/components/Footer';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
export default function PriceMenuCreate() {
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [model, setModel] = useState<PriceMenu | null>(null);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const { id } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  
  const getById = async (id: string) => {
    try {
        const response = await getPriceMenuById(id);
        setIsEdit(true);
        setModel(response.data);
    } catch (ex) {
        console.error(ex);
        const msg = ex.message ? ex.message : `Failed to load data by id`;
        enqueueSnackbar(t(msg), { variant: 'error' });
        setErrorMsg(msg);
    }
  }

    useEffect(() => {
      if (id?.length) {
          setIsLoading(true);
          getById(id).then(() => {
              setIsLoading(false);
          });
      }
  }, []);
  
  const onSave = async (updateModel: PriceMenu) => {
    let isSuccess = false;
    try {

      //trim items
      updateModel.items=updateModel.items.map(o=>{return {id:o.id} as any});
      setIsSaveInProgress(true);
      await savePriceMenu(updateModel);
      enqueueSnackbar(
        t(`Price menu was ${!isEdit ? 'created' : 'updated'} successfully`),
        { variant: 'success' }
      );
      isSuccess = true;
      goBack();
      setIsSaveInProgress(false);
      return true;
    } catch (ex) {
      setIsSaveInProgress(false);
      console.log(ex);
      const msg = parseToApiErrorMessage(
        ex,
        `Failed to ${!isEdit ? 'created' : 'updated'} Price menu`
      );
      enqueueSnackbar(t(msg), { variant: 'error' });
      return false;
    }
  };

  
  const goBack = async () => {
    navigate('/'+location.pathname.split('/')[1]+'/inventory/price-menu/list', { replace: true });
  };

  return (
    <>
      <PageTitleWrapper>
      <PageHeader type="create"  title="Price Menu" subtitle="Create or edit a price menu" route={'/'+location.pathname.split('/')[1]+"/inventory/price-menu/list"} buttonTitle="Go to all menus"></PageHeader>
        {/* <PageHeader /> */}
      </PageTitleWrapper>
      <Formik
        enableReinitialize={true}
        initialValues={{
          title: model?.title || '',
          description: model?.description || '',
          coverImage: model?.coverImage || '',
          availabilityType: model?.availabilityType || 'allday',
          availabilitySlot: {
            from:model?.availabilitySlot?.from,
            to:model?.availabilitySlot?.to
          },
          template: model?.template,
          items: model?.items || []
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string()
            .max(250)
            .required(t('The title field is required')),
          availabilityType: Yup.string().required(
            t('Availability type is required')
          ),
          availabilitySlot: Yup.object().when('availabilityType', {
            is: 'slot',
            then: Yup.object().test("slot-required", "Please enter slot timing", 
            function(value) {
               return (!value?.from || !value?.to)?false:true;
            }).test("slot-compare", '"Slot timing from" must be less than "to"', 
            function(value) {
                if(value?.from && value?.to && value.to<value.from){
                  return false;
                }
                return true;
            })
          }),
          // availabilitySlot: Yup.object().shape({
          //   from: Yup.date().when('availabilityType', {
          //     is: 'slot',
          //     then: Yup.date().required('From date is requuired')
          //   }),
          //   to: Yup.date().when('from', {
          //     is: (o) => (!!o ? true : false),
          //     then: Yup.date()
          //       .min(
          //         Yup.ref('from'),
          //         "To date can't be before from date"
          //       )
          //       .when('availabilityType', {
          //         is: 'slot',
          //         then: Yup.date().required('To date is requuired')
          //       })
          //   })
          // }),
          items: Yup.array()
            .min(1, 'Add at least 1 item')
            .required(t('Add at least 1 item'))
        })}
        onSubmit={async (
          _values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            var isSuccess = await onSave({id:model?.id,..._values});
            if (isSuccess) {
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            const msg = parseToApiErrorMessage(err, `Failed to save food item`);
            enqueueSnackbar(t(msg), { variant: 'error' });
            setStatus({ success: false });
            setSubmitting(false);
          }
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          errors,
          setFieldValue,
          touched
        }) => (
        
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={3}
              sx={{
                px: 4
              }}
            >
              <Grid item md={6}>
                <Card
                  sx={{
                    p: 3
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        name="title"
                        component={FmTextFiled}
                        label="Menu title *"
                        placeholder={t('Enter here...')}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field name="description">
                        {({ field, form, meta }: FieldProps) => (
                          <EditorWrapper>
                            <ReactQuill
                              placeholder="Menu Description"
                              value={field.value}
                              onChange={(o) =>
                                form.setFieldValue(field.name, o)
                              }
                            />
                          </EditorWrapper>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">Availibility</Typography>

                      <Field name="availabilityType">
                        {({ field, form }: FieldProps) => (
                          <ButtonGroup
                            variant="outlined"
                            aria-label="outlined button group"
                          >
                            <Button
                              onClick={() => {
                                form.setFieldValue(field.name, 'allday');
                              }}
                              variant={
                                values.availabilityType === 'allday'
                                  ? 'contained'
                                  : 'outlined'
                              }
                            >
                              All day
                            </Button>
                            <Button
                              onClick={() => {
                                form.setFieldValue(field.name, 'na');
                              }}
                              variant={
                                values.availabilityType  === 'na'
                                  ? 'contained'
                                  : 'outlined'
                              }
                            >
                              Not Available
                            </Button>
                            <Button
                              onClick={() => {
                                form.setFieldValue(field.name, 'slot');
                              }}
                              variant={
                                values.availabilityType === 'slot'
                                  ? 'contained'
                                  : 'outlined'
                              }
                            >
                              Slot time
                            </Button>
                          </ButtonGroup>
                        )}
                      </Field>
                     {Boolean(errors.availabilityType) &&  <Box><FormHelperText error={true}>{errors.availabilityType}</FormHelperText ></Box>}
                    </Grid>

                    {values.availabilityType=='slot' &&  <Grid item xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3} direction={'row'} mt={1}>
                          <TimePicker
                            label="Slot timing from:"
                            value={values.availabilitySlot?.from || null}
                            onChange={(e) => setFieldValue('availabilitySlot.from',e)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                          <TimePicker
                            label="to:"
                            value={values.availabilitySlot?.to || null}
                            onChange={(e) =>setFieldValue('availabilitySlot.to',e)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                      {Boolean(errors.availabilitySlot) &&  <Box><FormHelperText error={true}>{errors.availabilitySlot}</FormHelperText ></Box>}
                      {Boolean(errors.availabilitySlot?.to) &&  <Box><FormHelperText error={true}>{errors.availabilitySlot?.to}</FormHelperText ></Box>}
                    </Grid>}
                  </Grid>
                </Card>
              </Grid>
              <Grid item md={6} sx={{ pt: '0!important' }}>
                <Box>
                  <ImageDropZone
                    required={false}
                    uploadedFileName={values.coverImage}
                    label="Cover Image"
                    onChange={(e) => setFieldValue('coverImage', e)}
                    isSubmit={
                      errors.coverImage && touched.coverImage ? true : false
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <ItemListCard />
                {Boolean(errors.items) &&  <Box><FormHelperText error={true}>{errors.items}</FormHelperText ></Box>}
              </Grid>
              <Grid item xs={12}>
                <Button
                  sx={{ mr: 2 }}
                  type="button"
                  onClick={goBack}
                  variant="text"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={
                    isSubmitting || isSaveInProgress ? (
                      <CircularProgress size="1rem" />
                    ) : (
                      <SaveIcon />
                    )
                  }
                  disabled={
                    isSubmitting || isSaveInProgress || Boolean(errorMsg.length)
                  }
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <Footer />
    </>
  );
}
