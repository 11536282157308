import { MenuItems } from "./pos-items";
import GridOnTwoToneIcon from '@mui/icons-material/GridOnTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import TourTwoToneIcon from '@mui/icons-material/TourTwoTone';
import DiscountTwoToneIcon from '@mui/icons-material/DiscountTwoTone';
import { PortalNames } from "src/store/app-state";

const app=PortalNames.GolfCourse;

const GolfCourseMenuItems: MenuItems[] = [
    {
      heading: 'Manage',
      items: [
        {
          name: 'Tee Sheet',
          icon: GridOnTwoToneIcon,
          link: app + '/teesheets',
        },
        {
          name: 'Customers',
          icon: AssignmentIndTwoToneIcon,
          link: app + '/customers',
        },
        {
          name: 'Tour Operators',
          icon: TourTwoToneIcon,
          link: app + '/tour-operators',
        },
        {
          name: 'Promotions',
          icon: DiscountTwoToneIcon,
          link: app + '/discounts',
        }
      
      ]
    }
    
  ];
  
  export default GolfCourseMenuItems;
  