import {   useEffect, useState } from 'react';

import {
  ListItemText,
  Avatar,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Divider,
  ListItem,
  List,
  CardHeader,
  Alert,
  Card,
  styled,
  useTheme,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import FoodCategoryCard from './item-category-card';
import IngredientsCard from './ingredients-card';
import ImageDropZone from 'src/components/image-dropzone';
import { useField } from 'formik';
import { NameItem } from 'src/services/apiService/response-models';
import { GetAllCookingReferences } from 'src/services/apiService';


function Sidebar() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
    const [field, meta, helpers] = useField('featureImage');
  

    const getRefData = async () => {
        try {
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getRefData().then(o => {

        });
    }, [getRefData]);

    const onFeatureImageChange = (blobName: string): void => {
        helpers.setValue(blobName);
    }

  return (
      <Box>
          <ImageDropZone required={true} uploadedFileName={field.value} label="Feature Image" onChange={onFeatureImageChange} isSubmit={meta.error && meta.touched ?true:false}/>
          <FoodCategoryCard  />
          <IngredientsCard/>
    </Box>
  );
}

export default Sidebar;
