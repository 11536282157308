import { Fragment, useEffect, useState } from 'react';

import {
  Box,
  ListItemAvatar,
  ListItemText,
  Divider,
  List,
  Card,
  Button,
  Typography,
  Avatar,
  styled,
  ListItem,
  OutlinedInput,
  InputAdornment,
  FormControl,
  useTheme,
  Drawer,
  Grid,
  Badge,
  Tooltip
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import { Item, PriceMenu } from '../../../../services/apiService/response-models';
import { useTranslation } from 'react-i18next';
import RemoveTwoToneIcon from '@mui/icons-material/RemoveTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { getItemList} from 'src/services/apiService';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import cdnService from '../../../../services/cdnService';
import Text from 'src/components/Text';

import { closeSidebar, openSidebar } from 'src/store/slices/app';
import ItemPickerDrawer from './item-picker-drawer';
import { useDispatch, useSelector } from 'src/store';
import { useField } from 'formik';
import _ from 'lodash';

const AvatarGradient = styled(Avatar)(
  ({ theme }) => `
        background: ${theme.colors.gradients.green2};
        color: ${theme.colors.alpha.trueWhite[100]};
    `
);
 
const OutlinedInputWrapper = styled(OutlinedInput)(
  ({ theme }) => `
      background-color: ${theme.colors.alpha.white[100]};

      .MuiOutlinedInput-notchedOutline {
          border: 0;
      }
  `
);

const ListWrapper = styled(List)(
  () => `
      .MuiListItem-root:last-of-type + .MuiDivider-root {
          display: none;
      }
  `
);

function ItemListCard() {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [fieldItems, metaItems, helpersItems] = useField('items')
  const {   sidebarOpen  } = useSelector(
    (state) => state.app
  );
  const allItems:Item[]=fieldItems.value || [];
  const [filteredItems, setFilteredItems] = useState<Item[]>(fieldItems.value || []);
  const [searchValue, setSearchValue] = useState('');

  useEffect(()=>{
      applyFilter(searchValue);
  },[fieldItems.value]);

  useEffect(()=>{
    applyFilter(searchValue);
  },[searchValue]);
  
  const applyFilter=(e:string)=>{
    if(!e)
      setFilteredItems(allItems);
      else{
        const key=e.toString().trim().toLowerCase();
       
        const items = allItems.filter(o=>o.name.toLowerCase().includes(key) 
        || o.description?.toLowerCase().includes(key)
         || o.categories?.find(c=>c.name.toLowerCase().includes(key)));

         setFilteredItems(items);
      }
  }

  const removeItem=(id:string)=>{
    const items =  allItems.filter(o=>o.id!=id);
    helpersItems.setValue([...items]);
  }

  const removeAll=()=>{
    const items = _.differenceBy(allItems,filteredItems,'id');
    helpersItems.setValue(items);
  }

  const onItemPickerComplete=(newItems:Item[])=>{
    newItems=_.differenceBy(newItems,allItems,'id');
    newItems=[...allItems,...newItems];
    helpersItems.setValue(newItems);
    closeDrawer();
  }

  const closeDrawer = (): void => {
      dispatch(closeSidebar());
    };
  
    const getItemPrice=(e:Item)=>{
        if(e.variants?.length > 0){
          return _.first(e.variants[0].prices).price;
        }
        else if (e.prices?.length > 0){
          return _.first(e.prices).price;
        }
    }

  return (
    <Card>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={2}
      >
        <Box>
          
          <Typography variant="h4">{t('Menu Items')}</Typography>
        </Box>
        <Tooltip title="Item count" placement='top'>
        <AvatarGradient>{allItems.length}</AvatarGradient>
        </Tooltip>
      </Box>
      <Divider />
      <Box
      py={1}
      px={2}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        background: `${theme.colors.alpha.black[5]}`
      }}
    >
      <Button  onClick={(e)=>removeAll()}  size="small" color="error" >
        {t('Remove all')}
      </Button>
      <Button size="small" color="info" onClick={()=>dispatch(openSidebar())}>
        {t('Select More...')}
      </Button>
    </Box>
      <Divider />
      <Box>
        <FormControl variant="outlined" fullWidth>
          <OutlinedInputWrapper
            type="text"
            onChange={(e)=>{
              setSearchValue(e.target.value);
              applyFilter(e.target.value);
            }}
            placeholder={t('Search category or item name, code...')}
            startAdornment={
              <InputAdornment position="start">
                <SearchTwoToneIcon />
              </InputAdornment>
            }
            value={searchValue}
          />
        </FormControl>
      </Box>
      <Divider />
      
<Box
  sx={{
    height: 600,
  }}
>
<Scrollbar>
<ListWrapper disablePadding>
            {/* Render each item here */}
            {filteredItems.map((item) => <Fragment key={item.id}><ListItem
              sx={{
                '&:hover': {
                  background: `${theme.colors.alpha.black[5]}`,
                },
              }}
              secondaryAction={
                <Button
                  size="small"
                  variant="text"
                  color="error"
                  startIcon={<RemoveTwoToneIcon />}
                  onClick={()=>removeItem(item.id)}
                  sx={{
                    alignSelf: 'center',
                    padding: `${theme.spacing(0.5, 1.6, 0.5, 1.2)}`,
                    backgroundColor: `${theme.colors.secondary.lighter}`,
                    textTransform: 'uppercase',
                    fontSize: `${theme.typography.pxToRem(11)}`,
                    '&:hover': {
                      backgroundColor: `${theme.colors.secondary.main}`,
                      color: `${theme.palette.getContrastText(
                        theme.colors.secondary.main
                      )}`,
                    },
                  }}
                  // Disable the button if the item is already in the addedItems list
                >
                  {t('Remove')}
                </Button>
              }
            >
              {/* Render the item details */}
              <ListItemAvatar
                sx={{
                  mr: 1,
                }}
              >
                <Avatar
                  sx={{
                    width: 50,
                    height: 50,
                  }}
                 
                  src={cdnService.toImageUrl(item.featureImage)}
                />
              </ListItemAvatar>
              <ListItemText
                sx={{
                  flexGrow: 0,
                  maxWidth: '50%',
                  flexBasis: '50%',
                }}
                disableTypography
                primary={
                  <Typography
                    sx={{
                      pb: 0.6,
                    }}
                    color="text.primary"
                    variant="h5"
                  >
                    {item.name}
                  </Typography>
                }

                secondary={
                  <>
                    <Box display="flex" alignItems="flex-start">
                    <Typography
                        sx={{
                          fontSize: `${theme.typography.pxToRem(11)}`,
                          lineHeight: 1
                        }}
                        variant="body1"
                      >
                        <Text color="secondary">{t(_.first(item.categories)?.name)}</Text>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: `${theme.typography.pxToRem(11)}`,
                          lineHeight: 1,
                          ml:1

                        }}
                        variant="body1"
                      >
                         <Text color="success">{t('$'+getItemPrice(item)+'')}</Text>
                      </Typography>
                    </Box>
                  </>
                }
                
              />
            </ListItem>
            <Divider />
            </Fragment>)}
    </ListWrapper>
  </Scrollbar>
</Box>
      <Drawer
        variant="temporary"
        anchor={theme.direction === 'rtl' ? 'left' : 'right'}
        onClose={closeDrawer}
        open={sidebarOpen}
        elevation={9}
      >
        {sidebarOpen && (
          <ItemPickerDrawer
            preSelectedIds={allItems.map(o=>o.id)}
            onComplete={onItemPickerComplete}
            onCancel={closeDrawer}
          />
        )}
      </Drawer>
    </Card>
  );
}

export default ItemListCard;
