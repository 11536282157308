import {
    Card,
    CardHeader,
    Typography,
    CardActionArea,
    Link,
    Button,
    CardMedia,
    Box,
    alpha,
    useTheme,
    styled,
    IconButton,
    Divider,
    darken,
    Tooltip
  } from '@mui/material';
  
  import { useTranslation } from 'react-i18next';
  import { Swiper, SwiperSlide } from 'swiper/react';
  import { Pagination, Navigation } from 'swiper';
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
  import RectangleTwoToneIcon from '@mui/icons-material/RectangleTwoTone';
  import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
  import EditIcon from '@mui/icons-material/EditTwoTone';
  import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
  import ChevronLeftTwoToneIcon from '@mui/icons-material/ChevronLeftTwoTone';
import { DineLayoutSection } from 'src/services/apiService/response-models';
import { useState } from 'react';
import DeleteConfirm from 'src/components/delete-confirm';
import { deleteDineTableById } from 'src/services/apiService';
import { useSnackbar } from 'notistack';
  
  const SwipeIndicator = styled(IconButton)(
    ({ theme }) => `
          color: ${theme.colors.primary.main};
          width: ${theme.spacing(5)};
          height: ${theme.spacing(5)};
          border-radius: 100px;
          transition: ${theme.transitions.create(['all'])};
  
          &:hover {
            color: ${theme.colors.alpha.black[100]};
            background: ${theme.colors.primary.lighter};
          }
  `
  );
  
  const CardActions = styled(Box)(
    ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(2)};
    bottom: ${theme.spacing(2)};
    z-index: 7;
    display: flex;
  `
  );
  
  const LabelWrapper = styled(Box)(
    ({ theme }) => `
    background: ${theme.palette.info.main};
    color: ${theme.palette.info.contrastText};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(10)};
    font-weight: bold;
    line-height: 23px;
    height: 22px;
    padding: ${theme.spacing(0, 1.2)};
    border-radius: 50px;
  `
  );
  
  const CardActionAreaWrapper = styled(CardActionArea)(
    () => `
    display: flex;
    align-items: center;
    position: relative;
  
    .MuiTouchRipple-root {
        opacity: .3;
    }
  
    &:hover {
        .MuiCardActionArea-focusHighlight {
            opacity: .05;
        }
    }
  `
  );
  
const BgComposed = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: ${theme.transitions.create(['opacity'])};
    background: ${darken(alpha(theme.colors.primary.main, 0.9), 0.8)};
    z-index: 6;
    opacity: 0;
    box-shadow: inset 0 0 2.3rem 0.5rem ${darken(
      theme.colors.primary.main,
      0.9
    )};
  `
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};
        transition: ${theme.transitions.create(['color', 'transform'])};
        border-radius: 100px;

        .MuiSvgIcon-root {
            transform: scale(1);
            transition: ${theme.transitions.create(['transform'])};
        }

        &:hover {
            .MuiSvgIcon-root {
                transform: scale(1.3);
            }
        }
  `
);

  function TableSectionCard(props:{dineSections:DineLayoutSection[],onEdit:(sectionId:string,tableId:string)=>void,onDelete:(sectionId:string,tableId:string)=>void}) {
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [idRequestedToDelete, setIdRequestedToDelete] = useState<{sectionId:string,tableId:string}>(null);
    
    const handleConfirmDelete=async()=>{

      try{
        const success = await deleteDineTableById(idRequestedToDelete.sectionId,idRequestedToDelete.tableId);
        if(success){
          props.onDelete(idRequestedToDelete.sectionId,idRequestedToDelete.tableId);
        }
      } catch (err) {
        console.error(err);
        enqueueSnackbar(t('failed to delete'), { variant: 'error' });
      }
      setIdRequestedToDelete(null);
    }

    return (<>
     {props.dineSections.length==0 && <Card sx={{mt:5}}>
      <Box display="flex" alignItems="center">
      <Typography
                    sx={{
                        p: 5
                    }}
                    variant="h3"
                    fontWeight="normal"
                    color="text.secondary"
                    align="center"
                >
                    {t("No record to display")}
                </Typography>
      </Box>
      </Card>}
     {props.dineSections.map(section=> <Card key={section.id} sx={{mb:3}}>
        <CardHeader
          action={
            <>
              <SwipeIndicator className="MuiSwipe-root MuiSwipe-left">
                <ChevronLeftTwoToneIcon />
              </SwipeIndicator>
              <SwipeIndicator className="MuiSwipe-root MuiSwipe-right">
                <ChevronRightTwoToneIcon />
              </SwipeIndicator>
            </>
          }
          title={t(section.name)}
          subheader={t(section.description)}
        />
        <Divider />
        <Box
          px={3}
          pb={2}
          sx={{
            '.swiper-pagination-bullets': {
              bottom: '0 !important'
            }
          }}
        >
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            navigation={{
              nextEl: '.MuiSwipe-right',
              prevEl: '.MuiSwipe-left'
            }}
            breakpoints={{
              500: {
                slidesPerView: 2,
                spaceBetween: 12
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 12
              },
              1200: {
                slidesPerView: 6,
                spaceBetween: 24
              }
            }}
            modules={[Navigation, Pagination]}
            pagination={{ dynamicBullets: true, clickable: true }}
          >
           { section.tables.map(table=> <SwiperSlide key={table.tableName}>
              <Card
                sx={{
                  mt: 2,
                  textAlign: 'center',
                  transition: `${theme.transitions.create([
                    'box-shadow',
                    'transform'
                  ])}`,
                  transform: 'translateY(0px)',
  
                  '&:hover': {
                    transform: `translateY(-${theme.spacing(0.5)})`,
                    boxShadow: `0 2rem 8rem 0 ${alpha(
                      theme.colors.alpha.black[100],
                      0.05
                    )}, 
                      0 0.6rem 1.6rem ${alpha(theme.colors.alpha.black[100], 0.15)}, 
                      0 0.2rem 0.2rem ${alpha(theme.colors.alpha.black[100], 0.1)}`,
      
                    '& .MuiBgComposed': {
                      opacity: .8
                    }
                  }
                }}
              >
                   <BgComposed
            display="flex"
            alignItems="center"
            justifyContent="center"
            className="MuiBgComposed"
          >
           <Tooltip title="Edit" placement='top'>  
           <IconButtonWrapper
              onClick={()=>props.onEdit(section.id,table.id)}
              sx={{
                mx: 0.5,
                background: `${theme.colors.primary.main}`,
                color: `${theme.palette.getContrastText(
                  theme.colors.primary.main
                )}`,

                '&:hover': {
                  background: `${theme.colors.primary.main}`,
                  color: `${theme.palette.getContrastText(
                    theme.colors.primary.main
                  )}`
                }
              }}
            >
              <EditIcon fontSize="small" />
            </IconButtonWrapper></Tooltip>
            <Tooltip title="Delete" placement='top'>
            <IconButtonWrapper
              onClick={()=>setIdRequestedToDelete({sectionId:section.id,tableId:table.id})}
              sx={{
                mx: 0.5,
                background: `${theme.colors.error.main}`,
                color: `${theme.palette.getContrastText(
                  theme.colors.error.main
                )}`,

                '&:hover': {
                  background: `${theme.colors.error.main}`,
                  color: `${theme.palette.getContrastText(
                    theme.colors.error.main
                  )}`
                }
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButtonWrapper></Tooltip>
          </BgComposed>
                <CardActionAreaWrapper>
                  {/* <CardMedia
                    component="img"
                    height="130"
                    width="130"
                    image="/static/images/placeholders/fitness/1.jpg"
                    alt="..."
                  /> */}
                   <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            opacity: 0.4
          }}
          color="text.secondary"
        >
          <RectangleTwoToneIcon
            sx={{
              fontSize: 54,
              my:2
            }}
          />
        </Typography>
                  {/* <CardActions>
                    <LabelWrapper>{t('Updated')}</LabelWrapper>
                  </CardActions> */}
                </CardActionAreaWrapper>
              </Card>
              <Box
                sx={{
                  px: { md: 2, lg: 1.5, xl: 3 },
                  pt: 2,
                  textAlign: 'center'
                }}
              >
                <Link
                  lineHeight={1.5}
                  href="#"
                  sx={{
                    transition: `${theme.transitions.create(['color'])}`,
                    color: `${theme.colors.alpha.black[100]}`,
  
                    '&:hover': {
                      color: `${theme.colors.primary.main}`
                    }
                  }}
                  color="text.primary"
                  variant="h3"
                  underline="none"
                >
                  {t(table.tableName)}
                </Link>
                <Typography
                  variant="subtitle2"
                  sx={{
                    pb: 2
                  }}
                >
                  {t(table.tableShape + ' ('+table.capacity+' Persons)')}
                </Typography>
              </Box>
            </SwiperSlide>)}
          </Swiper>
        </Box>
      </Card>
     )}
     <DeleteConfirm open={idRequestedToDelete!=null} onClose={()=>setIdRequestedToDelete(null)} onDeleteConfirm={handleConfirmDelete} />
      </>
    );
  }
  
  export default TableSectionCard;
  