import {
  Grid,
  Card,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Field, FieldProps } from 'formik';
import { TextField as FmTextFiled } from 'formik-mui';
import EditorWrapper from 'src/components/StyledWrappers/EditorWrapper';

interface GeneralSectionProps {
  name: string;
  description: string;
}

function GeneralSection({ name, description }: GeneralSectionProps) {
  const { t }: { t: any } = useTranslation();
console.log(name);

  return (
    <Card
      sx={{
        p: 3,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field
            fullWidth
            name="name"
            component={FmTextFiled}
            label="Item name *"
            placeholder={t('Item name here...')}
            value={name} // Pass the name prop here
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            fullWidth
            name="code"
            component={FmTextFiled}
            label="Item code"
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12}>
          <Field name="description">
            {({ field, form }: FieldProps) => (
              <EditorWrapper>
                <ReactQuill
                  placeholder="Item Description"
                  value={field.value || description} // Pass the description prop here
                  onChange={(value) => form.setFieldValue(field.name, value)}
                />
              </EditorWrapper>
            )}
          </Field>
        </Grid>
      </Grid>
    </Card>
  );
}

export default GeneralSection;
