import { Navigate, RouteObject } from 'react-router-dom';

import AccentSidebarLayout from 'src/layouts/AccentSidebarLayout';
import Status404 from 'src/content/shared/pages/Status/Status404';
import Status500 from 'src/content/shared/pages/Status/Status500';
import StatusComingSoon from 'src/content/shared/pages/Status/ComingSoon';
import StatusMaintenance from 'src/content/shared/pages/Status/Maintenance';
import AuthCallback from 'src/content/shared/auth-callback';
import PosRutes from './pos-routes';
import { PortalNames } from 'src/store/app-state';
import GolfCourseRutes from './golf-course-routes';

const router: RouteObject[] = [
  {
    path:'/',
    element: (
        <AccentSidebarLayout />
    ),
    children:[
      {
        path: '/',
        element: <Navigate to="/pos-admin" replace />
      },
      {
        path:'auth/callback',
        element:<AuthCallback/>
      },
      {
        path: 'status',
        children: [
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: PortalNames.PosAdmin,
        children:PosRutes,
      },
      {
        path: PortalNames.GolfCourse,
        children:GolfCourseRutes,
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  }
 
];

export default router;
