import { Box, Grid, IconButton, LinearProgress, styled } from "@mui/material";
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import OrderBody from "./order-body";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { OrderMain } from "src/services/apiService/response-models";
import { useNavigate, useParams } from "react-router-dom";
import { getOrderById, getOrderStagesByOrderId } from "src/services/apiService";
import OrderStageCard from "./order-stage-card";
import PageHeader from "./PageHeader";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import Footer from "src/components/Footer";

const MainContentWrapper = styled(Box)(
    () => `
  flex-grow: 1;
`
);

function OrderDetail(){
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [orderStages, setOrderStages] = useState<OrderMain>();
    const [isSaveInProgress, setIsSaveInProgress] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Set loading to true initially
    const [errorMsg, setErrorMsg] = useState('');
    const [model, setModel] = useState<OrderMain>();
    const { id } = useParams();

    const fetchData = async () => {
        try {
            const [orderStagesResponse, orderResponse] = await Promise.all([
                // getOrderById(id),
                getOrderStagesByOrderId(id),
                getOrderById(id),
            ]);

            setModel(orderResponse.data);
            setOrderStages(orderStagesResponse.data);
        } catch (ex) {
            console.error(ex);
            const msg = ex.message ? ex.message : `Failed to load order by id`;
            enqueueSnackbar(t(msg), { variant: 'error' });
            navigate("/orders/today/list", { replace: true });
        } finally {
            setIsLoading(false); // Set loading to false after API calls are complete
        }
    };

    useEffect(() => {
        fetchData(); // Call the fetchData function when the component mounts
    }, [id]); // Include id in the dependency array to refetch data when id changes

    return (
        <>
         <PageTitleWrapper>
            <PageHeader orderNumber={model?.orderNumber}  ></PageHeader>
        </PageTitleWrapper>
        <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
            {isLoading ? <LinearProgress /> : null}
            {model && <OrderBody order={model} /> }
        </Grid>
      </Grid>
      <Footer />
         {false &&  <Box mb={3} display="flex">
                <MainContentWrapper>
                    <Grid
                        sx={{
                            px: 4
                        }}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={4}
                    >
                        <Grid item xs={12}>
                            <Box
                                mt={3}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                {/* <PageHeader isEdit={isEdit} /> */}
                             
                               
                            </Box>
                        </Grid>
                        <Grid item xs={8}>
                        {isLoading ? <LinearProgress /> : null}
                       {model && <OrderBody order={model} /> }
                        </Grid>
                        <Grid item xs={4}>
                       <OrderStageCard data={orderStages} />
                       </Grid>
                    </Grid>
                </MainContentWrapper>
            </Box>}
        </>
    );
}

export default OrderDetail;
