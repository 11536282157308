import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
    TextField,
    Autocomplete,
    Box,
    Card,
    Grid,
    styled,
    Button,
    Switch,
    FormControlLabel,
    CircularProgress,
    Skeleton
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import SaveIcon from '@mui/icons-material/Save';
import { getCategoryById, parseToApiErrorMessage, saveCategory } from "src/services/apiService";
import {  Category } from '../../../../services/apiService/response-models';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import EditorWrapper from '../../../../components/StyledWrappers/EditorWrapper';
import ImageDropZone from 'src/components/image-dropzone';
export default function CategoryEditor(props: { id?: string,onCancel:()=>void, onSave: (e: Category) => void }) {
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [isSaveInProgress, setIsSaveInProgress] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [model, setModel] = useState<Category | null>(null);
    const [isEdit, setIsEdit] = useState(false);
   // const [iconNames, setIconNames] = useState(['grs','mjn']);
   // const [inputTextIcon, setInputTextIcon] = useState('');
       
   const getById = async (id: string) => {
    try {
        const response = await getCategoryById(id);
        setIsEdit(true);
        setModel(response.data);
    } catch (ex) {
        console.error(ex);
        const msg = ex.message ? ex.message : `Failed to load data by id`;
        enqueueSnackbar(t(msg), { variant: 'error' });
        setErrorMsg(msg);
    }
};

useEffect(() => {
    if (props.id?.length) {
        setIsLoading(true);
        getById(props.id).then(() => {
            setIsLoading(false);
        });
    }else{
        setModel({
            name:'',
            description:'',
            featureImage:''
        });
    }
}, []);

    if(isLoading){
       return (<Grid container>
<Grid item xs={12}>
       <Card
            sx={{
                p: 3
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Skeleton variant="rectangular" height={60} />
                </Grid>
                <Grid item xs={12}>
                    <Skeleton variant="rectangular" height={60} />
                </Grid>
                <Grid item xs={12}>
                    <Skeleton variant="rectangular" width="40%" />
                </Grid>
                <Grid item xs={12}>
                    <Skeleton variant="rectangular" width="20%" />
                </Grid>
            </Grid>
        </Card>
        </Grid>
       </Grid>) 
    }
    return (
        <Card
            sx={{
                p: 3
            }}
        >
            <Formik
                initialValues={{
                    name: model?.name || '',
                    description: model?.description || '',
                    featureImage:  model?.featureImage || ''
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string()
                        .max(100)
                        .required(t('The name field is required')),
                    description: Yup.string()
                        .max(500),
                    featureImage: Yup.string()
                        .required(t('Category image is required'))
                })}
                
                onSubmit={async (
                    _values,
                    { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                    try {
                        setIsSaveInProgress(true);
                        const res = await saveCategory({...model,..._values});
                        if (res) {
                            resetForm();
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                        enqueueSnackbar(t(`Category was ${!isEdit ? 'created' : 'updated'} successfully`), { variant: 'success' });
                        props.onSave(res.data);
                    } catch (err) {
                        console.error(err);
                        enqueueSnackbar(t(parseToApiErrorMessage(err,'failed to save')), { variant: 'error' });
                        setStatus({ success: false });
                        // setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                    setIsSaveInProgress(false);
                }}
            >{({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue
            }) => (

                <form onSubmit={handleSubmit} noValidate onKeyDown={(e)=>e.stopPropagation()}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="name"
                                    placeholder={t('Enter category name...')}
                                variant="outlined"
                                    id="name" label="Item Category"
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <EditorWrapper>
                                <ReactQuill
                                        placeholder="Category description..."
                                    id="description"
                                    value={values.description}
                                    onChange={(o) => setFieldValue('description', o)}
                                />
                            </EditorWrapper>
                        </Grid>
                        <Grid item xs={12} p={'0!important'}>
                       <div style={{maxWidth:'300px'}}>
                            <ImageDropZone label="Feature Image" required={true} 
                                uploadedFileName={values.featureImage}  
                                onChange={(e)=>{
                                        setFieldValue('featureImage',e);
                                }} isSubmit={errors.featureImage && touched.featureImage ?true:false}/>
                        </div>
                        </Grid>
                            <Grid item xs={12}>
                                <Button sx={{ mr: 2 }} type="button" onClick={props.onCancel} variant="text">
                                    Cancel</Button>
                            <Button type="submit" 
                            variant="contained"
                                startIcon={
                                    isSubmitting || isSaveInProgress ? <CircularProgress size="1rem" /> : <SaveIcon />
                                }
                                disabled={isSubmitting || isSaveInProgress || Boolean(errorMsg.length)}>
                                Save</Button>
                        </Grid>
                    </Grid>
                </form>
            )}
            </Formik>
        </Card>
    );
}

