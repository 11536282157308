import { Typography, Button, Grid } from '@mui/material';

function PageHeader() {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item sm={8} style={{padding: 3}}>
        <Grid container style={{background: '#C8FACD', borderRadius: 10, height: '200px', padding: 20, flex: 1, alignContent: "center"}}>
            <Grid item sm={6}>
                <Typography variant='h2' fontWeight={'bold'}>Welcome back<br /> Steven Smith !</Typography>
                <Typography variant='subtitle1'>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything</Typography>
            </Grid>
            <Grid item sm={6} >
                <img style={{objectFit: "contain", height: 150, width: "100%"}} src='/static/images/Dashboard/header1.png' />
            </Grid>
        </Grid>
      </Grid>
      <Grid item sm={4} style={{backgroundImage: 'url("/static/images/Dashboard/food.jpg")', backgroundSize: "contain", borderRadius: 10}}>
        <Grid container style={{borderRadius: 10, height: '200px', flex: 1, alignContent: "flex-end", padding: 20, backgroundColor: 'rgba(0, 0, 0, 0.82)'}}>
            <Typography variant='h5' color={'white'}>Try Kiengange's Ki-Menu APP</Typography>
            <Typography variant='subtitle1' color={'white'}>Get fit with KiMenu's food varients routiners.</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
