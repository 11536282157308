import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Drawer, Grid, useTheme, IconButton, styled, Skeleton, Card, Button, CircularProgress, TextField, Autocomplete, CardHeader } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Field, Formik } from 'formik';
import { getItemVariantById, getItemVariants, parseToApiErrorMessage, saveItemVariant, } from 'src/services/apiService';
import { ItemVariantMain } from 'src/services/apiService/response-models';
import { TextField as FmTextFiled } from 'formik-mui';
import * as Yup from 'yup';
import EditorWrapper from 'src/components/StyledWrappers/EditorWrapper';
import ReactQuill from 'react-quill';
import SaveIcon from '@mui/icons-material/Save';

function VariantCreate(props: { selectedId: string , flag}) {
    const { selectedId } = props;
    const theme = useTheme();
    const navigate = useNavigate();
    const { id } = useParams();

    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [model, setModel] = useState<ItemVariantMain | null>(null);
    const [isSaveInProgress, setIsSaveInProgress] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);
    const [featureImage, setFeatureImage] = useState('');
    const [flag, setFlag] = useState(0);
    const [isEdit, setIsEdit] = useState(false);

    const getById = async (id: string) => {
        try {
            const response = await getItemVariantById(id);
            setIsEdit(true);
            setModel(response.data);
        } catch (ex) {
            console.error(ex);
            const msg = ex.message ? ex.message : `Failed to load variant by id`;
            enqueueSnackbar(t(msg), { variant: 'error' });
            goBack();
        }
    };

    const goBack=()=>{
        navigate(-1)
    }

    useEffect(() => {
        if (selectedId && selectedId.length > 0) {
            setIsLoading(true);
            getById(selectedId).then(() => {
                setIsLoading(false);
            });
        }
    }, [selectedId]);

    const onSave = async (updatedModel: ItemVariantMain) => {
        let isSuccess = false;
        props.flag(0)
        try {
          setIsSaveInProgress(true);
          console.log(updatedModel);
          await saveItemVariant(updatedModel);
          enqueueSnackbar(t(`Variant was ${!isEdit ? 'created' : 'updated'} successfully`), { variant: 'success' });
          isSuccess = true;
          setIsSaveInProgress(false);
          props.flag(1)
        } catch (ex) {
          setIsSaveInProgress(false);
          console.log(ex);
          const msg = parseToApiErrorMessage(ex, `Failed to ${!isEdit ? 'created' : 'updated'} Variant`);
          enqueueSnackbar(t(msg), { variant: 'error' });
          props.flag(1)
        }
        props.flag(1)
        return isSuccess; 
      };
    return (
        <>
            <Grid
                sx={{

                }}
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={4}
            >

                <Grid item xs={12}>
                    {isLoading && <Card
                        sx={{
                            p: 3
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Skeleton variant="rectangular" height={60} />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton variant="rectangular" width="40%" />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton variant="rectangular" width="20%" />
                            </Grid>
                        </Grid>
                    </Card>}
                    {!isLoading &&
                        <Card
                            sx={{
                                p: 3, pt: 1
                            }}
                        >
                            <CardHeader
                                subheader={t(isEdit ? '(Edit)' : '(Add)')}
                                title={t('Item Variant')}>

                            </CardHeader>


                            <Formik
                                initialValues={{
                                    type: model?.type || '',
                                    description: model?.description || '',
                                    names: model?.names || [],
                                }}
                                validationSchema={Yup.object().shape({
                                    type: Yup.string()
                                        .max(250)
                                        .required(t('The type field is required')),
                                    names: Yup.array().min(1, 'At least one name is required')
                                })}
                                onSubmit={async (
                                    _values,
                                    { resetForm, setErrors, setStatus, setSubmitting }
                                ) => {
                                    try {
                                        var isSuccess = await onSave({ ...model, ..._values });
                                        if (isSuccess) {
                                            resetForm();
                                            setStatus({ success: true });
                                            setSubmitting(false);
                                        }
                                    } catch (err) {
                                        console.error(err);
                                        setStatus({ success: false });
                                        // setErrors({ submit: err.message });
                                        setSubmitting(false);
                                    }
                                }}
                            >{({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values,
                                setFieldValue
                            }) => (

                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Field
                                                fullWidth
                                                name="type"
                                                component={FmTextFiled}
                                                label="Variant type *"
                                                placeholder={t('enter variant name here...')}
                                                helperText={t('E.g. Size, Color ')}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <EditorWrapper>
                                                <ReactQuill
                                                    placeholder="Variant description..."
                                                    id="description"
                                                    value={values.description}
                                                    onChange={(o) => setFieldValue('description', o)}
                                                />
                                            </EditorWrapper>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Autocomplete
                                                fullWidth
                                                blurOnSelect={true}
                                                multiple
                                                id="variant-names-auto-comp"
                                                freeSolo
                                                autoSelect
                                                value={values.names}
                                                options={(values.names || [])}
                                                onChange={(event: any, newValues) => {
                                                    if (newValues) {
                                                        setFieldValue('names', newValues);
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Enter variant names..."
                                                        helperText={errors.names || "E.g. Small, Medium, Large"}
                                                        error={Boolean(errors.names)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'search',
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button onClick={goBack} sx={{ mr: 2 }} type="button" variant="text">
                                                Cancel</Button>
                                            <Button type="submit" variant="contained"
                                                startIcon={
                                                    isSubmitting || isSaveInProgress ? <CircularProgress size="1rem" /> : <SaveIcon />
                                                }
                                                disabled={isSubmitting || isSaveInProgress}>
                                                Save</Button>
                                        </Grid>
                                    </Grid> </form>
                            )}
                            </Formik>
                        </Card>
                    }
                </Grid>
                <Grid item xs={12} md={6}>
                </Grid>

                <Grid item xs={12}
                    sx={{
                        display: { xs: "none", md: "block" }
                    }}>
                </Grid>
            </Grid>
        </>
    );
}

export default VariantCreate;
