import * as React from 'react';
import {
  Box,
  Grid,
  Divider,
  styled
} from '@mui/material';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from './page-header';
import Footer from 'src/components/Footer';
import SaleDetailCard from './sale-detail-card';
import PaymentInfotmation from './payment-information';
import ReceiptDelivery from './receipt-delivery';
import CompletePayment from './complete-payment';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import MenuItem from './menu-item';

const RootWrapper = styled(Box)(() => `
  background: #FFFFFF;
`);

function VirtualTerminal() {
  const { t }: { t: any } = useTranslation();
  const [isCheckMoto, setIsCheckMoto] = React.useState(false)
  const [searchText, setSearchText] = React.useState('')

  const [receiptValue, setReceiptVvalue] = React.useState('none');

  const handleChangeReceiptDelivery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReceiptVvalue((event.target as HTMLInputElement).value);
  };

  return (
    <RootWrapper>
      <PageTitleWrapper>
        <PageHeader title="Virtual Terminal (POS)" subtitle="" isCart={true} />
      </PageTitleWrapper>
      <MenuItem />
      {/* <Formik
        initialValues={{
          subtotal: '',
          tax: '',
          note: '',
          tip: '',
          isMoto: isCheckMoto,
          customer: '',
          cardNumber: '',
          cardHolderName: '',
          expiration: '',
          cvv: '',
          postalCode: '',
          address: '',
          receiptDelivery: '',
        }}
        validationSchema={Yup.object().shape({
          subtotal: Yup.string().required(t('Subtotal is required')),
          tax: Yup.string().required(t('Tax is required')),
          note: Yup.string(),
          tip: Yup.string(),
          customer: Yup.string(),
          cardNumber: Yup.string()
            .matches(/^[0-9]{12,19}$/, 'Card number must be between 12 to 19 digits')
            .required('Card number is required'),
          cardHolderName: Yup.string().required('Card holder name is required'),
          expiration: Yup.string().required('Expiration date is required'),
          cvv: Yup.string()
            .matches(/^[0-9]{3}$/, 'CVV must be a 3-digit number')
            .required('CVV is required'),
          postalCode: Yup.string().required('Zip / Postal Code is required'),
          address: Yup.string().max(127, 'Address must be 127 characters or less').required('Card holder street address is required'),
        })}
        onSubmit={(value) => {
          console.log("value", value);
        }}
      >{({
        handleSubmit, errors, touched, values, handleChange, handleBlur
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid
            sx={{
              px: 4
            }}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={4}
          >
            <Grid item xs={12}>
              <SaleDetailCard validationErrors={errors} touchedError={touched} values={values} setIsCheckMoto={setIsCheckMoto} />
              <PaymentInfotmation validationErrors={errors} touchedError={touched} inputValues={values} handleChange={handleChange} handleBlur={handleBlur} searchText={searchText} setSearchText={setSearchText} />
              <ReceiptDelivery receiptValue={receiptValue} handleChangeReceiptDelivery={handleChangeReceiptDelivery} />
            </Grid>
          </Grid>
          <Divider />
          <Grid
            sx={{
              px: 4
            }}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
          >
            <Grid item xs={12}>
              <CompletePayment />
            </Grid>
          </Grid>
        </form>
      )}
      </Formik> */}
      <Footer />
    </RootWrapper>
  );
}

export default VirtualTerminal;
